import React, { useState, useEffect }from 'react';
import { TailSpin } from  'react-loader-spinner'
import axios from 'axios';
import SftGlobalData from '../../library/SftGlobalData';
import Colors from '../../library/Colors';
import Button from '@mui/material/Button';

import 'react-toastify/dist/ReactToastify.css';


const LicenceLogoForm = (props) =>  {
    const [loading, setLoading] = useState(true);
    const [image, setImage] = useState("");
    const [imageTicket, setImageTicket] = useState("");


    useEffect (()=>{
        try{
            let url = SftGlobalData.baseURL_API+'client&action=fetchLogo';
            let postData = {key: props.api}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    setImage(response.data);
                    setLoading(false);
                });
            let url2 = SftGlobalData.baseURL_API+'client&action=fetchLogoTicket';
            let postData2 = {key: props.api}
            axios.post(url2, postData2, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response2 => {
                    if(SftGlobalData.debug){
                        console.log('url', url2);
                        console.log('postData', postData2);
                        console.log('resp', response2);
                    }
                    setImageTicket(response2.data);
                    setLoading(false);
                });
        } catch(e){
            console.error(e);
        }
    }, [props.api]);

    const onSave = async () => {
        try{
            let url = SftGlobalData.baseURL_API+'client&action=updateLogo';
            let postData = {
                key: props.api,
                logo: image, user: props.username, sftUserId: props.userId
            }
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    // TODO: Notify success
                });
        } catch(e){
            console.error(e);
            // TODO: Error notify, bad request.
        }
    }

    const onSaveTicket = async () => {
        try{
            let url = SftGlobalData.baseURL_API+'client&action=updateLogoTicket';
            let postData = {
                key: props.api,
                logo: imageTicket, user: props.username, sftUserId: props.userId
            }
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    // TODO: Notify success
                });
        } catch(e){
            console.error(e);
            // TODO: Error notify, bad request.
        }
    }

    function onFileChange(e) {
        let files = e.target.files;
        let fileReader = new FileReader();
        fileReader.readAsDataURL(files[0]);
 
        fileReader.onload = (event) => {
            setImage(event.target.result);
        }
    }

    function onTicketFileChange(e) {
        let files = e.target.files;
        let fileReader = new FileReader();
        fileReader.readAsDataURL(files[0]);
 
        fileReader.onload = (event) => {
            setImageTicket(event.target.result);
        }
    }

    return (
        <div className={props.theme + " _100perc"} style={{display:"flex", justifyContent: "space-evenly"}}>
            {loading && <div style={{display:"flex", justifyContent: "space-evenly", marginTop: 5}}>
                    <TailSpin 
                        height="40"
                        width="40"
                        color={Colors.sft_blue}
                        ariaLabel='loading'
                    />
                </div>}
            {!loading && <div className={props.theme + " _40perc"}>
                <p className={"primary_border_bottom text_center margin_top_none margin_bottom_15px bold "} style={{fontWeight: 1200}}>Logo</p>
                <div>
                    <div className='flex_center_column'>
                        <img alt="Logo" src={image} style={{width: 300, height: 100, objectFit: 'contain'}}/>
                    </div>
                    <br/>
                    <label className="text-white">Select File</label>
                    <input type="file" className="form-control" name="image" onChange={onFileChange} />
                    <Button className={"modal_button_container_no_margin__button margin_top_15px _100perc"} onClick={()=>{onSave();}}>{props.t('save')}</Button>
                </div>
            </div>}

            {!loading && <div className={props.theme + " _40perc"}>
                <p className={"primary_border_bottom text_center margin_top_none margin_bottom_15px bold "} style={{fontWeight: 1200}}>Ticket logo</p>
                <div>
                    <div className='flex_center_column'>
                        <img alt="Ticket logo" src={imageTicket} style={{width: 300, height: 100, objectFit: 'contain'}}/>
                    </div>
                    <br/>
                    <label className="text-white">Select File</label>
                    <input type="file" className="form-control" name="image" onChange={onTicketFileChange} />
                    <Button className={"modal_button_container_no_margin__button margin_top_15px _100perc"} onClick={()=>{onSaveTicket();}}>{props.t('save')}</Button>
                </div>
            </div>}
        </div>
        
    );
}

export default LicenceLogoForm;
