import React, { useState } from 'react';
import SftGlobalData from '../../library/SftGlobalData';
import axios from 'axios';
import { HexColorPicker } from "react-colorful";

const InputSetting = (props) => {
    const [value, setValue] = useState(props.defaultValue);
  
  const submitHandler = async (value) => {
    let url = SftGlobalData.baseURL_API_old + '?key='+props.api+'&edit=1&setting='+props.setting+"&value="+value+"&category="+props.category;
        if(props.groupId !== '0000'){
            url = SftGlobalData.baseURL_API_old + '?key='+props.api+'&edit=1&setting='+props.setting+"&value="+value+'&groupId='+props.groupId+"&category="+props.category;
        }
    axios.get(url)
        .then(response => {console.log(response)});
  }

  const change = (event) => {
        if(props.setting === "settSalesDefaultValue"){
            let valToSend = event.target.value;
            if(valToSend > 100){
                valToSend = "100";
            } else if(valToSend < 0 || valToSend === ""){
                valToSend = "0";
            }
            setValue(valToSend);
            submitHandler(valToSend.replace("#", ""));
        } else if(props.setting === "settSalesDefaultSalesLength"){
            let valToSend = event.target.value;
            if(valToSend > 100000){
                valToSend = "100000";
            } else if(valToSend < 0 || valToSend === ""){
                valToSend = "0";
            }
            setValue(valToSend);
            submitHandler(valToSend.replace("#", ""));
        } else {
            setValue(event.target.value);
            submitHandler(event.target.value.replace("#", ""));
        }
        
  }

  const changepickr = (event) => {
    setValue(event);
    submitHandler(event.replace("#", ""));
  }

    return (
        <div>
            <div className='_80perc align_left'>
                <h2>{props.title}</h2>
                <p>{props.description}</p>
            </div>
            <div className='right'>
                {props.category === "sales" && <input type={"number"} min={0} max={props.setting === "settSalesDefaultValue" ? 100 : 100000} className={(props.theme==='light') ? 'light_accent_darker _20perc margin_bottom_none' : 'dark_accent_darker _20perc margin_bottom_none'} onChange={change} value={value}/>}
                {props.category !== "sales" && <input type={"text"} className={(props.theme==='light') ? 'light_accent_darker _20perc margin_bottom_none' : 'dark_accent_darker _20perc margin_bottom_none'} onChange={change} value={(props.setting === "elementsColor" || props.setting === "elementsTextColor") ? value.toUpperCase() : value}/>}
                {props.colorpick ? <HexColorPicker className="colorpicker" color={value} onChange={changepickr} /> : ''}
            </div>
            {props.category === "sales" && <div><br/><br/><br/></div>}
            <hr className={'margin_top_40px' + (props.theme==='dark') ? 'light_text' : 'dark_text'}/>
        </div>
    );
}

export default InputSetting;
