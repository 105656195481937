import React, { useState, useEffect }from 'react';
import axios from 'axios';
import SftGlobalData from '../../library/SftGlobalData';
import 'react-toastify/dist/ReactToastify.css';
import { DataGridPro, GridActionsCellItem, useGridApiContext } from '@mui/x-data-grid-pro';
import DeleteIcon from '@mui/icons-material/Delete';
import Colors from '../../library/Colors';

function CustomEditComponent(props) {
    const { id, field, value } = props;
    const apiRef = useGridApiContext();
  
    const handleValueChange = (event) => {
        const newValue = event.target.value; // The new value entered by the user
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    const saveValueChange = () => {
        //console.log("orig", props.orig);
        //console.log("value", value);
        //if(props.orig !== value){
            if (window.confirm("Save changes?") === true) {
                try{
                    //console.log("props", props);
                    let url = SftGlobalData.baseURL_API+'permission&action=update';
                    let postData = {id: props.row.conn_id, value: value.replaceAll("\\", "\\\\'").replaceAll("'", "\\'")};
                    axios.post(url, postData, {
                        headers : {
                            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                        }})
                        .then(response => {
                            if(SftGlobalData.debug){
                                console.log('url', url);
                                console.log('postData', postData);
                                console.log('resp', response);
                            }
                            props.reload();
                        });
                } catch(e){
                    console.error(e);
                }
            } else {
                apiRef.current.setEditCellValue({ id, field, value: props.orig });
            }
        //}
    }

    const handleEnter = (e) => {
        if(e.keyCode === 13){
            saveValueChange();
        }
    }
  
    return <input type="text" value={value} onChange={handleValueChange} onKeyDown={(event)=>{handleEnter(event);}} onBlur={()=>{saveValueChange()}}/>;
  }

const LicenceConnectionsOverviewForm = (props) =>  {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [searchValue, setSearchValue] = useState("");

    useEffect (()=>{
        fetchData();
    }, [props.api]);

    const fetchData = () => {
        try{
            let url = SftGlobalData.baseURL_API+'permission&action=fetch';
            let postData = { key: props.api, limited: props?.limited };
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    setData(response.data);
                    setLoading(false);
                });
        } catch(e){
            console.error(e);
        }
    }

    const gridWebhookColumns = props?.limited ? [
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            align: "left",
        },
        {
            field: 'sql_user',
            headerName: 'SQL user',
            width: 150,
            align: "left",
        },
        {
            field: 'conn_reason',
            headerName: 'Reason',
            width: 500,
            align: "left",
        },
        {
            field: 'conn_valid_until',
            headerName: 'Valid until',
            width: 150,
            align: "left",
            editable: true,
            renderEditCell: (params)=>{return <CustomEditComponent reload={()=>{fetchData();}} orig={params.row.conn_valid_until} {...params}/>}
        },
        {
            field: 'delete',
            type: 'actions',
            minWidth: 40,
            maxWidth: 40,
            getActions: (params) => [
               <GridActionsCellItem
                icon={<DeleteIcon  sx={{ color: Colors.def_red }} />}
                label="Remove"
                onClick={()=>{if(window.confirm("Are you sure you want to delete this connection?") === true){onRemove(params.row.conn_id);}}}
              />
            ],
        }
      ] : [
        {
            field: 'conn_id',
            headerName: 'ID',
            width: 50,
            align: "left",
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            align: "left",
        },
        {
            field: 'sql_user',
            headerName: 'SQL user',
            width: 150,
            align: "left",
        },
        {
            field: 'conn_host',
            headerName: 'Host',
            width: 150,
            align: "left",
        },
        {
            field: 'conn_database',
            headerName: 'Database',
            width: 150,
            align: "left",
        },
        {
            field: 'conn_reason',
            headerName: 'Reason',
            width: 500,
            align: "left",
        },
        {
            field: 'conn_valid_until',
            headerName: 'Valid until',
            width: 200,
            align: "left",
            editable: true,
            renderEditCell: (params)=>{return <CustomEditComponent reload={()=>{fetchData();}} orig={params.row.conn_valid_until} {...params}/>}
        },
        {
            field: 'delete',
            type: 'actions',
            minWidth: 40,
            maxWidth: 40,
            getActions: (params) => [
               <GridActionsCellItem
                icon={<DeleteIcon  sx={{ color: Colors.def_red }} />}
                label="Remove"
                onClick={()=>{if(window.confirm("Are you sure you want to delete this connection?") === true){onRemove(params.row.conn_id);}}}
              />
            ],
        }
      ];

      const onRemove = async (row) => {
        setLoading(true);
        let url = SftGlobalData.baseURL_API + 'permission&action=delete';
        axios.post(url, {
            key: props.api,
            id: row
        }, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        })
        .then(response => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('response', response);
            }
            try{
                props.notify(response.data.message, 7500, "top-right", props.theme, 1); 
                fetchData();
            } catch(e){
                props.notify(response.data.message, 7500, "top-right", props.theme, 1); 
                setLoading(false);
            }

        });
    }

    function DataGrid() {
        return (
          <div className={props.theme} style={{width: '100%'}}>
            <input 
                type="text"
                name="search"
                className={props.theme+"_accent_darker "}
                style={{marginTop: 0 }}
                placeholder={props.t('search')}
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
            />
            
            <DataGridPro
                className={props.theme}
                columns={gridWebhookColumns}
                rows={data.filter(row => (row.lic_accounts_id.match(new RegExp(searchValue, "i")) || row.sql_user.match(new RegExp(searchValue, "i")) || row.user_id.match(new RegExp(searchValue, "i")) || row.name.match(new RegExp(searchValue, "i")) || row.conn_database.match(new RegExp(searchValue, "i")) || row.conn_host.match(new RegExp(searchValue, "i")) || row.conn_valid_until.match(new RegExp(searchValue, "i")) || row.conn_db_id.match(new RegExp(searchValue, "i")) || row.conn_client_id.match(new RegExp(searchValue, "i"))))
                .map((row, index) => {return {
                        id: row.conn_id,
                        lic_accounts_id: row.lic_accounts_id,
                        user_id: row.user_id,
                        softtouch: row.softtouch,
                        name: row.name,
                        sql_user: row.sql_user,
                        sql_duration: row.sql_duration,
                        sql_access_all: row.sql_access_all,
                        sql_max_connections: row.sql_max_connections,
                        conn_id: row.conn_id,
                        conn_host: row.conn_host,
                        conn_database: row.conn_database,
                        conn_db_id: row.conn_db_id,
                        conn_client_id: row.conn_client_id,
                        conn_reason: row.conn_reason,
                        conn_valid_until: row.conn_valid_until,
                        delete: ""
                    }
                })}
                rowHeight={38}
                style={{height: 400}}
                pagination={true}
                loading={loading}

                />   
          </div>
        );
    }


    return (
        <div>
            {DataGrid()}
        </div>
    );
}

export default LicenceConnectionsOverviewForm;
