import React, { useState, useEffect }from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { TailSpin } from  'react-loader-spinner'
import axios from 'axios';
import SftGlobalData from '../library/SftGlobalData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faExclamationTriangle, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import Colors from '../library/Colors';
import Button from '@mui/material/Button';

import { DataGridPro } from '@mui/x-data-grid-pro';
import FloatingAddButton from '../components/FloatingAddButton';

const ShippingLogScreen = (props) =>  {
    const params = useParams();
    const [loading, setLoading] = useState(true);    
    const [searchValue, setSearchValue] = useState("");    
    const [log, setLog] = useState([]);
    const [newShipping, setNewShipping] = useState(false);
    let navigate = useNavigate();

    const gridWebhookColumns = [
        {
            field: 'type',
            headerName: 'Type call',
            minWidth: 50,
        },
        {
            field: 'receipt_nr',
            headerName: 'Reference',
            minWidth: 200,
        },
        {
            field: 'tracking',
            headerName: 'Tracking',
            minWidth: 220,
        },
        {
            field: 'provider',
            headerName: 'provider',
            minWidth: 300,
        },
        {
            field: 'created_at',
            headerName: 'Created at',
            minWidth: 170,
        },
        {
            field: 'updated_at',
            headerName: 'Modified at',
            minWidth: 170,
        },
        {
            field: 'status',
            headerName: 'status',
            minWidth: 110,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                //console.log(params.row)
                if(params.row?.status?.status === "ok"){
                    return  <Button style={{color: (props.theme === 'dark' ? Colors.dark_text : Colors.light_text), height:"20px" }} >
                                <FontAwesomeIcon icon={faCheckCircle} color="green" size="2x" />
                            </Button>;
                } else {
                    return  <Button style={{color: (props.theme === 'dark' ? Colors.dark_text : Colors.light_text), height:"20px" }} onClick={()=>{props.notify(params.row.error, 7500, "top-right", props.theme, params.row.id)}}>
                                <FontAwesomeIcon icon={faExclamationTriangle} color="red" size="2x" />
                            </Button>;
                }
            }
        },
        {
            field: 'label',
            headerName: 'label',
            minWidth: 110,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                if((params.row.type === "label" || params.row.type === "shipment") && params.row?.status?.status === "ok"){
                    //console.log("url: ", params.row);
                    return <Button style={{color: (props.theme === 'dark' ? Colors.dark_text : Colors.light_text), height:"20px" }} onClick={() => getPdf(params.row.receipt_nr)}>
                                <FontAwesomeIcon icon={faFilePdf}  size="2x" />
                            </Button>
                }
                
            }
        },
      ];



    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    } 


    useEffect(() => {
        if(props.api !== ''){
            let url = SftGlobalData.baseURL_API + 'shippingLog';
            axios.post(url, 
                {
                    key: props.api
                }, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }
                }).then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('data', {key: props.api});
                        console.log('response', response);
                    }
                    setLog(response.data);                    
                    setLoading(false);
                }
            );   
        }
     },[props.api, params.userId]);

    useEffect(() => {
        if(props.api !== ''){
            let url = SftGlobalData.baseURL_API + 'shippingLog&action=fetchKasvar';
            axios.post(url, 
                {
                    key: props.api
                }, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }
                }).then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('data', {key: props.api});
                        console.log('response fetchKasvar', response);
                    }

                    if(response.data["type_of_shipping"] === "BPOST2"){
                        setNewShipping(true);
                    }
                }
            );   
        }
    },[props.api, params.userId]);
    
    function openBase64NewTab(base64Pdf) {
        var blob = base64toBlob(base64Pdf);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
        } else {
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl);
        }
      }
      
      function base64toBlob(base64Data) {
        const sliceSize = 1024;
        const byteCharacters = atob(base64Data);
        const bytesLength = byteCharacters.length;
        const slicesCount = Math.ceil(bytesLength / sliceSize);
        const byteArrays = new Array(slicesCount);
      
        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
          const begin = sliceIndex * sliceSize;
          const end = Math.min(begin + sliceSize, bytesLength);
      
          const bytes = new Array(end - begin);
          for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
          }
          byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: "application/pdf" });
      }

      function getPdf(receipt_nr){
        if(props.api !== ''){
            let url = SftGlobalData.baseURL_API + 'shippingLog&action=fetchPdf';
            axios.post(url, 
                {
                    key: props.api,
                    receipt_nr: receipt_nr
                }, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }
                }).then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('data', {key: props.api, receipt_nr: receipt_nr});
                        console.log('response', response);                        
                    }
                    if(response.data[0].url !== undefined){
                        openInNewTab(response.data[0].url);
                    } else {
                        openBase64NewTab(response.data[0].pdf);                
                    }
                }
            );
        }
      }

    function DataGridShippingLog() {
        return (
          <div style={{ height: 520, width: '100%' }}>
            {(log.length === 0 ? "" : <DataGridPro
                className={props.theme}
                columns={gridWebhookColumns}
                rows={log.filter(log => (log.receipt_nr.match(new RegExp(searchValue, "i")) || log.action.match(new RegExp(searchValue, "i")) || log.payload.first_name.match(new RegExp(searchValue, "i")) || log.payload.last_name.match(new RegExp(searchValue, "i")) || log.payload.email.match(new RegExp(searchValue, "i"))))   
                .map((row, index) => {return {
                    id: row.id, 
                    type: row.type,
                    receipt_nr: row.receipt_nr,
                    tracking: row.tracking,
                    created_at: SftGlobalData.dateTimeFormatter(row.created_at),
                    updated_at: SftGlobalData.dateTimeFormatter(row.updated_at),
                    provider: row.provider,
                    status: row.response,
                    error: row.response?.message,
                    url: row.url
                }
                })}
                //{...data}
                loading={log.length === 0}
                rowHeight={38}
                pagination={true}
                />)}            
          </div>
        );
      }

    return (
        <div className={(props.screenWidth) < 600 ? 'not_expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme : 'expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme} >
            {newShipping && <FloatingAddButton disabled={loading} onClick={()=>{navigate("/"+props.lang+"/shipping/new")}} />}
            <div className={"container_list__title " + props.theme}><h2>{props.t('shippingLog')}</h2></div>
            
            {loading && <div className={"container_list__title " + props.theme} style={{display:"flex", justifyContent: "space-evenly", marginTop: 5, border: 1}}>
                        <TailSpin 
                            height="40"
                            width="40"
                            color={Colors.sft_blue}
                            ariaLabel='loading'
                        />
                    </div>}
                   
            {!loading && (<div className='search'>
            {(log.length === 0 ? <p style={{textAlign:"center"}}>{props.t('noShippingHistory')}</p> : <input 
                    type="text"
                    name="search"
                    className={props.theme+"_accent_darker txt_user"}
                    placeholder={props.t('search')}
                    value={searchValue}
                    onChange={e => setSearchValue(e.target.value)}
                />)}
            </div>)}
            {!loading && (DataGridShippingLog())}
        </div>
    );
}

export default ShippingLogScreen;
