import React, { useState } from 'react';
import { Switch } from '@mui/material';
import "../styles/main.css";


const ListItemV2 = (props) => {
    const [active, setActive] = useState(props.active);
   
    return (
        <Switch
            checked={ active === 'Y' }
            disabled={props.disabled}
            onChange={() => {props.submitHandler(props.id, active === 'Y' ? 'N' : 'Y'); setActive(active === 'Y' ? 'N' : 'Y');}}
            inputProps={{ 'aria-label': 'controlled' }}
            className={props.className}
        />
    );

}

export default ListItemV2;