import React, { useState, useEffect }from 'react';
import { TailSpin } from  'react-loader-spinner'
import axios from 'axios';
import SftGlobalData from '../../library/SftGlobalData';
import Colors from '../../library/Colors';
import LicenceCheckBox from './LicenceCheckBox';
import 'react-toastify/dist/ReactToastify.css';


const LicenceAddonForm = (props) =>  {
    const [loading, setLoading] = useState(true);
    const [modules, setModules] = useState([]);


    useEffect (()=>{
        try{
            let url = SftGlobalData.baseURL_API+'client&action=fetchAddons';
            let postData = {key: props.api}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    setModules(response.data);
                    setLoading(false);
                });
        } catch(e){
            console.error(e);
        }
    }, [props.api]);


    return (
        <div className={props.theme + " _50perc"}>
            {loading && <div style={{display:"flex", justifyContent: "space-evenly", marginTop: 5}}>
                <TailSpin 
                    height="40"
                    width="40"
                    color={Colors.sft_blue}
                    ariaLabel='loading'
                />
            </div>}
            {!loading && <div style={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'flex-start'}}>
                {
                    modules.map((mod)=>{
                        return <LicenceCheckBox disabled={props.disabled} key={mod.mod_id} theme={props.theme} checked={mod.active==="Y"} api={props.api} database={props.database} mod={mod} label={<span>&nbsp;&nbsp; <b>{mod.mod_id}</b>&nbsp; - &nbsp; {mod.mod_name} </span>}/>
                    })
                }
            </div>}
        </div>
    );
}

export default LicenceAddonForm;
