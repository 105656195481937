import React, { useState } from 'react';
import {
  useNavigate
} from "react-router-dom";
import axios from 'axios';
import SftGlobalData from '../library/SftGlobalData';
import Android from '@mui/icons-material/Android';

import consultationLogo from '../assets/mobile-icons/ic_consultation.png'
import inventoryLogo from '../assets/mobile-icons/ic_inventory.png'
import inventoryInvertedLogo from '../assets/mobile-icons/ic_inventory_inverted.png'
import deliveryLogo from '../assets/mobile-icons/ic_delivery.png'
import giftLogo from '../assets/mobile-icons/ic_gift.png'
import pickingLogo from '../assets/mobile-icons/ic_picking.png'
import returnLogo from '../assets/mobile-icons/ic_return.png'
import stockposLogo from '../assets/mobile-icons/ic_stockpos.png'
import transferRequestLogo from '../assets/mobile-icons/ic_transfer_request.png'
import transferLogo from '../assets/mobile-icons/ic_transfer.png'
import settingLogo from '../assets/mobile-icons/ic_settings.png'
import catalogLogo from '../assets/mobile-icons/ic_catalog.png'
import webshopLogo from '../assets/mobile-icons/ic_webshop.png'
import customerLogo from '../assets/mobile-icons/ic_customer.png'
import stockCorrLogo from '../assets/mobile-icons/ic_correction.png'
import stockCorrInvertedLogo from '../assets/mobile-icons/ic_correction_inverted.png'
import manSaleLogo from '../assets/mobile-icons/ic_manual_sale.png'
import consignmentLogo from '../assets/mobile-icons/ic_consignment.png'
import packlistLogo from '../assets/mobile-icons/ic_packlist.png'
import labelLogo from '../assets/mobile-icons/ic_label_print.png'
import salesLogo from '../assets/mobile-icons/ic_mod_sales.png'
import scanLogo from '../assets/mobile-icons/ic_scan.png';
import Colors from '../library/Colors';

const ModuleCard = (props) => {
    const [checkedState, setCheckedState] = useState(parseInt(props.checked) === 1 ? true : false);
    let navigate = useNavigate();
  
    const submitHandler = async (value) => {
        let url = SftGlobalData.baseURL_API_old + '?key='+props.api+'&edit=1&mod='+props.setting+"&value="+value;
        if(props.groupId !== '0000'){
            url = SftGlobalData.baseURL_API_old + '?key='+props.api+'&edit=1&mod='+props.setting+"&value="+value+'&groupId='+props.groupId;
        }
        if(SftGlobalData.debug){console.log('url', url);}
        axios.get(url)
            .then(response => {if(SftGlobalData.debug){console.log(response);}});
    }

  

    let modIcon = inventoryLogo;
    //console.log("setting", props.setting);
    switch(props.setting){
        case 'Consultation':
            modIcon = consultationLogo;
            break;
        case 'Inventory':
            if(props.theme === 'dark'){
                modIcon = inventoryInvertedLogo;
            } else {
                modIcon = inventoryLogo;
            }
            break;
        case 'Transfer':
            modIcon = transferLogo;
            break;
        case 'StockPositions':
            modIcon = stockposLogo;
            break;
        case 'TransferRequest':
            modIcon = transferRequestLogo;
            break;
        case 'Picking':
            modIcon = pickingLogo;
            break;
        case 'Giftlist':
            modIcon = giftLogo;
            break;
        case 'Return':
            modIcon = returnLogo;
            break;
        case 'Delivery':
            modIcon = deliveryLogo;
            break;
        case 'StockCorrection':
            if(props.theme === 'dark'){
                modIcon = stockCorrInvertedLogo;
            } else {
                modIcon = stockCorrLogo;
            }
            break;
        case 'ManualSale':
            modIcon = manSaleLogo;
            break;
        case 'Manualsale':
            modIcon = manSaleLogo;
            break;
        case 'Consignment':
            modIcon = consignmentLogo;
            break;
        case 'Catalog':
            modIcon = catalogLogo;
            break;
        case 'Packlist':
            modIcon = packlistLogo;
            break;
        case 'Webshop':
            modIcon = webshopLogo;
            break;
        case 'Customer':
            modIcon = customerLogo;
            break;
        case 'Label':
            modIcon = labelLogo;
            break;
        case 'Sales':
            modIcon = salesLogo;
            break;
        case 'ScanModule':
            modIcon = scanLogo;
            break;
        default:
            modIcon = settingLogo;
            break;
    }

    const androidMods = ["Inventory", "Transfer", "StockPositions", "TransferRequest", "Picking", "Giftlist", "Return", "Delivery", "StockCorrection", "ManualSale", "Consignment", "Packlist", "Sales"]; 

    return (
          <div className={"card_container " + props.theme + "_accent card_container_" + props.theme + " " + props.className} style={{position: 'relative'}}>
            {(androidMods.indexOf(props.setting)>-1) && <div className={'card_corner_left padding_none no_background'} style={{marginLeft: 5}}><Android style={{color: Colors.def_green}} /></div>}
            <div  onClick={()=>{
                if(!props.noClick){
                    if(props?.groupId === "0000")
                        navigate("/"+props.lang+"/module/"+props.setting);
                    else
                        navigate("/"+props.lang+"/module/"+props.setting+"/"+props.groupId);
                }
            }}>
                <img src={modIcon} alt={props.title} width="64" height="64"/>
                <p>{props.title}</p>
            </div>
            <label className="switch" style={{visibility: ((props.title === "General" || props.title === "Label") ? "hidden" : "visible")}}>
                <input type="checkbox" checked={checkedState} onChange={() => {submitHandler(!checkedState); setCheckedState(!checkedState);}}/>
                <span className={"slider slider_" + props.theme + " round"}></span>
            </label>
          </div>
    );
}

export default ModuleCard;
