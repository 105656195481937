import React, { useState, useEffect }from 'react';
import { useParams } from "react-router-dom";
import { TailSpin } from  'react-loader-spinner'
import axios from 'axios';
import SftGlobalData from '../library/SftGlobalData';
import Colors from '../library/Colors';

import ShippingCustomer from '../components/shipping/ShippingCustomer';
import ShippingSender from '../components/shipping/ShippingSender';
import ShippingProvider from '../components/shipping/ShippingProvider';
import ShippingOptions from '../components/shipping/ShippingOptions';
import ShippingPrint from '../components/shipping/ShippingPrint';


const ShippingScreen = (props) =>  {
    const params = useParams();     
    const [loading, setLoading] = useState(false); 
    const [storeSet, setStoreSet] = useState(false);
    const [currentForm, setCurrentForm] = useState("ShippingCustomer");  
    const [error, setError] = useState('');       
    const [providers, setProviders] = useState([]);
    const [visitForm, setVisitForm] = useState([]);
    const [receiptNr, setReceiptNr] = useState(0);
    const [selectedStore, setSelectedStore] = useState(-1);
    const [showCustomerForm, setShowCustomerForm]   = useState(1);
    const [showShipperForm, setShowShipperForm]     = useState(1);
    const [showProviderForm, setShowProviderForm]   = useState(1);
    const [showOptionsForm, setShowOptionsForm]     = useState(1);  
    const [showPrintForm, setShowPrintForm]         = useState(1);  
    const [showLabelButton, setShowLabelButton]     = useState("ALL");
    const [selectedPrintOption, setSelectedPrintOption] = useState();
    const [selectedOption, setSelectedOption] = useState(0);
    const [stores, setStores] = useState([]);
    const [countries, setCountries] = useState([]);
    const [customerData, setCustomerData]   = useState({firstname: "", lastname: "", company: "", street_name: "", house_number: "", house_number_addition: "", zip: "", city: "",country: "",email: "", phone: "", language: ""});
    const [shipperData, setShipperData]     = useState({name: "", company: "", street_name: "", house_number: "", zip: "", city: "", country: "", email: "", phone: ""});
    const [shipmentTypeData, setShipmentTypeData]   = useState({shipper: "", provider: ""});
    const [shipmentOptionsData, setShipmentOptionsData] = useState({shipment_type: "", tracking_email: "invisible", tracking_sms: "invisible", saturday_delivery: "invisible", second_delivery: "invisible", evening_delivery: "invisible", return_label: "invisible", sameday_delivery: "invisible", signed: "invisible", basic_insurance: "invisible", extra_insurance: "0", weight: "0", parcelValue: "0", delivery_date: "01-01-2022", parcels: "1", paper_size: "A6"});

    /*
    let customer            = {firstname: "", lastname: "", company: "", street_name: "", house_number: "",zip: "", city: "",country: "",email: "", phone: "", language: ""};
    let shipper             = {name: "", company: "", street_name: "", house_number: "", zip: "", city: "", country: "", email: "", phone: ""};
    let shipment_type       = {shipper: "", provider: ""};
    let shipment_options    = {shipment_type: "", tracking_email: "", tracking_sms: "", saturday_delivery: "", second_delivery: "", evening_delivery: "", return_label: "", sameday_delivery: "", signed: "", basic_insurance: "", extra_insurance: "", weight: "", delivery_date: "", parcels: ""};*/

    useEffect(() => {
        if(props.api !== ''){
            console.log("lang", props.lang.toLowerCase());
            let getLang = "NED";
            if(props.lang.toLowerCase() === "en"){
                getLang = "ENG";
            } else if(props.lang.toLowerCase() === "fr"){
                getLang = "FRA";
            }
            
            let url = SftGlobalData.baseURL_API + 'sftData';

            axios.post(url, 
                {
                    key: props.api, 
                    type: "countries",
                    language: getLang                    
                }, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }
                }).then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('response', response);
                    }            
                setCountries(response.data.data);
            });

            axios.post(url, 
                {
                    key: props.api, 
                    type: "stores"
                }, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }
                }).then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('response', response);
                    }
                    
                    
                setStores(response.data.data);
                //console.log("stores", response.data.data);
                if(response.data.data.length === 1){
                    let shipper = {name: response.data.data[0]["shipping_name"], company: response.data.data[0]["shipping_company"], street_name: response.data.data[0]["shipping_street_name"], house_number: response.data.data[0]["shipping_street_number"], zip: response.data.data[0]["shipping_zip"], city: response.data.data[0]["shipping_city"], country: response.data.data[0]["shipping_country"], email: response.data.data[0]["shipping_email"], phone: response.data.data[0]["shipping_phone"]};
                    //console.log("shipperData", shipper);
                    setShipperData(shipper);
                    if(receiptNr !== 0){
                        setShowShipperForm(false);
                    }
                } else {
                    if(typeof params.storeId !== "undefined" && params.storeId.length > 1 && response.data.data.length > 0){
                        response.data.data.forEach(row => {
                            if(row["code"] === params.storeId){
                                let shipper2 = {name: row["shipping_name"], company: row["shipping_company"], street_name: row["shipping_street_name"], house_number: row["shipping_street_number"], zip: row["shipping_zip"], city: row["shipping_city"], country: row["shipping_country"], email: row["shipping_email"], phone: row["shipping_phone"]};
                                setShipperData(shipper2);
                                //console.log("shipper2", shipper2);
                                if(params.receiptNr !== undefined && params.receiptNr.length > 1){
                                    setShowShipperForm(false);
                                }
                            }
                        });
                    }
                }
            });               
        }
     },[props.api, props.lang, receiptNr]);

    useEffect(()=>{
        setReceiptNr(params.receiptNr);  
        if(params.receiptNr !== undefined && params.receiptNr.length > 1){    
            props.setHideSidebar(true);
            props.setHideTopbar(true);
            let pos_id = "";
            let url = SftGlobalData.baseURL_API + 'shipping&action=fetchReceipt';
            axios.post(url, 
                {
                    key: props.api,
                    receipt_nr: params.receiptNr
                }, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }
                }).then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('data', {key: props.api, receipt_nr: params.receiptNr});
                        console.log('response receipt', response.data);
                    }
                    if(params.receiptNr !== undefined){
                        setReceiptNr(params.receiptNr.replace(/[']/g, ''));                
                    }
                    setLoading(false);                     
                    
                    let customer            = {firstname: "", lastname: "", company: "", street_name: "", house_number: "", house_number_addition: "", zip: "", city: "",country: "",email: "", phone: "", language: ""};
                    customer.firstname      = response.data.firstname;
                    customer.lastname       = response.data.lastname;
                    customer.company        = response.data.company;
                    customer.street_name    = response.data.address;
                    customer.house_number   = response.data.house_number;
                    customer.house_number_addition = response.data.house_number_addition;
                    customer.zip            = response.data.zip;
                    customer.city           = response.data.city;
                    customer.country        = response.data.isocode;
                    customer.email          = response.data.email;
                    customer.phone          = response.data.gsm;
                    setCustomerData(customer);  
                    if(typeof params.storeId !== "undefined" && params.storeId.length > 1){
                        //console.log("params.storeId", params.storeId);
                        //console.log("response.data.store_id",response.data.store_id);
                        setSelectedStore(params.storeId);
                        setStoreSet(true);
                    } else {
                        setSelectedStore(response.data.store_id);
                        setStoreSet(false);
                    }
                    
                }
            );  

            url = SftGlobalData.baseURL_API + 'shipping&action=fetchSettings';
            axios.post(url, 
                {
                    key: props.api,
                    receipt_nr: params.receiptNr
                }, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }
                }).then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('data settings', {key: props.api, receipt_nr: params.receiptNr});
                        console.log('response settings', response.data);
                    }                   
                    if(params.receiptNr !== 0){
                        setShowCustomerForm(response.data.show_customer_data);
                        setShowShipperForm(response.data.show_customer_data);
                        setShowProviderForm(response.data.show_shipping_methode);
                        setShowOptionsForm(response.data.show_shipping_methode);
                        setShowPrintForm(response.data.bpost_auto_print);
                    }                    
                    setShowLabelButton(response.data.show_label_button);
                    
                    let shipment_type       = {shipper: "", provider: ""};
                    shipment_type.shipper   = response.data.shipper;
                    shipment_type.provider  = response.data.provider;
                    setShipmentTypeData(shipment_type);
                    setShipmentOptionsData({shipment_type: response.data.shipper, tracking_email: "invisible", tracking_sms: "invisible", saturday_delivery: "invisible", second_delivery: "invisible", evening_delivery: "invisible", return_label: "invisible", sameday_delivery: "invisible", signed: "invisible", basic_insurance: "invisible", extra_insurance: "0", weight: "0", parcelValue: "0", delivery_date: "01-01-2022", parcels: "1", paper_size: response.data.bpost_paper_size});
                });  
            } else {
                setLoading(false);
            }

            let url = SftGlobalData.baseURL_API + 'shipping&action=fetchShippingProviders';
            axios.post(url, 
                {
                    key: props.api
                }, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }
                }).then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('data', {key: props.api});
                        console.log('response', response.data);
                    }                
                    setLoading(false);                                    
                    setProviders(response.data);  
                }
            );     
    }, [props.api], [params.receiptNr])

    function changeForm(forwardOrBack){
        if(forwardOrBack === true){ //true is forward, false is backward  
            if(SftGlobalData.debug) 
                console.log("currentForm: ", currentForm);   
            if(currentForm === "ShippingCustomer"){
                if(SftGlobalData.debug)
                    console.log("Show customer form: " + showCustomerForm);
                setVisitForm([...visitForm, currentForm]);
                setCurrentForm("ShippingSender");
            } else if(currentForm === "ShippingSender"){
                if(SftGlobalData.debug)
                    console.log("Show sender form: " + showShipperForm);
                setVisitForm([...visitForm, currentForm]);
                setCurrentForm("ShippingProvider");
            } else if(currentForm === "ShippingProvider"){
                if(SftGlobalData.debug)
                    console.log("Show sender form: " + showProviderForm);
                setVisitForm([...visitForm, currentForm]);
                setCurrentForm("shippingOptions");
            } else if(currentForm === "shippingOptions"){
                if(SftGlobalData.debug)
                    console.log("Show sender form: " + showOptionsForm);
                setVisitForm([...visitForm, currentForm]);
                setCurrentForm("shippingPrint");
            } else {
                if(SftGlobalData.debug)
                    console.log("Euhm error?");
            }         
        } else {
            let popped = visitForm.pop();
            if (SftGlobalData.debug){
                console.log("visitForm:", visitForm)
                console.log("currentForm: ", currentForm);
                console.log("popped: ", popped);
            }
            setCurrentForm(popped);
        }    
    }
    function getForm() {
        switch(currentForm){
            case "ShippingCustomer":
                return <ShippingCustomer                 
                api={props.api} 
                error={error}
                setError={(error)=>setError(error)}
                t={props.t}
                hideSidebar={props.hideSidebar}
                hideTopbar={props.hideTopbar}
                countries={countries}
                customer={customerData}     setCustomer={(customerData)=>{setCustomerData(customerData)}}                
                receiptNr={receiptNr}
                showLabelButton={showLabelButton}
                theme={props.theme}
                showCustomerForm={showCustomerForm}
                switchForm={(forwardOrBack)=>{changeForm(forwardOrBack);}} 
                screenWidth={props.screenWidth}
                changeVisible={(visbility)=>{setShowCustomerForm(visbility);}}
                lang={props.lang} />
            case "ShippingSender":
                return <ShippingSender                 
                api={props.api} 
                error={error}
                setError={(error)=>setError(error)}
                t={props.t}
                hideSidebar={props.hideSidebar}
                hideTopbar={props.hideTopbar}
                stores={stores}
                countries={countries}
                selectedStore={selectedStore}
                showLabelButton={showLabelButton}
                receiptNr={receiptNr}
                shipper={shipperData}     setShipperData={(shipperData)=>{setShipperData(shipperData)}}
                theme={props.theme}
                switchForm={(forwardOrBack)=>{changeForm(forwardOrBack);}} 
                screenWidth={props.screenWidth}
                changeVisible={(visbility)=>{setShowShipperForm(visbility);}} 
                lang={props.lang} />
            case "ShippingProvider":
                return <ShippingProvider 
                notify={(text, time, position, theme, id) => {props.notify(text, time, position, theme, id);}}
                theme={props.theme} 
                t={props.t}
                api={props.api} 
                error={error}
                setError={(error)=>setError(error)}
                hideSidebar={props.hideSidebar}
                hideTopbar={props.hideTopbar}
                receiptNr={receiptNr} 
                showLabelButton={showLabelButton}
                showProviderForm={showProviderForm}
                providers={providers} setProviders={(providers)=>setProviders(providers)}
                shipmentType={shipmentTypeData} setShipmentTypeData={(shipmentTypeData)=>{setShipmentTypeData(shipmentTypeData)}}
                switchForm={(forwardOrBack)=>{changeForm(forwardOrBack);}}  
                screenWidth={props.screenWidth}
                changeVisible={(visbility)=>{setShowProviderForm(visbility);}}/>
            case "shippingOptions":
                return <ShippingOptions 
                theme={props.theme}
                t={props.t}
                lang={props.lang}
                receiptNr={receiptNr} 
                setReceiptNr={(receiptNr)=>setReceiptNr(receiptNr)}
                hideSidebar={props.hideSidebar}
                hideTopbar={props.hideTopbar}
                api={props.api} 
                error={error}
                setError={(error)=>setError(error)}
                customer={customerData}
                shipper={shipperData}
                showOptionsForm={showOptionsForm}
                shipmentType={shipmentTypeData}
                selectedOption={selectedOption} 
                setSelectedOption={(selectedOption)=>setSelectedOption(selectedOption)}
                setSelectedPrintOption={(selectedPrintOption)=>setSelectedPrintOption(selectedPrintOption)}
                shipmentOptions={shipmentOptionsData} setShipmentOptions={(shipmentOptionsData)=>{setShipmentOptionsData(shipmentOptionsData)}}
                switchForm={(forwardOrBack)=>{changeForm(forwardOrBack);}}  
                changeVisible={(visbility)=>{setShowOptionsForm(visbility);}}
                screenWidth={props.screenWidth}
                showLabelButton={showLabelButton}/>
            case "shippingPrint":
                return <ShippingPrint 
                theme={props.theme}
                t={props.t}
                lang={props.lang}
                receiptNr={receiptNr} 
                selectedStore={storeSet ? selectedStore : ""}
                hideSidebar={props.hideSidebar}
                hideTopbar={props.hideTopbar}
                error={error}
                setError={(error)=>setError(error)}
                api={props.api} 
                customer={customerData}
                shipper={shipperData}
                showPrintForm={showPrintForm}
                shipmentType={shipmentTypeData}
                selectedOption={selectedOption} 
                selectedPrintOption={selectedPrintOption}
                showLabelButton={showLabelButton}
                setSelectedPrintOption={(selectedPrintOption)=>setSelectedPrintOption(selectedPrintOption)}
                shipmentOptions={shipmentOptionsData} setShipmentOptions={(shipmentOptionsData)=>{setShipmentOptionsData(shipmentOptionsData)}}
                switchForm={(forwardOrBack)=>{changeForm(forwardOrBack);}}  
                screenWidth={props.screenWidth}
                changeVisible={(visbility)=>{setShowOptionsForm(visbility);}}/>
            default:
                return <div>An unexpected error occurred...</div>
        }
    }

    return (
        <div className={(props.hideSidebar === true ? ('container_content margin_top_none '+ props.theme + ' container_content_' + props.theme + (props.screenWidth < 810 ? ' shipping_content_small ' : ' shipping ')) : (props.screenWidth < 600 ? ('not_expanded shipping container_content _80perc '+ props.theme + ' container_content_' + props.theme) : ('expanded shipping container_content _80perc ' + props.theme + ' container_content_' + props.theme)))} >
            {loading && <div className={"container_list__title " + props.theme} style={{display:"flex", justifyContent: "space-evenly", marginTop: 5, border: 1}}>
                        <TailSpin 
                            height="40"
                            width="40"
                            color={Colors.sft_blue}
                            ariaLabel='loading'
                        />
                    </div>}
                   
            {!loading && getForm()}
        </div>
    );
}

export default ShippingScreen;
