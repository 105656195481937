import React, { useEffect, useState } from 'react';
import { DataGridPro, GridCellEditStopReasons, GridToolbarContainer, useGridApiRef } from '@mui/x-data-grid-pro';
import SftGlobalData from '../../library/SftGlobalData';
import Colors from '../../library/Colors';
import axios from 'axios';
import { Button } from '@mui/material';
import { TailSpin } from 'react-loader-spinner';
import AddIcon from '@mui/icons-material/Add';
import '../eticket/eticket.css';

const ExactClientTable = (props) =>  {
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [progVars, setProgVars] = useState([]);
    const apiRef = useGridApiRef();
    
    useEffect(()=>{
        loadData();
    },[props.api]);

    const loadData = () => {
        let url = SftGlobalData.baseURL_API + 'exact&action=fetchKassaExact';
        axios.post(url, {key: props.api}, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }}) 
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('response', response);
                }
                if(typeof response.error === 'undefined'){
                    if(typeof response.data !== "undefined") {
                        setList(response.data.data);
                        setProgVars(response.data.prog_var);
                    }

                } else { 
                    props.notify(props.t('errorOccured'), 7500, "top-center", props.theme, 1);
                }
                setLoading(false);
            });
    }

    const createClientTable = () => {
        setLoading(true);
        let url = SftGlobalData.baseURL_API + 'exact&action=createKassaExact';
        axios.post(url, {key: props.api}, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }}) 
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('response', response);
                }
                if(typeof response.error === 'undefined'){
                    if(typeof response.data !== "undefined") setList(response.data);

                } else { 
                    props.notify(props.t('errorOccured'), 7500, "top-center", props.theme, 1);
                }
                setLoading(false);
            });
        
    }

    // Dynamically generate columns based on keys of the first item in the list
    const columns = list.length > 0 ? Object.keys(list[0]).map((key) => ({
        field: key,
        headerName: key,
        width: 100,
        visible: (key === "id" ? false : true),
        editable: true // Make columns editable
    })) : [];

    const handleCellEditCommit = (id, field, value) => {

        let url = SftGlobalData.baseURL_API + 'exact&action=updateKassaExact';
        let postData = {
            key: props.api,
            row_id: id,
            field: field,
            value: value
            
        };
        
        axios.post(url, postData, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }}) 
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log("postData", postData);
                    console.log('response', response);
                }
                if(typeof response.error !== 'undefined'){
                    props.notify(props.t('errorOccured'), 7500, "top-center", props.theme, 1);
                } else { 
                    
                }
            });
    };

    function EditToolbar() {
      
        const handleClick = () => {
            setLoading(true);
            let url = SftGlobalData.baseURL_API + 'exact&action=addKassaExact';
            let postData = {
                key: props.api
            };
            
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }}) 
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('response', response);
                    }
                    if(typeof response.error === 'undefined'){
                        if(typeof response.data !== "undefined") setList(response.data);
    
                    } else { 
                        props.notify(props.t('errorOccured'), 7500, "top-center", props.theme, 1);
                    }
                    setLoading(false);
                });
        };
      
        return (
          <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
              Add record
            </Button>
          </GridToolbarContainer>
        );
    }
      

    return (
        <div>
            {loading && <div style={{display:"flex", flexDirection: "column", justifyContent: "center", alignContent: "center", alignItems: "center", marginTop: 5, height: "90%"}}>
                    <TailSpin 
                        height="100"
                        width="100"
                        color={Colors.sft_blue}
                        ariaLabel='loading'
                    />
            </div>}
            {!loading && <div>
                {list.length > 0 && <div className='rowContainerCenter _100perc'>
                    <DataGridPro
                          apiRef={apiRef}
                          //initialState={initialGrid}
                          initialState={{ pinnedColumns: { left: ['code'], right: [] }, columns: {
                            columnVisibilityModel: {
                              // Hide columns status and traderName, the other columns will remain visible
                              id: false,
                            },
                          } }}
                          className={'excel_grid ' + props.theme}
                          columns={columns}
                          rows={list.map((row, index) => {return row;})} 
                          onCellEditStop={(params, event) => {
                            //console.log("reason", params.reason )
                            //console.log("reasonsss", GridCellEditStopReasons.cellFocusOut )
                            if (params.reason === GridCellEditStopReasons.cellFocusOut || params.reason === "enterKeyDown" || params.reason === "tabKeyDown") {
                              //event.defaultMuiPrevented = true;
                              handleCellEditCommit(params.row.id, params.field, event.target.value);
                            }
                          }}
                          //onColumnWidthChange={()=>{visibilityChangedHandler();}}
                          //onColumnVisibilityModelChange={()=>{visibilityChangedHandler();}}
                          //onPreferencePanelClose={()=>{visibilityChangedHandler();}}
                          rowThreshold={0}
                          rowHeight={38}
                          autoHeight={true}
                          loading={loading}
                          pagination={false}
                          slots={{
                            toolbar: EditToolbar,
                          }}
                          /> 
                </div>}
                {list.length > 0 && <div className='rowContainerCenter _100perc slide-in-blurred-right styled-table margin_top_10px'>
                    <table className='_100perc textAlignLeft margin_top_15px '>
                        <thead>
                            <tr className='sftBorderBottom'>
                                <th className='sftBorderBottom textAlignLeft width_250px'>
                                    &nbsp; Code
                                </th>
                                <th className='sftBorderBottom textAlignLeft width_250px'>
                                    &nbsp; Variable
                                </th>
                                <th className='sftBorderBottom textAlignLeft'>
                                    &nbsp; Value
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {progVars.map((value) => (
                            <tr key={value.id}>
                                <td>{value.code}</td>
                                <td>{value.var}</td>
                                <td>{value.value}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>}
                {list.length === 0 && <div style={{justifyContent: "center", alignItems: "center", flex: 1, width: "100%", display: "flex"}}>
                    <Button className="modal_button_container_no_margin__button margin_top_15px" style={{width: 250}} onClick={()=>{createClientTable();}}>{props.t("add")}</Button>
                </div>}
            </div>}
        </div>
    )
}

export default ExactClientTable;