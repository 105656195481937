import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import SftGlobalData from '../library/SftGlobalData';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import Colors from '../library/Colors';
import ListItemV2 from '../components/ListItemV2';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@mui/material';
import FloatingAddButton from '../components/FloatingAddButton';


const BirthdayScreen = (props) =>  {
    const [loading, setLoading] = useState(true);
    const [pageSize, setPageSize] = useState(10);
    const [lists, setLists] = useState([]);
    const initialGrid = getCookie("initialBirthdaySms") === "{}" ? {columns: {columnVisibilityModel: {sms_filters: false, id: false, sms_birthday_report: false, sms_birthday_scheduled: false, sms_birthday_body: false,},}} : JSON.parse(getCookie("initialBirthdaySms"));
    const gridColumns = [
        {
            field: 'id',
            headerName: props.t('id'),
            width: 50,
            align: "left",
        },
        {
            field: 'name',
            headerName: props.t('name'),
            headerClassName: 'bold',
            minWidth: 175,
            width: 225,
            align: "left",
        },
        {
            field: 'sms_birthday_type',
            headerName: props.t('type'),
            width: 100,
            align: "left",
        },
        {
            field: 'sms_birthday_time',
            headerName: props.t('time'),
            width: 100,
            align: "left",
        },
        {
            field: 'sms_birthday_custom',
            headerName: props.t('custom'),
            width: 100,
            align: "left",
        },
        {
            field: 'sms_birthday_report',
            headerName: props.t('report'),
            width: 100,
            align: "left",
        },
        {
            field: 'sms_birthday_body',
            headerName: props.t('custom'),
            width: 300,
            align: "left",
        },
        {
            field: 'active',
            headerName: props.t('active'),
            width: 100,
            align: "left",
            renderCell: (params)=>{return <ListItemV2 
                key={params.row.id} 
                id={params.row.id} 
                lang={props.lang} 
                name={params.row.name}
                config={params.row.sms_birthday_body} 
                active={params.row.active ? 'Y' : 'N'} 
                api={props.api} 
                theme={props.theme}
                submitHandler={(list,value)=>{submitHandler(list,value)}}
                />}
        },
        {
            field: 'edit',
            headerName: props.t('edit'),
            minWidth: 60,    
            maxWidth: 60,  
            align: 'center',      
            headerAlign: 'center', 
            renderCell: (params) => {
            return  <Button style={{color: (props.theme === 'dark' ? Colors.dark_text : Colors.light_text), height:"100%", borderRadius: "50%" }} onClick={()=>{navigate("/"+props.lang+"/birthday-detail/"+params.row.id)}} >
                        <FontAwesomeIcon icon={faPencilAlt} color={Colors.def_orange} size="2x" />
                    </Button>;        
            }
        },
      ];
    const apiRef = useGridApiRef();
    let navigate = useNavigate();


    useEffect(() => {
        props.changeScreen('Licence');
        let url = SftGlobalData.baseURL_API_old + '?key='+props.api+'&get=birthdaylists';
        axios.get(url)
        .then(response => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('response', response);
            }
            setLists(response.data.lists);
            setLoading(false);
        });
     },[props]);

    useEffect(()=>{
        //console.log("adding subscriptions");
        try{
            apiRef.current.subscribeEvent(
                'columnOrderChange',
                ()=>{visibilityChangedHandler();},
            );
        }catch(ex){
    
        }
    }, [apiRef]);

    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        //console.log("cookie", cname + "=" + cvalue + ";" + expires + ";path=/");
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    const visibilityChangedHandler = () => {
        let temp = apiRef.current.exportState();
        if(typeof temp.columns.columnVisibilityModel === "undefined"){
            temp.columns.columnVisibilityModel = {};
        }
        try{
            temp.preferencePanel.open = false;
        }catch(e){
    
        }
        try{
            delete temp.pagination;
        }catch(e){
            console.error(e);
        }
        setCookie("initialBirthdaySms", JSON.stringify(temp), 100000);
    }

    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "{}";
    }

    const submitHandler = async (list, value) => {
        let updateValue = value === 'Y';
        let url = SftGlobalData.baseURL_API_old + '?key='+props.api+'&edit=1&birthdayList='+list+"&value="+updateValue;
        axios.get(url)
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('response', response);
                }
            });
      }


    function DataGrid() {
        return (
          <div>
            <DataGridPro
                apiRef={apiRef}
                className={props.theme}
                initialState={initialGrid}
                columns={gridColumns}
                onColumnWidthChange={()=>{visibilityChangedHandler();}}
                onColumnVisibilityModelChange={()=>{visibilityChangedHandler();}}
                onPreferencePanelClose={()=>{visibilityChangedHandler();}}
                rows={lists.map((row, index) => {return {
                        id: row.id,
                        name: row.name,
                        active: row.active,
                        sms_birthday_type: row.sms_birthday_type,
                        sms_birthday_scheduled: row.sms_birthday_scheduled,
                        sms_birthday_time: row.sms_birthday_time,
                        sms_birthday_body: row.sms_birthday_body,
                        sms_birthday_custom: row.sms_birthday_custom,
                        sms_birthday_report: row.sms_birthday_report,
                        sms_filters: row.sms_filters,
                        edit: "Edit",
                    }
                })}
                rowHeight={38}
                autoHeight={true}
                pagination={true}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 20, 30]}
                loading={loading}                
                />
          </div>
        );
    }

    return (
        <div className={(props.screenWidth) < 600 ? 'not_expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme : 'expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme}>
            <FloatingAddButton disabled={loading} onClick={()=>{navigate("/"+props.lang+"/birthday-detail/+")}} />
            <div className={"container_list__title " + props.theme}><h2>{props.t('birthdaySMS')}</h2></div>

            {DataGrid()}

        </div>
    );
}

export default BirthdayScreen;
