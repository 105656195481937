import React, { useState }from 'react';
import SftGlobalData from '../../library/SftGlobalData';
import axios from 'axios';
import { Switch } from '@mui/material';

const LicenceCheckBox = (props) =>  { 
    const [checked, setChecked] = useState(props.checked);
    const [clientModId, setClientModId] = useState(props.mod.id);

    const changeListener = () => {
        updateModule({key: props.api, database: props.database, mod_id: props.mod.mod_id, client_mod_id: clientModId});
        setChecked(!checked);
    }

    const updateModule = (postData) => {
        let url = SftGlobalData.baseURL_API+'client&action=updateModules';
        axios.post(url, postData, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('postData', postData);
                    console.log('resp', response);
                }
                try{
                    if(response.data.message === "success" && clientModId === null){
                        setClientModId(response.data.id);
                    }
                }catch(e){
                    
                }
            });
    }

    return (
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', justifyContent: 'center'}}>
            <Switch
                disabled={props.disabled}
                checked={ checked }
                onChange={() => {changeListener();}}
                inputProps={{ 'aria-label': 'controlled' }}
            />
            {props?.label}
        </div>
    );
}

export default LicenceCheckBox;
