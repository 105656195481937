import React, { useEffect, useState, useRef } from 'react';
import { TailSpin } from 'react-loader-spinner';
import {
    Link,
    useNavigate,
    useParams
} from "react-router-dom";
import { Button, TextField, Switch, Dialog, DialogTitle, DialogContent, DialogActions  } from '@mui/material';
import axios from 'axios';
import SftGlobalData from '../../library/SftGlobalData';
import Colors from '../../library/Colors';
import HorizontalLinearStepper from '../../components/Stepper';
import MailIntegrationOption from './MailIntegrationOption';
import mailchimpLogo from '../../assets/mailintegration-icons/ic_mailchimp.png'
import klaviyoLogo from '../../assets/mailintegration-icons/ic_klaviyo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { DataGridPro } from '@mui/x-data-grid-pro';

const MailIntegrationsSetup = (props) => {
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [mailIntegrationId, setMailIntegrationId] = useState(0);
    const [selectedIntegration, setSelectedIntegration] = useState(null);
    const [selectedIcon, setSelectedIcon] = useState(null); // Store selected icon
    const [currentStep, setCurrentStep] = useState(0); // Track current step in parent
    const [canProceed, setCanProceed] = useState(false);
    const [token, setToken] = useState("");
    const [listId, setListId] = useState("");
    const [dailyRepId, setDailyRepId] = useState(0);
    const [fullRepId, setFullRepId] = useState(0);
    const [active, setActive] = useState(false);
    const [useReport, setUseReport] = useState(false);
    const [reportDailySelect, setReportDailySelect] = useState(false);
    const [reportFullSelect, setReportFullSelect] = useState(false);
    const [famUseClientCard, setFamUseClientCard] = useState(false);

    const [tempTblQueryFull, setTempTblQueryFull] = useState("");
    const [tempTblQueryDaily, setTempTblQueryDaily] = useState("");
    const [isFullQueryValid, setIsFullQueryValid] = useState({ isValid: true, message: "" });
    const [isDailyQueryValid, setIsDailyQueryValid] = useState({isValid: true, message: "" });
    const [testingTmpTblQuery, setTestingTmpTblQuery] = useState(false);
    const [failedTestingTmpTblQuery, setFailedTestingTmpTblQuery] = useState(false);

    const [mainTbl, setMainTbl] = useState("klant");
    const [clientFields, setClientFields] = useState([
        { field: 'EMAIL', value: 'klant.email', canBeDeleted: false },
        { field: 'CUSTNO', value: 'klant.klnr', canBeDeleted: false },
        { field: 'FNAME', value: 'klant.firstname', canBeDeleted: true },
        { field: 'LNAME', value: 'klant.naam', canBeDeleted: true },
        { field: 'BIRTHDAY', value: "DATE_FORMAT(klant.gebdatum, '%m/%d')", canBeDeleted: true }
    ]);
    const [newClientMapField, setNewClientMapField] = useState("");
    const [newClientMappedField, setNewClientMappedField] = useState("");
    const [leftJoins, setLeftJoins] = useState("LEFT JOIN magazijn on klant.mag = magazijn.code");
    const [useFam, setUseFam] = useState(false);
    const [mainFamTbl, setMainFamTbl] = useState("klant_fam");
    const [clientFamFields, setClientFamFields] = useState([]);
    const [leftJoinsFam, setleftJoinsFam] = useState("LEFT JOIN magazijn on klant.mag = magazijn.code");
    const [fullQuery, setFullQuery] = useState("");

    const [checkingApiConnection, setCheckingApiConnection] = useState(true);
    const [apiConnectionSuccess, setApiConnectionSuccess] = useState(true);
    const [checkingKlKaart, setCheckingKlKaart] = useState(true);
    const [klkaartTypes, setKlkaartTypes] = useState([]);
    const allowedKlkaartTypes = [2];

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const [exampleDataQuery, setExampleDataQuery] = useState([]);

    const [gridIntegrationColumns, setGridIntegrationColumns] = useState([]);

    // Generate grid columns dynamically
    useEffect(() => {
        const dynamicClientColumns = clientFields.map(field => ({
            field: field.field,
            headerName: field.field,
            headerClassName: 'bold',
            minWidth: 150,
            width: 250,
            align: "left",
        }));

        setGridIntegrationColumns([...dynamicClientColumns]);
    }, [clientFields, clientFamFields, useFam]);


    useEffect(() => {
        props.changeScreen('Licence');
        if(props.api !== ""){
            if(params.id !== '+'){
                let url = SftGlobalData.baseURL_API + 'mailIntegration&action=fetchOne';
                let postData = {key: props.api, id: params.id};
                axios.post(url, postData, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log("URL", url);
                        console.log("POST", postData);
                        console.log("RESP", response);
                    }

                    //get the config json
                    let config = JSON.parse(response.data.config);
                    setSelectedIntegration(response.data.type);
                    handleSelectIntegration(response.data.type);
                    setToken(response.data.token);
                    setListId(response.data.list_id);
                    setDailyRepId(response.data.report_id_daily);
                    setFullRepId(response.data.report_id_full);
                    setActive(response.data.active === "1");
                    setUseReport((response.data.report_id_full !== "0" && response.data.report_id_daily !== "0"));
                    setFamUseClientCard(config.fam_use_client_card == "1");
                    setTempTblQueryFull(config.tbl_temp_queries_full);
                    setTempTblQueryDaily(config.tbl_temp_queries_daily);
                    setMainTbl(config.client_main_table);
                    setClientFields(config.client_fields);
                    setLeftJoins(config.client_joins);       
                    setMainFamTbl(config.client_fam_table);
                    setUseFam(config.use_fam);
                    setClientFamFields(config.client_fam_fields);
                    setleftJoinsFam(config.client_fam_joins);
    
                    setLoading(false);
                });
            } else {
                setLoading(false);
            }
        }
    },[props.api, params.id]);


    let navigate = useNavigate();

    const integrationTypes = [
        { key: 'MailChimp', label: 'MailChimp', value: 'mailchimp', icon: mailchimpLogo },
        { key: 'Klaviyo', label: 'Klaviyo', value: 'klaviyo', icon: klaviyoLogo },
    ];

    //use translations for step names
    const steps = [
        { label: props.t('mail_step_integration_type'), isOptional: false },
        { label: props.t('mail_step_credentials'), isOptional: false },
        { label: props.t('mail_step_configuration'), isOptional: false },
        { label: props.t('mail_step_tmp_tbls'), isOptional: false },
        { label: props.t('mail_step_client_fields'), isOptional: false },
        { label: props.t('mail_step_client_fam_fields'), isOptional: false },
        { label: props.t('mail_step_final_check'), isOptional: false },
        { label: props.t('mail_step_example'), isOptional: false },
        { label: props.t('mail_step_finish'), isOptional: false },
    ];

    const sendTempTableQuery = async (query) => {
        let url = SftGlobalData.baseURL_API + 'mailIntegration&action=createTempTableQuery';
        try {
            const response = await axios.post(url, { key: props.api, query: query }, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }
            });
            console.log("response:", response);
            // Assuming the backend sends a response with a 'success' field to indicate if the request succeeded
            if (response.data?.length > 0) {
                setFailedTestingTmpTblQuery(false);
                return { success: true, message: "Query successful" };
            } else {
                setFailedTestingTmpTblQuery(true);
                return { success: false, message: response?.data?.errorMsg || "Query failed" };
            }
        } catch (error) {
            // Handle network or other errors
            console.error("Error during API call:", error);
            setFailedTestingTmpTblQuery(true);
            return { success: false, message: "An error occurred while testing the query." };
        }
    };

    /** Step 0: Selection of Integration */
    const handleSelectIntegration = (key) => {
        const selected = integrationTypes.find(item => item.key === key);
        setSelectedIntegration(key);
        setSelectedIcon(selected.icon); // Store icon of selected integration
        if (selected && key) {
            setCanProceed(true);
        }
    };

    /** Step 1: Credentials */
    useEffect(() => {
        setCanProceed((token && listId));
    }, [token, listId]);

    /** Step 2: Configuration */
    useEffect(() => {
        setCanProceed(((dailyRepId && fullRepId) && (dailyRepId !== 0 && fullRepId !== 0)) || !useReport && dailyRepId == 0 && fullRepId == 0);
    }, [dailyRepId, fullRepId, useReport]);

    /** Step 3: Temporary tables  (Might not be needed since the queries can also be empty and should only be blocked if a filled in on fails*/
    // useEffect(() => {
    //     setCanProceed(tempTblQueryFull);
    // }, [tempTblQueryFull]);

    /** Step 4: ClientFields */
    useEffect(() => {
        // Only get client fields where both `field` and `value` are filled
        const filledClientFields = clientFields.filter(
            (x) => x.field && x.value
        );
        // the filled client fields should be copied to default clientfamfields but the values should be changed to klant_fam instead of KLANT and for field CUSTNO the value  should be CONCAT(klant_fam.klnr,".",klant_fam.trecno)
        const filledClientFamFields = filledClientFields.map((field) => {
            if (field.field === 'CUSTNO') {
                return { ...field, value: 'CONCAT(klant_fam.klnr,".",klant_fam.trecno)' };
            } else {
                // Check if the value contains a dot (i.e., table reference)
                const pointIndex = field.value.indexOf('.');
                console.log("pointIndex:", pointIndex);
        
                if (pointIndex !== -1) {
                    // Split into before and after the dot
                    const beforePoint = field.value.substring(0, pointIndex);
                    console.log("beforePoint:", beforePoint);
                    const afterPoint = field.value.substring(pointIndex);
                    console.log("afterPoint:", afterPoint);
        
                    // Clean up the beforePoint (the table name) - replace with 'klant_fam'
                    const cleanedBeforePoint = beforePoint.replace(/^[a-zA-Z]+(?=\W|$)/, mainFamTbl);
                    console.log("cleanedBeforePoint:", cleanedBeforePoint);
        
                    // Check if the value is inside a function (i.e., contains parentheses)
                    if (beforePoint.includes('(')) {
                        //trim from end of str to the index of the first open parenthesis
                        const openParenthesisIndex = beforePoint.indexOf('(');
                        const cleanedBeforePointWithoutParenthesis = beforePoint.substring(0, openParenthesisIndex);
                        console.log("cleanedBeforePointWithoutParenthesis:", cleanedBeforePointWithoutParenthesis);
                        
                        return { ...field, value: `${cleanedBeforePointWithoutParenthesis}(${mainFamTbl}${afterPoint.substring(openParenthesisIndex)}${afterPoint}` };
                       
                    } else {
                        // Regular case: just return with the cleaned before point
                        return { ...field, value: `${cleanedBeforePoint}${afterPoint}` };
                    }
                } else {
                    // If there's no dot, just prepend 'klant_fam.'
                    return { ...field, value: `${mainFamTbl}.${field.value}` };
                }
            }
        });
        
        setClientFamFields(filledClientFamFields);

        // Set canProceed if there are filled client fields and mainTbl is set
        setCanProceed(filledClientFields.length > 0 && mainTbl);
    }, [clientFields]);

    // useRef to track the previous mainTbl
    const previousMainTblRef = useRef(mainTbl);

    useEffect(() => {
        const previousMainTbl = previousMainTblRef.current;
    
        console.log("Previous Main Table:", previousMainTbl);
        console.log("Current Main Table:", mainTbl);
    
        // Update clientFields to replace or reinsert table name properly
        setClientFields((prevFields) =>
            prevFields.map((field) => {
                // Case where field value is empty or just whitespace
                if (!field.value.trim()) {
                    // If the field is empty, we don't prepend anything.
                    return field;
                }

                // If previousMainTbl is empty and mainTbl is provided, check if the field already has a table reference before the dot
                if (!previousMainTbl && mainTbl) {
                    // Check if there are alphabetic characters before the dot (indicating an existing table)
                    if (!/^[a-zA-Z]+(?=\.)/.test(field.value)) {
                        // If there is no table reference, prepend the mainTbl
                        const updatedValue = field.value.replace(".", ` ${mainTbl}.`);
                        return { ...field, value: updatedValue.trim() };
                    }else{
                        return field;
                    }
                }
    
                // Replace the table name part (before the first dot) if the old table name is present
                if (field.value.includes(previousMainTbl)) {
                    // This ensures only the table name before the first dot is replaced
                    const updatedValue = field.value.replace(new RegExp(`\\b${previousMainTbl}\\b(?=\.)`), mainTbl);
                    return {
                        ...field,
                        value: updatedValue.trim(),
                    };
                }
    
                // If the field value doesn't already have a table reference (no dot) and is not empty
                if (mainTbl && !field.value.includes('.') && field.value.trim()) {
                    const updatedValue = `${mainTbl}.${field.value}`;
                    return {
                        ...field,
                        value: updatedValue.trim(),
                    };
                }

                return field; // Leave unchanged if no changes needed
            })
        );
    
        // Update the ref to the current mainTbl for the next update
        previousMainTblRef.current = mainTbl;
    }, [mainTbl]);
    
    
    
    
    /** Step 5: ClientFamFields */
    useEffect(() => {
        setCanProceed((clientFamFields.length > 0 && mainFamTbl && clientFamFields.filter(field => !field.value).length === 0) || !useFam);
    }, [clientFamFields, mainFamTbl, useFam]);


    /** Step 6: Check API connection */
    useEffect(() => {
        setCheckingKlKaart(true);
        if (klkaartTypes) {
            setCanProceed(apiConnectionSuccess);
        } else {
            setCanProceed(false);
        }
        setCheckingKlKaart(false);
    }, [apiConnectionSuccess, klkaartTypes]);

    /** Step 7: Result query */
    useEffect(() => {

    }, [exampleDataQuery]);

    const checkApiConnection = () => {
        setCheckingApiConnection(true);
        const selected = integrationTypes.find(item => item.key === selectedIntegration);
    

        let postBody = {
            integration: selected.key,
            token: token,
        };


        if (selected) {
            axios.post(SftGlobalData.baseURL_API + 'mailIntegration&action=checkApi', JSON.stringify(postBody))
                .then(response => {
                    if (SftGlobalData.debug) {
                        console.log('url', SftGlobalData.baseURL_API + 'mailIntegration&action=checkApi');
                        console.log('data', JSON.stringify(postBody));
                        console.log('response', response.data);
                    }
                    if(response?.data?.success) {
                        setCheckingApiConnection(false);
                        setApiConnectionSuccess(true);
                    }else{
                        setCheckingApiConnection(false);
                        setApiConnectionSuccess(false);
                    }
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        console.log('Authentication failed: Incorrect API key.');
                    } else {
                        console.log('Error:', error.message);
                    }
                    setCheckingApiConnection(false);
                    setApiConnectionSuccess(false);
                });
        }
    };

    const getReportData = () => {
        // setCheckingDailyRep(true);
    //     let url = SftGlobalData.baseURL_API + 'sftData';
    //     axios.post(url, { key: props.api, type: "report", code: 'daily' }, {
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    //         }
    //     }).then(response => {
    //         if (SftGlobalData.debug) {
    //             console.log('url', url);
    //             console.log('data', { key: props.api });
    //             console.log('response fetchKasvar', response.data.data);
    //         }
    //         setDailyRepId(response.data?.data?.[0]?.id);
    //         // setCheckingDailyRep(false);
    //     }).catch(error => {
    //         if (SftGlobalData.debug) {
    //             console.log('url', url);
    //             console.log('data', { key: props.api });
    //             console.log('error', error);
    //         }
    //         // setCheckingDailyRep(false);
    //     });
    };

    const checkKlKaartTypes = () => {
        if (props.api !== '') {
            setCheckingKlKaart(true);
            let url = SftGlobalData.baseURL_API + 'sftData';
            axios.post(url, { key: props.api, type: "kasvars", code: 'klkaart_type' }, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }
            }).then(response => {
                if (SftGlobalData.debug) {
                    console.log('url', url);
                    console.log('data', { key: props.api });
                    console.log('response fetchKasvar', response.data.data);
                }
                setKlkaartTypes(response.data?.data?.map(x => x?.value)?.sort((a, b) => a - b));
                setCheckingKlKaart(false);
            }).catch(error => {
                if (SftGlobalData.debug) {
                    console.log('url', url);
                    console.log('data', { key: props.api });
                    console.log('error', error);
                }
                setCheckingKlKaart(false);
            });
        }
    }

    const canProceedToStep = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return !!selectedIntegration;
            case 1:
                return !!token && !!listId;
            case 2:
                return (dailyRepId && fullRepId && dailyRepId !== 0 && fullRepId !== 0) || (!useReport && dailyRepId === 0 && fullRepId === 0);
            case 3:
                return true;
            case 4:
                return clientFields.length > 0 && !!mainTbl;
            case 5:
                return (clientFamFields.length > 0 && !!mainFamTbl) || !useFam;
            case 8:
                return true;
            default:
                return false;
        }
    };

    const handleStepChange = async (stepIndex) => {
        const isNextStep = stepIndex === currentStep + 1;
        const isPreviousStep = stepIndex === currentStep - 1;

        // If moving to the next step (step 3, for API test)
        if (isNextStep && currentStep === 3 && (tempTblQueryFull || tempTblQueryDaily)) {
            setTestingTmpTblQuery(true);
            try {

                // Perform the API call to create a temporary table
                let promises = [];
                if (tempTblQueryFull) {
                    promises.push({ type: "full", promise: sendTempTableQuery(tempTblQueryFull) });
                }
                if (tempTblQueryDaily) {
                    promises.push({ type: "daily", promise: sendTempTableQuery(tempTblQueryDaily) });
                }

                // Wait for all promises to resolve
                let tmpQueryTests = await Promise.all(promises.map(p => p.promise));

                // Check for any failed queries and identify which one(s) failed
                let failedQueries = [];

                // Reset validity states
                setIsFullQueryValid({ isValid: true, message: '' });
                setIsDailyQueryValid({ isValid: true, message: '' });

                for (let i = 0; i < tmpQueryTests.length; i++) {
                    if (!tmpQueryTests[i].success) {
                        // Push the failed query type into the failedQueries array
                        failedQueries.push(promises[i].type, tmpQueryTests[i].message);
                        if (promises[i].type === "full") {
                            setIsFullQueryValid({ isValid: false, message: tmpQueryTests[i].message });
                        } else if (promises[i].type === "daily") {
                            setIsDailyQueryValid({ isValid: false, message: tmpQueryTests[i].message });
                        }
                    }
                }
                setTestingTmpTblQuery(false);
                if (failedQueries.length > 0) {
                    setCanProceed(false); // Disable "Next" button
                    setCurrentStep(3); // Move back to the current step (step 3)
                }else{
                    setCurrentStep(4); // Move to the next step (step 4)
                    setCanProceed(true);
                }
            } catch (error) {
                // Handle any errors that occur during the API call
                setCanProceed(false); // Disable "Next" button
                alert("An error occurred while testing the query. Please try again.");
                if(SftGlobalData.debug){
                    console.error("API call error:", error);
                }
            }

        } else if (isNextStep && currentStep === 2 && useReport) {

            // fetch the daily report data
            getReportData();


            // Handle the case where you skip to step 6 when using a report
            setCurrentStep(6); // Skip to step 7 (index 6)
            setCanProceed(false); // Disable "Next" until the API connection is checked
            checkApiConnection();
            checkKlKaartTypes();
        } else if (isPreviousStep && currentStep === 6 && useReport) {
            // Handle the case for navigating backward from step 6
            setCurrentStep(2); // Go back to step 3 (index 2)
            if (canProceedToStep(5)) setCanProceed(true);
        } else if (isNextStep && currentStep === 6 && !useReport) {
            createFullQuery();
            setCurrentStep(stepIndex);
        }
        else {
            // Handle regular step change (not involving API call)
            setCurrentStep(stepIndex);

            if (canProceedToStep(stepIndex)) {
                setCanProceed(true);
            } else {
                setCanProceed(false);
                if (isNextStep) {
                    // Additional condition when reaching step 6 or using reports at step 3
                    if (stepIndex === 6 || (useReport && stepIndex === 3)) {
                        checkApiConnection();
                        checkKlKaartTypes();
                    }
                }
            }
        }
    };

    const createFullQuery = async () => {
        let fullQueryClient = "SELECT ";

        clientFields.forEach((field, index) => {
            fullQueryClient += `${field.value} AS '${field.field}'`;
            if (index < clientFields.length - 1) {
                fullQueryClient += ", ";
            }
        });
        fullQueryClient += " FROM " + mainTbl;
        fullQueryClient += ` ${leftJoins}`;

        //TODO CLIENT FAM 
        // if (useFam) {
        //     let fullQueryFam = "SELECT ";
        //     clientFamFields.forEach((field, index) => {
        //         fullQueryFam += `${field.value} AS '${field.field}'`;
        //         if (index < clientFamFields.length - 1) {
        //             fullQueryFam += ", ";
        //         }
        //     });
        //     fullQueryFam += " FROM " + mainFamTbl;
        //     fullQueryFam += leftJoinsFam;
        //     console.log("fullQueryFam:", fullQueryFam);

        //     fullQueryClient += " UNION " + fullQueryFam;
        // }
        // setFullQuery(fullQueryClient + " UNION " + fullQueryFam);
     
        let emailField = clientFields.filter(field => field.field === "EMAIL").map(field => field.value);
        fullQueryClient +=  `  WHERE ${emailField} IS NOT NULL AND ${emailField} != ''
        GROUP BY ${emailField}`;

        //replace all occrurences of .email with .email2
        fullQueryClient += ` UNION
         ${fullQueryClient?.replace(/\.email/g, ".email2")};`;

        let url = SftGlobalData.baseURL_API + 'mailIntegration&action=exampleQuery';
        try {
            const response = await axios.post(url, { key: props.api, query: fullQueryClient }, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }
            });

            if (response.data?.length > 0) {
                setExampleDataQuery(response.data);
                setFullQuery(fullQueryClient);
            } else {
                console.log("something in config is wrong");
            }
        } catch (error) {
            console.log("something in config is wrong");
        }
    }

    

    const handleSetClientFields = (clientFieldValue, clientFieldId, clientFieldType) => {
        let updatedClientFields = [...clientFields];
        switch (clientFieldType) {
            case "field":
                updatedClientFields[clientFieldId] = {
                    ...updatedClientFields[clientFieldId],
                    field: clientFieldValue
                };
                break;
            case "value":
                updatedClientFields[clientFieldId] = {
                    ...updatedClientFields[clientFieldId],
                    value: clientFieldValue
                };
                break;
            case "delete":
                updatedClientFields.splice(clientFieldId, 1);
                break;
            default:
                break;

        }
        setClientFields(updatedClientFields);
    };

    const onAddNewClientFields = () => {
        setClientFields([...clientFields, { field: newClientMapField, value: newClientMappedField, canBeDeleted: true }]);
        setNewClientMapField('');
        setNewClientMappedField('');
    }

    const handleSetClientFamFields = (clientFieldValue, clientFieldId, clientFieldType) => {
        let updatedClientFamFields = [...clientFamFields];
        switch (clientFieldType) {
            case "field":
                updatedClientFamFields[clientFieldId] = {
                    ...updatedClientFamFields[clientFieldId],
                    field: clientFieldValue
                };
                break;
            case "value":
                updatedClientFamFields[clientFieldId] = {
                    ...updatedClientFamFields[clientFieldId],
                    value: clientFieldValue
                };
                break;
            case "delete":
                updatedClientFamFields.splice(clientFieldId, 1);
                break;
            default:
                break;
        }
        setClientFamFields(updatedClientFamFields);
    };


    const createReport = async () => {
        let url = SftGlobalData.baseURL_API + 'mailIntegration&action=createMyReport';
        let queries = { full: tempTblQueryFull, daily: tempTblQueryDaily, general: fullQuery };

        try {
            const response = await axios.post(url, { key: props.api, queries: queries, type: selectedIntegration, id: params.id == "+" ? mailIntegrationId : params.id }, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }
            });
            if(SftGlobalData.debug){
                console.log("url:", url);
                console.log("response:", response);
            }

            if (response.data?.success) {
                props.notify(props.t('mail_create_my_report_success'), 2000, "bottom-center", props.theme, 87879); 
            } else {
                props.notify(props.t('mail_create_my_report_fail'), 2000, "bottom-center", props.theme, 87879); 
            }
        } catch (error) {
            
        }
    }

    const buildConfigJson = () => {
        let config = {};
        config.client_main_table = mainTbl;
        config.client_fam_table = mainFamTbl;
        config.client_fields = clientFields;
        config.client_fam_fields = clientFamFields;
        config.client_joins = leftJoins;
        config.client_fam_joins = leftJoinsFam;
        config.use_fam = useFam;
        config.fam_use_client_card = famUseClientCard;
        config.tbl_temp_queries_full = tempTblQueryFull;
        config.tbl_temp_queries_daily = tempTblQueryDaily;
        config.myreports_full = '';
        config.myreports_daily = '';
        if(SftGlobalData.debug){
            console.log("config:", config);
        }
        return JSON.stringify(config);
    }

    const createOrUpdateMailIntegration = async () => {
        let url = SftGlobalData.baseURL_API + `mailIntegration&action=${params.id && params.id !== '+' ? 'update&id=' + params.id : 'create'}`;

        let postData = {
            key: props.api,
            type: selectedIntegration,
            list_id: listId,
            token: token,
            report_id_full: fullRepId,
            report_id_daily: dailyRepId,
            config: buildConfigJson(),
            active: active ? '1' : '0'
        };

        try {
            const response = await axios.post(url, postData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }
            });

            if (SftGlobalData.debug) {
                console.log("URL", url);
                console.log("response:", response);
            }
     
            if (response.data?.success) {
                props.notify(props.t('savedChanges'), 7500, "top-right", props.theme, 1);
                //get the id of the mailintegration that was created
                if (params.id == "+") {  
                    setMailIntegrationId(response.data.id); //set id for myReport 
                }
            } else {
                props.notify(props.t('errorOccured'), 7500, "top-right", props.theme, 1);
            }
        } catch (error) {
            if(SftGlobalData.debug){
                console.log("error:", error);
            }
            props.notify(props.t('errorOccured'), 7500, "top-right", props.theme, 1);
        }

    }

    return (
        <div className={(props.screenWidth) < 600 ? 'not_expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme : 'expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme} >

            {loading && (
                <div className={"container_list__title " + props.theme} style={{ display: "flex", justifyContent: "space-evenly", marginTop: 5, border: 1 }}>
                    <TailSpin height="40" width="40" color={Colors.sft_blue} ariaLabel='loading' />
                </div>
            )}

            {!loading && (
                <form>
                    <div className={"container_list__title " + props.theme}>
                        <h2>{currentStep > 0 || params.id != "+" ? `Mailintegration - ${selectedIntegration}` : 'Mailintegrations'}</h2>
                    </div>

                    <div className='container_split'>
                        <HorizontalLinearStepper
                            steps={steps}
                            t={props.t}
                            onStepChange={handleStepChange}
                            canProceed={canProceed}
                            activeStep={currentStep}
                            onFinishUrl={"/"+props.lang+"/mail-integrations/"}
                        />
                    </div>

                    {/* Step 0: Selection of Integration */}
                    {currentStep === 0 && (
                        <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', padding: '20px' }}>
                            {integrationTypes.map((integration) => (
                                <MailIntegrationOption
                                    key={integration.value}
                                    name={integration.label}
                                    icon={integration.icon}
                                    onSelect={handleSelectIntegration}
                                    isSelected={selectedIntegration === integration.key}
                                />
                            ))}
                        </div>
                    )}

                    {/* Step 1 and Step 2: Display Icon + Fields */}
                    {(currentStep === 1 || currentStep === 2) && selectedIcon && (
                        <div style={{ display: 'flex', gap: '30px', padding: '20px', alignItems: 'flex-start', margin: '2rem' }}>
                            {/* Left Side: Larger Icon */}
                            <div style={{ width: '25rem', textAlign: 'center' }}>
                                <img src={selectedIcon} alt={`${selectedIntegration} icon`} style={{ width: '100%', height: 'auto' }} />
                            </div>

                            <div style={{ flex: 1, marginLeft: '10rem' }}>
                                {currentStep === 1 && (
                                    <div>
                                        <TextField
                                            minRows={1}
                                            autoComplete="off"
                                            className={props.theme + '_accent _100perc margin_top_10px'}
                                            id="token"
                                            label="Token"
                                            size="small"
                                            sx={props.theme === 'light' ? lightTextStyle : darkTextStyle}
                                            value={token}
                                            onChange={(event) => setToken(event.target.value)}
                                        />
                                        <TextField
                                            minRows={1}
                                            autoComplete="off"
                                            className={props.theme + '_accent _100perc margin_top_10px'}
                                            id="listId"
                                            label="ListId"
                                            size="small"
                                            sx={props.theme === 'light' ? lightTextStyle : darkTextStyle}
                                            value={listId}
                                            onChange={(event) => setListId(event.target.value)}
                                        />
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                            <label className={'_100perc margin_top_10px'} style={{ fontSize: '0.9rem', alignItems: 'center', marginTop: '0.5rem' }}>
                                                <b>{props.t('active')}</b>
                                            </label>
                                            <Switch
                                                checked={active}
                                                className="align_right"
                                                style={{}}
                                                onChange={() => setActive(!active)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </div>
                                    </div>
                                )}


                                {currentStep === 2 && (
                                    <div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                            <label className={'_100perc margin_top_10px'} style={{ fontSize: '0.9rem', alignItems: 'center', marginTop: '0.5rem' }}>
                                                <b>{props.t('UseReport')}</b>
                                            </label>
                                            <Switch
                                                checked={useReport}
                                                className="align_right"
                                                style={{}}
                                                onChange={() => { setUseReport(!useReport); setDailyRepId(0); setFullRepId(0); }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </div>

                                        {
                                            useReport && (
                                                <>
                                                    <TextField
                                                        minRows={1}
                                                        autoComplete="off"
                                                        className={props.theme + '_accent _100perc margin_top_10px margin_right__5px'}
                                                        id="dailySyncRepId"
                                                        label={props.t('mail_daily_sync_report_id')}
                                                        size="small"
                                                        sx={props.theme === 'light' ? lightTextStyle : darkTextStyle}
                                                        value={dailyRepId}
                                                        onChange={(event) => setDailyRepId(event.target.value)}
                                                    />
                                                    <TextField
                                                        minRows={1}
                                                        autoComplete="off"
                                                        className={props.theme + '_accent _100perc margin_top_10px'}
                                                        id="fullSyncRepId"
                                                        label={props.t('mail_full_sync_report_id')}
                                                        size="small"
                                                        sx={props.theme === 'light' ? lightTextStyle : darkTextStyle}
                                                        value={fullRepId}
                                                        onChange={(event) => setFullRepId(event.target.value)}
                                                    />
                                                </>
                                            )
                                        }
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {currentStep === 3 && (
                        <div style={{ padding: '20px', alignItems: 'flex-start', margin: '2rem' }}>
                            {testingTmpTblQuery ? (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                        <TailSpin height="40" width="40" color={Colors.sft_blue} ariaLabel="loading" />
                                        <span style={{ color: Colors.sft_blue, fontSize: '1.2rem' }}>Testing Query</span>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {failedTestingTmpTblQuery && (
                                        <div className="error_text text_center">{props.t('mail_failed_to_test_query')}</div>
                                    )}
                                    <h4 className="error_text text_center">{props.t('mail_make_sure_tbls_start_with_t_mail')}</h4>

                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', padding: '20px', margin: '2rem' }}>
                                        {/* Full Query Section */}
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', flex: 1 }}>
                                            <div style={{ minHeight: '3rem' }}>
                                                {!isFullQueryValid.isValid && isFullQueryValid.message && (
                                                    <h4 className="error_text">{isFullQueryValid.message}</h4>
                                                )}
                                            </div>
                                            <textarea
                                                title="Full Query"
                                                className={props.theme + '_accent'}
                                                value={tempTblQueryFull}
                                                onChange={(e) => setTempTblQueryFull(e.target.value)}
                                                placeholder="Enter Full Query SQL code here..."
                                                style={{ width: '100%', height: '30rem', padding: '8px' }}
                                            />
                                        </div>

                                        {/* Daily Query Section */}
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', flex: 1 }}>
                                            <div style={{ minHeight: '3rem' }}>
                                                {!isDailyQueryValid.isValid && isDailyQueryValid.message && (
                                                    <h4 className="error_text">{isDailyQueryValid.message}</h4>
                                                )}
                                            </div>
                                            <textarea
                                                title="Daily Query"
                                                className={props.theme + '_accent'}
                                                value={tempTblQueryDaily}
                                                onChange={(e) => setTempTblQueryDaily(e.target.value)}
                                                placeholder="Enter Daily Query SQL code here..."
                                                style={{ width: '100%', height: '30rem', padding: '8px' }}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>


                    )}

                    {currentStep === 4 && (
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', padding: '20px', margin: '2rem' }}>
                            <div style={{ width: '100%' }}>
                                <TextField
                                    minRows={1}
                                    autoComplete="off"
                                    className={props.theme + '_accent _100perc margin_top_10px'}
                                    id="mainTable"
                                    label={props.t('mail_main_table')}
                                    size="small"
                                    sx={props.theme === 'light' ? lightTextStyle : darkTextStyle}
                                    value={mainTbl}
                                    onChange={(event) => setMainTbl(event.target.value)}
                                    fullWidth
                                />
                            </div>

                            <div style={{ width: '100%' }}>
                                <h2 style={{ textAlign: 'center' }}>{props.t('mail_client_fields')}</h2>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginLeft: '6rem', marginRight: '6rem' }}>
                                    {clientFields.map((field, i) => (
                                        <div key={i} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                            <TextField
                                                minRows={1}
                                                autoComplete="off"
                                                className={props.theme + '_accent _100perc margin_top_10px'}
                                                id={`clientMapField-${i}`}
                                                label=""
                                                size="small"
                                                sx={props.theme === 'light' ? lightTextStyle : darkTextStyle}
                                                value={field?.field}
                                                disabled={!field?.canBeDeleted}
                                                onChange={(event) => handleSetClientFields(event.target.value, i, 'field')}
                                                fullWidth
                                            />
                                            <TextField
                                                minRows={1}
                                                autoComplete="off"
                                                className={props.theme + '_accent _100perc margin_top_10px'}
                                                id={`clientMappedField-${i}`}
                                                label=""
                                                size="small"
                                                sx={props.theme === 'light' ? lightTextStyle : darkTextStyle}
                                                value={field?.value}
                                                disabled={!field?.canBeDeleted}
                                                onChange={(event) => handleSetClientFields(event.target.value, i, 'value')}
                                                fullWidth
                                            />

                                            {field?.canBeDeleted ? (
                                                <Button
                                                    style={{
                                                        color: props.theme === 'dark' ? Colors.dark_text : Colors.light_text,
                                                        height: "100%",
                                                        borderRadius: "50%",
                                                        justifyContent: "center",
                                                        alignItems: 'center',
                                                        marginTop: '0.5rem'
                                                    }}

                                                    onClick={() => handleSetClientFields('', i, 'delete')}>
                                                    <FontAwesomeIcon icon={faTrash} color={Colors.def_red} size="2x" />
                                                </Button>
                                            )
                                                :
                                                <div style={{ width: "8.6rem" }} />

                                            }
                                        </div>
                                    ))}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginLeft: '6rem', marginRight: '6rem' }}>
                                        <TextField
                                            minRows={1}
                                            autoComplete="off"
                                            className={props.theme + '_accent _100perc margin_top_10px'}
                                            id={`newClientMapField`}
                                            label=""
                                            size="small"
                                            sx={props.theme === 'light' ? lightTextStyle : darkTextStyle}
                                            value={newClientMapField}
                                            onChange={(event) => setNewClientMapField(event.target.value)}
                                            fullWidth
                                        />
                                        <TextField
                                            minRows={1}
                                            autoComplete="off"
                                            className={props.theme + '_accent _100perc margin_top_10px'}
                                            id={`newClientMappedField`}
                                            label=""
                                            size="small"
                                            sx={props.theme === 'light' ? lightTextStyle : darkTextStyle}
                                            value={newClientMappedField}
                                            onChange={(event) => setNewClientMappedField(event.target.value)}
                                            fullWidth
                                        />

                                        <Button
                                            style={{
                                                color: props.theme === 'dark' ? Colors.dark_text : Colors.light_text,
                                                height: "100%",
                                                borderRadius: "50%",
                                                justifyContent: "center",
                                                alignItems: 'center',
                                                marginTop: '0.5rem'
                                            }}

                                            onClick={() => onAddNewClientFields()}>
                                            <FontAwesomeIcon icon={faPlus} color={Colors.sft_blue} size="2x" />
                                        </Button>
                                    </div>
                                </div>


                                <h2 style={{ textAlign: 'center', marginTop: '2rem' }}>{props.t('mail_left_joins')}</h2>
                                <div style={{ marginLeft: '6rem', marginRight: '6rem' }}>{leftJoins}<br></br>...</div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginLeft: '6rem', marginRight: '6rem' }}>
                                    <TextField
                                        minRows={1}
                                        autoComplete="off"
                                        className={props.theme + '_accent _100perc margin_top_10px'}
                                        id={`newClientMapField`}
                                        label=""
                                        size="small"
                                        sx={props.theme === 'light' ? lightTextStyle : darkTextStyle}
                                        value={leftJoins}
                                        onChange={(event) => setLeftJoins(event.target.value)}
                                        fullWidth
                                    />
                                </div>

                                {/* <div style={{ marginTop: '1rem', marginLeft: '6rem', marginRight: '6rem' }}>
                                    OF Selectie gebaseerd:<br></br>JOIN table - JOIN alias - JOIN from table - JOIN to table - JOIN to field
                                </div> */}
                            </div>
                        </div>
                    )}


                    {currentStep === 5 && (
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', padding: '20px', margin: '2rem' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <label className={'_100perc margin_top_10px'} style={{ fontSize: '0.9rem', alignItems: 'center', marginTop: '0.5rem' }}>
                                    <b>{props.t('use_fam')}</b>
                                </label>
                                <Switch
                                    checked={useFam}
                                    className="align_right"
                                    style={{}}
                                    onChange={() => {
                                        setUseFam(!useFam);
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <label className={'_100perc margin_top_10px'} style={{ fontSize: '0.9rem', alignItems: 'center', marginTop: '0.5rem' }}>
                                    <b>{props.t('famUseClientCard')}</b>
                                </label>
                                <Switch
                                    checked={famUseClientCard}
                                    className="align_right"
                                    style={{}}
                                    onChange={() => setFamUseClientCard(!famUseClientCard)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </div>
                            {useFam && (
                                <>
                                    <div style={{ width: '100%' }}>
                                        <TextField
                                            minRows={1}
                                            autoComplete="off"
                                            className={props.theme + '_accent _100perc margin_top_10px'}
                                            id="mainFamTable"
                                            label={props.t('main_fam_table')}
                                            size="small"
                                            sx={props.theme === 'light' ? lightTextStyle : darkTextStyle}
                                            value={mainFamTbl}
                                            onChange={(event) => setMainFamTbl(event.target.value)}
                                            fullWidth
                                        />
                                    </div>

                                    <div style={{ width: '100%' }}>
                                        <h2 style={{ textAlign: 'center' }}>{props.t('mail_client_fam_fields')}</h2>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginLeft: '6rem', marginRight: '6rem' }}>
                                            {clientFamFields.map((field, i) => (
                                                <div key={i} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                    <TextField
                                                        minRows={1}
                                                        autoComplete="off"
                                                        className={props.theme + '_accent _100perc margin_top_10px'}
                                                        id={`clientMapField-${i}`}
                                                        label=""
                                                        size="small"
                                                        sx={props.theme === 'light' ? lightTextStyle : darkTextStyle}
                                                        value={field?.field}
                                                        disabled={true}
                                                        onChange={(event) => handleSetClientFamFields(event.target.value, i, 'field')}
                                                        fullWidth
                                                    />
                                                    <TextField
                                                        minRows={1}
                                                        autoComplete="off"
                                                        className={props.theme + '_accent _100perc margin_top_10px'}
                                                        id={`clientMappedField-${i}`}
                                                        label=""
                                                        size="small"
                                                        sx={props.theme === 'light' ? lightTextStyle : darkTextStyle}
                                                        value={field?.value}
                                                        // disabled={!field?.canBeDeleted}
                                                        onChange={(event) => handleSetClientFamFields(event.target.value, i, 'value')}
                                                        fullWidth
                                                    />

                                                        
                                                    <div style={{ width: "8.6rem" }} />

                                                </div>
                                            ))}
                                        </div>


                                        <h2 style={{ textAlign: 'center', marginTop: '2rem' }}>{props.t('mail_left_joins')}</h2>
                                        <div style={{ marginLeft: '6rem', marginRight: '6rem' }}>{leftJoinsFam}<br></br>...</div>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginLeft: '6rem', marginRight: '6rem' }}>
                                            <TextField
                                                minRows={1}
                                                autoComplete="off"
                                                className={props.theme + '_accent _100perc margin_top_10px'}
                                                id={`newClientMapField`}
                                                label=""
                                                size="small"
                                                sx={props.theme === 'light' ? lightTextStyle : darkTextStyle}
                                                value={leftJoinsFam}
                                                onChange={(event) => setleftJoinsFam(event.target.value)}
                                                fullWidth
                                            />
                                        </div>

                                        {/* <div style={{ marginTop: '1rem', marginLeft: '6rem', marginRight: '6rem' }}>
                                            OF Selectie gebaseerd:<br></br>JOIN table - JOIN alias - JOIN from table - JOIN to table - JOIN to field
                                        </div> */}
                                    </div>
                                </>
                            )}

                        </div>
                    )}

                    {currentStep === 6 && (
                        <div style={{ textAlign: 'center' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px' }}>
                                {/* API Connection Status */}
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                        {checkingApiConnection ? (
                                            <TailSpin height="40" width="40" color={Colors.sft_blue} ariaLabel="loading" />
                                        ) : apiConnectionSuccess ? (
                                            <FontAwesomeIcon icon={faCheck} color={Colors.sft_blue} size="2x" />
                                        ) : (
                                            <FontAwesomeIcon icon={faTimes} color={Colors.def_red} size="2x" />
                                        )}
                                        <span style={{ color: Colors.sft_blue, fontSize: '1.2rem' }}>{props.t('mail_test_api_connection')}</span>
                                    </div>
                                </div>

                                {/* Side-by-Side Klkaart Types */}
                                <div style={{ display: 'flex', justifyContent: 'center', gap: '2rem', marginTop: '1rem' }}>
                            
                                    {checkingKlKaart ? (
                                    <>
                                        <TailSpin height="40" width="40" color={Colors.sft_blue} ariaLabel="loading" />
                                        <span style={{ color: Colors.sft_blue, fontSize: '1.2rem' }}>{props.t('mail_check_klkaart_types')}</span>         
                                    </>
                                    ) : (
                                    
                                    <>
                                    {klkaartTypes.filter((type) => allowedKlkaartTypes.includes(parseInt(type))).length > 0 && (
                                        <div style={{ textAlign: 'left' }}>
                                            <span style={{ color: Colors.sft_blue, fontSize: '1.2rem', marginBottom: '0.5rem', display: 'block' }}>{props.t('mail_supported_klkaart_types')}</span>
                                            <ul style={{ listStyleType: 'disc', paddingLeft: '1rem' }}>
                                                {klkaartTypes.filter((type) => allowedKlkaartTypes.includes(parseInt(type))).map((type, index) => (
                                                <li key={index}>{type}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
  
                                    {/* <div style={{ textAlign: 'left' }}>
                                        <span style={{ color: Colors.sft_blue, fontSize: '1.2rem', marginBottom: '0.5rem', display: 'block' }}>{props.t('mail_unsupported_klkaart_types')}</span>
                                        <ul style={{ listStyleType: 'disc', paddingLeft: '1rem' }}>
                                            {klkaartTypes.filter((type) => !allowedKlkaartTypes.includes(parseInt(type))).map((type, index) => (
                                                <li key={index}>{type}</li>
                                            ))}
                                        </ul>
                                    </div> */}
                                    </>
                                )}
                                </div>
                            </div>
                        </div>
                    )}


                    {currentStep === 7 && (
                        <div style={{ width: '100%' }}>
                            <h2 style={{ textAlign: 'center' }}>{props.t('mail_example_of_query')}</h2>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginLeft: '6rem', marginRight: '6rem' }}>

                                {exampleDataQuery.length > 0 ? (
                                    <DataGridPro
                                        className={props.theme}
                                        columns={gridIntegrationColumns}
                                        rows={exampleDataQuery.map((row, index) => {
                                            // Assuming `row` has fields that match those in `gridIntegrationColumns`
                                            return {
                                                id: index, // You may want to set a unique identifier if `id` is required
                                                ...row    // Spread `row` properties if they match your `gridIntegrationColumns` fields
                                            };
                                        })}
                                        loading={loading}
                                        rowHeight={38}
                                        autoHeight={true}
                                        pagination={true}
                                    />
                                ):
                                <div style={{ display: 'flex', justifyContent: 'center', gap: '2rem', marginTop: '1rem' }}>
                                    <TailSpin height="40" width="40" color={Colors.sft_blue} ariaLabel='loading' />
                                </div>
                            }

                            </div>
                        </div>
                    )}

                    {currentStep === 8 && (
                        <div style={{ width: '100%' }}>
                            <h2 style={{ textAlign: 'center' }}>{props.t('mail_finish')}</h2>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '1rem',
                                    margin: '2rem 6rem'
                                }}
                            >
                                <Button
                                    color="inherit"
                                    variant="outlined"
                                    sx={{
                                        borderRadius: 3,
                                        border: `2px solid ${Colors.sft_blue}`,
                                        color: Colors.sft_blue,
                                    }}
                                    onClick={createOrUpdateMailIntegration}
                                >
                                    {params.id === "+" ? props.t('mail_create') : props.t('mail_update')}
                                </Button>
                                <Button
                                    color="inherit"
                                    variant="outlined"
                                    sx={{
                                        borderRadius: 3,
                                        border: `2px solid ${Colors.sft_blue}`,
                                        color: Colors.sft_blue,
                                    }}
                                    onClick={handleOpenModal}
                                >
                                    {props.t('mail_show_full_query')}
                                </Button>
                                <Button
                                    color="inherit"
                                    variant="outlined"
                                    sx={{
                                        borderRadius: 3,
                                        border: `2px solid ${Colors.sft_blue}`,
                                        color: Colors.sft_blue,
                                    }}
                                    onClick={createReport}
                                    disabled={params.id === "+" && mailIntegrationId === 0}
                                >
                                    {props.t('mail_create_report')}
                                </Button>
                            </div>
                        </div>
                    )}

                    {/* Modal Component */}
                    <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="md">
                        <DialogTitle>{props.t('mail_full_query_title')}</DialogTitle>
                        <DialogContent>
                            <div
                                style={{
                                    whiteSpace: 'pre-wrap',
                                    wordWrap: 'break-word',
                                    padding: '1rem', // Padding inside the query display area
                                    backgroundColor: '#f5f5f5', // Light background for contrast
                                    borderRadius: '4px', // Rounded corners
                                    maxHeight: '400px', // Max height for scroll
                                    overflowY: 'auto', // Scroll if content overflows
                                    fontFamily: 'monospace', // Monospace font for code-like appearance
                                    fontSize: '14px', // Set font size
                                    lineHeight: '1.5', // Line height for readability
                                    color: '#333', // Dark text for better contrast
                                }}
                            >
                                {fullQuery}
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseModal} color="primary">
                                {props.t('close')}
                            </Button>
                        </DialogActions>
                    </Dialog>

                </form>
            )}
        </div>
    );
}

export default MailIntegrationsSetup;

// Styles for light and dark themes
const lightTextStyle = {
    '& .MuiOutlinedInput-input': { color: Colors.light_text },
    '& .MuiInputLabel-root': { color: Colors.light_text_accent },
    borderRadius: 3,
    marginTop: 0,
};

const darkTextStyle = {
    '& .MuiOutlinedInput-input': { color: Colors.dark_text },
    '& .MuiInputLabel-root': { color: Colors.dark_text_accent },
    borderRadius: 3,
    marginTop: 0,
};
