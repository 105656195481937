import React, { useState, useEffect }from 'react';
import { TailSpin } from  'react-loader-spinner'
import axios from 'axios';
import SftGlobalData from '../../library/SftGlobalData';
import Colors from '../../library/Colors';
import Button from '@mui/material/Button';
import { Input } from '@mui/material';


const LicenceSMSForm = (props) =>  {
    const [loading, setLoading] = useState(true);
    const [type, setType] = useState("spryng");
    const [clientId, setClientId] = useState("");
    const [token, setToken] = useState("");
    const [active, setActive] = useState(false);
    const [prepaid, setPrepaid] = useState(false);
    const [email, setEmail] = useState("");
    const [orig, setOrig] = useState("");
    const [curCredits, setCurCredits] = useState("0");
    const [credits, setCredits] = useState("0");


    useEffect (()=>{
        try{
            let url = SftGlobalData.baseURL_API+'client&action=fetchSms';
            let postData = {key: props.api}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    setClientId(response.data.appId);
                    setType(response.data.provider.toLowerCase());
                    setToken(response.data.token);
                    setActive(response.data.active);
                    setPrepaid(response.data.prepaid);
                    setCurCredits(response.data.credits);
                    setOrig(response.data.orig);
                    setEmail(response.data.email);
                    setLoading(false);
                });
        } catch(e){
            console.error(e);
        }
    }, [props.api]);

    const addCredits = async () => {
        if(type !== "---"){
            try{
            let url = SftGlobalData.baseURL_API+'client&action=addSmsCredits';
            let postData = {key: props.api, provider: type, credits: credits, user: props.username, sftUserId: props.userId}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    setCurCredits(parseInt(curCredits) + parseInt(credits));
                    setCredits("0");
                    // TODO: Notify success
                });
        } catch(e){
            console.error(e);
        }
        } else {
            // TODO: Error notify, No account.
        }
    }

    const onSave = async () => {
        if(type !== "---"){
            try{
                let url = SftGlobalData.baseURL_API+'client&action=updateSmsSettings';
                let postData = {
                    key: props.api,
                    provider: type,
                    app_id: clientId,
                    token: token,
                    prepaid: prepaid, user: props.username, sftUserId: props.userId,
                    email: email,
                    orig: orig
                }
                axios.post(url, postData, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                    .then(response => {
                        if(SftGlobalData.debug){
                            console.log('url', url);
                            console.log('postData', postData);
                            console.log('resp', response);
                        }
                        setClientId(response.data.id);
                        // TODO: Notify success
                    });
            } catch(e){
                console.error(e);
                // TODO: Error notify, bad request.
            }
        } else {
            // TODO: Error notify, select type.
        }
    }

    const onSaveWithNewToken = async (newToken) => {
        if(type !== "---"){
            try{
                let url = SftGlobalData.baseURL_API+'client&action=updateSmsSettings';
                let postData = {
                    key: props.api,
                    provider: type,
                    app_id: clientId,
                    token: newToken,
                    prepaid: prepaid, user: props.username, sftUserId: props.userId,
                    email: email,
                    orig: orig
                }
                axios.post(url, postData, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                    .then(response => {
                        if(SftGlobalData.debug){
                            console.log('url', url);
                            console.log('postData', postData);
                            console.log('resp', response);
                        }
                        setClientId(response.data.id);
                        setEmail(response.data.email);
                        // TODO: Notify success
                    });
            } catch(e){
                console.error(e);
                // TODO: Error notify, bad request.
            }
        } else {
            // TODO: Error notify, select type.
        }
    }

    function generateToken(){
        var length           = 9;
        var result           = '';
        var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            if(i === 4){
                result += '-';
            }else{
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
        }
        
        return result;
    }

    const onGenerate = () => {
        if(token === ""){
            let newToken = generateToken();
            setToken(newToken);
            onSaveWithNewToken(newToken);
        } else {
            onSave();
        }
    }

    const onActiveChange = () => {
        if(!active){
            setClientId(clientId.replaceAll('---', ''));
            setToken(token.replaceAll('---', ''));
        } else{
            setClientId("---"+clientId+"---");
            setToken("---"+token+"---");
        }
        setActive(!active);
        
    }

    return (
        <div className={props.theme + " _100perc"} >
            {loading && <div style={{display:"flex", justifyContent: "space-evenly", marginTop: 5}}>
                <TailSpin 
                    height="40"
                    width="40"
                    color={Colors.sft_blue}
                    ariaLabel='loading'
                />
            </div>}
            {!loading && <div className='container_split _100perc' >
                {/* LEFT SIDE */}
                <div className='_45perc'>
                    <label><b>Provider</b></label>
                    <select className={props.theme + " _100perc"} value={type} onChange={(e)=>{setType(e.target.value)}}>
                        <option value="---">---</option>
                        {/*<option value="proximus" >Proximus</option>*/}
                        <option value="spryng" >Spryng</option>
                    </select>

                    <div className={props.theme + " _100perc"} style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center", lineHeight: 1}}>
                        <p className='padding_top__10px'><b>ID:</b></p>
                        <Input disabled={!active || props.disabled} className={props.theme + " _90perc margin_top_10px"} placeholder='client id' value={clientId} onChange={(e)=>{setClientId(e.target.value);}}/>
                    </div>
                    
                    <div className={props.theme + " _100perc"} style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center", lineHeight: 1}}>
                        <p className='padding_top__10px'><b>Token:</b></p>
                        <Input disabled={!active || props.disabled} className={props.theme + " _90perc margin_top_10px"} placeholder='token' value={token} onChange={(e)=>{setToken(e.target.value);}}/>
                    </div>

                    <div className={props.theme + " _100perc"} style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center", lineHeight: 1}}>
                        <p className='padding_top__10px'><b>Email:</b></p>
                        <Input disabled={!active} className={props.theme + " _90perc margin_top_10px"} placeholder='email' value={email} onChange={(e)=>{setEmail(e.target.value);}}/> 
                    </div>

                    
                </div> 
                {/* RIGHT SIDE */}
                <div className='_45perc '>
                    <div className={props.theme + " _100perc"} style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center", paddingLeft: 10}}>
                        <p><b>Active?</b></p>
                        <label className="switch align_right">
                            <input disabled={props.disabled} type="checkbox" checked={active} onChange={() => {onActiveChange()}}/>
                            <span className={"slider slider_" + props.theme + " round"}></span>
                        </label>
                    </div>
                    <div className={props.theme + " _100perc"} style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center", paddingLeft: 10, lineHeight: 1}}>
                        <p><b>Credits:</b></p>
                        <label className="align_right text_right">
                            <span>{curCredits} &nbsp;&nbsp;&nbsp;</span>
                        </label>
                    </div>
                    {!props.disabled && <div className={props.theme + " _100perc"} style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center", paddingLeft: 10}}>
                        <Input inputProps={{inputMode: "numeric", pattern: '[0-9]*'}} className={props.theme + " _80perc margin_bottom_10px"} placeholder='Credits' value={credits} onChange={(e)=>{setCredits(e.target.value);}}/>
                        <Button className={"modal_button_container_no_margin_total__button"} onClick={()=>{addCredits();}}>{props.t('add')}</Button>
                    </div>}
                    <div className={props.theme + " _100perc"} style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center", paddingLeft: 10}}>
                        <p><b>Prepaid?</b></p>
                        <label className="switch align_right">
                            <input disabled={props.disabled} type="checkbox" checked={prepaid} onChange={() => {setPrepaid(!prepaid);}}/>
                            <span className={"slider slider_" + props.theme + " round"}></span>
                        </label>
                    </div>
                    <div className={props.theme + " _100perc"} style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center", paddingLeft: 10, lineHeight: 1}}>
                        <p className='padding_top__10px'><b>Origin:</b></p>
                        <Input disabled={!active} className={props.theme + " _90perc margin_top_10px"} placeholder='origin' value={orig} onChange={(e)=>{setOrig(e.target.value);}}/> 
                    </div>
                </div>
            </div>}
            <Button className={"modal_button_container_no_margin__button margin_top_15px _100perc"} onClick={()=>{onSave();}}>{props.t('save')}</Button>
            {clientId === "" && <Button className={"modal_button_container_no_margin__button _100perc"} onClick={()=>{onGenerate();}}>{props.t('generate')}</Button>}
        </div>
    );
}

export default LicenceSMSForm;
