import React, { useState, useEffect }from 'react';
import axios from 'axios';
import SftGlobalData from '../../library/SftGlobalData';
import 'react-toastify/dist/ReactToastify.css';
import { DataGridPro } from '@mui/x-data-grid-pro';

const LicenceShippingForm = (props) =>  {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [searchValue, setSearchValue] = useState("");

    useEffect (()=>{
        try{
            let url = SftGlobalData.baseURL_API+'client&action=fetchShippingInfo';
            let postData = {key: props.api}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    setData(response.data);
                    setLoading(false);
                });
        } catch(e){
            console.error(e);
        }
    }, [props.api]);

    const gridWebhookColumns = [
        {
            field: 'id',
            headerName: 'ID',
            minWidth: 75,
            width: 75,
            align: "left",
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 200,
            align: "left",
        },
        {
            field: 'name2',
            headerName: 'Name 2',
            width: 100,
            align: "left",
        },
        {
            field: 'address1',
            headerName: 'Address 1',
            minWidth: 200,
            width: 200,
            align: "left",
        },
        {
            field: 'address2',
            headerName: 'Address 2',
            width: 100,
            align: "left",
        },
        {
            field: 'zip',
            headerName: 'ZIP',
            width: 75,
            align: "left",
        },
        {
            field: 'city',
            headerName: 'City',
            width: 150,
            align: "left",
        },
        {
            field: 'country',
            headerName: 'Country',
            width: 100,
            align: "left",
        },
        {
            field: 'tel1',
            headerName: 'Tel. 1',
            width: 110,
            align: "left",
        },
        {
            field: 'tel2',
            headerName: 'Tel. 2',
            width: 100,
            align: "left",
        },
        {
            field: 'mobile',
            headerName: 'Mobile',
            width: 100,
            align: "left",
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 175,
            align: "left",
        },
        {
            field: 'divers',
            headerName: 'Divers',
            width: 100,
            align: "left",
        },
      ];

    function DataGrid() {
        return (
          <div className={props.theme} style={{width: '100%'}}>
            <input 
                type="text"
                name="search"
                className={props.theme+"_accent_darker "}
                style={{marginTop: 0 }}
                placeholder={props.t('search')}
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
            />
            
            {props.accountId !== "0" && <DataGridPro
                className={props.theme}
                columns={gridWebhookColumns}
                rows={data.filter(row => (row.klde_id.match(new RegExp(searchValue, "i")) || row.klde_naam1.match(new RegExp(searchValue, "i")) || row.klde_naam2.match(new RegExp(searchValue, "i")) || row.klde_adres1.match(new RegExp(searchValue, "i")) || row.klde_gemeente.match(new RegExp(searchValue, "i"))))
                .map((row, index) => {return {
                        id: row.klde_id,
                        name: row.klde_naam1,
                        name2: row.klde_naam2,
                        address1: row.klde_adres1,
                        address2: row.klde_adres2,
                        zip: row.klde_postcode,
                        city: row.klde_gemeente,
                        country: row.klde_country,
                        tel1: row.klde_tel1,
                        tel2: row.klde_tel2,
                        mobile: row.klde_gsm,
                        email: row.klde_email,
                        divers: row.klde_divers
                    }
                })}
                //{...data}
                rowHeight={38}
                style={{height: 400}}
                pagination={true}
                loading={loading}

                />   }       
          </div>
        );
    }


    return (
        <div>
            {DataGrid()}
        </div>
    );
}

export default LicenceShippingForm;
