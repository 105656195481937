import React, { useEffect, useState } from 'react';
import SftGlobalData from '../../library/SftGlobalData';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Colors from '../../library/Colors';


const ExactAddModuleForm = (props) =>  {  
    const [selectedModule, setSelectedModule] = useState("");
    const [selectedInterval, setSelectedInterval] = useState("weekly");
    const [selectedIntervalWeeklyOn, setSelectedIntervalWeeklyOn] = useState("1")
    const [selectedIntervalMonthlyOn, setSelectedIntervalMonthlyOn] = useState("begin")
    const [selectedHours, setSelectedHours] = useState('');
    const [selectedMinutes, setSelectedMinutes] = useState('');

    const handleHoursChange = (event) => {
        const { value } = event.target;
        // Ensure value is between 0 and 23 (inclusive) and numeric
        if (/^\d*$/.test(value) && value >= 0 && value <= 23) {
          setSelectedHours(value);
        }
    };
    
    const handleMinutesChange = (event) => {
        const { value } = event.target;
        // Ensure value is between 0 and 59 (inclusive) and numeric
        if (/^\d*$/.test(value) && value >= 0 && value <= 59) {
          setSelectedMinutes(value);
        }
    };

    return (
        <div className='columnContainerStart' style={{width: "40%", marginRight: 10, marginLeft: 20}}>
            <h3>Add module</h3>
            <FormControl className={props.theme+ '_accent _100perc'}>
              <InputLabel id="demo-simple-select-label">Module</InputLabel>
              <Select
                className={props.theme+"_accent "+props.theme+"_text _100perc"}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedModule}
                label="Module"
                onChange={(event)=>{const {target: { value },} = event; setSelectedModule(value);}}
                style={{height: 40, paddingTop: 8, paddingBottom: 8}}>
                    {props.modules.filter(module => !props.row.modules.some(rowModule => rowModule.api_module_id === module.id))
                        .map((row, index) => (
                            <MenuItem
                                key={index}
                                value={row?.id}
                            >
                                {row?.id} - {row?.name} ({row?.description})
                            </MenuItem>
                        ))
                    }
              </Select>
            </FormControl>
            <div className='rowContainerBetween' style={{marginTop: 10, marginBottom: 10}}>
                <div className='columnContainerStart _100perc margin_right__10px'>
                    <FormControl className={props.theme+ '_accent '}>
                      <InputLabel id="demo-simple-select-label">Interval</InputLabel>
                      <Select
                        className={props.theme+"_accent "+props.theme+"_text _100perc"}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedInterval}
                        label="Interval"
                        onChange={(event) => setSelectedInterval(event.target.value)}
                        style={{height: 40, paddingTop: 8, paddingBottom: 8}}>
                            <MenuItem value="daily">Daily</MenuItem>
                            <MenuItem value="weekly">Weekly</MenuItem>
                            <MenuItem value="monthly">Monthly</MenuItem>
                      </Select>
                    </FormControl>
                </div>




                <div className='columnContainerStart _100perc '>


                    {selectedInterval === "weekly" && (
                        <FormControl className={props.theme + '_accent '}>
                            <InputLabel id="demo-simple-select-label">On</InputLabel>
                            <Select
                                className={props.theme + "_accent " + props.theme + "_text _100perc"}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedIntervalWeeklyOn}
                                label="Interval"
                                disabled={selectedInterval !== "weekly" && selectedInterval !== "monthly"}
                                onChange={(event) => setSelectedIntervalWeeklyOn(event.target.value)}
                                style={{ height: 40, paddingTop: 8, paddingBottom: 8 }}>
                                <MenuItem value="1">Monday</MenuItem>
                                <MenuItem value="2">Tuesday</MenuItem>
                                <MenuItem value="4">Wednesday</MenuItem>
                                <MenuItem value="8">Thursday</MenuItem>
                                <MenuItem value="16">Friday</MenuItem>
                                <MenuItem value="32">Saturday</MenuItem>
                                <MenuItem value="64">Sunday</MenuItem>
                            </Select>
                        </FormControl>
                    )}

                    {selectedInterval === "monthly" && (
                        <FormControl className={props.theme + '_accent '}>
                            <InputLabel id="demo-simple-select-label">On</InputLabel>
                            <Select
                                className={props.theme + "_accent " + props.theme + "_text _100perc"}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedIntervalMonthlyOn}
                                label="Interval"
                                disabled={selectedInterval !== "weekly" && selectedInterval !== "monthly"}
                                onChange={(event) => setSelectedIntervalMonthlyOn(event.target.value)}
                                style={{ height: 40, paddingTop: 8, paddingBottom: 8 }}>
                                <MenuItem value="begin">Start of month</MenuItem>
                                <MenuItem value="end">End of month</MenuItem>
                            </Select>
                        </FormControl>

                    )}

                </div>
            </div>

            <div className='rowContainerBetween' >
                <TextField
                    className={props.theme+ '_accent _100perc margin_right__10px '}
                    label="Hour"
                    value={selectedHours}
                    onChange={handleHoursChange}
                    variant="outlined"
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.sft_blue
                        }} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.sft_blue
                            }}}
                  />
                <TextField
                    className={props.theme+ '_accent _100perc '}
                    label="Minute"
                    value={selectedMinutes}
                    onChange={handleMinutesChange}
                    variant="outlined"
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.sft_blue
                        }} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.sft_blue
                            }}}
                  />
            </div>
            <Button className="modal_button_container_no_margin__button margin_top_15px" style={{width: '100%'}} onClick={()=>{props.save(props.row, selectedModule, selectedInterval, selectedIntervalWeeklyOn, selectedIntervalMonthlyOn, selectedHours, selectedMinutes);}}>{props.t("add")}</Button>
        </div>
    )
}


export default ExactAddModuleForm;