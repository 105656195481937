import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import SftGlobalData from '../../library/SftGlobalData';
import Colors from '../../library/Colors';
import { Button, FormControl, InputLabel, MenuItem, Select, Switch, TextField } from '@mui/material';

const LicenceDoorCounterGenerateModal = (props) => {

    const [doorcountType, setDoorcountType] = useState("");
    const [addToLicCLientMod, setAddToLicCLientMod] = useState(true);
    const [doorcounterUserId, setDoorcounterUserId] = useState("");
    const [doorcounterUserIdError, setDoorcounterUserIdError] = useState("");
    const [tokenOrApiKey, setTokenOrApiKey] = useState("");
    const [tokenOrApiKeyError, setTokenOrApiKeyError] = useState("");
    const [data, setData] = useState([]);
    const [isActive, setIsActive] = useState(true);
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {

        switch (doorcountType) {
            case 13:
                (!doorcounterUserId || !tokenOrApiKey) ? setDisabled(true) : setDisabled(false);
                (!doorcounterUserId) ? setDoorcounterUserIdError("User Id is required") : setDoorcounterUserIdError("");
                (!tokenOrApiKey) ? setTokenOrApiKeyError("Token is required") : setTokenOrApiKeyError("");
                break;
            case 59:
                (!tokenOrApiKey) ? setDisabled(true) : setDisabled(false);
                (!tokenOrApiKey) ? setTokenOrApiKeyError("ApiKey is required") : setTokenOrApiKeyError("");
                break;
        }

    }, [doorcounterUserId, tokenOrApiKey]);

    const save = () => {
        setDisabled(true);
        let configJson = {};
        configJson.user_id = doorcounterUserId ?? "";

        try {
            let url = SftGlobalData.baseURL_API + 'client&action=generateDoorCounter';
            let postData = {
                mod_id: doorcountType,
                chk_module: addToLicCLientMod,
                is_active: isActive ? "Y" : "N",
                key: props.api,
                doorcounter_user_config: JSON.stringify(configJson),
                token_or_api_key: tokenOrApiKey,
                user: props.username,
                sftUserId: props.userId
            }
            console.log("postData:", postData)

            axios.post(url, postData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }
            })
                .then(response => {
                    if (SftGlobalData.debug) {
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    let tempData = data;
                    tempData.push(response.data);
                    setData(tempData);
                    props.success();
                    props.refresh();
                });
        } catch (e) {
            console.error(e);
        }
    }


    return (
        <div className="columnContainerBetween" style={{ width: '100%', height: 250 }} >
            <div className="columnContainerBetween" style={{ width: '100%', textAlign: 'left'}} >     
            <div className="rowContainerBetween margin_bottom_15px" style={{ width: '100%'}} >
                <div style={{ textAlign: 'left', width: '30%' }}>
                    <FormControl className={`${props.theme}_accent _100perc`}>
                        <InputLabel id="lblDoorCounterType">Doorcounter type</InputLabel>
                        <Select
                            className={`${props.theme}_accent ${props.theme}_text _100perc`}
                            labelId="lblDoorCounterType"
                            id="doorcountType-select"
                            value={doorcountType}
                            label="Doorcounter type"
                            onChange={(event) => {
                                const { value } = event.target;
                                setDoorcountType(value);
                                setDoorcounterUserId("");
                            }}
                            style={{ height: 40, paddingTop: 8, paddingBottom: 8 }}
                        >
                            <MenuItem value={13}>Crosscan</MenuItem>
                            <MenuItem value={59}>Vemcount</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', justifyContent: 'center'}}>
                    <span><b>Activate</b></span>
                    <Switch
                        disabled={props.disabled}
                        checked={addToLicCLientMod}
                        onChange={() => setAddToLicCLientMod(!addToLicCLientMod)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </div>
            </div>

            
            {doorcountType === 13 && (
                <FormControl className={` _100perc margin_bottom_15px`}>
                    <TextField
                        type='number'
                        className={` _100perc`}
                        label="User Id"
                        error={!!doorcounterUserIdError}
                        helperText={doorcounterUserIdError}
                        value={doorcounterUserId}
                        onChange={(event) => setDoorcounterUserId(event.target.value)}
                        variant="outlined"
                    />
                </FormControl>
            )}

            {doorcountType && (
                <FormControl className={`_100perc`}>
                    <TextField
                        className={`_100perc`}
                        label={doorcountType === 13 ? 'Token' : 'Api key'}
                        error={!!tokenOrApiKeyError}
                        helperText={tokenOrApiKeyError}
                        value={tokenOrApiKey}
                        onChange={(event) => setTokenOrApiKey(event.target.value)}
                        variant="outlined"
                    />
                </FormControl>
            )}
            </div>
            <div>
                <Button
                    variant="outlined"
                    sx={{
                        borderRadius: 3,
                        border: `2px solid ${Colors.sft_blue}`,
                        color: Colors.sft_blue,
                        width: '100%',
                    }}
                    disabled={disabled}
                    onClick={save}
                >
                    {props.t('save')}
                </Button>
            </div>
        </div>
    );
};

export default LicenceDoorCounterGenerateModal;
