import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SftGlobalData from '../../library/SftGlobalData';
import axios from 'axios';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import LicenceCheckBox from '../../components/licence/LicenceCheckBox';
import { Box, Button, Paper, Stack, Tab, Tabs, styled } from '@mui/material';
import { faPencilAlt, faPlay } from '@fortawesome/free-solid-svg-icons';
import Colors from '../../library/Colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExactAddModuleForm from './ExactAddModuleForm';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import ExactModal from './ExactModal';
import PropTypes from 'prop-types';
import ExactClientTable from './ExactClientTable';

const StyledModal = styled(ModalUnstyled)`
    position: fixed;
    text-align: center;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    `;

const Backdrop = styled('div')`
    z-index: -1;
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    `;

const style = {
    border: '0px solid #000',
    borderRadius: 6,
    p: 2,
    px: 4,
    pb: 3,
};

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div style={{padding: 5, paddingTop: 10}}>{children}</div>
          </Box>
        )}
      </div>
    );
  }
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
  }

let customerRow = {};

const ExactScreen = (props) =>  {  
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [modules, setModules] = useState([]);
    const [modal, setModal] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const [modalTarget, setModalTarget] = useState({});
    const [openDetailPanelRowId, setOpenDetailPanelRowId] = useState([]);
    const [selectedModule, setSelectedModule] = useState("");
    const [selectedInterval, setSelectedInterval] = useState("weekly");
    const [selectedIntervalWeeklyOn, setSelectedIntervalWeeklyOn] = useState("1")
    const [selectedIntervalMonthlyOn, setSelectedIntervalMonthlyOn] = useState("begin")
    const [monthlyOn, setMonthlyOn] = useState([4095, 1]); 
    const [selectedHours, setSelectedHours] = useState('');
    const [selectedMinutes, setSelectedMinutes] = useState('');
    const [selectedCustRow, setSelectedCustRow] = useState({});
    const [value, setValue] = useState(0);
    let navigate = useNavigate();
    const apiRef = useGridApiRef();
    const gridColumns = [
        {
            field: 'id',
            headerName: "ID",
            width: 75,
            align: "left",
        },
        {
            field: 'external_name',
            headerName: "External name",
            headerClassName: 'bold',
            minWidth: 175,
            width: 400,
            align: "left",
        },
        {
            field: 'updated_at',
            headerName: "Updated at",
            headerClassName: 'bold',
            minWidth: 160,
            width: 160,
            align: "left",
        },
    ];

    const DetailPanelContent = ( row ) => {
        //console.log("modules", row.row.modules);
        //console.log(row.row);
        customerRow = row.row;
        return (
            <Stack
            sx={{ py: 1, height: '100%', boxSizing: 'border-box' }}
            direction="column"
            >
                <Paper sx={{ flex: 1, mx: 'auto', width: '95%', p: 1 }}>
                    <Stack direction="column" spacing={1} sx={{ height: 1 }}>
                        <div className='rowContainerBetween' style={{ flex: 1, width: "100%" }}>
                            <DataGridPro
                              density="compact"
                              columns={[
                                { 
                                    field: 'api_module_id', 
                                    headerName: 'ID', 
                                    headerClassName: 'bold',
                                    width: 75,
                                    align: "left",
                                },
                                { 
                                    field: 'name', 
                                    headerName: 'Module', 
                                    headerClassName: 'bold',
                                    minWidth: 175,
                                    width: 250,
                                    align: "left",
                                },
                                { 
                                    field: 'schedule', 
                                    headerName: 'Next run', 
                                    headerClassName: 'bold',
                                    minWidth: 175,
                                    width: 250,
                                    align: "left",
                                    renderCell: (params) => {
                                        return <div>{params.row.schedule?.nextRun}</div>
                                    }
                                },
                                {
                                    field: 'edit',
                                    headerName: ((props.login.sft_status !== "0" && props.login.sft_group === "SUPER") ? props.t('edit') : ""),
                                    minWidth: 60,    
                                    maxWidth: 60,  
                                    align: 'center',      
                                    headerAlign: 'center', 
                                    renderCell: (params) => {
                                    return  (props.login.sft_status !== "0" && props.login.sft_group === "SUPER") ? <Button style={{color: (props.theme === 'dark' ? Colors.dark_text : Colors.light_text), height:"100%", borderRadius: "50%" }} onClick={()=>{setModal(true); setModalContent("edit"); setSelectedModule(params.row?.api_module_id); setModalTarget(params.row);}} >
                                                <FontAwesomeIcon icon={faPencilAlt} color={Colors.def_orange} size="2x" />
                                            </Button> : <div></div>;
                                    }
                                },
                                {
                                    field: 'test',
                                    headerName: ((props.login.sft_status !== "0" && props.login.sft_group === "SUPER") ? props.t('Test') : ""),
                                    minWidth: 60,    
                                    maxWidth: 60,  
                                    align: 'center',      
                                    headerAlign: 'center', 
                                    renderCell: (params) => { 
                                    return  (props.login.sft_status !== "0" && props.login.sft_group === "SUPER" && params.row.api_module_id != "5") ? <Button style={{color: (props.theme === 'dark' ? Colors.dark_text : Colors.light_text), height:"100%", borderRadius: "50%" }} onClick={()=>{setModal(true); setModalContent("test"); setSelectedModule(params.row?.api_module_id); setModalTarget(params.row);}} >
                                                <FontAwesomeIcon icon={faPlay} color={Colors.def_green} size="2x" />
                                            </Button> : <div></div>;
                                    }
                                },
                                {
                                    field: 'error',
                                    headerName: "Error",
                                    headerAlign: 'center',
                                    align: 'center',
                                    headerClassName: 'bold',
                                    width: 100,
                                    renderCell: (params)=>{ return <LicenceCheckBox key={params.row.api_customer_link_id} disabled={params.row.error !== "1"} theme={props.theme} checked={params.row.error ==="1"} api={props.api} database={"EXACT-ONLINE"} mod={{id: params.row.id}} /> }
                                },
                              ]}
                              rows={row.row.modules}
                              style={{flex: 1, heigth: "100%"}}
                              hideFooter
                            />
                            <ExactAddModuleForm row={row.row} modules={modules} save={(row, mod, modInt, modIntWeeklyOn, modIntMonthlyOn, modHour, modMinute)=>{addModuleHandler(row, mod, modInt, modIntWeeklyOn, modIntMonthlyOn, modHour, modMinute)}} t={props.t} theme={props.theme}/>
                        </div>
                        
                    </Stack>
                </Paper>
            </Stack>
        );
    }


    const addModuleHandler = (row, mod, modInt, modIntWeeklyOn, modIntMonthlyOn, modHour, modMinute) => {
        if(
            modHour !== "" && 
            modMinute !== "" &&
            modInt !== "" && 
            modIntWeeklyOn !== "" &&
            modIntMonthlyOn !== "" &&
            mod !== ""
        ){
            setSelectedModule(mod);
            setSelectedInterval(modInt);
            setSelectedIntervalWeeklyOn(modIntWeeklyOn);
            handleMonthlyOn(modIntMonthlyOn);
            setSelectedHours(modHour);
            setSelectedMinutes(modMinute);
            setModal(true); setModalContent("add"); setModalTarget(row);
        } else {
            // TODO: fill in all fields message error msg's toevoegen
        }
    }

    const handleMonthlyOn = (value) => {
        setSelectedIntervalMonthlyOn(value);
        if (value === "begin"){
            setMonthlyOn([4095, 1]); // Start of month $this->monthlyOn[1] = 1073741824, you should check for the 30th bit (pow(2, 30)).
        } else if (value === "end") {
            setMonthlyOn([4095, 2147483648]); // End of month $this->monthlyOn[1] = 2147483648, you should check for the 31st bit (pow(2, 31)).
        }
    };

    useEffect(() => {
        loadData();
        props.changeScreen('Licence');
    },[props.api]);

    const loadData = () => {
        let url = SftGlobalData.baseURL_API + 'exact&action=fetchCustomers';
        axios.post(url, {key: props.api}, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }}) 
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('response', response);
                }
                if(typeof response.error === 'undefined'){
                    if(typeof response.data !== "undefined") setList(response.data);

                } else { 
                    props.notify(props.t('errorOccured'), 7500, "top-center", props.theme, 1);
                }

                let url2 = SftGlobalData.baseURL_API + 'exact&action=fetchModules';
                axios.post(url2, {key: props.api}, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }}) 
                    .then(response2 => {
                        if(SftGlobalData.debug){
                            console.log('url', url2);
                            console.log('response', response2);
                        }
                        setLoading(false);
                        setModules(response2.data);
                    });
            });
    }

    const onCloseModal = (data) => {
        if(SftGlobalData.debug)
            console.log("postData", data);
        
        setModal(false);
        setLoading(true);

        let url = SftGlobalData.baseURL_API + 'exact&action=edit';
        axios.post(url, data, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }}) 
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log("postData", data);
                    console.log('response', response);
                }
                if(typeof response.error === 'undefined'){
                    if(typeof response.data !== "undefined") 
                        props.notify(response.data?.message, 7500, "top-center", props.theme, 1);
                } else { 
                    props.notify(props.t('errorOccured'), 7500, "top-center", props.theme, 1);
                }
                loadData();
            });
    }

    const handleDetailPanelOpen = (rowId) => {
        try{
            if(openDetailPanelRowId[0] === rowId) 
                setOpenDetailPanelRowId([]);
            else
                setOpenDetailPanelRowId([rowId]);
        }catch(e){
            setOpenDetailPanelRowId([rowId]);
        }
        
    };

    const handleDetailPanelClose = () => {
        setOpenDetailPanelRowId(null);
    };

    //console.log("row", openDetailPanelRowId);

    const getSofttouchData = () => {
        if(modalContent === "edit"  || modalContent === "test"){
            switch(modalTarget?.api_module_id){
                case "1": 
                    return modalTarget?.config?.softtouchData?.invoices;
                case "2": 
                    return modalTarget?.config?.softtouchData?.revenues;
                case "3":
                    return modalTarget?.config?.softtouchData?.cashEntries;
            }
        } else {
            const today = new Date();
            // Get the year, month, and day from the Date object
            const year = today.getFullYear();
            // Add 1 to month because months are zero-indexed (January is 0)
            const month = String(today.getMonth() + 1).padStart(2, '0');
            const day = String(today.getDate()).padStart(2, '0');
            switch(selectedModule){
                case "1": 
                case "2": 
                case "3":
                    return {
                        reportId: "0",
                        bindingData: {
                            corporationId: "01",
                            fromDate: `${year}-${month}-${day}`,
                            untilDate: `${year}-${month}-${day}`
                        },
                        newBindingData: {
                            fromDate: "firstOfMonth",
                            untilDate: "lastOfMonth"
                        }
                    };
            }
        }
        
    }

    const getExactData = () => {
        if(modalContent === "edit" || modalContent === "test"){
            return modalTarget?.config?.exactData;
        } else {
            const today = new Date();
            // Get the year, month, and day from the Date object
            const year = today.getFullYear();
            // Add 1 to month because months are zero-indexed (January is 0)
            const month = String(today.getMonth() + 1).padStart(2, '0');
            const day = String(today.getDate()).padStart(2, '0');
            switch(selectedModule){
                case "1": 
                    return {
                        journal: "",
                        paymentCondition: "0 ",
                        vatCode: {
                            "0": "0  ",
                            "6": "1  ",
                            "21": "5  "
                        },
                        glAccount: {}
                    };
                case "2": 
                    return {
                        journal: "",
                        paymentCondition: "0 ",
                        customer: "",
                        vatCode: {
                            "0": "0  ",
                            "6": "1  ",
                            "21": "5  "
                        },
                        glAccount: {}
                    };
                case "3": 
                    return {
                        journalCode: {},
                        account: { "1": "", "2": null, 3: null},
                        glAccount: {}
                    };
            }
        }
        return modalTarget?.config?.exactData;
    }

    const getTimeData = () => {
        if(modalContent !== "edit" && modalContent !== "test"){
            let now = new Date();

            // Ensure hours fall within the range of 0 to 23
            const adjustedHours = (selectedHours - 1) % 24;

            // Set the hour and minutes based on user input
            now.setHours(adjustedHours);
            now.setMinutes(selectedMinutes);
            now.setSeconds(0); // Reset seconds to 0

            // Set the timezone offset to +01:00
            const timezoneOffsetInMinutes = 60; // +01:00 is one hour ahead of UTC
            now.setTime(now.getTime() + timezoneOffsetInMinutes * 60 * 1000);

            // Check if the selected time has already passed today
            if (now.getTime() <= Date.now()) {
                // If it has, set the date to tomorrow
                now.setDate(now.getDate() + 1);
            }
            console.log("selectedInterval:", selectedInterval);


            if (selectedInterval === "monthly") {
                if (selectedIntervalMonthlyOn === "begin") {
                    // Get the first day of the next month
                    const nextMonth = now.getMonth() + 1;
                    const nextYear = now.getFullYear() + (nextMonth === 12 ? 1 : 0);

                    // Set `now` to the first day of the next month
                    now.setFullYear(nextYear);
                    now.setMonth(nextMonth % 12); // Wrap around to January if needed
                    now.setDate(1); // First day of the month

                } else if (selectedIntervalMonthlyOn === "end") {
                    const nextMonth = now.getMonth() + 1;
                    const nextYear = now.getFullYear() + (nextMonth === 12 ? 1 : 0);

                    // Set `now` to the last day of the next month
                    now.setFullYear(nextYear);
                    now.setMonth(nextMonth % 12); // Wrap around to January if needed

                    // Use the 0th day of the next month to get the last day of the current month
                    now.setDate(0); // Set to the last day of the month
                }
            }
 
            // If selectedInterval is "weekly", find the next matching day of the week
            if (selectedInterval === "weekly") {
                let nextDayIndex = Math.log2(selectedIntervalWeeklyOn); // Convert the power of 2 to index of daysOfWeek array
                nextDayIndex = nextDayIndex === 7 ? 1 : nextDayIndex + 1; // Shift to match Monday as index 1
                let nextDay = nextDayIndex - now.getDay();
                if (nextDay <= 0) {
                    nextDay += 7;
                }
                now.setDate(now.getDate() + nextDay);
            }
            // Construct the timestamp string in the desired format
            const formattedDate = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}T${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}:00+01:00`;

            return {
                between: null,
                unlessBetween: null,
                monthlyOn: (selectedInterval === "monthly" ? monthlyOn : null),
                weeklyOn: (selectedInterval === "weekly" ? selectedIntervalWeeklyOn : null),
                nextRun: formattedDate,
                method: selectedInterval
            };

        } else {
            return modalTarget?.schedule;
        }
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column'}} className={(props.screenWidth) < 600 ? 'not_expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme : 'expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme} >
            {/*<FloatingBackButton disabled={loading} onClick={()=>{navigate("/"+props.lang+"/exact");}} down={true} />*/}
            <StyledModal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={modal}
                onClose={()=>{setModal(false);}}
                BackdropComponent={Backdrop}
                >
                <Box 
                    sx={style} 
                    style={{minWidth: 750, height: (modalContent === "test" ? "75%" : "95%"), width: (modalContent === "test" ? "80%" : "90%")}} 
                    className={props.theme + "  box_fade-in"}>
                    <ExactModal api={props.api} t={props.t} theme={props.theme} onClose={(data)=>{onCloseModal(data);}} content={modalContent} target={modalTarget} module={selectedModule} customer={customerRow?.id} scheduleData={getTimeData()} softtouchData={getSofttouchData()} exactData={getExactData()} notify={(text, time, position, theme, id) => {props.notify(text, time, position, theme, id);}}/>
                </Box>
            </StyledModal>
            <div className={"container_list__title " + props.theme}><h2>Exact Online</h2></div>

            <Box className={props.theme + ' licence__enivronment'}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className={props.theme + ' container_tabs_below_subtitle'}>
                  <Tabs value={value} onChange={(event, newValue) => {setValue(newValue);}} className={props.theme} variant="scrollable" scrollButtons="auto">
                    <Tab label={"Schedules "} {...a11yProps(0)} className={props.theme}/>
                    <Tab label={"Client data "} {...a11yProps(1)} className={props.theme}/>
                  </Tabs>
              </Box>

              <TabPanel value={value} index={0}>
                <div className='rowContainerCenter _100perc'>
                    <DataGridPro
                          apiRef={apiRef}
                          //initialState={initialGrid}
                          className={'excel_grid ' + props.theme}
                          columns={gridColumns}
                          rows={list.map((row, index) => {return {
                                  id: row.id,
                                  api_token_id: row.api_token_id,
                                  external_id: row.external_id,
                                  external_name: row.external_name,
                                  created_at: row.created_at,
                                  updated_at: row.updated_at,
                                  modules: row.modules,
                              }                          
                          })} 
                          getDetailPanelHeight={({ row }) => 300} // Optional, default is 500px.
                          getDetailPanelContent={({ row }) => (
                                <DetailPanelContent row={row} /> 
                           )}
                          detailPanelExpandedRowIds={openDetailPanelRowId}
                          onDetailPanelExpandedRowIdsChange={(params, event) => {
                            //console.log("params", params);
                            try{
                                if(typeof params[0] !== "undefined")
                                    handleDetailPanelOpen(params[0]);
                                else 
                                    setOpenDetailPanelRowId([]);
                            }catch(Ex){
                                setOpenDetailPanelRowId([]);
                            }
                          }}
                          onRowClick={(params, event) => {
                                handleDetailPanelOpen(params.id);
                          }}
                          
                          initialState={{ detailPanel: { expandedRowIds: openDetailPanelRowId } }}
                          //onColumnWidthChange={()=>{visibilityChangedHandler();}}
                          //onColumnVisibilityModelChange={()=>{visibilityChangedHandler();}}
                          //onPreferencePanelClose={()=>{visibilityChangedHandler();}}
                          rowThreshold={0}
                          rowHeight={38}
                          autoHeight={true}
                          loading={loading}
                          pagination={false}
                          /> 
                </div>
              </TabPanel>

              <TabPanel value={value} index={1}>
                    <ExactClientTable api={props.api} theme={props.theme} t={props.t} notify={(text, time, position, theme, id) => {props.notify(text, time, position, theme, id);}}/>
              </TabPanel>
              
          </Box>

            
        </div>
    );
}

export default ExactScreen;
