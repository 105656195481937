import React, { useState, useEffect, useRef }from 'react';
import './logging.css';
import axios from 'axios';
import SftGlobalData from '../../library/SftGlobalData';
import Colors from '../../library/Colors';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { Autocomplete, TextField } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import licenceIcon from '../../assets/module-icons/ic_licence.png';
import { Link } from 'react-router-dom';
import { DataGridPro } from '@mui/x-data-grid-pro';


const LoggingAddScreenMobile = (props) =>  {
    const [customer, setCustomer] = useState({});
    const [customers, setCustomers] = useState([]);
    const [store, setStore] = useState({id: "NULL"});
    const [stores, setStores] = useState([]);
    const [tags, setTags] = useState ([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [duration, setDuration] = useState("< 10m");
    const [remark, setRemark] = useState("");
    const [solution, setSolution] = useState("");
    const [disabled, setDisabled] = useState(false);
    //const [extraFields, setExtraFields] = useState(false);
    const [caller, setCaller] = useState("");
    const [phone, setPhone] = useState("");
    const [teamviewerId, setTeamviewerId] = useState("");
    const [teamViewerPass, setTeamViewerPass] = useState("");
    const [lastLogs, setLastLogs] = useState([]);

    const inputEl = useRef();
    const inputCust = useRef();
    const inputStore = useRef();

    // validation states
    const [remarkError, setRemarkError] = useState(false);
    const [tagsError, setTagsError] = useState(false);
    const [customerError, setCustomerError] = useState(false);
 
    useEffect (()=>{
        props.changeScreen('logging');

        try{
            let extraLogFields = sessionStorage.getItem('LogExtraFields');
            /*if(typeof extraLogFields !== "undefined"){
                setExtraFields(extraLogFields==="true");
            } else {
                setExtraFields(false);
            }*/
        }catch(ex){}
        try{
            let url = SftGlobalData.baseURL_API+'login';
            let postData = {action: "logging", login: props.loginObj?.login, password: props.loginObj?.password}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    setCustomers(response.data);
                    //setLoading(false);
                });

            let url2 = SftGlobalData.baseURL_API+'logging&action=fetchLogTags';
            let postData2 = {sft_id: 0, sft: props.loginObj?.sft_status}
            axios.post(url2, postData2, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url2);
                        console.log('postData', postData2);
                        console.log('resp', response);
                    }
                    setTags(response.data);
                });
        } catch(e){
            console.error(e);
        }
    }, [props.loginObj]);


    const handleChangeDuration = (event) => {
        const {
            target: { value },
        } = event;
        setDuration(value);
    };

    const onSelectCustomer = (cust) => {
        try{
            let url = SftGlobalData.baseURL_API+'logging&action=fetchDeliveryAddress';
            let postData = {sft_id: cust.cust_no, sft: props.loginObj?.sft_status}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    setStores(response.data);
                    //setLoading(false);
                });
        } catch(e){
            console.error(e);
        }

        try{
            let url = SftGlobalData.baseURL_API+'logging&action=fetchLogs';
            let postData = {sft_id: cust.cust_no, limit: 5, sft: props.loginObj?.sft_status}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    setLastLogs(response.data);
                });
        } catch(e){
            console.error(e);
        }
    };

    function onSaveLogging(task) {
        //console.log("TEST1");
        if(remark !== "" && typeof customer?.cust_no !== "undefined"){
            //console.log("TEST2");
            //console.log("unedited", inputEl.current.innerText);
            try{
                let temp = inputEl.current.innerText.replaceAll("\n", ",").replaceAll("Tags", "").replaceAll("*", "");
                temp = temp.substring(1,temp.length-1);
                temp = temp.split(',');
                //console.log("tags", temp);

                let chips = "[";
                temp.forEach(tag => {
                    let splitTag =  tag.split("-");
                    chips += '"' + splitTag[0].trim() + '",';
                });
                chips = chips.slice(0, -1);
                chips += "]";

                chips = chips.replaceAll("\"\",", "").replaceAll(",\"\"", "");
                //console.log("chips", chips);
                //if(chips === '[","]') chips = '[""]';
                if(chips !== '[""]'){
                    //console.log("TEST3");
                    setDisabled(true);
                    let url = SftGlobalData.baseURL_API+'logging&action=createLog';
                    let postData = {
                        sft_id: customer.cust_no, 
                        cust_del_id: store.id,
                        user_id: props.userId, 
                        remark: remark.replaceAll("\\", "\\\\'").replaceAll("'", "\\'"),
                        solution: solution.replaceAll("\\", "\\\\'").replaceAll("'", "\\'"),
                        tags: chips,
                        duration: duration,
                        caller: caller.replaceAll("\\", "\\\\'").replaceAll("'", "\\'"),
                        phone: phone.replaceAll("\\", "\\\\'").replaceAll("'", "\\'"),
                        teamviewer_id: teamviewerId.replaceAll("\\", "\\\\'").replaceAll("'", "\\'"),
                        teamviewer_pass: teamViewerPass.replaceAll("\\", "\\\\'").replaceAll("'", "\\'"),
                        is_task: task, 
                        sft: props.loginObj?.sft_status

                    }
                    axios.post(url, postData, {
                        headers : {
                            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                        }})
                        .then(response => {
                            if(SftGlobalData.debug){
                                console.log('url', url);
                                console.log('postData', postData);
                                console.log('resp', response);
                            }
                            setRemark("");
                            setSolution("");
                            setDuration("< 10m");
                            setCaller("");
                            setPhone("");
                            setTeamViewerPass("");
                            setTeamviewerId("");
                            setDisabled(false);
                            const ele = inputCust.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
                            if (ele) ele.click();
                            const ele2 = inputStore.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
                            if (ele2) ele2.click();
                            const ele3 = inputEl.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
                            if (ele3) ele3.click();
                            setSelectedTags([]);
                            setCustomer({});
                            setStore({id: "NULL"});
                            props.notify(props.t('log_added'), 2000, "top-center", props.theme, 14447);  
                        });
                    } else {
                        props.notify(props.t('tags_required'), 2000, "top-center", props.theme, 486); 
                    }
            } catch(e){
                console.error(e);
            }
        } else {
            if(remark === "") props.notify(props.t('remark_required'), 2000, "top-center", props.theme, 456); 
            if(typeof customer?.cust_no === "undefined") props.notify(props.t('customer_required'), 2000, "top-center", props.theme, 4532); 
        }
    }

    const checkTagsError = () => {
        let temp = inputEl.current.innerText.replaceAll("\n", ",").replaceAll("Tags", "").replaceAll("*", "");
        temp = temp.substring(1,temp.length-1);
        temp = temp.split(',');
        //console.log("tags", temp);

        let chips = "[";
        temp.forEach(tag => {
            let splitTag =  tag.split("-");
            chips += '"' + splitTag[0].trim() + '",';
        });
        chips = chips.slice(0, -1);
        chips += "]";

        chips = chips.replaceAll("\"\",", "").replaceAll(",\"\"", "");
        //console.log("chips", chips);
        //if(chips === '[","]') chips = '[""]';
        if(chips !== '[""]'){
            setTagsError(false);
        } else {
            setTagsError(true);
        }
    }

    /*const lastLogging = lastLogs.map((row) => (      
        <Card style={{width: '100%', marginBottom: 5, marginTop: 5, paddingTop: 5, paddingBottom: 5, flex: 1, display: 'flex', flexDirection: 'row', boxShadow: "0 2px 3px rgba(0, 0, 0, 0.2)"}} func={()=>{}}>
            <div style={{minWidth: 50, maxWidth: 225}}><p style={{paddingBottom: 0, paddingTop: 0, marginBottom: 0, marginTop: 0}}><b>{row.log_date} - {row.log_owner} </b></p></div>
            <div style={{minWidth: 140, maxWidth: 140, fontSize: 13}}><p style={{paddingBottom: 0, paddingTop: 0, marginBottom: 0, marginTop: 0, color: Colors.def_red}}>{row.log_tags}</p></div>
            <div style={{width: '100%'}}><p style={{paddingBottom: 0, paddingTop: 0, marginBottom: 0, marginTop: 0}}>{row.log_remark}</p></div>
        </Card>
    ))*/

    const gridWebhookColumns = [
        {
            field: 'log_date',
            headerName: 'Date',
            width: 120,
        },
        {
            field: 'log_remark',
            headerName: 'problem',
            width: 1000,
        },
        {
            field: 'log_tags',
            headerName: 'Tags',
            width: 200,
        }
      ];
   

    function DataGridLast5Logs() {
    return (
        <div style={{ height: 520, width: '100%' }}>
        {(lastLogs.length === 0 ? "" : <DataGridPro
            className={props.theme}
            columns={gridWebhookColumns}
            rows={lastLogs.map((row, index) => {return {
                id: index,
                log_date: row.log_date, 
                log_tags: row.log_tags,
                log_remark: row.log_remark,
            }
            })}
            //{...data}
            rowHeight={38}
            pagination={true}
            />)}            
        </div>
    );
    }

    return (
        <div className='log_mobile_content'>
            <div className={(typeof customer?.cust_no !== "undefined") ? " log__flex_add" : " log__100_perc log__stretch_items"}>
                <div className={props.theme + " log__container_add_block box_fade-in"}>
                    <h3 style={{textAlign: 'center'}}>{props.t('log_add')}</h3>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '90%'}}>
                        <div >
                            <div className={"log__container_options log__100_perc "}>
                                {typeof customer.encrypted_key !== "undefined" && (<Link className='margin_right__10px' tabIndex={-1} style={{ borderRadius: 3, border: '2px solid '+Colors.sft_blue, height: 40, padding: 4}} to={"/"+props.lang+"/auth/"+customer.encrypted_key+"/cookie"} target="_blank" rel="noopener noreferrer">
                                    <img src={licenceIcon} width="30px" alt={"Licences"}/>
                                </Link>)}
                                {typeof customer.encrypted_key === "undefined" && <div className='margin_right__10px' style={{ borderRadius: 3, border: '2px solid '+Colors.sft_blue, height: 40, padding: 4}} ><img src={licenceIcon} width="30px" alt={"Licences"}/></div>}

                                <Autocomplete
                                  ref={inputCust}
                                  required
                                  id="customer-select"
                                  className={props.theme+ '_accent log__100_perc'}
                                  label={props.t('customer')}
                                  size={'small'}
                                  sx={(props.theme==='light') ? {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.light_text
                                      },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.light_text
                                     }, borderRadius: 3, marginTop: 0} 
                                    :
                                    {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.dark_text
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.dark_text_accent
                                        }, borderRadius: 3, marginTop: 0}}

                                  options={customers}
                                  isOptionEqualToValue={(option, val)=>{return ((option.search_string === "" ? option.alt_name : option.search_string) + " (" + option.description + ")") === ((val.search_string === "" ? val.alt_name : val.search_string) + " (" + val.description + ")");}}
                                  onChange={(event, value, reason)=>{if(reason === "selectOption"){setCustomer(value); onSelectCustomer(value); const ele2 = inputStore.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0]; if (ele2) ele2.click(); setStore({id: "NULL"});}else{setCustomer({}); const ele1 = inputStore.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0]; if (ele1) ele1.click(); setStores([]); setStore({id: "NULL"}); setLastLogs([]); const ele2 = inputStore.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0]; if (ele2) ele2.click();}}}
                                  autoHighlight
                                  getOptionLabel={(option) => {return (option.search_string === "" ? option.alt_name : option.search_string) + " (" + option.description + ")";}}
                                  renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                      <img
                                        loading="lazy"
                                        width="32"
                                        src={option.logo}
                                        alt=""
                                      />
                                      {(option.search_string === "" ? option.alt_name : option.search_string)} ({option.description})
                                    </Box>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                    error={customerError}
                                    required
                                      {...params}
                                      //key={uniqueCustomerState}
                                      onBlur={()=>{if(typeof customer?.cust_no === "undefined"){setCustomerError(true);} else { setCustomerError(false); }}}
                                      label={props.t('customer')}
                                      inputProps={{
                                        ...params.inputProps,
                                         // disable autocomplete and autofill
                                      }}
                                    />
                                  )}
                                />

                                
                            </div>
                            <Autocomplete
                                      ref={inputStore}
                                      id="store-select"
                                      className={props.theme+ '_accent log__100_perc margin_right__10px margin_bottom_10px'}
                                      label={props.t('store')}
                                      size={'small'}
                                      sx={(props.theme==='light') ? {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.light_text
                                          },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.light_text
                                         }, borderRadius: 3, marginTop: 0} 
                                        :
                                        {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.dark_text
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: Colors.dark_text_accent
                                            }, borderRadius: 3, marginTop: 0}}

                                      options={stores}
                                      isOptionEqualToValue={(option, val)=>{return option.name === val.name;}}
                                      onChange={(event, value, reason)=>{if(reason === "selectOption"){setStore(value);}else{setStore({})}}}
                                      autoHighlight
                                      getOptionLabel={(option) => {return option.name;}}
                                      renderOption={(props, option) => (
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                          {option.id} - {option.name}
                                        </Box>
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label={props.t('store')}
                                          inputProps={{
                                            ...params.inputProps,
                                             // disable autocomplete and autofill
                                          }}
                                        />
                                      )}
                                    />

                        <TextField
                            required
                            multiline
                            minRows={3}
                            maxRows={3}
                            error={remarkError}
                            autoComplete="off" 
                            className={props.theme+ '_accent log__100_perc margin_right__10px margin_bottom_10px'}
                            id="remark"
                            label={props.t('remark')}
                            size={'small'}
                            sx={(props.theme==='light') ? {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.light_text
                                    },
                                '& .MuiInputLabel-root': {
                                    color: Colors.light_text
                                    }, borderRadius: 3, marginTop: 0} 
                                :
                                {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.dark_text
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.dark_text_accent
                                    }, borderRadius: 3, marginTop: 0}}
                            value={remark}
                            onChange={(event)=>{setRemark(event.target.value)}}
                            onBlur={()=>{if(remark === "") setRemarkError(true); else setRemarkError(false);}}
                            />

                        <TextField
                            multiline
                            minRows={2}
                            maxRows={2}
                            autoComplete="off" 
                            className={props.theme+ '_accent log__100_perc margin_bottom_10px'}
                            id="solution"
                            label={props.t('solution')}
                            size={'small'}
                            sx={(props.theme==='light') ? {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.light_text
                                    },
                                '& .MuiInputLabel-root': {
                                    color: Colors.light_text
                                    }, borderRadius: 3, marginTop: 0} 
                                :
                                {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.dark_text
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.dark_text_accent
                                    }, borderRadius: 3, marginTop: 0}}
                            value={solution}
                            onChange={(event)=>{setSolution(event.target.value)}}
                            />  


                        <div className={"log__container_options log__100_perc margin_bottom_10px"}>
                            <Autocomplete
                                    multiple
                                    ref={inputEl}
                                    id="tags-outlined"
                                    className={props.theme+ '_accent log__100_perc margin_right__10px '}
                                    size={'small'}
                                    sx={(props.theme==='light') ? {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.light_text
                                            },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.light_text
                                            }, borderRadius: 3, marginTop: 0, height: '100%'} 
                                        :
                                        {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.dark_text
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: Colors.dark_text_accent
                                            }, borderRadius: 3, marginTop: 0, height: '100%'}}
                                    options={tags}
                                    isOptionEqualToValue={(option, val)=>{return ((option.tag + " - " + option.parent) === (val.tag + " - " + val.parent))}}
                                    getOptionLabel={(option) => (option.tag + " - " + option.parent)}
                                    defaultValue={selectedTags}
                                    renderInput={(params) => (
                                        <TextField
                                        {...params}
                                        error={tagsError}
                                        onBlur={()=>{checkTagsError();}}
                                        variant="outlined"
                                        label={props.t('tags')}
                                        required
                                        //placeholder="Tags"
                                        />
                                    )}
                                    />
                            <FormControl className={props.theme+ '_accent log__100_perc'}>
                                    <InputLabel id="demo-simple-select-label">{props.t('duration')}</InputLabel>
                                    <Select
                                    className={props.theme+"_accent "+props.theme+"_text log__100_perc"}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={duration}
                                    label={props.t('duration')}
                                    onChange={handleChangeDuration}
                                    style={{height: 40, paddingTop: 8, paddingBottom: 8}}>
                                    <MenuItem value={"< 10m"}>&lt; 10m</MenuItem>
                                    <MenuItem value={"< 20m"}>&lt; 20m</MenuItem>
                                    <MenuItem value={"< 30m"}>&lt; 30m</MenuItem>
                                    <MenuItem value={"< 60m"}>&lt; 60m</MenuItem>
                                    <MenuItem value={"> 60m"}>&gt; 60m</MenuItem>
                                    </Select>
                                </FormControl>
                        </div>
                            
                        <div className={"log__container_options log__100_perc"}>
                            <TextField
                                autoComplete="off" 
                                className={props.theme+ '_accent margin_right__10px log__100_perc'}
                                id="phone"
                                type='tel'
                                label={props.t('phone')}
                                size={'small'}
                                sx={(props.theme==='light') ? {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.light_text
                                        },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.light_text
                                        }, borderRadius: 3, marginTop: 0} 
                                    :
                                    {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.dark_text
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.dark_text_accent
                                        }, borderRadius: 3, marginTop: 0}}
                                value={phone}
                                onChange={(event)=>{setPhone(event.target.value)}}
                                />  
                            <TextField
                                autoComplete="off" 
                                className={props.theme+ '_accent log__100_perc'}
                                id="caller"
                                label={props.t('caller')}
                                size={'small'}
                                sx={(props.theme==='light') ? {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.light_text
                                        },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.light_text
                                        }, borderRadius: 3, marginTop: 0} 
                                    :
                                    {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.dark_text
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.dark_text_accent
                                        }, borderRadius: 3, marginTop: 0}}
                                value={caller}
                                onChange={(event)=>{setCaller(event.target.value)}}
                                />  
                        </div>
                        <div className={"log__container_buttons log__100_perc "} >
                            <div className={"log__container_buttons log__100_perc "} >
                                
                                <Button 
                                    disabled={disabled} 
                                    variant="outlined" 
                                    sx={{ borderRadius: 3, border: '2px solid '+Colors.def_green, color: Colors.def_green}}
                                    className="  log__button_width margin_right__5px" 
                                    onClick={()=>{onSaveLogging(true)}}>{props.t("task_add")}</Button>
                                <Button 
                                    disabled={disabled} 
                                    variant="outlined" 
                                    sx={{ borderRadius: 3, border: '2px solid '+Colors.sft_blue, color: Colors.sft_blue}}
                                    className="  log__button_width " 
                                    onClick={()=>{onSaveLogging(false)}}>{props.t("log_add")}</Button>
                            </div>
                        </div>
                    </div>

                    {(typeof customer?.cust_no !== "undefined") && <div className={props.theme + " box_fade-in log__container_add_block"}>
                    <h3 style={{textAlign: 'center'}}>{props.t('customer_last_logs')}</h3>
                    {lastLogs.length > 0 && (DataGridLast5Logs())}
                </div>}


                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoggingAddScreenMobile;
