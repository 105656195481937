import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faUser, faHammer, faServer } from '@fortawesome/free-solid-svg-icons';
import leeuw from '../../assets/leeuw.png';
import coq from '../../assets/coq.png';
import { Tooltip } from '@mui/material';


const CardWithImage = (props) =>  {
    const getIconForType = () =>{
        try{
            if(props.type){
                switch(props.type){
                    case "environment":
                        return <FontAwesomeIcon icon={faDatabase} color={props.theme==='dark' ? 'white' : 'black'} size="1x" />
                    default:
                        return <span></span>
                }
            }
            
        }catch(e){
            return <span></span>
        }
    }

    const getIcon2ForType = () =>{
        try{
            if(props.type){
                switch(props.type){
                    case "environment":
                        return <FontAwesomeIcon icon={faUser} color={props.theme==='dark' ? 'white' : 'black'} size="1x" />
                    default:
                        return <span></span>
                }
            }
            
        }catch(e){
            return <span></span>
        }
    }
    
    return (
        <div className={"environment__card environment__card_" + props.theme + " " + props.theme } onClick={()=>{props.func();}}>
            {(props.zone === "VL" || props.zone === "WL") && <div className={'card_corner padding_none'}>{props.zone === "VL" && <Tooltip title='VLAANDEREN' arrow><img alt="Vlaanderen" className='small_icon' src={leeuw} /></Tooltip>}{props.zone === "WL" && <Tooltip title='WALLONIE' arrow><img alt="Wallonie" className='small_icon' src={coq} /></Tooltip>}</div>}
            {(props.test === "1") && <Tooltip title='Test environment' arrow><div className={'card_corner_left'}><FontAwesomeIcon icon={faHammer} color={'white'} size="1x" /></div></Tooltip>}
            {(props.local === "1") && <Tooltip title='Local environment' arrow><div className={'card_corner_left red'}><FontAwesomeIcon icon={faServer} color={'white'} size="1x" /></div></Tooltip>}
            
            <div className='icon'>
                <img alt="icon" src={props.src}  style={{objectFit: 'contain', height: '100%', width: '100%'}} loading={"lazy"} />
            </div>
            <div className='environment__card_body'>
                <p className='environment__text_body'>{getIconForType()} &nbsp;{props.title}</p>
                {(props.subtitle && props.subtitle !== " ()") && <p className='environment__text_body'>{getIcon2ForType()} &nbsp;{props.subtitle}</p>}
            </div>
        </div>
    );
}

export default CardWithImage;
