import React from 'react';


const Card = (props) =>  {

    return (
        <div className={"general_card_container border_radius " + props.theme + " " + props?.className } style={props.style} onClick={()=>{props?.func();}}>
            {props.children}
        </div>
    );
}

export default Card;
