import * as React from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import SyncIcon from '@mui/icons-material/Sync';

export default function FloatingSyncButton(props) {
  return (
    <Box onClick={()=>{if(typeof props?.onClick !== "undefined") props?.onClick()}} disabled={props.disabled} style={{position: 'fixed', right: 14, bottom: (props?.bottom ? 40 : 92), zIndex: 10}} sx={{ '& > :not(style)': { m: 1 } }}>
      <a rel="noreferrer" target={"_BLANK"} href={props.url} >
          <Fab size="medium" color={"default"} aria-label="save">
            <SyncIcon />
          </Fab>
      </a>
    </Box>
  );
}