import React from 'react';
import Colors from '../../library/Colors';
const MailIntegrationOption = ({ name, icon, onSelect, isSelected }) => {
  return (
    <div
      onClick={() => onSelect(name)}
      onKeyPress={(e) => e.key === 'Enter' && onSelect(name)}
      role="button"
      aria-pressed={isSelected}
      tabIndex={0}
      style={{
        border: isSelected ? '3px solid #4a90e2' : '2px solid #ddd',
        borderRadius: '10px',
        padding: '20px',
        margin: '10px',
        maxWidth: '60em',
        minHeight: '15rem', // Ensures consistent height for proper centering
        display: 'flex',    // Use flexbox for centering
        flexDirection: 'column', // Stack content vertically
        alignItems: 'center', // Center horizontally
        justifyContent: 'center', // Center vertically
        textAlign: 'center', // Align text inside
        cursor: 'pointer',
        transition: 'transform 0.3s, box-shadow 0.3s',
        boxShadow: isSelected ? '0 4px 12px rgba(74, 144, 226, 0.5)' : 'none',
        transform: isSelected ? 'scale(1.05)' : 'scale(1)',
        outline: 'none',
      }}
    >
      <img 
        src={icon} 
        alt={`${name} icon`} 
        style={{ 
          width: '100%', 
          maxWidth: '20rem', 
          height: 'auto', 
          transition: 'opacity 0.3s', 
          opacity: isSelected ? 1 : 0.85 
        }} 
      />
    </div>
  );
};

export default MailIntegrationOption;
