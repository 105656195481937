import React, { useState } from 'react';
import axios from 'axios';
import SftGlobalData from '../../library/SftGlobalData';

const SwitchSetting = (props) => {
    const [checkedState, setCheckedState] = useState(parseInt(props.checked) === 1 ? true : false);
  console.log("SWITCH", props);
    const submitHandler = async (value) => {
        let intValue = (value ? "1" : "0");
        let url = SftGlobalData.baseURL_API_old + '?key='+props.api+'&edit=1&setting='+props.setting+"&value="+intValue+"&category="+props.category;
            if(props.groupId !== '0000'){
                url = SftGlobalData.baseURL_API_old + '?key='+props.api+'&edit=1&setting='+props.setting+"&value="+intValue+'&groupId='+props.groupId+"&category="+props.category;
            }
        axios.get(url)
            .then(response => {console.log(response)});
    }

    return (
          <div className="setting-container">
            <label className="switch" style={{float: "right"}}>
              <input type="checkbox" checked={checkedState} onChange={() => {submitHandler(!checkedState); setCheckedState(!checkedState)}}/>
              <span className={"slider slider_" + props.theme + " round"}></span>
            </label>
            <div>
                <h2>{props.title}</h2>
                <p>{props.description}</p>
            </div>
            <hr className={'margin_top_40px' + (props.theme==='dark') ? 'light_text' : 'dark_text'}/>
          </div>
    );
}

export default SwitchSetting;
