import React, { useState, useEffect }from 'react';
import axios from 'axios';
import SftGlobalData from '../../library/SftGlobalData';
import 'react-toastify/dist/ReactToastify.css';
import { DataGridPro } from '@mui/x-data-grid-pro';

const LicenceConnectionHistoryForm = (props) =>  {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [searchValue, setSearchValue] = useState("");

    useEffect (()=>{
        try{
            let url = SftGlobalData.baseURL_API+'client&action=fetchHistory';
            let postData = {key: props.api, licence_id: props.licenceId}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    setData(response.data);
                    setLoading(false);
                });
        } catch(e){
            console.error(e);
        }
    }, [props.api, props.licenceId]);

    const gridWebhookColumns = [
        {
            field: 'user_display',
            headerName: 'User',
            minWidth: 100,
            width: 100,
            align: "left",
        },
        {
            field: 'hist_type',
            headerName: 'Type',
            minWidth: 100,
            width: 100,
            align: "left",
        },
        {
            field: 'hist_action',
            headerName: 'Action',
            minWidth: 100,
            width: 100,
            align: "left",
        },
        {
            field: 'licence_id',
            headerName: 'Key ID',
            minWidth: 75,
            maxWidth: 75,
            width: 75,
            align: "left",
        },
        {
            field: 'remark',
            headerName: 'remark',
            width: 320,
            align: "left",
        },
        {
            field: 'created_at',
            headerName: 'Time',
            minWidth: 160,
            align: "left",
        },
      ];

    function DataGrid() {
        return (
          <div className={props.theme} style={{width: props.screenWidth - props.screenWidth/100*10}}>
            <input 
                type="text"
                name="search"
                className={props.theme+"_accent_darker "}
                style={{marginTop: 0 }}
                placeholder={props.t('search')}
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
            />
            
            {props.accountId !== "0" && <DataGridPro
                className={props.theme}
                columns={gridWebhookColumns}
                rows={data.filter(row => (row.id?.match(new RegExp(searchValue, "i")) || row.remark?.match(new RegExp(searchValue, "i")) || row.user_display?.match(new RegExp(searchValue, "i")) || row.hist_action?.match(new RegExp(searchValue, "i")) || row.hist_type?.match(new RegExp(searchValue, "i"))))
                .map((row) => {return {
                        id: row.id,
                        hist_type: row.hist_type,
                        licence_id: row.licence_id,
                        remark: row.remark,
                        created_at: row.created_at,
                        hist_action: row.hist_action,
                        is_admin: row.is_admin,
                        user_display: row.user_display
                    }
                })}
                //{...data}
                rowHeight={38}
                style={{height: 500}}
                pagination={true}
                loading={loading}

                />   }       
          </div>
        );
    }


    return (
        <div>
            {DataGrid()}
        </div>
    );
}

export default LicenceConnectionHistoryForm;
