import * as React from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function FloatingBackButton(props) {
  return (
    <Box disabled={props.disabled} onClick={()=>{props?.onClick();}} style={(props?.down === true ? {position: 'fixed', right: 14, bottom: 24, zIndex: 10} : {position: 'fixed', right: 14, bottom: 84, zIndex: 10})} sx={{ '& > :not(style)': { m: 1 } }}>
      <Fab size="medium" color={"default"} aria-label="save">
        <ArrowBackIcon />
      </Fab>
    </Box>
  );
}