import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Colors from '../../library/Colors';
import SftGlobalData from '../../library/SftGlobalData';
import axios from 'axios';

const EditableJsonComponent = ( props ) => {
  const [softtouchData, setSofttouchData] = useState(props?.jsonData);
  const [newField, setNewField] = useState("");
  const [newValue, setNewValue] = useState("");

    useEffect(()=>{
        if(props.save){
            props.handleData(softtouchData);
        }
    }, [props.save])

  const handleChange = (e, key, field, orig) => {
    const { value } = e.target;
    if(key === "key"){
        handleRemoveField(field);
    }
    setSofttouchData(prevState => {
      /*console.log("Previous State:", prevState); // Logging previous state
      console.log("Previous binding:", prevState?.[props.target]); // broken
      console.log("Previous specific:", prevState?.[props.target][key]);
      console.log("key:", key);
      console.log("field:", field);
      console.log("value:", value);*/
      let prevBinding = prevState[props.target];

      if(key === "key"){
        /*console.log("newState:", {
            ...prevState,
            [props.target]: {
              ...prevBinding,
              [value]: orig
            }
          });*/
          
          return {
            ...prevState,
            [props.target]: {
              ...prevBinding,
              [value]: orig
            }
          };
      } else {
        /*console.log("newState:", {
            ...prevState,
            [props.target]: {
              ...prevBinding,
              [field]: value
            }
          });*/
          
          return {
            ...prevState,
            [props.target]: {
              ...prevBinding,
              [field]: value
            }
          };
      }
      
    });
  };
  

  //console.log("data", softtouchData);

  const handleRemoveField = (key) => {
    setSofttouchData(prevState => {
      const updatedBindingData = { ...prevState[props.target] };
      delete updatedBindingData[key];
      return {
        ...prevState,
        [props.target]: updatedBindingData
      };
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') 
        handleAddField(newField, newValue);
  }

  const handleKeyDownFetchAccount = (event) => {
    if (event.key === 'Enter') 
        fetchAccounts();
  }

  const handleKeyDownFetch = (event) => {
    if (event.key === 'Enter') 
        fetchGlAccounts();
  }

  const handleAddField = (key, value) => {
    if(key !== ""){
        setNewField("");
        setNewValue("");
        setSofttouchData(prevState => ({
            ...prevState,
            [props.target]: {
              ...prevState[props.target],
              [key]: value
            }
          }));
    }
    
  };

  const getData = () => {
    switch(props?.target) {
        case "bindingData":
            return softtouchData?.bindingData;
        case "newBindingData":
            return softtouchData?.newBindingData;
        case "vatCode":
            return softtouchData?.vatCode;
        case "glAccount":
            return softtouchData?.glAccount; 
        case "journalCode":
            return softtouchData?.journalCode;
        case "account":
            return softtouchData?.account;
    }
    return {};
  }

  const fetchGlAccounts = () => {
    if(newValue !== "") {
        let url = SftGlobalData.baseURL_API + 'exact&action=fetchExact';
        let filter = newValue.split(',').map(item => item.trim());
        let postData = {key: props.api, customer: props.cust, glfilter: filter};
        axios.post(url, postData, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }}) 
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('postData', postData);
                    console.log('response', response);
                }
                if(typeof response.error === 'undefined'){
                    if(typeof response.data.exactData?.glAccounts !== "undefined") {
                        try{
                            //console.log(response.data.exactData?.glAccounts);
                            response.data.exactData?.glAccounts.forEach( item => {
                                //console.log("glAccount", item);
                                handleAddField(item.code, item.id);
                            });
                        }catch(e){
                            console.error(e);
                        }
                    }
                } else { 
                    props.notify(props.t('errorOccured'), 7500, "top-center", props.theme, 1);
                }
            });
    }
}
    const fetchAccounts = () => {
        if(newValue !== "") {
            let url = SftGlobalData.baseURL_API + 'exact&action=fetchExact';
            let filter = newValue.split(',').map(item => item.trim());
            let postData = {key: props.api, customer: props.cust, accfilter: filter};
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }}) 
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('response', response);
                    }
                    if(typeof response.error === 'undefined'){
                        if(typeof response.data.exactData?.accounts !== "undefined") {
                            try{
                                //console.log(response.data.exactData?.glAccounts);
                                response.data.exactData?.accounts.forEach( (item, index) => {
                                    //console.log("glAccount", item);
                                    if(index < 2){
                                        handleAddField(((index+1)+""), item.id);
                                        props.notify(item.name, (85648+index), "top-center", props.theme, 1);
                                    }
                                });
                            }catch(e){
                                console.error(e);
                            }
                        }
                    } else { 
                        props.notify(props.t('errorOccured'), 7500, "top-center", props.theme, 1);
                    }
                });
        }
    }

  return (
    <div>
        <div className='rowContainerBetween _100perc ' style={{textAlign: "left", marginTop: 10, marginBottom: 10}}></div>
        {/*<div className='rowContainerBetween _100perc ' style={{textAlign: "left", marginTop: 25, marginBottom: 10}}>
            <span className=' _50perc margin_right__10px'>Key</span>
            <span className=' _50perc margin_right__10px'>Value</span>
            <span className=' ' style={{width: 62}}></span>
        </div>*/}
        {Object.entries(getData()).map(([key, value], index) => { return (
          <div key={key} className='rowContainerBetween _100perc ' style={{textAlign: "left", justifyContent: "center", alignItems: "center"}}>
              <span className='_50perc margin_right__10px' style={((props?.target === "vatCode" || props?.target === "journalCode" || props?.target === "account") ? {textAlign: "center"} : {})}><i>{key}</i></span>
              {/*<input
                className='_50perc margin_right__10px'
                type="text"
                value={key}
                onChange={(e) => handleChange(e, "key", key, value)}
                />*/}
            
              {props?.target === "vatCode" && <span className='_50perc margin_right__10px' style={{textAlign: "center"}}><i>"{value}"</i></span>}
              {(props?.target !== "vatCode" && props?.target !== "journalCode")  && <input
                className='_50perc margin_right__10px'
                type="text"
                value={value}
                onChange={(e) => handleChange(e, "val", key, value)}
              />}

              {props?.target === "journalCode" && <FormControl className={props.theme+ '_accent _50perc'} style={{textAlign: "left", marginBottom: 8, marginTop: 8, height: "100%", marginRight: 10}} >
               {/* Journal (SELECT input) */}
               <InputLabel id="demo-simple-select-label">Journal</InputLabel>
               <Select
                className={props.theme+"_accent "+props.theme+"_text _100perc"}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                label="Vat code"
                onChange={(e) => handleChange(e, "val", key, value)}
                style={{height: 40, paddingTop: 8, paddingBottom: 8}}>
                    {props?.journalCodes.map((row, index) => (
                            <MenuItem
                                key={index}
                                value={row.code}
                            >
                                {row?.code} - {row?.description} 
                            </MenuItem>
                        ))
                    }
                    
              </Select>
            </FormControl>}

              <Button style={{color: (props.theme === 'dark' ? Colors.dark_text : Colors.light_text), height:"100%", borderRadius: "50%", width: 48 }} onClick={()=>{handleRemoveField(key)}} >
                <FontAwesomeIcon icon={faTrash} color={Colors.def_red} size="2x" />
              </Button>
          </div>
        )})}
        {(props?.target !== "glAccount" && props?.target !== "account" && props?.target !== "journalCode") && <div className='rowContainerBetween _100perc '>
          <input
            className='_50perc margin_right__10px'
            type="text"
            value={newField}
            onChange={(e) => setNewField(e.target.value)}
          />
        
          {props?.target !== "vatCode" && <input
            className='_50perc margin_right__10px'
            type="text"
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
            onKeyDown={handleKeyDown}
          />}

          {props?.target === "vatCode" && <FormControl className={props.theme+ '_accent _50perc'} style={{textAlign: "left", marginBottom: 8, marginTop: 8, height: "100%", marginRight: 10}} >
               {/* Journal (SELECT input) */}
               <InputLabel id="demo-simple-select-label">Vat code</InputLabel>
               <Select
                className={props.theme+"_accent "+props.theme+"_text _100perc"}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={newValue}
                label="Vat code"
                onChange={(event)=>{const {target: { value },} = event; setNewValue(value);}}
                style={{height: 40, paddingTop: 8, paddingBottom: 8}}>
                    {props.vatCodes.map((row, index) => (
                            <MenuItem
                                key={index}
                                value={row.code}
                            >
                                {row?.code} - {row?.description} 
                            </MenuItem>
                        ))
                    }
                    
              </Select>
            </FormControl>}

            <Button style={{color: (props.theme === 'dark' ? Colors.dark_text : Colors.light_text), height:"100%", borderRadius: "50%", marginTop: 8, width: 48 }} onClick={()=>{handleAddField(newField, newValue)}} >
                <FontAwesomeIcon icon={faPlus} color={Colors.sft_blue} size="2x" />
            </Button>
        </div>}

        {props?.target === "glAccount" && <div className='rowContainerBetween _100perc '>
            <input
                className='_100perc margin_right__10px'
                type="text"
                value={newValue}
                onChange={(e) => setNewValue(e.target.value)}
                onKeyDown={handleKeyDownFetch}
              />

            <Button style={{color: (props.theme === 'dark' ? Colors.dark_text : Colors.light_text), height:"100%", borderRadius: "50%", marginTop: 8, width: 48 }} onClick={()=>{fetchGlAccounts();}} >
                <FontAwesomeIcon icon={faPlus} color={Colors.sft_blue} size="2x" />
            </Button>
        </div>}
        {props?.target === "account" && <div className='rowContainerBetween _100perc '>
            <input
                className='_100perc margin_right__10px'
                type="text"
                value={newValue}
                onChange={(e) => setNewValue(e.target.value)}
                onKeyDown={handleKeyDownFetchAccount}
              />

            <Button style={{color: (props.theme === 'dark' ? Colors.dark_text : Colors.light_text), height:"100%", borderRadius: "50%", marginTop: 8, width: 48 }} onClick={()=>{fetchAccounts();}} >
                <FontAwesomeIcon icon={faPlus} color={Colors.sft_blue} size="2x" />
            </Button>
        </div>}
        <div className='rowContainerBetween _100perc ' style={{textAlign: "left", marginBottom: 25}}></div>
        
    </div>
  );
};

export default EditableJsonComponent;