import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import TextField from '@mui/material/TextField';
import EmailEditor from 'react-email-editor';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import axios from 'axios';

import SftGlobalData from '../../library/SftGlobalData';
import Colors from '../../library/Colors';

import MenuItem from '@mui/material/MenuItem';
import { FormControl, FormControlLabel, InputLabel, Select, Switch } from '@mui/material';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import MailIcon from '@mui/icons-material/Mail';
import FloatingBackButton from '../../components/FloatingBackButton';
import FloatingSaveButton from '../../components/FloatingSaveButton';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

const TemplateDetailScreen = (props) =>  {  
    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState(0);

    const [type, setType] = useState("ETICKET");
    const [active, setActive] = useState(false);

    const [title, setTitle] = useState("");

    const [htmlNL, setHtmlNL] = useState("<div></div>");
    const [htmlFR, setHtmlFR] = useState("<div></div>");
    const [htmlEN, setHtmlEN] = useState("<div></div>");

    const [designNL, setDesignNL] = useState(null);
    const [designFR, setDesignFR] = useState(null);
    const [designEN, setDesignEN] = useState(null);
    
    const [idNL, setIdNL] = useState(0);
    const [idFR, setIdFR] = useState(0);
    const [idEN, setIdEN] = useState(0);

    let params = useParams();
    let navigate = useNavigate();
    const emailEditorRefNL = useRef(null);
    const emailEditorRefFR = useRef(null);
    const emailEditorRefEN = useRef(null);

    useEffect(()=>{
        props.changeScreen('logging');
        if(params.id !== "+"){ 
            let id = params.id + "";

            let url = SftGlobalData.baseURL_API + 'templates&action=fetchTemplate';
            let postData = {
                sft_id: props.login.sft_id,
                sft_group: props.login.sft_group, 
                login: props.login.login,
                id: id
                };
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('PostData', postData);
                    console.log('response', response);
                }
                setTitle(response.data.title);
                setActive(response.data.active === "1");
                setType(response.data.type);
                
                response.data.detail.forEach(element => {
                    switch(element.language){
                        case "NL":
                            setHtmlNL(element.html);
                            setIdNL(element.id);
                            try{setDesignNL(JSON.parse(element.design));
                            try{
                                if(element.design){
                                    emailEditorRefNL.current.loadDesign(JSON.parse(element.design));
                                }
                            }catch(e){
                            }}catch(e){}
                            break;
                        case "FR":
                            setHtmlFR(element.html);
                            setIdFR(element.id);
                            try{setDesignFR(JSON.parse(element.design));
                            try{
                                if(element.design){
                                    emailEditorRefFR.current.loadDesign(JSON.parse(element.design));
                                }
                            }catch(e){
                            }}catch(e){}
                            break;
                        case "EN":
                            setHtmlEN(element.html);
                            setIdEN(element.id);
                            try{setDesignEN(JSON.parse(element.design));
                            try{
                                if(element.design){
                                    emailEditorRefEN.current.loadDesign(JSON.parse(element.design));
                                }
                            }catch(e){
                            }}catch(e){}
                            break;
                        default:
                            break;
                    }
                });

            });
        } 
        setLoading(false);
    }, [props.api, params.id]);


    const onSave = () => {
        exportHtml();
    }

    const exportHtml = () => {
        let design_temp  = "";
        let html_temp    = "";
        try{
            emailEditorRefFR.current.editor.exportHtml((data) => {
                const { design, html } = data;
                setHtmlFR(html);
                setDesignFR(design);
                design_temp = JSON.stringify(design);
                html_temp = html;
                if(params.id !== "+"){ // SAVE
                    updateManual(design_temp, html_temp, value);
                } else {
                    storeManual("FR", design_temp, html_temp);
                }
            });
        }catch(e){

        }
        try{
            emailEditorRefEN.current.editor.exportHtml((data) => {
                const { design, html } = data;
                setHtmlEN(html);
                setDesignEN(design);
                design_temp = JSON.stringify(design);
                html_temp = html;
                if(params.id !== "+"){ // SAVE
                    updateManual(design_temp, html_temp, value);
                } else {
                    storeManual("EN", design_temp, html_temp);
                }
            });
        }catch(e){

        }
        try{
            emailEditorRefNL.current.editor.exportHtml((data) => {
              const { design, html } = data;
              setHtmlNL(html);
              setDesignNL(design);
              //console.log("design", JSON.stringify(design));
              design_temp = JSON.stringify(design);
              html_temp = html;
              if(params.id !== "+"){ // SAVE
                updateManual(design_temp, html_temp, value);
              } else {
                storeManual("NL", design_temp, html_temp);
              }
            });
        }catch(e){

        }


    }

    const publishToNode = () => {
        let url = SftGlobalData.baseURL_node + 'refresh';
        let postData = {}
        axios.post(url, postData, {
            headers : {
                'Content-Type' : 'application/json; charset=UTF-8'
            }})
        .then(response => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('postData', postData);
                console.log('response', response);
            }
        });
    }

    const updateManual = (design, html, langId) => {
        if(title === ""){
            props.notify(props.t('notify_title_fields_required'), 7500, "top-right", props.theme, 8797); 
        } else {
            let id = params.id + "";
            //console.log("App id", selectedApp);
            //console.log("DESIGN", design);c
            let url = SftGlobalData.baseURL_API + 'templates&action=update';
            let postData = {
                sft_id: props.login.sft_status,
                sft_group: props.login.sft_group, 
                login: props.login.login,
                id: id,

                type: type,
                active: active,
                title: title,
 
                detail_nl_id: idNL,
                detail_fr_id: idFR,
                detail_en_id: idEN,

                nl_html: langId === 0 ? html : htmlNL,
                fr_html: langId === 1 ? html : htmlFR,
                en_html: langId === 2 ? html : htmlEN,

                nl_design: langId === 0 ? design : JSON.stringify(designNL),
                fr_design: langId === 1 ? design : JSON.stringify(designFR),
                en_design: langId === 2 ? design : JSON.stringify(designEN)
            };
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('postData', postData);
                    console.log('response', response);
                }
                props.notify(props.t('savedChanges'), 2000, "top-right", props.theme, 1);
                setLoading(false);
                publishToNode();
            });
        }
    }

    const storeManual = (lang, design, html) => {
        if(title === ""){
            props.notify(props.t('notify_title_fields_required'), 7500, "top-right", props.theme, 8797); 
        } else {
            let url = SftGlobalData.baseURL_API + 'templates&action=store';
            let postData = {
                sft_id: props.login.sft_status,
                sft_group: props.login.sft_group, 
                login: props.login.login,

                active: active,
                type: type,

                title: title,

                html_nl: lang === "NL" ? html : htmlNL,
                html_fr: lang === "FR" ? html : htmlFR,
                html_en: lang === "EN" ? html : htmlEN,

                design_nl: lang === "NL" ? design : JSON.stringify(designNL),
                design_fr: lang === "FR" ? design : JSON.stringify(designFR),
                design_en: lang === "EN" ? design : JSON.stringify(designEN)

                };
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('postData', postData);
                    console.log('response', response);
                }
                props.notify(props.t('savedChanges'), 2000, "top-right", props.theme, 1);
                navigate("/"+props.lang+"/templates/edit/"+response.data.id);
                publishToNode();
            });
        }
    }

    const onLoad = () => {
        // editor instance is created
        // you can load your template here;
        // const templateJson = {};
        // emailEditorRef.current.editor.loadDesign(templateJson);
    }

    const onReady = () => {
        // editor is ready
        //console.log('design', designNL);
        try{
            if(designNL){
                emailEditorRefNL.current.loadDesign(designNL);
            }
        }catch(e){
            console.error(e);
        }

        try{
            if(designFR){
                emailEditorRefFR.current.loadDesign(designFR);
            }
        }catch(e){
        }

        try{
            if(designEN){
                emailEditorRefEN.current.loadDesign(designEN);
            }
        }catch(e){
        }
        //console.log('onReady');
    }

    const handleChange = (event, newValue) => {
        try{
            switch(value){
                case 0: // NL
                    emailEditorRefNL.current.editor.exportHtml((data) => {
                        const { design, html } = data;
                        //console.log("design", typeof design !== "undefined");
                        if(typeof design !== "undefined"){
                            setHtmlNL(html);
                            setDesignNL(design);
                        }
                        setValue(newValue);
                      });
                    break;
                case 1: // FR
                    emailEditorRefFR.current.editor.exportHtml((data) => {
                        const { design, html } = data;
                        if(typeof design !== "undefined"){
                            setHtmlFR(html);
                            setDesignFR(design);
                        }
                        setValue(newValue);
                    });
                    break;
                case 2: // EN
                    emailEditorRefEN.current.editor.exportHtml((data) => {
                        const { design, html } = data;
                        if(typeof design !== "undefined"){
                            setHtmlEN(html);
                            setDesignEN(design);
                        }
                        setValue(newValue);
                    });
                    break;
                default:
                    break;
            }
        }catch(e){
            //console.error(e);
            setValue(newValue);
        }
    };


    const copyDesign = (orig, target) => {
        try{
            switch(value){
                case 0: // NL
                    emailEditorRefNL.current.editor.exportHtml((data) => {
                        const { design, html } = data;
                        //console.log("design", typeof design !== "undefined");
                        if(typeof design !== "undefined"){
                            setHtmlNL(html);
                            setDesignNL(design);
                            if(target === "FR"){
                                setHtmlFR(html);
                                setDesignFR(design);
                            } else if(target === "EN"){
                                setHtmlEN(html);
                                setDesignEN(design);
                            }
                        }
                      });
                    break;
                case 1: // FR
                    emailEditorRefFR.current.editor.exportHtml((data) => {
                        const { design, html } = data;
                        if(typeof design !== "undefined"){
                            setHtmlFR(html);
                            setDesignFR(design);
                            if(target === "NL"){
                                setHtmlNL(html);
                                setDesignNL(design);
                            } else if(target === "EN"){
                                setHtmlEN(html);
                                setDesignEN(design);
                            }
                        }
                    });
                    break;
                case 2: // EN
                    emailEditorRefEN.current.editor.exportHtml((data) => {
                        const { design, html } = data;
                        if(typeof design !== "undefined"){
                            setHtmlEN(html);
                            setDesignEN(design);
                            if(target === "FR"){
                                setHtmlFR(html);
                                setDesignFR(design);
                            } else if(target === "NL"){
                                setHtmlNL(html);
                                setDesignNL(design);
                            }
                        }
                    });
                    break;
                default:
                    break;
            }
        }catch(e){
            console.error(e);
        }
    }

    return (
        <div style={{height: 'fit-content'}} className={(props.screen === "logging" ? ('log__margin_sidebar log__container_content _80perc ' + props.theme + ' container_content_' + props.theme + '  box_fade-in') : (props.screenWidth) < 600 ? 'not_expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme : 'expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme)} >
            <FloatingBackButton disabled={false} onClick={()=>{navigate("/"+props.lang+"/templates");}} />
            <FloatingSaveButton disabled={loading} onClick={()=>{onSave();}} />
            <div className={"container_list__title " + props.theme}><h2>Template - {params.id === "+" ? props.t('add') : props.t('edit')}</h2></div>

            <br/><br/>
            {loading && <div style={{display:"flex", justifyContent: "space-evenly", marginTop: 10}}>
                <TailSpin 
                    height="40"
                    width="40"
                    color={Colors.sft_blue}
                    ariaLabel='loading'
                />
            </div>}
            {!loading && 
            <div>
                <div className={"log__container_options log__100_perc padding_left__5px padding_right__5px"}>
                    <TextField
                        className={props.theme+'_accent shipping_field_full_width margin_right__10px'}
                        id="title"
                        label={props.t('title_translation')}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.sft_blue
                            }} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.sft_blue
                                }}}
                        value={title}            
                        onChange={(event)=>{setTitle(event.target.value)}}
                        />

                        <FormControl fullWidth >
                          <InputLabel id="demo-simple-select-label" style={{color: Colors.sft_blue}}>{props.t('type')}</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            className={props.theme+'_accent shipping_field_full_width margin_right__10px'}
                            id="demo-simple-select"
                            value={type}
                            label={props.t('type')}
                            size={'small'}
                            style={{marginBottom:10, borderRadius: 6, height: 43}}
                            sx={(props.theme==='light') ? {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.light_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.sft_blue
                                }} 
                                :
                                {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.dark_text
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.sft_blue
                                    }}}
                            onChange={(event)=>{setType(event.target.value);}}
                            MenuProps={MenuProps}
                          >
                            <MenuItem value={"DOCS"}>
                                <Box style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}} sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    <LibraryBooksIcon style={{color: Colors.sft_blue, marginRight: 5}}/>
                                    DOCS
                                </Box>
                            </MenuItem>
                            <MenuItem value={"ETICKET"}>
                                <Box style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}} sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    <MailIcon style={{color: Colors.sft_blue, marginRight: 5}}/>
                                    E-TICKET
                                </Box>
                            </MenuItem>
                          </Select>
                        </FormControl>

                        <FormControlLabel
                          sx={{textAlign: 'center', marginRight: 1, width: '30%'}}
                          control={
                            <Switch
                              checked={active}
                              onChange={()=>{setActive(!active);}}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          labelPlacement="start"
                          label={props.t('active')}
                        />
                </div>



                {
                    /****************************************************************************
                     * 
                     *                             HTML SECTION
                     * 
                     ****************************************************************************/
                }


            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className={props.theme + ' container_tabs_below_subtitle'}>
              <Tabs value={value} onChange={handleChange} className={props.theme} variant="scrollable" scrollButtons="auto">
                <Tab label={"NL "} {...a11yProps(0)} className={props.theme}/>
                <Tab label={"FR "} {...a11yProps(1)} className={props.theme}/>                
                <Tab label={"EN "} {...a11yProps(2)} className={props.theme}/>
              </Tabs>
            </Box>
          
              <TabPanel value={value} index={0}>
                {!loading && <div style={{height: 750, width: '100%', marginTop: 3 }}>
                    {(props.login.sft_group === "ADMIN" || props.login.sft_group === "SUPER") && 
                    <Button type="button" className="button_container__button width_150px"  style={{marginBottom: 0, marginRight: 10}} onClick={()=>{if (window.confirm("Copy design? (NL -> FR)") === true) {copyDesign('NL', 'FR');}}}>&nbsp;NL -&gt; FR&nbsp;</Button>}
                    {((props.login.sft_group === "ADMIN" || props.login.sft_group === "SUPER") && props.login.sft_status !== "3") && 
                    <Button type="button" className="button_container__button width_150px"  style={{marginBottom: 0}} onClick={()=>{if (window.confirm("Copy design? (NL -> EN)") === true) {copyDesign('NL', 'EN');}}}>&nbsp;NL -&gt; EN&nbsp;</Button>}
                        {(props.login.sft_status !== "3") && <EmailEditor
                            minHeight={700}
                            ref={emailEditorRefNL}
                            onLoad={onLoad}
                            onReady={onReady}
                            appearance={
                                {
                                    theme: props.theme,
                                    panels: {
                                      tools: {
                                        dock: 'right'
                                      }
                                    }
                                }
                            }
                            options={{
                                displayMode: "web", 
                                fonts: {
                                    customFonts: [
                                        {
                                            label: "Urbane Rounded",
                                            value: "'Urbane Rounded',cursive",
                                            url: "https://my.softtouch.eu/files/fonts/stylesheet.css",
                                            weights: [{label: 'Regular', value: 600}]
                                        }
                                    ]
                                }
                               
                            }}
                        />}
                        {props.login.sft_status === "3" && <div style={{height: "fit-content"}} dangerouslySetInnerHTML={{__html: htmlNL}}></div>}
                    </div>}
              </TabPanel>
              <TabPanel value={value} index={1}>
                {!loading && <div style={{height: 750, width: '100%', marginTop: 3 }}>
                    {((props.login.sft_group === "ADMIN" || props.login.sft_group === "SUPER") && props.login.sft_status !== "3") && 
                    <Button type="button" className="button_container__button width_150px"  style={{marginBottom: 0, marginRight: 10}} onClick={()=>{if (window.confirm("Copy design? (FR -> NL)") === true) {copyDesign('FR', 'NL');}}}>&nbsp;FR -&gt; NL&nbsp;</Button>}
                    {((props.login.sft_group === "ADMIN" || props.login.sft_group === "SUPER") && props.login.sft_status !== "3") && 
                    <Button type="button" className="button_container__button width_150px"  style={{marginBottom: 0}} onClick={()=>{if (window.confirm("Copy design? (FR -> EN)") === true) {copyDesign('FR', 'EN');}}}>&nbsp;FR -&gt; EN&nbsp;</Button>}
                        <EmailEditor
                            minHeight={700}
                            ref={emailEditorRefFR}
                            onLoad={onLoad}
                            onReady={onReady}
                            appearance={
                                {
                                    theme: props.theme,
                                    panels: {
                                      tools: {
                                        dock: 'right'
                                      }
                                    }
                                }
                            }
                            options={{
                                displayMode: "web", 
                                fonts: {
                                    customFonts: [
                                        {
                                            label: "Urbane Rounded",
                                            value: "'Urbane Rounded',cursive",
                                            url: "https://my.softtouch.eu/files/fonts/stylesheet.css",
                                            weights: [{label: 'Regular', value: 600}]
                                        }
                                    ]
                                }
                               
                            }}
                        />
                    </div>}
              </TabPanel>
              <TabPanel value={value} index={2}>
                {!loading && <div style={{height: 750, width: '100%', marginTop: 3 }}>
                    {((props.login.sft_group === "ADMIN" || props.login.sft_group === "SUPER") && props.login.sft_status !== "3") && 
                    <Button type="button" className="button_container__button width_150px"  style={{marginBottom: 0, marginRight: 10}} onClick={()=>{if (window.confirm("Copy design? (EN -> NL)") === true) {copyDesign('EN', 'NL');}}}>&nbsp;EN -&gt; NL&nbsp;</Button>}
                    {(props.login.sft_group === "ADMIN" || props.login.sft_group === "SUPER") && 
                    <Button type="button" className="button_container__button width_150px"  style={{marginBottom: 0}} onClick={()=>{if (window.confirm("Copy design? (EN -> FR)") === true) {copyDesign('EN', 'FR');}}}>&nbsp;EN -&gt; FR&nbsp;</Button>}
                        {props.login.sft_status !== "3" && <EmailEditor
                            minHeight={700}
                            ref={emailEditorRefEN}
                            onLoad={onLoad}
                            onReady={onReady}
                            appearance={
                                {
                                    theme: props.theme,
                                    panels: {
                                      tools: {
                                        dock: 'right'
                                      }
                                    }
                                }
                            }
                            options={{
                                displayMode: "web", 
                                fonts: {
                                    customFonts: [
                                        {
                                            label: "Urbane Rounded",
                                            value: "'Urbane Rounded',cursive",
                                            url: "https://my.softtouch.eu/files/fonts/stylesheet.css",
                                            weights: [{label: 'Regular', value: 600}]
                                        }
                                    ]
                                }
                            }}
                        />}
                        {props.login.sft_status === "3" && <div style={{height: "fit-content"}} dangerouslySetInnerHTML={{__html: htmlEN}}></div>}
                    </div>}
              </TabPanel>
                
                
            </div>}
        </div>
    );

}
export default TemplateDetailScreen;