import React, { useState, useEffect }from 'react';
import { TailSpin } from  'react-loader-spinner'
import axios from 'axios';
import SftGlobalData from '../../library/SftGlobalData';
import Colors from '../../library/Colors';
import Button from '@mui/material/Button';

import 'react-toastify/dist/ReactToastify.css';
import LicenceAdministrationCheckBox from './LicenceAdministrationCheckBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import LinearProgressWithLabel from '../connection/LinearProgressWithLabel';
import { useNavigate } from 'react-router-dom';

const StyledModal = styled(ModalUnstyled)`
    position: fixed;
    text-align: center;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    `;

const Backdrop = styled('div')`
    z-index: -1;
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    `;

const style = {
    bgcolor: 'background.paper',
    border: '0px solid #000',
    borderRadius: 6,
    p: 2,
    px: 4,
    pb: 3,
    };

const LicenceAdministrationForm = (props) =>  {
    const [loading, setLoading] = useState(true);
    const [modEdi, setModEdi] = useState(false);
    const [modMarketing, setModMarketing] = useState(false);
    const [modWebshop, setModWebshop] = useState(false);
    const [modB2B, setModB2B] = useState(false);
    const [modMyfasmanWeb, setModMyfasmanWeb] = useState(false);
    const [allowUpdate, setAllowUpdate] = useState(true);

    const [disable, setDisable] = useState(false);
    const [confirmDeletion, setConfirmDeletion] = useState(false);
    const [progress, setProgress] = useState(0);
    const [step, setStep] = useState("Confirm");

    const [modalOpen, setModalOpen] = useState(false);
    const [deleteLic, setDeleteLic] = useState(true);
    const [deleteTrainingDb, setDeleteTrainingDb] = useState(true);
    const [deleteApi, setDeleteApi] = useState(true);
    const [deleteSms, setDeleteSms] = useState(true);
    const [deleteDoorcounter, setDeleteDoorcounter] = useState(true);
    const [deleteScheduler, setDeleteScheduler] = useState(true);
    
    let navigate = useNavigate();


    useEffect (()=>{
        try{
            let url = SftGlobalData.baseURL_API+'client&action=fetchAdministration';
            let postData = {key: props.api}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    setModEdi(response.data[0].mod_edi === "1");
                    setModMarketing(response.data[0].mod_marketing === "1");
                    setModWebshop(response.data[0].mod_webshop === "1");
                    setModB2B(response.data[0].mod_b2b === "1");
                    setModMyfasmanWeb(response.data[0].mod_myfasman_web === "1");
                    setAllowUpdate(response.data[0].allow_update === "1");
                    setLoading(false);
                });
        } catch(e){
            console.error(e);
        }
    }, [props.api]);

    const onDelete = async () => {
        setDisable(true);
        setProgress(1);
        setStep("Starting deletion...");
        onDeleteApi();
    }
    
    const onDeleteApi = async () => {
        setStep("API...");
        if(deleteApi){
            let i = 0;
            if(props.apiData?.length > 0){
                props.apiData.forEach((user)=>{
                    let url = SftGlobalData.baseURL_API + 'client&action=removeApiUser';
                    let postData = {
                        key: props.api,
                        email: user.email,
                        id: props.apiAccount, 
                        user: props.username, 
                        sftUserId: props.userId
                    }
                    axios.post(url, postData, {
                        headers : {
                            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                        }
                    })
                    .then(response => {
                        if(SftGlobalData.debug){
                            console.log('url', url);
                            console.log('postData', postData);
                            console.log('response', response);
                        }
                        i++;
                        if(i === props.apiData.length){
                            try{
                                let url = SftGlobalData.baseURL_API+'client&action=removeApiAccount';
                                let postData = {key: props.api, id: props.apiAccount, user: props.username, sftUserId: props.userId };
                                axios.post(url, postData, {
                                    headers : {
                                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                                    }})
                                    .then(response => {
                                        if(SftGlobalData.debug){
                                            console.log('url', url);
                                            console.log('postData', postData);
                                            console.log('resp', response);
                                        }
                                        onDeleteDoorcounter();
                                    });
                            } catch(e){
                                console.error(e);
                            }
                        }
                    });
                });
            } else {
                try{
                    let url = SftGlobalData.baseURL_API+'client&action=removeApiAccount';
                    let postData = {key: props.api, id: props.apiAccount, user: props.username, sftUserId: props.userId };
                    axios.post(url, postData, {
                        headers : {
                            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                        }})
                        .then(response => {
                            if(SftGlobalData.debug){
                                console.log('url', url);
                                console.log('postData', postData);
                                console.log('resp', response);
                            }
                            onDeleteDoorcounter();
                        });
                } catch(e){
                    console.error(e);
                }
            }
        } else {
            onDeleteDoorcounter();
        }
        
    }

    const onDeleteDoorcounter = async() => {
        setProgress(20);
        setStep("Doorcounters...");
        if(deleteDoorcounter){
            try{
                let url = SftGlobalData.baseURL_API+'client&action=deleteAllDoorCounter';
                let postData = {key: props.api, user: props.username, sftUserId: props.userId };
                axios.post(url, postData, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                    .then(response => {
                        if(SftGlobalData.debug){
                            console.log('url', url);
                            console.log('postData', postData);
                            console.log('resp', response);
                        }
                        onDeleteSms();
                    });
            } catch(e){
                console.error(e);
            }
        } else {
            onDeleteSms();
        }
    }

    const onDeleteSms = async () => {
        setProgress(40);
        setStep("SMS...");
        if(deleteSms){
            try{
                let url = SftGlobalData.baseURL_API+'client&action=removeSms';
                let postData = {key: props.api, user: props.username, sftUserId: props.userId };
                axios.post(url, postData, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                    .then(response => {
                        if(SftGlobalData.debug){
                            console.log('url', url);
                            console.log('postData', postData);
                            console.log('resp', response);
                        }
                        onDeleteScheduler();
                    });
            } catch(e){
                console.error(e);
            }
        } else {
            onDeleteScheduler();
        }
    }

    const onDeleteScheduler = async () => {
        setProgress(60);
        setStep("Scheduler...");
        if(deleteScheduler){
            try{
                let url = SftGlobalData.baseURL_API+'client&action=deleteScheduler';
                let postData = {key: props.api, user: props.username, sftUserId: props.userId };
                axios.post(url, postData, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                    .then(response => {
                        if(SftGlobalData.debug){
                            console.log('url', url);
                            console.log('postData', postData);
                            console.log('resp', response);
                        }
                        onDeleteTraining();
                    });
            } catch(e){
                console.error(e);
            }
        } else {
            onDeleteTraining();
        }
    }

    const onDeleteTraining = async () => {
        setProgress(80);
        setStep("Training...");
        if(deleteTrainingDb){
            try{
                let url = SftGlobalData.baseURL_API+'client&action=deleteTraining';
                let postData = {key: props.api, user: props.username, sftUserId: props.userId };
                axios.post(url, postData, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                    .then(response => {
                        if(SftGlobalData.debug){
                            console.log('url', url);
                            console.log('postData', postData);
                            console.log('resp', response);
                        }
                        onDeleteLic();
                    });
            } catch(e){
                console.error(e);
            }
        } else {
            onDeleteLic();
        }
        
    }

    const onDeleteLic = async () => {
        setProgress(90);
        setStep("Licences, modules,...");
        if(deleteLic){
            try{
                let url = SftGlobalData.baseURL_API+'client&action=deleteLicenceModule';
                let postData = {key: props.api, user: props.username, sftUserId: props.userId };
                axios.post(url, postData, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                    .then(response => {
                        if(SftGlobalData.debug){
                            console.log('url', url);
                            console.log('postData', postData);
                            console.log('resp', response);
                        }
                        onFinishDelete();
                    });
            } catch(e){
                console.error(e);
            }
        }else {
            onFinishDelete();
        }
    }

    const deleteFromDbs = async () => {
        let curDbs = props.dbs;
        let match = -1;
        curDbs.forEach((db, index)=>{
            if(SftGlobalData.debug){
                console.log('selectedDb', props.selectedDb);
                console.log('compareDb', db);
            }
            if(db?.db_id === props.selectedDb.db_id && db?.client_id === props.selectedDb.client_id) match = index;
        });
        if(match !== -1){
            console.log("Found, removing.");
            curDbs.splice(match, 1);
            props.setDbs(curDbs);
        }
        navigate("/"+props.lang+"/environment");
    }

    const onFinishDelete = async () => {
        setProgress(100);
        setStep("Finished");
        setConfirmDeletion(false);
        setDisable(false);
        if(deleteLic){
            deleteFromDbs();
        } else {
            setModalOpen(false);
        }

    }

    return (
        <div className={props.theme + " _100perc"} style={{display: 'flex', flexDirection: 'row'}}>
            {loading && <div className={" _100perc"} style={{display:"flex", justifyContent: "space-evenly", marginTop: 5}}>
                <TailSpin 
                    height="40"
                    width="40"
                    color={Colors.sft_blue}
                    ariaLabel='loading'
                />
            </div>}
            <StyledModal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={modalOpen}
                onClose={()=>{if(!disable){setModalOpen(false);}}}
                BackdropComponent={Backdrop}
                >
                <Box sx={style} style={{minWidth: 500}} className={props.theme + " _50perc"}>
                    <p className={"primary_border_bottom text_center margin_top_none margin_bottom_15px bold "} style={{fontWeight: 1200}}>Delete connection?</p>
                    <div>
                        <p>Are you sure you wish to <b>DELETE the connection</b>?</p>
                        <div>
                        {
                            // --------------------------------------------------------------------
                            // Options
                            // --------------------------------------------------------------------
                        }
                        <p className={"primary_border_bottom margin_top_10px margin_bottom_15px bold _100perc padding_bottom__5px"} style={{fontWeight: 1200}}></p>
                        <div className={props.theme + " _100perc"}>
                            <div style={{width: '100%', display: "flex", flexDirection: "row", justifyContent: "space-evenly"}}>
                                <div className={props.theme } style={{width: 155, display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center"}}>
                                    <p>Lic client db</p>
                                    <label className="switch align_right">
                                        <input disabled={disable} type="checkbox" checked={deleteLic} onChange={() => {setDeleteLic(!deleteLic);}}/>
                                        <span className={"slider slider_" + props.theme + " round"}></span>
                                    </label>
                                </div>
                                <div className={props.theme } style={{width: 155, display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center"}}>
                                    <p>Training</p>
                                    <label className="switch align_right">
                                        <input disabled={disable} type="checkbox" checked={deleteTrainingDb} onChange={() => {setDeleteTrainingDb(!deleteTrainingDb);}}/>
                                        <span className={"slider slider_" + props.theme + " round"}></span>
                                    </label>
                                </div>
                                <div className={props.theme } style={{width: 155, display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center"}}>
                                    <p>Doorcounter</p>
                                    <label className="switch align_right">
                                        <input disabled={disable} type="checkbox" checked={deleteDoorcounter} onChange={() => {setDeleteDoorcounter(!deleteDoorcounter);}}/>
                                        <span className={"slider slider_" + props.theme + " round"}></span>
                                    </label>
                                </div>
                            </div>
                            
                            <div style={{width: '100%', display: "flex", flexDirection: "row", justifyContent: "space-evenly"}}>
                                    <div className={props.theme } style={{width: 155, display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center"}}>
                                        <p>SMS</p>
                                        <label className="switch align_right">
                                            <input disabled={disable} type="checkbox" checked={deleteSms} onChange={() => {setDeleteSms(!deleteSms);}}/>
                                            <span className={"slider slider_" + props.theme + " round"}></span>
                                        </label>
                                    </div>
                                    <div className={props.theme } style={{width: 155, display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center"}}>
                                        <p>API</p>
                                        <label className="switch align_right">
                                            <input disabled={disable} type="checkbox" checked={deleteApi} onChange={() => {setDeleteApi(!deleteApi);}}/>
                                            <span className={"slider slider_" + props.theme + " round"}></span>
                                        </label>
                                    </div>
                                    <div className={props.theme } style={{width: 155, display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center"}}>
                                        <p>Scheduler</p>
                                        <label className="switch align_right">
                                            <input disabled={disable} type="checkbox" checked={deleteScheduler} onChange={() => {setDeleteScheduler(!deleteScheduler);}}/>
                                            <span className={"slider slider_" + props.theme + " round"}></span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <p className={"primary_border_bottom margin_top_10px margin_bottom_15px bold _100perc padding_bottom__5px"} style={{fontWeight: 1200}}></p>
                        {confirmDeletion && <LinearProgressWithLabel step={step} value={progress} />}
                        {!confirmDeletion && <Button className={"modal_button_container_no_margin__button margin_top_15px _100perc"} onClick={()=>{setConfirmDeletion(true);}}>{props.t('yes')}</Button>}
                        {!confirmDeletion && <Button className={"modal_button_container_no_margin__button margin_top_5px _100perc"} onClick={()=>{setModalOpen(false);}}>{props.t('no')}</Button>}
                        {confirmDeletion && <Button disabled={disable} className={"red margin_top_15px _100perc"} onClick={()=>{onDelete();}}>CONFIRM DELETION</Button>}
                        {confirmDeletion && <Button disabled={disable} className={"modal_button_container_no_margin__button margin_top_10px _100perc"} onClick={()=>{setConfirmDeletion(false);}}>{props.t('back')}</Button>}
                    </div>
                </Box>
            </StyledModal>


            {!loading && <div className={"_40perc"} style={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'flex-start'}}>
                <LicenceAdministrationCheckBox disabled={props.disabled} theme={props.theme} checked={modEdi} api={props.api} mod={"mod_edi"} label={<span>&nbsp;&nbsp; <b>EDI</b></span>}/>
                <LicenceAdministrationCheckBox disabled={props.disabled} theme={props.theme} checked={modMarketing} api={props.api} mod={"mod_marketing"} label={<span>&nbsp;&nbsp; <b>Marketing</b></span>}/>
                <LicenceAdministrationCheckBox disabled={props.disabled} theme={props.theme} checked={modWebshop} api={props.api} mod={"mod_webshop"} label={<span>&nbsp;&nbsp; <b>WEB</b></span>}/>
                <LicenceAdministrationCheckBox disabled={props.disabled} theme={props.theme} checked={modB2B} api={props.api} mod={"mod_b2b"} label={<span>&nbsp;&nbsp; <b>B2B</b></span>}/>
                <LicenceAdministrationCheckBox disabled={props.disabled} theme={props.theme} checked={modMyfasmanWeb} api={props.api} mod={"mod_myfasman_web"} label={<span>&nbsp;&nbsp; <b>MyFasMan Web</b></span>}/>
                <LicenceAdministrationCheckBox disabled={props.disabled} theme={props.theme} checked={allowUpdate} api={props.api} mod={"allow_update"} label={<span>&nbsp;&nbsp; <b>Allow update?</b></span>}/>
            </div>}
            {!loading && <div className={"_40perc"} style={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'flex-start'}}>
                <div className={props.theme + " _100perc"} style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center", paddingLeft: 10, lineHeight: 1}}>
                    <p style={{width: 100}}><b>Client ID:</b></p>
                    <label className="">
                        <span>{props.clientId} &nbsp;&nbsp;&nbsp;</span>
                    </label>
                </div>
                <div className={props.theme + " _100perc"} style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center", paddingLeft: 10, lineHeight: 1}}>
                    <p style={{width: 100}}><b>DB ID:</b></p>
                    <label className="">
                        <span>{props.dbId} &nbsp;&nbsp;&nbsp;</span>
                    </label>
                </div>
                <div className={props.theme + " _100perc"} style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center", paddingLeft: 10, lineHeight: 1}}>
                    <p style={{width: 100}}><b>Cust. ID:</b></p>
                    <label className="">
                        <span>{props.custId} &nbsp;&nbsp;&nbsp;</span>
                    </label>
                </div>
                {!props.disabled && <Button style={{color: Colors.def_red}} onClick={()=>{setModalOpen(true);}}><FontAwesomeIcon icon={faTrash} color={Colors.def_red} size="1x" /> &nbsp;Delete Connection</Button>}
            </div>}
        </div>
    );
}

export default LicenceAdministrationForm;
