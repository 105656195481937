import React, { useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import SftGlobalData from '../library/SftGlobalData';
import Colors from '../library/Colors';

const ManualViewScreen = (props) =>  {  
    const [loading, setLoading] = useState(true);
    //const [title, setTitle] = useState("");
    const [htmlData, setHtmlData] = useState("<div></div>");
    let params = useParams();

    useEffect(()=>{
        let manualId = params.id + "";

        let url = SftGlobalData.baseURL_API + 'docs&action=fetchManual';
        axios.post(url, {
            sft_id: props.login.sft_id,
            sft_group: props.login.sft_group, 
            language: props.lang,
            login: props.login.login,
            id: manualId
            }, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
        .then(response => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('response', response);
            }

            /*switch(props.lang){
                case "nl":
                    setTitle(response.data.title_nl);
                    break;
                case "fr":
                    setTitle(response.data.title_fr);
                    break;
                case "en":
                    setTitle(response.data.title_en);
                    break;
                default:
                    setTitle("");
                    break;
            }*/
            setHtmlData(response.data.detail[0].html)
            setLoading(false);
        });
    }, [props.api, props.login, params.id, props.lang]);

    return (
        <div  style={{height: "fit-content", paddingTop: 10, marginBottom: 10}} className={(props.screen === "logging" ? ('log__margin_sidebar log__container_content _80perc ' + props.theme + ' container_content_' + props.theme + '  box_fade-in') : (props.screenWidth) < 600 ? 'not_expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme : 'expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme)}>
            {loading && <div style={{display:"flex", justifyContent: "space-evenly", marginTop: 5}}>
                <TailSpin 
                    height="40"
                    width="40"
                    color={Colors.sft_blue}
                    ariaLabel='loading'
                />
            </div>}
            {!loading && <div style={{height: "fit-content"}} dangerouslySetInnerHTML={{__html: htmlData}}></div>}
        </div>
    );

}
export default ManualViewScreen;