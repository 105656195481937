import React from 'react';
import crypto from 'crypto-js';
import {
    useParams,
    Navigate
  } from 'react-router-dom';

const AuthScreen = (props) =>  {
    //console.log(props);
    
    const params = useParams();

    sessionStorage.setItem('key', params.key);
    let redirectUrl = "/" + props.lang + "/home";

    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        //console.log("KOEKJE", cname + "=" + cvalue + ";" + expires + ";path=/");
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        
    }

    if(params.redirect !== "" && params.redirect !== "undefined" && params.redirect){
        switch(params.redirect){
            case "excel":
                redirectUrl = "/" + props.lang + "/excel";
                if(params.val !== "" && typeof params.val !== "undefined"){
                    if(params.val2 !== "" && typeof params.val2 !== "undefined" && params.val2 !== "undefined"){
                        redirectUrl += "/"+params.val+"/"+params.val2;
                    } else {
                        redirectUrl += "/"+params.val;
                    }
                }  
                break;
            case "kb":
                redirectUrl = "/" + props.lang + "/kb";
                if(params.val !== "" && typeof params.val !== "undefined"){
                    if(params.val2 !== "" && typeof params.val2 !== "undefined" && params.val2 !== "undefined"){
                        redirectUrl += "/"+params.val+"/"+params.val2;
                    } else {
                        redirectUrl += "/"+params.val;
                    }
                }  
                break;
            case "licence":
                redirectUrl = "/" + props.lang + "/licence";
                break;
            case "mobile":
                redirectUrl = "/" + props.lang + "/mobile";
                break;
            case "rma":
                redirectUrl = "/" + props.lang + "/rma";
                if(params.val !== "" && typeof params.val !== "undefined"){
                    redirectUrl += "/"+params.val;
                }  
                break;
            case "shipping":
                redirectUrl = "/" + props.lang + "/shipping";
                if(params.val !== "" && typeof params.val !== "undefined"){
                    if(params.val2 !== "" && typeof params.val2 !== "undefined" && params.val2 !== "undefined"){
                        redirectUrl += "/"+params.val+"/"+params.val2;
                    } else {
                        redirectUrl += "/"+params.val;
                    }
                }  
                break;
            case "users":
                redirectUrl = "/" + props.lang + "/users";
                break;
            default:
                redirectUrl = "/" + props.lang + "/home";
                break;
        }
    }
    if(params?.redirect !== "cookie"){
        let baseLogin = {sft_group: "", sft_status:"0", sft_user_id:""};
        setCookie("cred", crypto.AES.encrypt(JSON.stringify(baseLogin), 'Soft295Touch!').toString());
    }

    if(params.key===""){
        redirectUrl = "/" + props.lang + "/login";
    }

    return (
        <Navigate to={redirectUrl} />
    );

}

export default AuthScreen;
