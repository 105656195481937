import React from 'react';


const Holiday = (props) =>  {

    return (
        <div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
            <div className={props.occasion}></div>
        </div>
    );
}

export default Holiday;
