import React, { useState } from 'react';
import axios from 'axios';
import SftGlobalData from '../../library/SftGlobalData';

import Label_template_1 from '../../assets/mobile-icons/template_label_1.png';
import Label_template_2 from '../../assets/mobile-icons/template_label_2.png';

const OptionSetting = (props) => {
    const [value, setValue] = useState(props.defaultValue);
  
  const submitHandler = async (value) => {
    let url = SftGlobalData.baseURL_API_old + '?key='+props.api+'&edit=1&setting='+props.setting+"&value="+value+"&category="+props.category;
        if(props.groupId !== '0000'){
            url = SftGlobalData.baseURL_API_old + '?key='+props.api+'&edit=1&setting='+props.setting+"&value="+value+'&groupId='+props.groupId+"&category="+props.category;
        }
    axios.get(url)
        .then(response => {console.log(response)});
  }

  const change = (event) => {
    setValue(event.target.value);
    submitHandler(event.target.value);
  }

    return (        
            <div>
                
                <div style={{height: 68}}>
                    <h2 className='_80perc align_left'>{props.title}</h2>
                    <select className={props.theme+'_accent_darker _20perc ' + (props.description !== "-" ?  ' margin_bottom_20px ' : 'margin_bottom_10px') } onChange={change} value={value}>
                        {props.children}
                    </select>       
                </div>  
                {props.description !== "-" && <div className='_100perc'>    
                    <p>{props.description}</p>
                </div> }
                {props.description !== "-" && <hr className={'margin_top_40px' + (props.theme==='dark') ? 'light_text' : 'dark_text'}/>}
                {props.setting === "settLabelTemplate" && <div className=' _100perc flex_center_column'>
                    <img style={{margin: 'auto'}} src={value==="Template 2" ? Label_template_2 : Label_template_1} alt={props.title} />
                </div>}
            </div>
        
    );
}

export default OptionSetting;