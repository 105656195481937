import React, { useEffect, useState } from 'react';
import SftGlobalData from '../../library/SftGlobalData';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Colors from '../../library/Colors';
import { TailSpin } from 'react-loader-spinner';
import axios from 'axios';
import EditableJsonComponent from './EditableJsonComponent';
import { DataGridPro, GridToolbarContainer, GridToolbarExport, useGridApiRef } from '@mui/x-data-grid-pro';
import { GridCsvExportOptions } from '@mui/x-data-grid-pro'


const ExactModal = (props) =>  {  
    const [loading, setLoading] = useState(true);
    const [journals, setJournals] = useState([]);
    const [vatCodes, setVatCodes] = useState([]);

    const [selectedInterval, setSelectedInterval] = useState(props?.scheduleData?.method);
    const [selectedIntervalWeeklyOn, setSelectedIntervalWeeklyOn] = useState(props?.scheduleData?.weeklyOn ?? "1");
    const [selectedIntervalMonthlyOn, setSelectedIntervalMonthlyOn] = useState(props?.scheduleData?.monthlyOn?.[1] == 2147483648 ? "end" : "begin" );
    const [selectedNextRun, setSelectedNextRun] = useState(props?.scheduleData?.nextRun);
    const [monthlyOn, setMonthlyOn] = useState(props?.scheduleData?.monthlyOn ?? [4095, 1]); // Default to "Start of month"
    const [nextRunHelper, setNextRunHelper] = useState("");

    const [saveChanges, setSaveChanges] = useState(false);
    const [softtouchData, setSofttouchData] = useState(props.softtouchData);
    const [exactData, setExactData] = useState(props.exactData);

    const [kassaExact, setKassaExact] = useState({journalCode: {}});

    const [reportId, setReportId] = useState((typeof props?.softtouchData?.reportId !== "undefined" ? props?.softtouchData?.reportId : 0));
    const [journal, setJournal] = useState(typeof props?.exactData?.journal !== "undefined" ? props?.exactData?.journal : "");
    const [paymentCondition, setPaymentCondition] = useState(typeof props?.exactData?.paymentCondition !== "undefined" ? props?.exactData?.paymentCondition : "");
    const [customer, setCustomer] = useState(typeof props?.exactData?.customer !== "undefined" ? props?.exactData?.customer : " - ");
    const [customerName, setCustomerName] = useState("");
    const [exactCustomer, setExactCustomer] = useState("");
    const [saveTime, setSaveTime] = useState(0);
    const [savedBinding, setSavedBinding] = useState(false);
    const [savedNewBinding, setSavedNewBinding] = useState(false);
    const [savedVat, setSavedVat] = useState(false);
    const [savedGlAcc, setSavedGlAcc] = useState(false);
    const [savedAcc, setSavedAcc] = useState(false);

    const [testResult, setTestResult] = useState([]);

    const apiRef = useGridApiRef();

    //console.log("modal", props);

    useEffect(()=>{
        if(saveTime > 0){
            if((savedBinding && savedNewBinding && savedVat && savedGlAcc && (props?.module !== "3" || savedAcc)))
                finisher();
            if(SftGlobalData.debug)
                console.log("saveTime", saveTime);
        }
    }, [saveTime]);

    useEffect(() => {
        if(props.customer !== "") {
            if(props.content === "test") { 
               testCall();
            } else {
                let url = SftGlobalData.baseURL_API + 'exact&action=fetchExact';
                let postData = {key: props.api, jourfilter: [], vatfilter: [], customer: ((props.content === "edit" || props.content === "test") ? props?.target?.api_customer_link_id : props.customer)};
                axios.post(url, postData, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }}) 
                    .then(response => {
                        if(SftGlobalData.debug){
                            console.log('url', url);
                            console.log('postData', postData);
                            console.log('response', response);
                        }
                        if(typeof response.error === 'undefined'){
                            if(typeof response.data.exactData?.journals !== "undefined") 
                                setJournals(response.data.exactData?.journals);
                            if(typeof response.data.exactData?.vat !== "undefined") 
                                setVatCodes(response.data.exactData?.vat);
                            
                            if(props?.module === "3"){
                                let url = SftGlobalData.baseURL_API + 'exact&action=fetchKassaExact';
                                let postData = {key: props.api};
                                axios.post(url, postData, {
                                    headers : {
                                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                                    }}) 
                                    .then(response => {
                                        if(SftGlobalData.debug){
                                            console.log('url', url);
                                            console.log('postData', postData);
                                            console.log('response', response);
                                        }
                                        if(typeof response.error === 'undefined'){
                                            if(typeof response.data !== "undefined"){
                                                const result = response.data.data.reduce((acc, item) => {
                                                    // Check if the code exists in the journalCode object
                                                    const value = exactData?.journalCode[item.code] || ''; // Use empty string if not found
                                                    // Add the code and value to the accumulator object
                                                    acc[item.code] = value;
                                                    return acc;
                                                }, {});
                                                if(SftGlobalData.debug)
                                                    console.log("journalCodes", {journalCode: result});
                                                setKassaExact({journalCode: result});
                                            } 
                                            setLoading(false);
                                        } else { 
                                            props.notify(props.t('errorOccured'), 7500, "top-center", props.theme, 1);
                                        }
                                    });
                            } else{
                                setLoading(false);
                            }
                        } else { 
                            props.notify(props.t('errorOccured'), 7500, "top-center", props.theme, 1);
                        }
                    });
                }
        }
    },[props.api]);

    const testCall = () => {
        //console.log("props", props);
        let url = SftGlobalData.baseURL_API + 'exact&action=testExact';
        let postData = {key: props.api, bindingData: props.softtouchData.bindingData, exactData: props.exactData, repId: props.softtouchData.reportId };
        axios.post(url, postData, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }}) 
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('postData', postData);
                    console.log('response', response);
                }
                if(response?.data?.error){
                    setTestResult([]);
                } else {
                    setTestResult(response?.data?.data);
                }
                props.notify(response?.data?.message, 7500, "top-center", props.theme, 1);
                setLoading(false);
            });
    }

    const finisher = () => {
        let keyWord = "invoices";
        switch(((props.content === "edit" || props.content === "test") ? props?.target?.api_module_id : props?.module)){
            case "1": keyWord = "invoices"; break;
            case "2": keyWord = "revenues"; break;
            case "3": keyWord = "cashEntries"; break;
        }
        let postData = {
            key: props.api,
            method: props.content,
            customer: props?.customer,
            module: props?.module,
            config: {},
            schedule: {
                nextRun: selectedNextRun,
                method: selectedInterval,
                weeklyOn: (selectedInterval === "weekly" ? parseInt(selectedIntervalWeeklyOn) : null),
                monthlyOn: (selectedInterval === "monthly" ? monthlyOn : null),
                between: null,
                unlessBetween: null
            }
        }
        if(props?.module === "1" || props?.module === "2"){
            postData = {
                key: props.api,
                method: props.content,
                customer: props?.customer,
                module: props?.module,
                config:{
                    softtouchData: {
                        [keyWord]: softtouchData
                    },
                    exactData: exactData
                },
                schedule: {
                    nextRun: selectedNextRun,
                    method: selectedInterval,
                    weeklyOn: (selectedInterval === "weekly" ? parseInt(selectedIntervalWeeklyOn) : null),
                    monthlyOn: (selectedInterval === "monthly" ? monthlyOn : null),
                    between: null,
                    unlessBetween: null
                }
            }
        } else if(props?.module === "3") {
            postData = {
                key: props.api,
                method: props.content,
                customer: props?.customer,
                module: props?.module,
                config:{
                    softtouchData: {
                        [keyWord]: softtouchData
                    },
                    exactData: exactData
                },
                schedule: {
                    nextRun: selectedNextRun,
                    method: selectedInterval,
                    weeklyOn: (selectedInterval === "weekly" ? parseInt(selectedIntervalWeeklyOn) : null),
                    monthlyOn: (selectedInterval === "monthly" ? monthlyOn : null),
                    between: null,
                    unlessBetween: null
                }
            }
        } else if(props?.module === "5") {
            postData = {
                key: props.api,
                method: props.content,
                customer: props?.customer,
                module: props?.module,
                config:{},
                schedule: {
                    nextRun: selectedNextRun,
                    method: selectedInterval,
                    weeklyOn: (selectedInterval === "weekly" ? parseInt(selectedIntervalWeeklyOn) : null),
                    monthlyOn: (selectedInterval === "monthly" ? monthlyOn : null),
                    between: null,
                    unlessBetween: null
                }
            }
        }
        props.onClose(postData);
    }

    const fetchCustomer = () => {
        if(exactCustomer !== "") {
            let url = SftGlobalData.baseURL_API + 'exact&action=fetchExact';
            let postData = {key: props.api, customer: props?.target?.api_customer_link_id, accfilter: [exactCustomer]};
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }}) 
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('response', response);
                    }
                    if(typeof response.error === 'undefined'){
                        if(typeof response.data.exactData?.accounts !== "undefined") {
                            try{
                                setCustomer(response.data.exactData?.accounts[0].id);
                                setCustomerName(response.data.exactData?.accounts[0].name);
                            }catch(e){
                                setCustomerName("Customer not found.");
                            }
                        } else {
                            setCustomerName("Customer not found.");
                        }
                    } else { 
                        setCustomerName("Customer not found.");
                        props.notify(props.t('errorOccured'), 7500, "top-center", props.theme, 1);
                    }
                });
        }
    }

    const handleIntervalMonthlyOnChange = (value) => {
        setSelectedIntervalMonthlyOn(value);
        if (selectedInterval === "weekly") {
            // Directly set the bitmask based on the weekly value (already provided in `MenuItem` values)
            setMonthlyOn([0, parseInt(value, 10)]); // Use `value` directly as bitmask for weekly
        } else if (selectedInterval === "monthly") {
            if (value === "begin"){
                setMonthlyOn([4095, 1]); // Start of month
            } else if (value === "end") {
                setMonthlyOn([4095, 2147483648]); // End of month
            }
        }
    };

    const handleData = (data, target, objectTarget) => {
        if(target === "softtouchData"){
            setSofttouchData(prevState => {
                /*console.log("object",objectTarget);
                console.log("old", prevState);
                console.log("new", {
                    ...prevState,
                    [objectTarget]: {
                      ...data[objectTarget]
                    }
                });*/
                return ({
                    ...prevState,
                    reportId: reportId,
                    [objectTarget]: {
                      ...data[objectTarget]
                    }
                });
              });
            if(objectTarget === "bindingData") setSavedBinding(true);
            if(objectTarget === "newBindingData") setSavedNewBinding(true);
        } else if (target === "exactData"){
            setExactData(prevState => {
                /*console.log("object_exact",objectTarget);
                console.log("old_exact", prevState);
                console.log("new_exact", {
                    ...prevState,
                    journal: journal,
                    paymentCondition: paymentCondition,
                    customer: customer,
                    [objectTarget]: {
                      ...data[objectTarget]
                    }
                });*/
                if(props?.module === "2"){
                    return ({
                        ...prevState,
                        journal: journal,
                        paymentCondition: paymentCondition,
                        customer: customer,
                        [objectTarget]: {
                          ...data[objectTarget]
                        }
                    });
                } else if (props?.module === "1"){
                    return ({
                        ...prevState,
                        journal: journal,
                        paymentCondition: paymentCondition,
                        [objectTarget]: {
                          ...data[objectTarget]
                        }
                    });
                } else if (props?.module === "3"){
                    return ({
                        ...prevState,
                        [objectTarget]: {
                          ...data[objectTarget]
                        }
                    });
                }
                
            });
            if(objectTarget === "vatCode") setSavedVat(true);
            if(objectTarget === "glAccount") setSavedGlAcc(true);
            if(objectTarget === "journalCode") setSavedVat(true);
            if(objectTarget === "account") setSavedAcc(true);
        }
        setSaveTime(saveTime+1);
    }

    const getModuleContent = () => {
        let content = (<div></div>);
        switch(((props.content === "edit" || props.content === "test") ? props?.target?.api_module_id : props?.module)){
            case "1": // MODULE 1
                content = (
                    <div>
                        <div className='rowContainerBetween' style={{ flex: 1, width: "100%", alignContent: "flex-start", justifyContent: "flex-start" }}>
                            <div className='columnContainerStart' style={{width: "50%"}}>
                                <h3 style={{marginBottom: 0}}>SoftTouch data</h3>
                                <hr/>
                            </div>

                            <div style={{width: 1, marginRight: 5, marginLeft: 5}}></div>

                            <div className='columnContainerStart' style={{width: "50%"}}>
                                <h3 style={{marginBottom: 0}}>Exact data</h3>
                                <hr/>
                            </div>
                        </div>
                        <div className='rowContainerBetween' style={{ flex: 1, width: "100%", alignContent: "flex-start", justifyContent: "flex-start", minHeight: 150, textAlign: "left" }}>
                         <div className='columnContainerStart' style={{width: "50%", paddingLeft: 5, paddingRight: 5}}>
                            {
                                /* SOFTTOUCH DATA */
                            }
                            {/* Report ID */}
                            <h4 style={{textAlign: "left", marginTop: 5}}>Report</h4>
                            <div className='rowContainerBetween _100perc ' style={{textAlign: "left", marginTop: 10, marginBottom: 10}}></div>
                            <div className='rowContainerBetween' style={{textAlign: "left", marginLeft: -5}} >
                                <TextField
                                    className={props.theme+ '_accent _100perc '}
                                    label="Report ID"
                                    value={reportId}
                                    onChange={(event)=>{
                                        setReportId(event.target.value);
                                    }}
                                    variant="outlined"
                                    style={{marginBottom: 25}}
                                    sx={(props.theme==='light') ? {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.light_text
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.sft_blue
                                        }} 
                                        :
                                        {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.dark_text
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: Colors.sft_blue
                                            }}}
                                  />
                            </div>
                            <hr/>
                            <h4 style={{textAlign: "left", marginTop: 5}}>Binding data</h4>
                            {/* Binding data table */}
                            <EditableJsonComponent jsonData={props?.softtouchData} target={"bindingData"} theme={props.theme} t={props.t} save={saveChanges} handleData={(data) => {handleData(data, "softtouchData", "bindingData")}}/>
                            <hr/>
                            <h4 style={{textAlign: "left", marginTop: 5}}>New binding data</h4>
                            {/* NEW binding data table */}
                            <EditableJsonComponent jsonData={props?.softtouchData} target={"newBindingData"} theme={props.theme} t={props.t} save={saveChanges} handleData={(data) => {handleData(data, "softtouchData", "newBindingData")}}/>
                         </div>

                         <div style={{border: 'solid grey 1px', width: 1, flex: 1, marginRight: 5, marginLeft: 5}}></div>

                         <div className='columnContainerStart' style={{width: "50%", paddingLeft: 5, paddingRight: 5}}>
                            {
                                /* EXACT DATA */
                            }
                            <h4 style={{textAlign: "left", marginTop: 5, marginLeft: 5}}>General</h4>
                            <div className='rowContainerBetween _100perc ' style={{textAlign: "left", marginTop: 10, marginBottom: 10}}></div>
                            <div className='rowContainerBetween _100perc ' style={{textAlign: "left", marginBottom: 10, height: 43}}>
                                <FormControl className={props.theme+ '_accent _100perc'} style={{textAlign: "left", marginBottom: 8, height: "100%", marginRight: 10}} >
                                   {/* Journal (SELECT input) */}
                                  <InputLabel id="demo-simple-select-label">Journal</InputLabel>
                                  <Select
                                    className={props.theme+"_accent "+props.theme+"_text _100perc"}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={journal}
                                    label="Journal"
                                    onChange={(event)=>{const {target: { value },} = event; setJournal(value);}}
                                    style={{height: "100%", paddingTop: 8, paddingBottom: 8}}>
                                        {journals.map((row, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={row.code}
                                                >
                                                    {row?.code} - {row?.description} 
                                                </MenuItem>
                                            ))
                                        }
                                        
                                  </Select>
                                </FormControl>

                                {/* paymentCondition */}
                                <TextField
                                    className={props.theme+ '_accent _100perc '}
                                    label="Payment condition"
                                    value={paymentCondition}
                                    onChange={(event)=>{
                                        setPaymentCondition(event.target.value);
                                    }}
                                    variant="outlined"
                                    style={{height: "100%"}}
                                    sx={(props.theme==='light') ? {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.light_text
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.sft_blue
                                        }} 
                                        :
                                        {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.dark_text
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: Colors.sft_blue
                                            }}}
                                  />
                            </div>
                            <hr/>
                            {/* VatCode */}
                            <h4 style={{textAlign: "left", marginTop: 5, marginLeft: 5}}>VAT Codes</h4>
                            <EditableJsonComponent jsonData={props?.exactData} target={"vatCode"} theme={props.theme} t={props.t} save={saveChanges} handleData={(data) => {handleData(data, "exactData", "vatCode")}} vatCodes={vatCodes}/>
                            <hr/>
                            {/* GL Accounts */}
                            <h4 style={{textAlign: "left", marginTop: 5, marginLeft: 5}}>GL accounts</h4>
                            <div className='rowContainerBetween _100perc ' style={{textAlign: "left", marginTop: 10, marginBottom: 10}}></div>
                            <EditableJsonComponent jsonData={props?.exactData} target={"glAccount"} theme={props.theme} t={props.t} save={saveChanges} handleData={(data) => {handleData(data, "exactData", "glAccount")}} cust={((props.content === "edit" || props.content === "test") ? props?.target?.api_customer_link_id : props.customer)} api={props.api} notify={(text, time, position, theme, id) => {props.notify(text, time, position, theme, id);}} />
                         </div>
                     </div>
                 </div>
                )
                break;
            case "2": // MODULE 2
                content = (
                    <div>
                        <div className='rowContainerBetween' style={{ flex: 1, width: "100%", alignContent: "flex-start", justifyContent: "flex-start" }}>
                            <div className='columnContainerStart' style={{width: "50%"}}>
                                <h3 style={{marginBottom: 0}}>SoftTouch data</h3>
                                <hr/>
                            </div>

                            <div style={{width: 1, marginRight: 5, marginLeft: 5}}></div>

                            <div className='columnContainerStart' style={{width: "50%"}}>
                                <h3 style={{marginBottom: 0}}>Exact data</h3>
                                <hr/>
                            </div>
                        </div>
                        <div className='rowContainerBetween' style={{ flex: 1, width: "100%", alignContent: "flex-start", justifyContent: "flex-start", minHeight: 150, textAlign: "left" }}>
                         <div className='columnContainerStart' style={{width: "50%", paddingLeft: 5, paddingRight: 5}}>
                            {
                                /* SOFTTOUCH DATA */
                            }
                            {/* Report ID */}
                            <h4 style={{textAlign: "left", marginTop: 5}}>Report</h4>
                            <div className='rowContainerBetween _100perc ' style={{textAlign: "left", marginTop: 10, marginBottom: 10}}></div>
                            <div className='rowContainerBetween' style={{textAlign: "left", marginLeft: -5}} >
                                <TextField
                                    className={props.theme+ '_accent _100perc '}
                                    label="Report ID"
                                    value={reportId}
                                    onChange={(event)=>{
                                        setReportId(event.target.value);
                                    }}
                                    variant="outlined"
                                    style={{marginBottom: 25}}
                                    sx={(props.theme==='light') ? {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.light_text
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.sft_blue
                                        }} 
                                        :
                                        {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.dark_text
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: Colors.sft_blue
                                            }}}
                                  />
                            </div>
                            <hr/>
                            <h4 style={{textAlign: "left", marginTop: 5}}>Binding data</h4>
                            {/* Binding data table */}
                            <EditableJsonComponent jsonData={props?.softtouchData} target={"bindingData"} theme={props.theme} t={props.t} save={saveChanges} handleData={(data) => {handleData(data, "softtouchData", "bindingData")}}/>
                            <hr/>
                            <h4 style={{textAlign: "left", marginTop: 5}}>New binding data</h4>
                            {/* NEW binding data table */}
                            <EditableJsonComponent jsonData={props?.softtouchData} target={"newBindingData"} theme={props.theme} t={props.t} save={saveChanges} handleData={(data) => {handleData(data, "softtouchData", "newBindingData")}}/>
                         </div>

                         <div style={{border: 'solid grey 1px', width: 1, flex: 1, marginRight: 5, marginLeft: 5}}></div>

                         <div className='columnContainerStart' style={{width: "50%", paddingLeft: 5, paddingRight: 5}}>
                            {
                                /* EXACT DATA */
                            }
                            <h4 style={{textAlign: "left", marginTop: 5, marginLeft: 5}}>General</h4>
                            <div className='rowContainerBetween _100perc ' style={{textAlign: "left", marginTop: 10, marginBottom: 10}}></div>
                            <div className='rowContainerBetween _100perc ' style={{textAlign: "left", marginBottom: 10, height: 43}}>
                                <FormControl className={props.theme+ '_accent _100perc'} style={{textAlign: "left", marginBottom: 8, height: "100%", marginRight: 10}} >
                                   {/* Journal (SELECT input) */}
                                  <InputLabel id="demo-simple-select-label">Journal</InputLabel>
                                  <Select
                                    className={props.theme+"_accent "+props.theme+"_text _100perc"}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={journal}
                                    label="Journal"
                                    onChange={(event)=>{const {target: { value },} = event; setJournal(value);}}
                                    style={{height: "100%", paddingTop: 8, paddingBottom: 8}}>
                                        {journals.map((row, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={row.code}
                                                >
                                                    {row?.code} - {row?.description} 
                                                </MenuItem>
                                            ))
                                        }
                                        
                                  </Select>
                                </FormControl>

                                {/* paymentCondition */}
                                <TextField
                                    className={props.theme+ '_accent _100perc '}
                                    label="Payment condition"
                                    value={paymentCondition}
                                    onChange={(event)=>{
                                        setPaymentCondition(event.target.value);
                                    }}
                                    variant="outlined"
                                    style={{height: "100%"}}
                                    sx={(props.theme==='light') ? {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.light_text
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.sft_blue
                                        }} 
                                        :
                                        {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.dark_text
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: Colors.sft_blue
                                            }}}
                                  />
                            </div>
                            <div className='rowContainerBetween _100perc ' style={{textAlign: "left", marginBottom: 10}}>
                                <p className='_100perc margin_right__10px' style={{marginTop: 0, marginBottom: 0}}><span className='bold'>Customer: </span> {customer} {customerName !== "" && <span className={""+(customerName==="Customer not found."?"error_text": "")}><br/><i>{customerName}</i></span>}</p>
                                <div className=' rowContainerBetween _100perc ' style={{justifyContent: "flex-start"}}>
                                    <TextField
                                        className={props.theme+ '_accent _100perc margin_right__10px '}
                                        label="Exact Customer"
                                        value={exactCustomer}
                                        onChange={(event)=>{
                                            setExactCustomer(event.target.value);
                                        }}
                                        variant="outlined"
                                        style={{height: 43}}
                                        sx={(props.theme==='light') ? {
                                            '& .MuiOutlinedInput-input': {
                                                color: Colors.light_text
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: Colors.sft_blue
                                            }} 
                                            :
                                            {
                                            '& .MuiOutlinedInput-input': {
                                                color: Colors.dark_text
                                                },
                                                '& .MuiInputLabel-root': {
                                                    color: Colors.sft_blue
                                                }}}
                                      />
                                    <Button className="modal_button_container_no_margin__button " style={{width: 100, height: 43}} onClick={()=>{fetchCustomer();}}>{props.t("fetch")}</Button>
                                </div>
                            </div>
                            <hr/>
                            {/* VatCode */}
                            <h4 style={{textAlign: "left", marginTop: 5, marginLeft: 5}}>VAT Codes</h4>
                            <EditableJsonComponent jsonData={props?.exactData} target={"vatCode"} theme={props.theme} t={props.t} save={saveChanges} handleData={(data) => {handleData(data, "exactData", "vatCode")}} vatCodes={vatCodes}/>
                            <hr/>
                            {/* GL Accounts */}
                            <h4 style={{textAlign: "left", marginTop: 5, marginLeft: 5}}>GL accounts</h4>
                            <div className='rowContainerBetween _100perc ' style={{textAlign: "left", marginTop: 10, marginBottom: 10}}></div>
                            <EditableJsonComponent jsonData={props?.exactData} target={"glAccount"} theme={props.theme} t={props.t} save={saveChanges} handleData={(data) => {handleData(data, "exactData", "glAccount")}} cust={((props.content === "edit" || props.content === "test") ? props?.target?.api_customer_link_id : props.customer)} api={props.api} notify={(text, time, position, theme, id) => {props.notify(text, time, position, theme, id);}} />
                         </div>
                     </div>
                 </div>
                )
                break;
            case "3": // MODULE 3
                content = (
                    <div>
                        <div className='rowContainerBetween' style={{ flex: 1, width: "100%", alignContent: "flex-start", justifyContent: "flex-start" }}>
                            <div className='columnContainerStart' style={{width: "50%"}}>
                                <h3 style={{marginBottom: 0}}>SoftTouch data</h3>
                                <hr/>
                            </div>

                            <div style={{width: 1, marginRight: 5, marginLeft: 5}}></div>

                            <div className='columnContainerStart' style={{width: "50%"}}>
                                <h3 style={{marginBottom: 0}}>Exact data</h3>
                                <hr/>
                            </div>
                        </div>
                        <div className='rowContainerBetween' style={{ flex: 1, width: "100%", alignContent: "flex-start", justifyContent: "flex-start", minHeight: 150, textAlign: "left" }}>
                         <div className='columnContainerStart' style={{width: "50%", paddingLeft: 5, paddingRight: 5}}>
                            {
                                /* SOFTTOUCH DATA */
                            }
                            {/* Report ID */}
                            <h4 style={{textAlign: "left", marginTop: 5}}>Report</h4>
                            <div className='rowContainerBetween _100perc ' style={{textAlign: "left", marginTop: 10, marginBottom: 10}}></div>
                            <div className='rowContainerBetween' style={{textAlign: "left", marginLeft: -5}} >
                                <TextField
                                    className={props.theme+ '_accent _100perc '}
                                    label="Report ID"
                                    value={reportId}
                                    onChange={(event)=>{
                                        setReportId(event.target.value);
                                    }}
                                    variant="outlined"
                                    style={{marginBottom: 25}}
                                    sx={(props.theme==='light') ? {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.light_text
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.sft_blue
                                        }} 
                                        :
                                        {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.dark_text
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: Colors.sft_blue
                                            }}}
                                  />
                            </div>
                            <hr/>
                            <h4 style={{textAlign: "left", marginTop: 5}}>Binding data</h4>
                            {/* Binding data table */}
                            <EditableJsonComponent jsonData={props?.softtouchData} target={"bindingData"} theme={props.theme} t={props.t} save={saveChanges} handleData={(data) => {handleData(data, "softtouchData", "bindingData")}}/>
                            <hr/>
                            <h4 style={{textAlign: "left", marginTop: 5}}>New binding data</h4>
                            {/* NEW binding data table */}
                            <EditableJsonComponent jsonData={props?.softtouchData} target={"newBindingData"} theme={props.theme} t={props.t} save={saveChanges} handleData={(data) => {handleData(data, "softtouchData", "newBindingData")}}/>
                         </div>

                         <div style={{border: 'solid grey 1px', width: 1, flex: 1, marginRight: 5, marginLeft: 5}}></div>

                         <div className='columnContainerStart' style={{width: "50%", paddingLeft: 5, paddingRight: 5}}>
                            {
                                /* EXACT DATA */
                            }
                            <h4 style={{textAlign: "left", marginTop: 5, marginLeft: 5}}>General</h4>
                            <div className='rowContainerBetween _100perc ' style={{textAlign: "left", marginTop: 10, marginBottom: 10}}></div>
                            {/* JournalCode */}
                            <h4 style={{textAlign: "left", marginTop: 5, marginLeft: 5}}>Journal code</h4>
                            <div className='rowContainerBetween _100perc ' style={{textAlign: "left", marginTop: 10, marginBottom: 10}}></div>
                            <EditableJsonComponent jsonData={kassaExact} target={"journalCode"} theme={props.theme} t={props.t} save={saveChanges} handleData={(data) => {handleData(data, "exactData", "journalCode")}} journalCodes={journals}/>
                            <hr/>
                            {/* Accounts */}
                            <h4 style={{textAlign: "left", marginTop: 5, marginLeft: 5}}>Accounts</h4>
                            <div className='rowContainerBetween _100perc ' style={{textAlign: "left", marginTop: 10, marginBottom: 10}}></div>
                            <EditableJsonComponent jsonData={props?.exactData} target={"account"} theme={props.theme} t={props.t} save={saveChanges} handleData={(data) => {handleData(data, "exactData", "account")}} cust={((props.content === "edit" || props.content === "test") ? props?.target?.api_customer_link_id : props.customer)} api={props.api} notify={(text, time, position, theme, id) => {props.notify(text, time, position, theme, id);}}/>
                            <hr/>
                            {/* GL Accounts */}
                            <h4 style={{textAlign: "left", marginTop: 5, marginLeft: 5}}>GL accounts</h4>
                            <div className='rowContainerBetween _100perc ' style={{textAlign: "left", marginTop: 10, marginBottom: 10}}></div>
                            <EditableJsonComponent jsonData={props?.exactData} target={"glAccount"} theme={props.theme} t={props.t} save={saveChanges} handleData={(data) => {handleData(data, "exactData", "glAccount")}} cust={((props.content === "edit" || props.content === "test") ? props?.target?.api_customer_link_id : props.customer)} api={props.api} notify={(text, time, position, theme, id) => {props.notify(text, time, position, theme, id);}} />
                         </div>
                     </div>
                 </div>);
                break;
            case "4":
                break;
            case "5": // MODULE 5 (REFRESH TOKEN)
                break;
        }
        return content;
    }

    const columns = testResult.length > 0 ? Object.keys(testResult[0]).map((key) => {
        const specials = ["gl_account"];
        if(specials.includes(key)){
            let checks = [];
            if(key === "gl_account")
                try{ checks = Object.keys(props?.exactData?.glAccount); }catch(e){console.error(e);}
            return {
                field: key,
                headerName: key,
                renderCell: (params) => {
                    //console.log("checks", checks);
                    return <div className={(checks.includes(params.row[key]) ? "" : "error_text")}>{params.row[key]}</div>
                }
            }
        } 
        return {
            field: key,
            headerName: key,
        }
    }) : [];

    function CustomToolbar() {
        return (
          <GridToolbarContainer>
            <GridToolbarExport 
                csvOptions={{
                    fileName: ('ExactTest_'+props?.module),
                    delimiter: ';',
                    utf8WithBom: true,
                  }}/>
          </GridToolbarContainer>
        );
      }

    return (
        <div style={{height: "100%", width: "100%", overflow: 'auto'}}>
            <h2>Module {((props.content === "edit" || props.content === "test") ? props?.target?.api_module_id : props.module)}{(props.content === "edit" || props.content === "test") && <span> - </span>}{props?.target?.name}</h2>
            {loading && <div style={{display:"flex", flexDirection: "column", justifyContent: "center", alignContent: "center", alignItems: "center", marginTop: 5, height: "90%"}}>
                    <TailSpin 
                        height="100"
                        width="100"
                        color={Colors.sft_blue}
                        ariaLabel='loading'
                    />
            </div>}
            {(!loading && props.content !== "test") && (
            <div className='columnContainerStart _100perc'>
                <div className='rowContainerBetween' style={{marginTop: 10, marginBottom: 10, textAlign: "left", height: 43}}>
                    <div className='columnContainerStart _100perc margin_right__10px'>
                        <FormControl className={props.theme+ '_accent '}>
                          <InputLabel id="demo-simple-select-label">Interval</InputLabel>
                          <Select
                            className={props.theme+"_accent "+props.theme+"_text _100perc"}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedInterval}
                            label="Interval"
                            onChange={(event)=>{const {target: { value },} = event; setSelectedInterval(value);}}
                            style={{height: 43, paddingTop: 8, paddingBottom: 8}}>
                                <MenuItem value="daily">Daily</MenuItem>
                                <MenuItem value="weekly">Weekly</MenuItem>
                                <MenuItem value="monthly">Monthly</MenuItem>
                          </Select>
                        </FormControl>
                    </div>
                   
                        <div className='columnContainerStart _100perc margin_right__10px '>
                            {selectedInterval === "weekly" && (
                                <FormControl className={props.theme + '_accent '}>
                                    <InputLabel id="demo-simple-select-label">On</InputLabel>
                                    <Select
                                        className={props.theme + "_accent " + props.theme + "_text _100perc"}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedIntervalWeeklyOn}
                                        label="Interval"
                                        disabled={selectedInterval !== "weekly" && selectedInterval !== "monthly"}
                                        onChange={(event) => setSelectedIntervalWeeklyOn(event.target.value)}
                                        style={{ height: 43, paddingTop: 8, paddingBottom: 8 }}>
                                        <MenuItem value="1">Monday</MenuItem>
                                        <MenuItem value="2">Tuesday</MenuItem>
                                        <MenuItem value="4">Wednesday</MenuItem>
                                        <MenuItem value="8">Thursday</MenuItem>
                                        <MenuItem value="16">Friday</MenuItem>
                                        <MenuItem value="32">Saturday</MenuItem>
                                        <MenuItem value="64">Sunday</MenuItem>

                                    </Select>
                                </FormControl>
                            )}
                            {selectedInterval === "monthly" && (
                                <FormControl className={props.theme + '_accent '}>
                                    <InputLabel id="demo-simple-select-label">On</InputLabel>
                                    <Select
                                        className={props.theme + "_accent " + props.theme + "_text _100perc"}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedIntervalMonthlyOn}
                                        label="Interval"
                                        disabled={selectedInterval !== "weekly" && selectedInterval !== "monthly"}
                                        onChange={(event) => handleIntervalMonthlyOnChange(event.target.value)}
                                        style={{ height: 43, paddingTop: 8, paddingBottom: 8 }}>
                                        <MenuItem value="begin">Start of month</MenuItem>
                                        <MenuItem value="end">End of month</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                        </div>

                    <TextField
                        className={props.theme+ '_accent _100perc  '}
                        label="Next run"
                        value={selectedNextRun}
                        error={(nextRunHelper === "" ? false : true)}        
                        helperText={nextRunHelper} 
                        onChange={(e)=>{ 
                            //first the next run should be validated to see if is valid in the format we expect 'YYYY-MM-DDTHH:MM:SS+01:00' and that it is not more than 1 year in the future
                            //then we should set the next run
                            const dateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\+(\d{2}):(\d{2})$/;
                            // debugger;
                            if(!dateRegex.test(e.target.value)) {
                                setNextRunHelper("Invalid date format, should be 'YYYY-MM-DDTHH:MM:SS+01:00'");
                                setSelectedNextRun(e.target.value);
                                return;
                            }
                            const date = new Date(e.target.value);
                            console.log("date:", date);
                            const now = new Date();
                            const oneYearFromNow = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate());
                            oneYearFromNow.setHours(now.getHours(), now.getMinutes(), now.getSeconds());
                            console.log("oneYearFromNow:", oneYearFromNow);
                            
                            if(date > oneYearFromNow) {
                                setNextRunHelper("Date can't be later than 1 year from now");
                                setSelectedNextRun(e.target.value);
                                return;
                            }
                            setNextRunHelper("");
                            setSelectedNextRun(e.target.value);
                        }}
                        variant="outlined"
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.sft_blue
                            }} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.sft_blue
                                }}}
                      />
                </div>
                {getModuleContent()}
                <Button className="modal_button_container_no_margin__button margin_top_15px" style={{width: '100%' }} onClick={()=>{if(props?.module === "5") finisher(); else setSaveChanges(true);}} disabled={nextRunHelper !== ""}>
                    {props.t("save")}
                </Button>
                {props.content === "edit" && <p><b>Last update: </b> {props?.target?.updated_at}</p>}

            </div>)}

            {(!loading && props.content === "test") && (
            <div className='columnContainerStart _100perc'>
                <div className='rowContainerCenter _100perc'>
                    <DataGridPro
                          //initialState={initialGrid}
                          /*initialState={{ pinnedColumns: { left: ['code'], right: [] }, columns: {
                            columnVisibilityModel: {
                              id: false,
                            },
                          } }}*/
                          apiRef={apiRef}
                          className={'excel_grid ' + props.theme}
                          columns={columns}
                          rows={testResult.map((row, index) => {return {...row, id: index};})} 
                          rowThreshold={0}
                          rowHeight={38}
                          autoHeight={true}
                          loading={loading}
                          pagination={false}
                          autosizeOnMount={true}
                          autosizeOptions={{
                            includeOutliers: true,
                            includeHeaders: true,
                          }}
                          slotProps={{ toolbar: { csvOptions: { allColumns: true } } }}
                          slots={{
                            toolbar: CustomToolbar,
                          }}
                          /> 
                </div>
            </div>)}
        </div>
    )


}

export default ExactModal;