import '../../styles/main.css';
import { React, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MuiPhoneNumber from 'material-ui-phone-number';
import Colors from '../../library/Colors';
import { TailSpin } from  'react-loader-spinner';
import SftGlobalData from '../../library/SftGlobalData';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';

const ShippingCustomer = (props) =>  {
    const [nameHelper, setNameHelper] = useState("");    
    const [loading, setLoading] = useState(true); 
    const [streetNameHelper, setStreetNameHelper] = useState("");
    const [houseNumberHelper, setHouseNumberHelper] = useState("");
    const [zipHelper, setZipHelper] = useState("");
    const [cityHelper, setCityHelper] = useState("");
    const [countryHelper, setCountryHelper] = useState("");
    const [emailHelper, setEmailHelper] = useState("");
    const [phoneHelper, setPhoneHelper] = useState("");
    const [firstname, setFirstname] = useState(props.customer.firstname);
    const [lastname, setLastname] = useState(props.customer.lastname);
    const [company, setCompany] = useState(props.customer.company);
    const [streetName, setStreetName] = useState(props.customer.street_name);
    const [houseNumber, setHouseNumber] = useState(props.customer.house_number);
    const [houseNumberAddition, setHouseNumberAddition] = useState(props.customer.house_number_addition);
    const [zip, setZip] = useState(props.customer.zip);
    const [city, setCity] = useState(props.customer.city);
    const [country, setCountry] = useState({iso: 'BE', iso3: 'BEL', name: (props?.lang === "en" ? "Belgium" : (props?.lang === "fr" ? "Belgique" : 'België'))});
    const [email, setEmail] = useState(props.customer.email);
    const [phone, setPhone] = useState(props.customer.phone);
    const maxLength = 40;

    /*
    customer={customerData}     setCustomer={(customerData)=>{setCustomerData(customerData)}}
    let customer = {firstname: "", lastname: "", company: "", street_name: "", house_number: "",zip: "", city: "",country: "",email: "", phone: "", language: ""};
    */

    useEffect(()=>{
        if(props.countries.length > 0 && props.customer !== undefined && props.receiptNr !== undefined){
            if(props.customer.firstname !== "" && props.customer.firstname !== undefined)  {
                setFirstname(props.customer.firstname); 
                setLoading(false);   
            }  
                
            if(props.customer.lastname !== "" && props.customer.lastname !== undefined){  
                setLastname(props.customer.lastname);
                setLoading(false);   
            }
            if(props.customer.company !== "" && props.customer.company !== undefined){  
                setCompany(props.customer.company);
                setLoading(false);   
            }  
            if(props.customer.street_name && props.customer.street_name !== undefined){  
                setStreetName(props.customer.street_name);
                setLoading(false);   
            }  
            if(props.customer.house_number && props.customer.house_number !== undefined){  
                setHouseNumber(props.customer.house_number);
                setLoading(false);   
            }  
            if(props.customer.house_number_addition && props.customer.house_number_addition !== undefined){  
                setHouseNumberAddition(props.customer.house_number_addition);
                setLoading(false);   
            }  
            if(props.customer.zip && props.customer.zip !== undefined){  
                setZip(props.customer.zip);
                setLoading(false);   
            }  
            if(props.customer.city && props.customer.city !== undefined){  
                setCity(props.customer.city);
                setLoading(false);   
            }  
            if(props.customer.country && props.customer.country !== undefined){
                let countryId   = 0;
                countryId       = getCountry(props.customer.country);
                setCountry(props.countries[countryId]);
                setLoading(false);           
            }
            if(props.customer.email && props.customer.email !== undefined){  
                setEmail(props.customer.email);
                setLoading(false);   
            }  
            if(props.customer.phone && props.customer.phone !== undefined){  
                setPhone(props.customer.phone);
                setLoading(false);   
            }  

                if((props.showCustomerForm === "0" || props.showCustomerForm === "F" || props.showCustomerForm === "FALSE" || props.showCustomerForm === false) && props.receiptNr !== false){          
                let allowNext = true;   
                if(props.customer.firstname !== "" || props.customer.lastname !== "" && (validationTest(firstname, /^([A-z])/) || validationTest(lastname, /^([A-z])/) || (validationTest(props.customer.firstname, /^([A-z])/) || validationTest(props.customer.lastname, /^([A-z])/)))){
                    setNameHelper("");
                } else {
                    setNameHelper("Firstname or lastname is required."); 
                    allowNext = false;
                }

                if(props.customer.street_name !== "" && (validationTest(streetName, /^([A-z])/) || validationTest(props.customer.street_name, /^([A-z])/))){
                    setStreetNameHelper("");
                } else {
                    setStreetNameHelper("Streetname is required."); 
                    allowNext = false;
                }

                if(props.customer.house_number !== "" && (validationTest(houseNumber, /^([0-9])/) || validationTest(props.customer.house_number, /^([0-9])/))){
                    setHouseNumberHelper("");
                } else {
                    setHouseNumberHelper("Housenumber is required.");  
                    allowNext = false;
                }

                if(props.customer.zip !== "" && (validationTest(zip, /([A-Za-z0-9])/) || validationTest(props.customer.zip, /([A-Za-z0-9])/))){
                    setZipHelper("");
                } else {
                    setZipHelper("Zip is required.");
                    allowNext = false;
                }

                if(props.customer.city !== "" && (validationTest(city, /^([A-z])/) || validationTest(props.customer.city, /^([A-z])/))){
                    setCityHelper("");
                } else {
                    setCityHelper("City is required."); 
                    allowNext = false;
                }

                if(props.customer.country !== "" && (validationTest(country["iso"], /^([A-z]{2})$/) || validationTest(props.customer.country, /^([A-z]{2})$/))){
                    setCountryHelper("");
                } else {
                    setCountryHelper("Country is required.");
                    allowNext = false;
                }

                if(props.customer.email !== "" && ((validationTest(email, /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/)) || (validationTest(props.customer.email, /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/)))){
                    setEmailHelper("");
                } else {
                    setEmailHelper("Email is required.");  
                    allowNext = false;
                }    

                // TODO: Phone validation?
                setPhoneHelper("");

                if(allowNext === true && props.error === ''){
                    props.switchForm(true);   
                }            
            }
        } else {
            setLoading(false);
        }
    }, [props.customer, props.countries, props.showCustomerForm]);

    function getCountry(countryIso){
        if(props.countries.length > 1){
            for (let i = 0; i < props.countries.length; i++) {
                if(props.countries[i]["iso"] === countryIso)
                    return i;
              }
        }
    }
    
    function showName(){
            return (<div className={' margin_top_10px ' + (props.screenWidth < 600 ? ' columnContainerBetween ' : ' rowContainerBetween ')}><TextField
            autoComplete="off"          
            error={(nameHelper === "" ? false : true)}        
            helperText={nameHelper}    
            className={((props.theme==='light') ? 'light_accent margin_bottom_10px ' : 'dark_accent_darker margin_bottom_10px ') + (props.screenWidth < 600 ? '_100perc' : 'shipping_width_49perc')}
            id="customer_firstname"
            label={props.t('firstname')}            
            sx={(props.theme==='light') ? {
                '& .MuiOutlinedInput-input': {
                    color: Colors.light_text
                  },
                '& .MuiInputLabel-root': {
                    color: Colors.light_text
                }} 
                :
                {
                '& .MuiOutlinedInput-input': {
                    color: Colors.dark_text
                    },
                    '& .MuiInputLabel-root': {
                        color: Colors.dark_text_accent
                    }}}
            value={firstname}            
            onChange={(event)=>{setFirstname(event.target.value)}}
            />
            <TextField
            autoComplete="off"                  
            error={(nameHelper === "" ? false : true)}        
            helperText={nameHelper}          
            className={((props.theme==='light') ? 'light_accent shipping_float_right margin_bottom_10px ' : 'dark_accent_darker shipping_float_right margin_bottom_10px ') + (props.screenWidth < 600 ? '_100perc' : 'shipping_width_50perc')}
            id="customer_lastname"
            label={props.t('lastname')}
            sx={(props.theme==='light') ? {
                '& .MuiOutlinedInput-input': {
                    color: Colors.light_text
                  },
                '& .MuiInputLabel-root': {
                    color: Colors.light_text
                }} 
                :
                {
                '& .MuiOutlinedInput-input': {
                    color: Colors.dark_text
                    },
                    '& .MuiInputLabel-root': {
                        color: Colors.dark_text_accent
                    }}}
            value={lastname}
            onChange={(event)=>{setLastname(event.target.value)}}
            /></div>) ;
    }

    function showCompany(){
        return <TextField      
                    autoComplete="off"     
                    className={(props.theme==='light') ? 'light_accent shipping_field_full_width margin_bottom_10px' : 'dark_accent_darker shipping_field_full_width margin_bottom_10px'}
                    id="customer_company"
                    label={props.t('company')}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text
                        }} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }}}
                    value={company}
                    onChange={(event)=>{setCompany(event.target.value)}}
                    />
    }

    function showStreetName(){
            return <TextField
            autoComplete="off" 
            required                        
            error={(streetNameHelper === "" ? false : true)}        
            helperText={streetNameHelper} 
            className={(props.theme==='light') ? 'light_accent _100perc margin_bottom_10px' : 'dark_accent_darker _100perc margin_bottom_10px'}
            id="customer_streetname"
            label={props.t('streetname')}
            sx={(props.theme==='light') ? {
                '& .MuiOutlinedInput-input': {
                    color: Colors.light_text
                  },
                '& .MuiInputLabel-root': {
                    color: Colors.light_text
                }} 
                :
                {
                '& .MuiOutlinedInput-input': {
                    color: Colors.dark_text
                    },
                    '& .MuiInputLabel-root': {
                        color: Colors.dark_text_accent
                    }}}
            value={streetName}
            onChange={(event)=>{setStreetName(event.target.value)}}
            />          
    }

    function showHouseNumber() {
            return <TextField
            autoComplete="off" 
            required
            error={(houseNumberHelper === "" ? false : true)}        
            helperText={houseNumberHelper} 
            className={(props.theme==='light') ? 'light_accent width_120px margin_bottom_10px' : 'dark_accent_darker width_120px margin_bottom_10px'}
            id="customer_housenumber"
            label={props.t('housenumber')}
            sx={(props.theme==='light') ? {
                '& .MuiOutlinedInput-input': {
                    color: Colors.light_text
                  },
                '& .MuiInputLabel-root': {
                    color: Colors.light_text
                }} 
                :
                {
                '& .MuiOutlinedInput-input': {
                    color: Colors.dark_text
                    },
                    '& .MuiInputLabel-root': {
                        color: Colors.dark_text_accent
                    }}}
            value={houseNumber}
            onChange={(event)=>{setHouseNumber(event.target.value)}}
            />  
    }

    function showHouseNumberAddition() {
        return <TextField
        autoComplete="off" 
        className={(props.theme==='light') ? 'light_accent width_120px margin_bottom_10px' : 'dark_accent_darker width_120px margin_bottom_10px'}
        id="customer_housenumberaddition"
        label={props.t('shipping_box')}
        sx={(props.theme==='light') ? {
            '& .MuiOutlinedInput-input': {
                color: Colors.light_text
              },
            '& .MuiInputLabel-root': {
                color: Colors.light_text
            }} 
            :
            {
            '& .MuiOutlinedInput-input': {
                color: Colors.dark_text
                },
                '& .MuiInputLabel-root': {
                    color: Colors.dark_text_accent
                }}}
        value={houseNumberAddition}
        onChange={(event)=>{setHouseNumberAddition(event.target.value)}}
        />  
    }

    function showZip() {
            return <TextField
            autoComplete="off" 
            required
            error={(zipHelper === "" ? false : true)}        
            helperText={zipHelper} 
            className={(props.theme==='light') ? 'light_accent shipping_width_49perc margin_bottom_10px ' + props.theme : 'dark_accent_darker shipping_width_49perc margin_bottom_10px ' + props.theme}
            id="customer_zip"
            label={props.t('zip')}
            sx={(props.theme==='light') ? {
                '& .MuiOutlinedInput-input': {
                    color: Colors.light_text
                  },
                '& .MuiInputLabel-root': {
                    color: Colors.light_text
                }} 
                :
                {
                '& .MuiOutlinedInput-input': {
                    color: Colors.dark_text
                    },
                    '& .MuiInputLabel-root': {
                        color: Colors.dark_text_accent
                    }}}
            value={zip}
            onChange={(event)=>{setZip(event.target.value)}}
            />  
    }

    function showCity() {
            return <TextField
            autoComplete="off" 
            required
            error={(cityHelper === "" ? false : true)}        
            helperText={cityHelper} 
            className={(props.theme==='light') ? 'light_accent shipping_width_50perc shipping_float_right' : 'dark_accent_darker shipping_width_50perc shipping_float_right'}
            id="customer_city"
            label={props.t('city')}
            sx={(props.theme==='light') ? {
                '& .MuiOutlinedInput-input': {
                    color: Colors.light_text
                  },
                '& .MuiInputLabel-root': {
                    color: Colors.light_text
                }} 
                :
                {
                '& .MuiOutlinedInput-input': {
                    color: Colors.dark_text
                    },
                    '& .MuiInputLabel-root': {
                        color: Colors.dark_text_accent
                    }}}
            value={city}
            onChange={(event)=>{setCity(event.target.value)}}
            />    
    }

    function showEmail() {
            return <TextField
            autoComplete="off" 
            required
            error={(emailHelper === "" ? false : true)}        
            helperText={emailHelper} 
            className={((props.theme==='light') ? 'light_accent ' : 'dark_accent_darker ') + (props.screenWidth < 600 ? ' shipping_field_full_width ' : 'shipping_width_49perc')}
            id="customer_email"
            label={props.t('emailAddress')}
            sx={(props.theme==='light') ? {
                '& .MuiOutlinedInput-input': {
                    color: Colors.light_text
                  },
                '& .MuiInputLabel-root': {
                    color: Colors.light_text
                }} 
                :
                {
                '& .MuiOutlinedInput-input': {
                    color: Colors.dark_text
                    },
                    '& .MuiInputLabel-root': {
                        color: Colors.dark_text_accent
                    }}}
            value={email}
            onChange={(event)=>{setEmail(event.target.value)}}
            /> 
    }

    function handleOnPhoneChange(value) {
        setPhone(value);
    }

    function showPhoneNumber(){
            return <MuiPhoneNumber 
            autoComplete="off" 
            error={(phoneHelper === "" ? false : true)}        
            helperText={phoneHelper} 
            className={((props.theme==='light') ? 'light_accent shipping_float_right ' : 'dark_accent_darker shipping_float_right ') + (props.screenWidth < 600 ? ' shipping_field_full_width ' : 'shipping_width_50perc')} 
            defaultCountry={'be'} 
            value={phone} 
            sx={(props.theme==='light') ? {
                '& .MuiInput-input': {
                    color: Colors.light_text
                  }} 
                :
                  {
                    '& .MuiInput-input': {
                        color: Colors.dark_text_accent
                      }}}
            onChange={handleOnPhoneChange} />;
    }
    
    function validateData(){  
        let allowNext = true;   

        if(validationTest(firstname, /^([A-z])/) || validationTest(lastname, /^([A-z])/)){
            setNameHelper("");
            let fullname_length = (firstname?.length ?? 0) + (lastname?.length ?? 0);
            (fullname_length > maxLength) ? setNameHelper(props.t('nameSizeHelperText') + ` ${maxLength}`) : setNameHelper("");
        } else {
            setNameHelper(props.t('nameHelperText')); 
            allowNext = false;
        }

        if(validationTest(streetName, /^([A-z])/)){
            setStreetNameHelper("");
        } else {
            setStreetNameHelper(props.t('streetnameHelperText')); 
            allowNext = false;
        }

        if(validationTest(houseNumber, /^([0-9])/)){
            setHouseNumberHelper("");
        } else {
            setHouseNumberHelper(props.t('housenumberHelperText'));   
            allowNext = false;
        }

        if(validationTest(zip, /([A-Za-z0-9])/)){
            setZipHelper("");
        } else {
            setZipHelper(props.t('zipHelperText')); 
            allowNext = false;
        }

        if(validationTest(city, /^([A-z])/)){
            setCityHelper("");
        } else {
            setCityHelper(props.t('cityHelperText')); 
            allowNext = false;
        }

        if(validationTest(country["iso"], /^([A-z]{2})$/)){
            setCountryHelper("");
        } else{
            setCountryHelper(props.t('countryHelperText')); 
            allowNext = false;
        }


        if((validationTest(email, /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/))){
            setEmailHelper("");
        } else {
            setEmailHelper(props.t('emailHelperText'));  
            allowNext = false;
        }

        if(allowNext === true){
            let customer = {firstname: firstname, lastname: lastname, company: company, street_name: streetName, house_number: houseNumber, house_number_addition: houseNumberAddition, zip: zip, city: city, country: country["iso"], email: email, phone: phone, language: "NL"};
            if(SftGlobalData.debug){
            console.log("customer: ", customer);
            }
            props.setCustomer(customer);
            props.switchForm(true);
        }
    }

    function validationTest(data, validationString) 
    {
        if(data === undefined)
            return false;

        if (validationString.test(data))
        {
            return true;
        } else {
            return false;
        }
    }

  return (
    <div style={{padding: 5}}>
        {<div className="container_list__title margin_bottom_none">
                    <div className="width_140px align_left" style={{height: "1px"}}> </div>
                    {props.screenWidth < 600 && <h2 className={(props.theme==='dark') ? "dark " : "light margin_bottom_none "} style={{textAlign: 'center', width: '100%'}}>{props.t('titleShippingCustomer')}</h2>}
                    {props.screenWidth >= 600 && <h2 className={(props.theme==='dark') ? "dark container_list__title  detail" : "light container_list__title margin_bottom_none detail"} type="text" id="list_name" name="listName">{props.t('titleShippingCustomer')}</h2>}
                    {props.screenWidth < 600 && <div className="margin_bottom_10px"></div>}
                    <Button variant="contained" className={'button_container__button margin_bottom_none width_140px align_right'} onClick={()=>{validateData();}}>{props.t('next')}</Button>
            </div>
        }   
        {loading && <div className={"container_list__title " + props.theme} style={{display:"flex", justifyContent: "space-evenly", marginTop: 5, border: 1}}>
                <TailSpin 
                    height="40"
                    width="40"
                    color={Colors.sft_blue}
                    ariaLabel='loading'
                />
            </div>}
        {!loading && <div className='_100perc margin_bottom_15px'>  
                <br/>
                <br/>
                {showName()}
                {showCompany()}
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    {showStreetName()}  
                    {showHouseNumber()}  
                    {showHouseNumberAddition()}
                </div>
                {showZip()}  
                {showCity()}
                {props.countries.length > 0 && <Autocomplete
                id="country"
                className={(props.theme==='light') ? 'light_accent shipping_field_full_width' : 'dark_accent_darker shipping_field_full_width'} 
                sx={(props.theme==='light') ? {
                    '& .MuiInputLabel-root ': {
                        color: Colors.light_text
                      },
                    '& .MuiOutlinedInput-input ': {
                        color: Colors.light_text,
                        height: '28px',
                        padddingLeft: '0px'
                        }
                    } 
                    :
                    {
                    '& .MuiInputLabel-root ': {
                        color: Colors.dark_text
                        },
                    '& .MuiOutlinedInput-input ': {
                        color: Colors.dark_text,
                        height: '28px',
                        padddingLeft: '0px'
                        }  
                    }}
                options={props.countries}
                autoHighlight
                getOptionLabel={(option) => option.name}
                value={country}
                helperText={countryHelper} 
                isOptionEqualToValue = {(option, value) => value.iso === option.iso}               
                onChange={(event, newValue)=>{setCountry(newValue);}}
                renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.iso.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.iso.toLowerCase()}.png 2x`}
                        alt=""
                        border="1px"
                    />
                    {option.name} ({option.iso})
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                    {...params}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                    />
                )}
                />}
               
               <div style={{display: 'flex', flexDirection: (props.screenWidth < 600 ? 'column' : 'row'), justifyContent: 'space-between'}}>
                {showEmail()}
                {showPhoneNumber()}
               </div>
                
        </div>}
    </div>
  );
}

export default ShippingCustomer;
