import React, { useState, useEffect, useRef }from 'react';
import './logging.css';
import axios from 'axios';
import SftGlobalData from '../../library/SftGlobalData';
import Colors from '../../library/Colors';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { Autocomplete, FormControlLabel, Switch, TextField } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import licenceIcon from '../../assets/module-icons/ic_licence.png';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import Card from '../../components/Card';
import LoggingCustomerInfoBlock from './LoggingCustomerInfoBlock';
import LoggingCustomerStoreFilesBlock from './LoggingCustomerStoreFilesBlock';
import { styled } from '@mui/system';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import LoggingAddDeliveryAddressModal from './LoggingAddDeliveryAddressModal';
import LoggingAddFileModal from './LoggingAddFileModal';


const StyledModal = styled(ModalUnstyled)`
    position: fixed;
    text-align: center;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    `;

const Backdrop = styled('div')`
    z-index: -1;
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    `;

const style = {
    border: '0px solid #000',
    borderRadius: 6,
    p: 2,
    px: 4,
    pb: 3,
    };

const LoggingAddScreen = (props) =>  {
    const [refresh, setRefresh] = useState(false)
    const [customer, setCustomer] = useState({});
    const [customers, setCustomers] = useState([]);
    const [store, setStore] = useState({id: "NULL"});
    const [stores, setStores] = useState([]);
    const [tags, setTags] = useState ([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [duration, setDuration] = useState("< 10m");
    const [remark, setRemark] = useState("");
    const [solution, setSolution] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [extraFields, setExtraFields] = useState(false);
    const [caller, setCaller] = useState("");
    const [phone, setPhone] = useState("");
    const [teamviewerId, setTeamviewerId] = useState("");
    const [teamViewerPass, setTeamViewerPass] = useState("");
    const [lastLogs, setLastLogs] = useState([]);
    const inputEl = useRef();
    const inputCust = useRef();
    const inputStore = useRef();
    const inputFocus = useRef();

    // validation states
    const [remarkError, setRemarkError] = useState(false);
    const [tagsError, setTagsError] = useState(false);
    const [customerError, setCustomerError] = useState(false);

    // Modal variables
    const [openModal, setOpenModal] = useState(false);
    const [modalView, setModalView] = useState("data"); // data or add
    const [fileType, setFileType] = useState("");
    const [fileName, setFileName] = useState("");
    const [txtContents, setTxtContents] = useState("");
 
    useEffect (()=>{
        props.changeScreen('logging');

        try{
            let extraLogFields = sessionStorage.getItem('LogExtraFields');
            if(typeof extraLogFields !== "undefined"){
                setExtraFields(extraLogFields==="true");
            } else {
                setExtraFields(false);
            }
        }catch(ex){}
        try{
            let url = SftGlobalData.baseURL_API+'login';
            let postData = {action: "logging", login: props.loginObj?.login, password: props.loginObj?.password}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    setCustomers(response.data);
                    //setLoading(false);
                });

            let url2 = SftGlobalData.baseURL_API+'logging&action=fetchLogTags';
            let postData2 = {sft_id: 0, sft: props.loginObj?.sft_status}
            axios.post(url2, postData2, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url2);
                        console.log('postData', postData2);
                        console.log('resp', response);
                    }
                    setTags(response.data);
                });
        } catch(e){
            console.error(e);
        }
    }, [props.loginObj]);


    const handleChangeDuration = (event) => {
        const {
            target: { value },
        } = event;
        setDuration(value);
    };

    const onSelectCustomer = (cust) => {
        if(SftGlobalData.debug)
            console.log("selected", cust);
        props.setSelectedDb(cust);
        try{
            let url = SftGlobalData.baseURL_API+'logging&action=fetchDeliveryAddress';
            let postData = {sft_id: cust.cust_no, sft: props.loginObj?.sft_status}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    setStores(response.data);
                    //setLoading(false);
                });
        } catch(e){
            console.error(e);
        }

        try{
            let url = SftGlobalData.baseURL_API+'logging&action=fetchLogs';
            let postData = {sft_id: cust.cust_no, limit: 5, sft: props.loginObj?.sft_status}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    setLastLogs(response.data);
                });
        } catch(e){
            console.error(e);
        }
    };

    function onSaveLogging(task) {
        //console.log("TEST1");
        if(remark !== "" && typeof customer?.cust_no !== "undefined"){
            //console.log("TEST2");
            //console.log("unedited", inputEl.current.innerText);
            try{
                let temp = inputEl.current.innerText.replaceAll("\n", ",").replaceAll("Tags", "").replaceAll("*", "");
                temp = temp.substring(1,temp.length-1);
                temp = temp.split(',');
                //console.log("tags", temp);

                let chips = "[";
                temp.forEach(tag => {
                    let splitTag =  tag.split("-");
                    chips += '"' + splitTag[0].trim() + '",';
                });
                chips = chips.slice(0, -1);
                chips += "]";

                chips = chips.replaceAll("\"\",", "").replaceAll(",\"\"", "");
                //console.log("chips", chips);
                //if(chips === '[","]') chips = '[""]';
                if(chips !== '[""]'){
                    //console.log("TEST3");
                    setDisabled(true);
                    let url = SftGlobalData.baseURL_API+'logging&action=createLog';
                    let postData = {
                        sft_id: customer.cust_no, 
                        cust_del_id: store.id,
                        user_id: props.userId, 
                        remark: remark.replaceAll("\\", "\\\\'").replaceAll("'", "\\'"),
                        solution: solution.replaceAll("\\", "\\\\'").replaceAll("'", "\\'"),
                        tags: chips,
                        duration: duration,
                        caller: caller.replaceAll("\\", "\\\\'").replaceAll("'", "\\'"),
                        phone: phone.replaceAll("\\", "\\\\'").replaceAll("'", "\\'"),
                        teamviewer_id: teamviewerId.replaceAll("\\", "\\\\'").replaceAll("'", "\\'"),
                        teamviewer_pass: teamViewerPass.replaceAll("\\", "\\\\'").replaceAll("'", "\\'"),
                        is_task: task, 
                        sft: props.loginObj?.sft_status

                    }
                    axios.post(url, postData, {
                        headers : {
                            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                        }})
                        .then(response => {
                            if(SftGlobalData.debug){
                                console.log('url', url);
                                console.log('postData', postData);
                                console.log('resp', response);
                            }
                            setRemark("");
                            setSolution("");
                            setDuration("< 10m");
                            setCaller("");
                            setPhone("");
                            setTeamViewerPass("");
                            setTeamviewerId("");
                            setDisabled(false);
                            const ele = inputCust.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
                            if (ele) ele.click();
                            const ele2 = inputStore.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
                            if (ele2) ele2.click();
                            const ele3 = inputEl.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
                            if (ele3) ele3.click();
                            setSelectedTags([]);
                            setCustomer({});
                            setStore({id: "NULL"});
                            props.notify(props.t('log_added'), 2000, "top-center", props.theme, 14447);  
                            try{
                                inputFocus.current.select();
                            }catch(e){console.error(e);}
                    });
                } else {
                    props.notify(props.t('tags_required'), 2000, "top-center", props.theme, 486); 
                }
            } catch(e){
                console.error(e);
            }
        } else {
            if(remark === "") props.notify(props.t('remark_required'), 2000, "top-center", props.theme, 456); 
            if(typeof customer?.cust_no === "undefined") props.notify(props.t('customer_required'), 2000, "top-center", props.theme, 4532); 
        }
    }

    const checkTagsError = () => {
        let temp = inputEl.current.innerText.replaceAll("\n", ",").replaceAll("Tags", "").replaceAll("*", "");
        temp = temp.substring(1,temp.length-1);
        temp = temp.split(',');
        //console.log("tags", temp);

        let chips = "[";
        temp.forEach(tag => {
            let splitTag =  tag.split("-");
            chips += '"' + splitTag[0].trim() + '",';
        });
        chips = chips.slice(0, -1);
        chips += "]";

        chips = chips.replaceAll("\"\",", "").replaceAll(",\"\"", "");
        //console.log("chips", chips);
        //if(chips === '[","]') chips = '[""]';
        if(chips !== '[""]'){
            setTagsError(false);
        } else {
            setTagsError(true);
        }
    }

    const openDataModal = (file_type, file_name) => {
        setFileType(file_type);
        setFileName(file_name);
        setModalView("data");
        if(file_type === "TXT"){
            axios.get(SftGlobalData.baseURL_files + customer.cust_no + "/"  + file_name)
            .then(res => {
                console.log("res", res);
                setTxtContents(res.data);
            })
        }
        setOpenModal(true);
    }

    const openAddFileModal = (is_store) => {
        setModalView("addFile" + (is_store ? "Store" : ""));
        setOpenModal(true);
    }

    const saveTxt = () => {
        try{
            let url2 = SftGlobalData.baseURL_API+'logging&action=overwriteFile';
            let postData2 = {
                sft_id: customer?.cust_no,
                filename: fileName,
                txt: txtContents,
                is_txt: true, 
                sft: props.loginObj?.sft_status
            }
            axios.post(url2, postData2, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url2);
                        console.log('postData', postData2);
                        console.log('resp', response);
                    }
                    props.notify(props.t('savedChanges'), 2000, "top-right", props.theme, 1);
                    setOpenModal(false);
                });
        } catch(e){
            console.error(e);
        }
    }

    const openAddAddressModal = () => {
        setModalView("addStore");
        setOpenModal(true);
        
    }

    const customerInfoBlock = () => {
        return (
            <div className={props.theme + " log__container_add_block box_fade-in log__margin_right_small"}>
                <h3 style={{textAlign: 'center'}}>{props.t('customer_info')}</h3>
                <LoggingCustomerInfoBlock login={props.loginObj} closeModal={()=>{setOpenModal(false);}} onRefresh={()=>{setRefresh(!refresh);}} refresh={refresh} userId={props.userId} username={props.username} customer={customer} theme={props.theme} t={props.t} notify={props.notify} openDataModal={(ft, fn)=>{openDataModal(ft, fn)}} openFileModal={()=>{openAddFileModal(false);}}/>
            </div>
        )
    }

    const customerStoresBlock = () => {
        return (
            <div className={props.theme + " box_fade-in log__container_add_block log__margin_right_small"}>
                <h3 style={{textAlign: 'center'}}>{props.t('customer_store_info')}</h3>
                <LoggingCustomerStoreFilesBlock login={props.loginObj} onSaveChanges={()=>{onSelectCustomer(customer); props.notify(props.t('savedChanges'), 2000, "top-right", props.theme, 1);}} closeModal={()=>{setOpenModal(false);}} onRefresh={()=>{setRefresh(!refresh);}} refresh={refresh} userId={props.userId} username={props.username} store={store} customer={customer} theme={props.theme} t={props.t} notify={props.notify} openDataModal={(ft, fn)=>{openDataModal(ft, fn)}} openFileModal={()=>{openAddFileModal(true);}}/>
            </div>
        )
    }

    const lastLogging = lastLogs.map((row) => (
        <Card style={{fontSize: 15, width: '100%', marginBottom: 5, marginTop: 5, paddingLeft: 5, paddingRight: 5, paddingTop: 5, paddingBottom: 5, flex: 1, display: 'flex', flexDirection: 'row', boxShadow: "0 2px 3px rgba(0, 0, 0, 0.2)"}} func={()=>{}}>
            <div style={{minWidth: 225, maxWidth: 225}}><p style={{paddingBottom: 0, paddingTop: 0, marginBottom: 0, marginTop: 0}}><b>{row.log_date} - {row.log_owner} </b></p></div>
            <div style={{minWidth: 140, maxWidth: 140, fontSize: 13}}><p style={{paddingBottom: 0, paddingTop: 0, marginBottom: 0, marginTop: 0, color: Colors.def_red}}>{row.log_tags}</p></div>
            <div style={{width: '100%'}}><p style={{paddingBottom: 0, paddingTop: 0, marginBottom: 0, marginTop: 0}}>{row.log_remark}</p></div>
        </Card>
    ))

    const getFileContent = () => {
        switch(fileType){
            case "TXT":
                return (
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: 425, textAlign: 'left'}}>
                        <TextField
                            multiline
                            minRows={15}
                            maxRows={15}
                            autoComplete="off" 
                            className={props.theme+ '_accent log__100_perc'}
                            id="txtContents"
                            label={props.t('note')}
                            size={'small'}
                            sx={(props.theme==='light') ? {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.light_text
                                  },
                                '& .MuiInputLabel-root': {
                                    color: Colors.light_text
                                 }, borderRadius: 3, marginTop: 0} 
                                :
                                {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.dark_text
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.dark_text_accent
                                    }, borderRadius: 3, marginTop: 0}}
                            value={txtContents}
                            onChange={(event)=>{setTxtContents(event.target.value)}}
                            />
                        <Button 
                            variant="outlined" 
                            sx={{ borderRadius: 3, border: '2px solid '+Colors.sft_blue, color: Colors.sft_blue, width: '100%'}}
                            className=" " 
                            onClick={()=>{saveTxt()}}>{props.t('save')}</Button>
                    </div>
                );
            case "IMG":
                return <img alt="Customer file" src={SftGlobalData.baseURL_files + customer.cust_no + "/"  + fileName}  style={{height: "100%", width: "100%"}} />
            case "PDF":
                return <embed src={SftGlobalData.baseURL_files + customer.cust_no + "/"  + fileName} height="750" style={{width: '100%'}} type="application/pdf"></embed>
            case "VID":
                return (<video width="750" height="750" controls>
                            <source src={SftGlobalData.baseURL_files + customer.cust_no + "/"  + fileName} type={"video/"+fileName.split('.').pop()}/>
                            <p>{props.t('video_unsupported')}</p>
                        </video>);
            case "HTML":
                return <iframe title="HTML file" src={SftGlobalData.baseURL_files + customer.cust_no + "/"  + fileName} height="750" style={{width: '100%'}} />
            default:
                return <a href={SftGlobalData.baseURL_files + customer.cust_no + "/"+fileName} target="_blank" rel="noopener noreferrer" download={fileName}>{props.t('download')}</a>
        }
    }

    return (
        <div className='log__margin_sidebar' style={{width: '100%', textAlign: 'start', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', marginTop: 5}}>
            <StyledModal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={openModal}
                onClose={()=>{setOpenModal(false);}}
                BackdropComponent={Backdrop}
                >
                <Box sx={style} style={{minWidth: 500, minHeight: 500}} className={props.theme + " _50perc"}>
                    <p className={"primary_border_bottom text_center margin_top_none margin_bottom_15px bold " + props.theme} style={{fontWeight: 1200}}>{modalView === "data" ? fileName : modalView}</p>
                    {modalView === "data" && <div>
                        {getFileContent()}
                    </div>}
                    {modalView === "addStore" && <div>
                        <LoggingAddDeliveryAddressModal login={props.loginObj} refresh={()=>{setRefresh(!refresh); onSelectCustomer(customer); setOpenModal(false); props.notify(props.t('savedChanges'), 2000, "top-right", props.theme, 1); }} userId={props.userId} username={props.username} customer={customer.cust_no} theme={props.theme} t={props.t} notify={props.notify} />
                    </div>}
                    {modalView === "addFile" && <div>
                        <LoggingAddFileModal login={props.loginObj} is_store={false} refresh={()=>{setRefresh(!refresh); onSelectCustomer(customer); setOpenModal(false); props.notify(props.t('savedChanges'), 2000, "top-right", props.theme, 1); }} userId={props.userId} username={props.username} customer={customer.cust_no} store={store.id} theme={props.theme} t={props.t} notify={props.notify} />
                    </div>}
                    {modalView === "addFileStore" && <div>
                        <LoggingAddFileModal login={props.loginObj} is_store={true} refresh={()=>{setRefresh(!refresh); setStore(store); setOpenModal(false); props.notify(props.t('savedChanges'), 2000, "top-right", props.theme, 1); }} userId={props.userId} username={props.username} customer={customer.cust_no} store={store.id} theme={props.theme} t={props.t} notify={props.notify} />
                    </div>}
                </Box>
            </StyledModal>
            <div className={(typeof customer?.cust_no !== "undefined") ? " log__flex_add log__min_height_490 " : " log__100_perc log__min_height_490 log__stretch_items"}>
                <div className={props.theme + " log__container_add_block box_fade-in log__margin_left_small"}>
                    <h3 style={{textAlign: 'center'}}>{props.t('log_add')}</h3>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '90%'}}>
                        <div>
                            <div className={"log__container_options log__100_perc padding_left__5px padding_right__5px"}>
                                {typeof customer.encrypted_key !== "undefined" && (<Link className='margin_right__10px' tabIndex={-1} style={{ borderRadius: 3, border: '2px solid '+Colors.sft_blue, height: 40, padding: 4}} to={"/"+props.lang+"/auth/"+customer.encrypted_key+"/cookie"} target="_blank" rel="noopener noreferrer">
                                    <img src={licenceIcon} width="30px" alt={"Licences"}/>
                                </Link>)}
                                {typeof customer.encrypted_key === "undefined" && <div className='margin_right__10px' style={{ borderRadius: 3, border: '2px solid '+Colors.sft_blue, height: 40, padding: 4}} ><img src={licenceIcon} width="30px" alt={"Licences"}/></div>}

                                <Autocomplete
                                  ref={inputCust}
                                  required
                                  id="customer-select"
                                  className={props.theme+ '_accent log__100_perc margin_right__10px'}
                                  label={props.t('customer')}
                                  size={'small'}
                                  sx={(props.theme==='light') ? {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.light_text
                                      },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.light_text
                                     }, borderRadius: 3, marginTop: 0} 
                                    :
                                    {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.dark_text
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.dark_text_accent
                                        }, borderRadius: 3, marginTop: 0}}

                                  options={customers}
                                  isOptionEqualToValue={(option, val)=>{return ((option.search_string === "" ? option.alt_name : option.search_string) + " (" + option.description + ")") === ((val.search_string === "" ? val.alt_name : val.search_string) + " (" + val.description + ")");}}
                                  onChange={(event, value, reason)=>{if(reason === "selectOption"){setCustomer(value); onSelectCustomer(value); const ele2 = inputStore.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0]; if (ele2) ele2.click(); setStore({id: "NULL"});}else{setCustomer({}); const ele1 = inputStore.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0]; if (ele1) ele1.click(); setStores([]); setStore({id: "NULL"}); setLastLogs([]); const ele2 = inputStore.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0]; if (ele2) ele2.click();}}}
                                  autoHighlight
                                  getOptionLabel={(option) => {return (option.search_string === "" ? option.alt_name : option.search_string) + " (" + option.description + ")";}}
                                  renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                      <img
                                        loading="lazy"
                                        width="32"
                                        src={option.logo}
                                        alt=""
                                      />
                                      {(option.search_string === "" ? option.alt_name : option.search_string)} ({option.description})
                                    </Box>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                    error={customerError}
                                    inputRef={inputFocus}
                                    autoFocus
                                    required
                                      {...params}
                                      //key={uniqueCustomerState}
                                      onBlur={()=>{if(typeof customer?.cust_no === "undefined"){setCustomerError(true);} else { setCustomerError(false); }}}
                                      label={props.t('customer')}
                                      inputProps={{
                                        ...params.inputProps,
                                         // disable autocomplete and autofill
                                      }}
                                    />
                                  )}
                                />

                                <Autocomplete
                                      ref={inputStore}
                                      id="store-select"
                                      className={props.theme+ '_accent log__100_perc margin_right__10px'}
                                      label={props.t('store')}
                                      size={'small'}
                                      sx={(props.theme==='light') ? {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.light_text
                                          },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.light_text
                                         }, borderRadius: 3, marginTop: 0} 
                                        :
                                        {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.dark_text
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: Colors.dark_text_accent
                                            }, borderRadius: 3, marginTop: 0}}

                                      options={stores}
                                      isOptionEqualToValue={(option, val)=>{return option.name === val.name;}}
                                      onChange={(event, value, reason)=>{if(reason === "selectOption"){setStore(value);}else{setStore({})}}}
                                      autoHighlight
                                      getOptionLabel={(option) => {return option.name;}}
                                      renderOption={(props, option) => (
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                          {option.id} - {option.name}
                                        </Box>
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label={props.t('store')}
                                          inputProps={{
                                            ...params.inputProps,
                                             // disable autocomplete and autofill
                                          }}
                                        />
                                      )}
                                    />

                                <Button 
                                    variant="outlined" 
                                    tabIndex={-1}
                                    sx={{ borderRadius: 3, border: '2px solid '+Colors.sft_blue, color: Colors.sft_blue, height: 40, maxWidth: 40, paddingLeft: 0, paddingRight: 0}}
                                    className="log__small_button_width" 
                                    onClick={()=>{openAddAddressModal()}}>
                                    <AddIcon style={{Color: Colors.sft_blue}}/>
                                </Button>
                                
                                
                                
                            </div>

                            <div className={"log__container_options  log__100_perc padding_left__5px padding_right__5px"}>
                                <TextField
                                    required
                                    multiline
                                    minRows={3}
                                    maxRows={3}
                                    inputProps={{ maxLength: 255 }}
                                    error={remarkError}
                                    autoComplete="off" 
                                    className={props.theme+ '_accent log__100_perc margin_right__10px'}
                                    id="remark"
                                    label={props.t('remark')}
                                    size={'small'}
                                    sx={(props.theme==='light') ? {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.light_text
                                          },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.light_text
                                         }, borderRadius: 3, marginTop: 0} 
                                        :
                                        {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.dark_text
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: Colors.dark_text_accent
                                            }, borderRadius: 3, marginTop: 0}}
                                    value={remark}
                                    onChange={(event)=>{setRemark(event.target.value)}}
                                    onBlur={()=>{if(remark === "") setRemarkError(true); else setRemarkError(false);}}
                                    />
                                <div className={props.theme+ ' log__100_perc '} style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
                                    <Autocomplete
                                        multiple
                                        ref={inputEl}
                                        id="tags-outlined"
                                        className={props.theme+ '_accent log__100_perc'}
                                        size={'small'}
                                        sx={(props.theme==='light') ? {
                                            '& .MuiOutlinedInput-input': {
                                                color: Colors.light_text
                                              },
                                            '& .MuiInputLabel-root': {
                                                color: Colors.light_text
                                             }, borderRadius: 3, marginTop: 0, height: '100%'} 
                                            :
                                            {
                                            '& .MuiOutlinedInput-input': {
                                                color: Colors.dark_text
                                                },
                                                '& .MuiInputLabel-root': {
                                                    color: Colors.dark_text_accent
                                                }, borderRadius: 3, marginTop: 0, height: '100%'}}
                                        options={tags}
                                        isOptionEqualToValue={(option, val)=>{return ((option.tag + " - " + option.parent) === (val.tag + " - " + val.parent))}}
                                        getOptionLabel={(option) => (option.tag + " - " + option.parent)}
                                        defaultValue={selectedTags}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            error={tagsError}
                                            onBlur={()=>{checkTagsError();}}
                                            variant="outlined"
                                            label={props.t('tags')}
                                            required
                                            //placeholder="Tags"
                                          />
                                        )}
                                      />
                                    
                                    <FormControl className={props.theme+ '_accent log__100_perc'}>
                                      <InputLabel id="demo-simple-select-label">{props.t('duration')}</InputLabel>
                                      <Select
                                        className={props.theme+"_accent "+props.theme+"_text log__100_perc"}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={duration}
                                        label={props.t('duration')}
                                        onChange={handleChangeDuration}
                                        style={{height: 40, paddingTop: 8, paddingBottom: 8}}>
                                        <MenuItem value={"< 10m"}>&lt; 10m</MenuItem>
                                        <MenuItem value={"< 20m"}>&lt; 20m</MenuItem>
                                        <MenuItem value={"< 30m"}>&lt; 30m</MenuItem>
                                        <MenuItem value={"< 60m"}>&lt; 60m</MenuItem>
                                        <MenuItem value={"> 60m"}>&gt; 60m</MenuItem>
                                      </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className={"log__container_options log__100_perc padding_left__5px padding_right__5px"}>
                                <TextField
                                    multiline
                                    minRows={2}
                                    maxRows={2}
                                    autoComplete="off" 
                                    className={props.theme+ '_accent log__100_perc '}
                                    id="solution"
                                    label={props.t('solution')}
                                    size={'small'}
                                    sx={(props.theme==='light') ? {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.light_text
                                          },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.light_text
                                         }, borderRadius: 3, marginTop: 0} 
                                        :
                                        {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.dark_text
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: Colors.dark_text_accent
                                            }, borderRadius: 3, marginTop: 0}}
                                    value={solution}
                                    onChange={(event)=>{setSolution(event.target.value)}}
                                    />  
                                    
                            </div>

                            
                            {extraFields && <div className={"log__container_options log__100_perc padding_left__5px padding_right__5px"}>
                                <TextField
                                    autoComplete="off" 
                                    className={props.theme+ '_accent margin_right__10px log__100_perc'}
                                    id="phone"
                                    label={props.t('phone')}
                                    size={'small'}
                                    sx={(props.theme==='light') ? {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.light_text
                                          },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.light_text
                                         }, borderRadius: 3, marginTop: 0} 
                                        :
                                        {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.dark_text
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: Colors.dark_text_accent
                                            }, borderRadius: 3, marginTop: 0}}
                                    value={phone}
                                    onChange={(event)=>{setPhone(event.target.value)}}
                                    />  
                                <TextField
                                    autoComplete="off" 
                                    className={props.theme+ '_accent log__100_perc'}
                                    id="caller"
                                    label={props.t('caller')}
                                    size={'small'}
                                    sx={(props.theme==='light') ? {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.light_text
                                          },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.light_text
                                         }, borderRadius: 3, marginTop: 0} 
                                        :
                                        {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.dark_text
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: Colors.dark_text_accent
                                            }, borderRadius: 3, marginTop: 0}}
                                    value={caller}
                                    onChange={(event)=>{setCaller(event.target.value)}}
                                    />  
                            </div>}

                            {extraFields && <div className={"log__container_options log__100_perc padding_left__5px padding_right__5px"}>
                                <TextField
                                    autoComplete="off" 
                                    className={props.theme+ '_accent margin_right__10px log__100_perc'}
                                    id="teamviewerId"
                                    label={props.t('teamviewer_id')}
                                    size={'small'}
                                    sx={(props.theme==='light') ? {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.light_text
                                          },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.light_text
                                         }, borderRadius: 3, marginTop: 0} 
                                        :
                                        {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.dark_text
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: Colors.dark_text_accent
                                            }, borderRadius: 3, marginTop: 0}}
                                    value={teamviewerId}
                                    onChange={(event)=>{setTeamviewerId(event.target.value)}}
                                    />  
                                <TextField
                                    autoComplete="off" 
                                    className={props.theme+ '_accent log__100_perc'}
                                    id="teamviewerPass"
                                    label={props.t('teamviewer_pass')}
                                    size={'small'}
                                    sx={(props.theme==='light') ? {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.light_text
                                          },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.light_text
                                         }, borderRadius: 3, marginTop: 0} 
                                        :
                                        {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.dark_text
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: Colors.dark_text_accent
                                            }, borderRadius: 3, marginTop: 0}}
                                    value={teamViewerPass}
                                    onChange={(event)=>{setTeamViewerPass(event.target.value)}}
                                    />  
                            </div>}
                        </div>

                        <div className={"log__container_buttons log__100_perc padding_left__5px padding_right__5px"} >
                            <FormControlLabel
                                          sx={{textAlign: 'right', width: '60%'}}
                                          style={{marginBottom: 5}}
                                          control={
                                            <Switch
                                              checked={extraFields}
                                              onChange={()=>{sessionStorage.removeItem('LogExtraFields'); sessionStorage.setItem('LogExtraFields', !extraFields ? "true" : "false"); setExtraFields(!extraFields);}}
                                              inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                          }
                                          labelPlacement="end"
                                          label={props.t('extra_fields')}
                                        />
                            <div className={"log__container_buttons log__100_perc padding_left__5px padding_right__5px"} >
                                
                                <Button 
                                    disabled={disabled} 
                                    variant="outlined" 
                                    sx={{ borderRadius: 3, border: '2px solid '+Colors.def_green, color: Colors.def_green}}
                                    className="  log__button_width margin_right__5px" 
                                    onClick={()=>{onSaveLogging(true)}}>{props.t("task_add")}</Button>
                                <Button 
                                    disabled={disabled} 
                                    variant="outlined" 
                                    sx={{ borderRadius: 3, border: '2px solid '+Colors.sft_blue, color: Colors.sft_blue}}
                                    className="  log__button_width " 
                                    onClick={()=>{onSaveLogging(false)}}>{props.t("log_add")}</Button>
                            </div>
                        </div>

                    </div>
                </div>
                {(typeof customer?.cust_no !== "undefined") && customerInfoBlock()}
            </div>
            <div className={(store?.id !== "NULL" && typeof store?.id !== "undefined") ? " log__flex_add log__min_height_434" : " log__100_perc log__stretch_items log__min_height_434"}>

                {(typeof customer?.cust_no !== "undefined") && <div className={props.theme + " box_fade-in log__container_add_block log__margin_left_small"}>
                    <h3 style={{textAlign: 'center'}}>{props.t('customer_last_logs')}</h3>
                    {lastLogs.length > 0 && lastLogging}
                </div>}

                {(store?.id !== "NULL" && typeof store?.id !== "undefined") && customerStoresBlock()}
            </div>
            

        </div>
    );
}

export default LoggingAddScreen;
