import React, { useEffect, useState } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend, Bar, BarChart } from 'recharts';
import { DataGridPro } from '@mui/x-data-grid-pro';
import axios from 'axios';
import SftGlobalData from '../library/SftGlobalData';
import { TailSpin } from 'react-loader-spinner';
import Colors from '../library/Colors';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import './logging/logging.css';
import FloatingSyncButton from '../components/FloatingSyncButton';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AdminStatScreen = (props) =>  {
    const [topCaller, setTopCaller] = useState([]);
    const [topLogging, setTopLogging] = useState([]);
    const [topLogger, setTopLogger] = useState([]);
    const [topProblems, setTopProblems] = useState([]);
    const [mobileUsage, setMobileUsage] = useState(0);
    const [mobileUsageDetail, setMobileUsageDetail] = useState([]);
    const [revenueUsage, setRevenueUsage] = useState(0);
    const [revenueUsageDetail, setRevenueUsageDetail] = useState([]);
    const [apiSales, setApiSales] = useState([]);
    const [apiSalesTotal, setApiSalesTotal] = useState([]);
    const [myFasmanMobileUsageTotal, setmyFasmanMobileUsageTotal] = useState([]);
    const [revenueUsageGraph, setRevenueUsageGraph] = useState([]);
    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState(0);

  const mobileUsageDetailColumns = [
    {
        field: 'name',
        headerName: props.t("description"),
        minWidth: 300,
    },
    {
      field: 'type',
      headerName: props.t("type"),
      minWidth: 200,
    },
    {
      field: 'amount',
      headerName: props.t("amount"),
      minWidth: 100,
      headerAlign: 'center',
      align: 'center'
    }
  ];

  const revenueUsageDetailColumns = [
    {
        field: 'description',
        headerName: props.t("description"),
        minWidth: 300,
    },
    {
      field: 'last_login',
      headerName: "Last login",
      minWidth: 200,
    },
    {
      field: 'amount',
      headerName: props.t("amount"),
      minWidth: 100,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'logins',
      headerName: "Logins",
      minWidth: 400,
    },
  ];

  const apiSalesColumns = [
    {
        field: 'accountId',
        headerName: "Account ID",
        minWidth: 75,
        headerAlign: 'center',
        align: 'center'
    },
    {
      field: 'name',
      headerName: props.t("description"),
      minWidth: 300,
    },
    {
      field: 'quantity',
      headerName: props.t("amount"),
      minWidth: 100,
      headerAlign: 'center',
      align: 'center'
    }
  ];

  const CustomToolTip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{backgroundColor: 'white', padding: 10}}>
          <p className="desc">{`${payload[0].payload.name} : ${payload[0].value}`}</p>
        </div>
      );
    }
  
    return null;
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

    useEffect(() => {
        props.changeScreen('logging');
        loadData();
    },[props]);

    const loadData = () => {
        let url = SftGlobalData.baseURL_API + 'adminStat';
        axios.post(url, {key: "BA7C68DFEBEADE4CDC301BDE6AD95BF2BDC3B0B561A60C702857DBD0CB68B6C4AF152976E970A57E7A52A39669676A1E797C8EC81495DBEA9444F57F9F2F4AC554D6A70E7DEB35FE25E3270B848015B1B5C070FB7AE2D598C43AB681E9AE69B9AA78ED0DFFBF6DC63876C2320463BB446037D0A30BBAAD2522F91B02BAA6F5AA4AEB1765E0921B0D248E076E5DB8EEE1F1D780D2FC046D0D867F357C3115F3D5BC6638CF906CCE75CA340F9E87BB13833C536AFD35D8B77BB7234C7E9E73899246D24DA70AA0DA141CFBF6861821664BD6AE8059AA68C6636D20900CA2E59A297270CE8912953E4FCAF0D3F1B7BF6404"}, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
        .then(response => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('response', response);
            }
            setApiSalesTotal(response.data.apiSalesDetail);
            setApiSales(response.data.apiSales);
            setMobileUsage(response.data.mobileUsage);
            setRevenueUsage(response.data.revenueUsage);
            setMobileUsageDetail(response.data.mobileUsageDetail);
            setRevenueUsageDetail(response.data.revenueUsageDetail);
            setmyFasmanMobileUsageTotal(response.data.myFasmanMobileUsageData);
            setRevenueUsageGraph(response.data.revenueUsageGraph);
            loadLoggingData();
        });
    }

    const loadLoggingData = () => {
        let url = SftGlobalData.baseURL_API + 'logging&action=fetchLogAdvancedStats';
        let postData = {sft: props.loginObj.sft_status};
        axios.post(url, postData, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
        .then(response => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('postData', postData);
                console.log('response', response);
            }
            setTopCaller(response.data.topCaller);
            setTopLogging(response.data.topLogging);
            setTopLogger(response.data.topLogger);
            setTopProblems(response.data.topProblem);
            setLoading(false);
        });
        if(SftGlobalData.debug)
            console.log("topLogger", topLogger);
    }

    const getRevenueAppChart = () => {
        return (
          <ResponsiveContainer width="100%" height={300}>
            <LineChart width={500} height={300} margin={{top: 5,right: 30,left: 20,bottom: 5,}} data={revenueUsageGraph}>
              <Line type="monotone" dataKey="quantity" stroke="#2196f3" activeDot={{ r: 8 }}/>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
        )
      }

    const getApiSalesChart = () => {
      return (
        <ResponsiveContainer width="100%" height={300}>
          <LineChart width={500} height={300} margin={{top: 5,right: 30,left: 20,bottom: 5,}} data={apiSalesTotal}>
            <Line type="monotone" dataKey="quantity" stroke="#2196f3" activeDot={{ r: 8 }}/>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="call_date" />
            <YAxis />
            <Tooltip />
          </LineChart>
        </ResponsiveContainer>
      )
    }

    const getApiSalesDetailChart = () => {
      return (
        <ResponsiveContainer width="100%" height={300}>
          <LineChart width={500} height={300} margin={{top: 5,right: 30,left: 20,bottom: 5,}} data={apiSales}>
            <Line type="monotone" dataKey="quantity" stroke="#2196f3" activeDot={{ r: 8 }}/>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
          </LineChart>
        </ResponsiveContainer>
      )
    }

    const getMyFasmanUsageDetailChart = () => {
        return (
          <ResponsiveContainer width="100%" height={300}>
            <LineChart width={500} height={300} margin={{top: 5,right: 30,left: 20,bottom: 5,}} data={myFasmanMobileUsageTotal}>
              <Legend verticalAlign="top" height={36}/>
              <Line type="monotone" dataKey="total" name="Totaal zonder trial" stroke="#2196f3" activeDot={{ r: 8 }}/>
              <Line type="monotone" dataKey="smartscanner" name="Smartscanner" stroke="#800000" activeDot={{ r: 8 }}/>
              <Line type="monotone" dataKey="smartphone" name="Smartphone" stroke="#3cb44b" activeDot={{ r: 8 }}/>
              <Line type="monotone" dataKey="trial" name="Trial" stroke="#9A6324" activeDot={{ r: 8 }}/>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
        )
    }

  return (
    <div className={'_100perc log__main_container log__main_stats log__padding_sidebar'}>
        <FloatingSyncButton disabled={loading} onClick={()=>{setLoading(true);loadData();}} bottom={true}/>
        <div className={'log__container_content ' + props.theme + ' container_content_' + props.theme + ' box_fade-in'} >
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}>
                
                {
                    // --------------------------------------------------------------------
                    // TITLE / HEADER
                    // --------------------------------------------------------------------
                }
                <div className={props.theme + " _100perc "}>
                    <div className="container_list__title align_left ">
                        <h2 className={props.theme} type="text" id="logging" name="logging">{props.t('admin')} - {props.t('statistics')}</h2>
                    </div>
                </div>
            </div>

        <Box className={props.theme + ' licence__enivronment'}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className={props.theme + ' container_tabs_below_subtitle'}>
              <Tabs value={value} onChange={handleChange} className={props.theme} variant="scrollable" scrollButtons="auto">
                <Tab label={"Logging "} {...a11yProps(0)} className={props.theme}/>
                {(props.group === "ADMIN" || props.group === "SUPER") && <Tab label={"Webshop "} {...a11yProps(1)} className={props.theme}/>}
                {(props.group === "ADMIN" || props.group === "SUPER") && <Tab label={"MyFasman Mobile "} {...a11yProps(2)} className={props.theme}/>}
                {(props.group === "ADMIN" || props.group === "SUPER") && <Tab label={"Revenue "} {...a11yProps(3)} className={props.theme}/>}
              </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
                {loading && <div style={{display:"flex", justifyContent: "space-evenly", marginTop: 5}}>
                    <TailSpin 
                        height="40"
                        width="40"
                        color={Colors.sft_blue}
                        ariaLabel='loading'
                    />
                </div>}
                {!loading && (<div className='_90perc'><div><h3 className={props.theme} type="text" id="logging" name="logging">&nbsp;&nbsp;{props.t('logging')} - {props.t('top_caller')}</h3>
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart

                                data={topCaller}
                                margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                                }}
                                >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="month" />
                                <YAxis />
                                <Tooltip content={<CustomToolTip />}/>
                                <Bar dataKey="amount" fill={Colors.sft_blue} />
                            </BarChart>
                        </ResponsiveContainer>
                        </div>
                        
                        <div><h3 className={props.theme} type="text" id="logging" name="logging">&nbsp;&nbsp;{props.t('logging')} - {props.t('total_calls')}</h3>                        
                            <ResponsiveContainer width="100%" height={300}>
                                <BarChart
                                width={1400}
                                height={300}
                                data={topLogging}
                                margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                                }}
                                >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="month" />
                                <YAxis />
                                <Tooltip />
                                <Bar dataKey="amount" fill={Colors.sft_blue} />
                            </BarChart>
                            </ResponsiveContainer>
                        </div>
                        
                        <div><h3 className={props.theme} type="text" id="logging" name="logging">&nbsp;&nbsp;{props.t('logging')} - {props.t('top_problem')}</h3>                        
                            <ResponsiveContainer width="100%" height={300}>
                            <BarChart
                                width={1400}
                                height={300}
                                data={topProblems}
                                margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                                }}
                                >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="month" />
                                <YAxis />
                                <Tooltip content={<CustomToolTip />} />
                                <Bar dataKey="amount" fill={Colors.sft_blue} />
                            </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>)}
          </TabPanel>
          
          {(props.group === "ADMIN" || props.group === "SUPER") && <TabPanel value={value} index={1}>
            <h3>&nbsp;&nbsp;Webshop Verkopen - totaal (laatste 14 dagen)</h3>
            {!loading && getApiSalesChart()}
            {loading && <div style={{display:"flex", justifyContent: "space-evenly", marginTop: 5}}>
                <TailSpin 
                    height="40"
                    width="40"
                    color={Colors.sft_blue}
                    ariaLabel='loading'
                />
            </div>}

            <h3>&nbsp;&nbsp;Webshop Verkopen - detail (gister)</h3>
            {!loading && getApiSalesDetailChart()}
            {loading && <div style={{display:"flex", justifyContent: "space-evenly", marginTop: 5}}>
                <TailSpin 
                    height="40"
                    width="40"
                    color={Colors.sft_blue}
                    ariaLabel='loading'
                />
            </div>}
            <div style={{height: 300, width: '100%', marginTop: 10 }}>
                  <DataGridPro
                      className={props.theme}
                      columns={apiSalesColumns}
                      rows={apiSales.map((row, index) => {return {
                              id: row.id,
                              accountId: row.account_id,
                              name: row.name,
                              quantity: parseInt(row.quantity)
                          }
                      })}
                      rowThreshold={0}
                      rowHeight={38}
                      height={300}
                      loading={loading}
                      pagination={false}

                      /> 
                </div>
          </TabPanel>}
          {(props.group === "ADMIN" || props.group === "SUPER") && <TabPanel value={value} index={2}>
            <div>
                <h3>&nbsp;&nbsp;MyFasMan Mobile gebuikers - totaal (laatste 14 dagen)</h3>
                {!loading && getMyFasmanUsageDetailChart()}
                {loading && <div style={{display:"flex", justifyContent: "space-evenly", marginTop: 5}}>
                    <TailSpin 
                        height="40"
                        width="40"
                        color={Colors.sft_blue}
                        ariaLabel='loading'
                    />
                </div>}
                
              <h3>&nbsp;&nbsp;<b>MyFasMan Mobile gebruikers (vandaag):</b> &nbsp;&nbsp;&nbsp;&nbsp;{mobileUsage}</h3>
              <div style={{height: 300, width: '100%' }}>
                <DataGridPro
                    className={props.theme}
                    columns={mobileUsageDetailColumns}
                    rows={mobileUsageDetail.map((row, index) => {return {
                            id: row.id,
                            name: row.name,
                            type: row.type,
                            amount: parseInt(row.amount)
                        }
                    })}
                    rowThreshold={0}
                    rowHeight={38}
                    height={300}
                    loading={loading}
                    pagination={false}

                    /> 
              </div>
            </div>
          </TabPanel>}

          {(props.group === "ADMIN" || props.group === "SUPER") && <TabPanel value={value} index={3}>
            <div>
                <h3>&nbsp;&nbsp;MyFasMan Revenue app gebruikers - totaal (laatste 14 dagen)</h3>
                {!loading && getRevenueAppChart()}
                {loading && <div style={{display:"flex", justifyContent: "space-evenly", marginTop: 5}}>
                    <TailSpin 
                        height="40"
                        width="40"
                        color={Colors.sft_blue}
                        ariaLabel='loading'
                    />
                </div>}
                <h3>&nbsp;&nbsp;<b>MyFasMan Revenue gebruikers (vandaag):</b> &nbsp;&nbsp;{revenueUsage}</h3>
                    <div style={{height: 300, width: '100%' }}>
                        <DataGridPro
                            className={props.theme}
                            columns={revenueUsageDetailColumns}
                            rows={revenueUsageDetail.map((row, index) => {return {
                                    id: row.id,
                                    description: row.description,
                                    last_login: row.last_login,
                                    amount: parseInt(row.amount),
                                    logins: row.logins
                                }
                            })}
                            rowThreshold={0}
                            rowHeight={38}
                            height={300}
                            loading={loading}
                            pagination={false}
                        /> 
                </div>             
            </div>
          </TabPanel>}
          
      </Box>
        </div>
    </div>
  );
}

export default AdminStatScreen;
