import React, { useEffect, useState } from 'react';
import './logging.css';
import SftGlobalData from '../../library/SftGlobalData';
import { TailSpin } from 'react-loader-spinner';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Colors from '../../library/Colors';
import Card from '../../components/Card';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import LoggingTable from '../../components/logging/LoggingTable';
import TaskTable from '../../components/logging/TaskTable';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        style={{width: '100%'}}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 2}}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const LoggingScreen = (props) =>  {
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(0);
    const [initialGrid, setInitialGrid] = useState(getCookie("initialState") === "{}" ? {columns: {columnVisibilityModel: {priority: false, teamviewer_id: false, teamviewer_pass: false, log_solution: false, log_time: false, caller: false, phone: false, cust_store: true,},}} : JSON.parse(getCookie("initialState")));
    const [initialGridTasks, setInitialGridTasks] = useState(getCookie("initialStateTasks") === "{}" ? {columns: {columnVisibilityModel: {teamviewer_id: false, teamviewer_pass: false, log_solution: false, log_time: false, caller: false, phone: false,},}} : JSON.parse(getCookie("initialStateTasks")));
    let navigate = useNavigate();


    // Stats
    const [users, setUsers] = useState([]);

    // Stats
    const [stats, setStats] = useState([]);

    // Today logs
    const [todayLogs, setTodayLogs] = useState([]);
    const [todayLogsLoading, setTodayLogsLoading] = useState(true);

    // All logs
    const [logs, setLogs] = useState([]);
    const [logsLoading, setLogsLoading] = useState(true);

    // User logs
    const [userLogs, setUserLogs] = useState([]);
    const [userLogsLoading, setUserLogsLoading] = useState(true);

    // User tasks
    const [userTasks, setUserTasks] = useState([]);
    const [userTasksLoading, setUserTasksLoading] = useState(true);

    // All tasks
    const [tasks, setTasks] = useState([]);
    const [tasksLoading, setTasksLoading] = useState(true);

    // Time refresh
    const [time, setTime] = useState(Date.now());

    useEffect(()=>{
        props.changeScreen('logging');
        //console.log("Refreshing");
        reload();
        props.notify("Refreshed...", 1000, "top-center", props.theme, 1);
    }, [time]);

    useEffect(()=>{
        // Fetch users
        try{
            let url = SftGlobalData.baseURL_API+'logging&action=fetchUsers';
            let postData = {sft: props.loginObj?.sft_status}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    setUsers(response.data);
                });
        } catch(e){
            console.error(e);
        }
        
    }, [props.loginObj]);

    // Refresher
    useEffect(() => {
        const interval = setInterval(() => setTime(Date.now()), 300000);
        return () => {
          clearInterval(interval);
        };
      }, []);

    const reload = () => {
        if(getCookie("initialState") !== "{}"){
            let temp = JSON.parse(getCookie("initialState"));
            try{
                temp.preferencePanel.open = false;
            }catch(e){
    
            }
            setInitialGrid(temp);
        }

        if(getCookie("initialStateTasks") !== "{}"){
            let temp2 = JSON.parse(getCookie("initialStateTasks"));
            try{
                temp2.preferencePanel.open = false;
            }catch(e){
    
            }
            setInitialGridTasks(temp2);
        }
        
        softLoadLogs();

        // ALL Logs
        try{
            let url = SftGlobalData.baseURL_API+'logging&action=fetchLogs';
            let postData = {sft_id: 0, sft: props.loginObj?.sft_status}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    setLogs(response.data);
                    setLogsLoading(false);
                });
        } catch(e){
            console.error(e);
        }
        // Tasks
        loadTasks();
    }

    const loadTasks = () => {
        // ALL Tasks
        try{
            let url = SftGlobalData.baseURL_API+'logging&action=fetchLogs';
            let postData = {sft_id: 0, tasks: true, sft: props.loginObj?.sft_status}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    setTasks(response.data);
                    setTasksLoading(false);
                });
        } catch(e){
            console.error(e);
        }

        // USER Tasks
        try{
            let url = SftGlobalData.baseURL_API+'logging&action=fetchLogs';
            let postData = {sft_id: 0, tasks: true, userId: props.userId, sft: props.loginObj?.sft_status}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    setUserTasks(response.data);
                    setUserTasksLoading(false);
                });
        } catch(e){
            console.error(e);
        }
    };

    const softLoadLogs = () => {
        // Compact log stats
        try{
            let url = SftGlobalData.baseURL_API+'logging&action=fetchLogCompactStats';
            let postData = {test: "test", sft: props.loginObj?.sft_status}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    setStats(response.data);
                    setLoading(false);
                });
        } catch(e){
            console.error(e);
        }


        // TODAY Logs
        try{
            let url = SftGlobalData.baseURL_API+'logging&action=fetchLogs';
            let postData = {sft_id: 0, today: true, sft: props.loginObj?.sft_status}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    setTodayLogs(response.data);
                    setTodayLogsLoading(false);
                });
        } catch(e){
            console.error(e);
        }


        // USER Logs
        try{
            let url = SftGlobalData.baseURL_API+'logging&action=fetchLogs';
            let postData = {sft_id: 0, userId: props.userId, sft: props.loginObj?.sft_status}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    setUserLogs(response.data);
                    setUserLogsLoading(false);
                });
        } catch(e){
            console.error(e);
        }
    }

    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "{}";
    }
    
    const fetchStats = stats.map((row, index) => {
        return (
            <Card key={index} theme={props.theme + "  box_fade-in"} style={{textAlign: "center", width:300, height: 166, overflow: 'hidden'}} func={()=>{}}>
                {loading && <div style={{display:"flex", justifyContent: "space-evenly", alignContent:"center", alignItems: "center", marginTop: 5, width: "100%", height: "95%", overflow: "hidden"}}>
                    <TailSpin 
                        height="40"
                        width="40"
                        color={Colors.sft_blue}
                        ariaLabel='loading'
                    />
                </div>}
                {!loading && <div>
                    <h2 style={{fontSize: 18}}>{row.description} {row.cur_month}</h2>
                    <p style={{fontSize: 15}}>{row.cur_name} {row.cur_name === "" ? "" : "-"} {row.cur_count}</p>
                    <p style={{fontSize: 15}}>{props.t('last_month')}: {row.prev_name} {row.prev_name === "" ? "" : "-"} {row.prev_count}</p>
                </div>}
            </Card>
        )
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if(getCookie("initialState") !== "{}"){
            let temp = JSON.parse(getCookie("initialState"));
            try{
                temp.preferencePanel.open = false;
            }catch(e){
    
            }
            setInitialGrid(temp);
        }
        if(getCookie("initialStateTasks") !== "{}"){
            let temp2 = JSON.parse(getCookie("initialStateTasks"));
            try{
                temp2.preferencePanel.open = false;
            }catch(e){
    
            }
            setInitialGridTasks(temp2);
        }
    };

    const onOpenModal = () => {
        //setModal(true);
        navigate("/"+props.lang+"/logging/add");
    }
    
    return (
        <div className={'_100perc log__main_container log__padding_sidebar  box_fade-in'}>
            {
                // --------------------------------------------------------------------
                // Add log modal
                // --------------------------------------------------------------------
            }
            {/*<StyledModal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={modal}
                onClose={()=>{setModal(false);}}
                BackdropComponent={Backdrop}
                >
                <Box 
                    sx={style} 
                    style={{minWidth: 500}} 
                    className={props.theme + "  box_fade-in"}>
                    <h2 className={props.theme} >{props.t('log_add')}</h2>
                    <LoggingModal lang={props.lang} login={props.loginObj} userId={props.userId} screenWidth={props.screenWidth} theme={props.theme} t={props.t} addLog={(logObj)=>{addLog(logObj);}} addTask={(logObj)=>{addTask(logObj);}}/>
                </Box>
            </StyledModal>*/}
            {
                // --------------------------------------------------------------------
                // Statistics cards
                // --------------------------------------------------------------------
            }
                <div className={"log__stats_cards _100perc padding_top__30px"}>
                    {fetchStats}
                </div>
            <div className={'log__container_content ' + props.theme + ' container_content_' + props.theme + '  box_fade-in'} >
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}>
                    
                    {
                        // --------------------------------------------------------------------
                        // TITLE / HEADER
                        // --------------------------------------------------------------------
                    }
                    <div className={props.theme + " _100perc "}>
                        <div className="container_list__title ">
                            <h2 className={props.theme} type="text" id="logging" name="logging">{props.t('logging')}</h2>
                        </div>
                    </div>
                    {
                        // --------------------------------------------------------------------
                        // CONTENT
                        // --------------------------------------------------------------------
                    }
                    <Box className={props.theme + ' log__container_inner_content'}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '99%'}} className={props.theme + ' container_tabs_below_subtitle  '}>
                          <Tabs value={value} onChange={handleChange} className={props.theme} variant="scrollable" scrollButtons="auto">
                            <Tab label={props.t('logs_today') + " " + todayLogs.length} {...a11yProps(0)} className={props.theme}/>
                            <Tab label={props.t('logs_all') + " " + logs.length} {...a11yProps(1)} className={props.theme}/>                
                            <Tab label={props.t('logs_my') + " " + userLogs.length} {...a11yProps(2)} className={props.theme}/>
                            <Tab label={props.t('tasks_my') + " " + userTasks.length} {...a11yProps(3)} className={props.theme}/>
                            <Tab label={props.t('tasks_all') + " " + tasks.length} {...a11yProps(4)} className={props.theme}/>
                          </Tabs>
                      </Box>
                      {
                        // --------------------------------------------------------------------
                        // TODAY LOGS
                        // --------------------------------------------------------------------
                      }
                      <TabPanel value={value} index={0}>
                            <LoggingTable login={props.loginObj} loadLogs={()=>{softLoadLogs();}} loadTasks={()=>{loadTasks();}} userId={props.userId} initialGrid={initialGrid} data={todayLogs} loading={todayLogsLoading} t={props.t} theme={props.theme} openModal={()=>{onOpenModal();}} screenWidth={props.screenWidth}/> 
                        </TabPanel>
                      {
                        // --------------------------------------------------------------------
                        // ALL LOGS
                        // --------------------------------------------------------------------
                      }
                      <TabPanel value={value} index={1}>
                            <LoggingTable login={props.loginObj} loadLogs={()=>{softLoadLogs();}} loadTasks={()=>{loadTasks();}} userId={props.userId} initialGrid={initialGrid} data={logs} loading={logsLoading} t={props.t} theme={props.theme} openModal={()=>{onOpenModal();}} screenWidth={props.screenWidth}/>
                      </TabPanel>
                      {
                        // --------------------------------------------------------------------
                        // MY LOGS
                        // --------------------------------------------------------------------
                      }
                      <TabPanel value={value} index={2}>
                            <LoggingTable login={props.loginObj} loadLogs={()=>{softLoadLogs();}} loadTasks={()=>{loadTasks();}} userId={props.userId} initialGrid={initialGrid} data={userLogs} loading={userLogsLoading} t={props.t} theme={props.theme} openModal={()=>{onOpenModal();}} screenWidth={props.screenWidth}/>
                      </TabPanel>
                      {
                        // --------------------------------------------------------------------
                        // MY TASKS
                        // --------------------------------------------------------------------
                      }
                      <TabPanel value={value} index={3}>
                            <TaskTable login={props.loginObj} userSft={props.loginObj.sft_status} users={users} loadTasks={()=>{loadTasks();}} userId={props.userId} initialGrid={initialGridTasks} data={userTasks} loading={userTasksLoading} t={props.t} theme={props.theme} openModal={()=>{onOpenModal();}} screenWidth={props.screenWidth}/>
                      </TabPanel>
                      {
                        // --------------------------------------------------------------------
                        // ALL TASKS
                        // --------------------------------------------------------------------
                      }
                      <TabPanel value={value} index={4}>
                            <TaskTable login={props.loginObj} userSft={props.loginObj.sft_status} users={users} loadTasks={()=>{loadTasks();}} userId={props.userId} initialGrid={initialGridTasks} data={tasks} loading={tasksLoading} t={props.t} theme={props.theme} openModal={()=>{onOpenModal();}} screenWidth={props.screenWidth}/>
                      </TabPanel>
                    </Box>
                </div>
            </div>
        </div>
    );
}

export default LoggingScreen;