import React from 'react';


const DevScreen = (props) =>  {  
   
  return (
    <div>

    </div>
  );
}

export default DevScreen;
