import React from 'react';

const CardWithImageSmall = (props) =>  {   
    return (
        <div className={"small__card small__card_" + props.theme + " " + props.theme } style={props.style} onClick={()=>{props.func();}}>
            <div className='image' style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                <img src={props.src} alt={props.name} style={{objectFit: 'contain', height: '80px', width: '80px'}} loading={"lazy"} />
            </div>
        </div>
    );
}

export default CardWithImageSmall;
