import '../styles/main.css';
import { React } from 'react';
import SftGlobalData from '../library/SftGlobalData';


const Footer = (props) =>  {

  let dbName = "";
  try{
    dbName = props.db.settings.modules.length === 0 ? '' : ' - ' + props.db.description
  }catch(e){
      //console.error(e);
  }
  return (
    <div className={props.theme+' footer'}>
      <p className={props.theme+' footer__text'}>Powered by SoftTouch - {SftGlobalData.version} {dbName}</p>    
    </div>
  );
}

export default Footer;
