import { Switch, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SftGlobalData from '../../library/SftGlobalData';
import './excel.css';
import Colors from '../../library/Colors';
import FloatingBackButton from '../../components/FloatingBackButton';
import { OutTable, ExcelRenderer } from 'react-excel-renderer';
import FloatingSaveButton from '../../components/FloatingSaveButton';

import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import axios from 'axios';



const ExcelEditScreen = (props) => {
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState("");
    const [nameFr, setNameFr] = useState("");
    const [dbIds, setDbIds] = useState("");
    const [description, setDescription] = useState("");
    const [descriptionFr, setDescriptionFr] = useState("");
    const [impact, setImpact] = useState("");
    const [example, setExample] = useState("");
    const [exampleOverwrite, setExampleOverwrite] = useState(false);
    const [exampleFileName, setExampleFileName] = useState("");
    const [exampleCols, setExampleCols] = useState([]);
    const [exampleRows, setExampleRows] = useState([]);
    const [requiredFields, setRequiredFields] = useState("");
    const [table, setTable] = useState("");
    const [index, setIndex] = useState("");
    const [filter, setFilter] = useState(false);
    const [queries, setQueries] = useState("");
    const [validationQueries, setValidationQueries] = useState("");
    const params = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        let url = SftGlobalData.baseURL_API + 'excel&action=fetchOne';
        axios.post(url, { key: props.api, mod: params.mod }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        })
            .then(response => {
                if (SftGlobalData.debug) {
                    console.log('url', url);
                    console.log('response', response);
                }
                if (typeof response.error === 'undefined') {
                    if (typeof response.data.mod_name !== "undefined") setName(response.data.mod_name);
                    if (typeof response.data.mod_name_fr !== "undefined") setNameFr(response.data.mod_name_fr);
                    if (typeof response.data.required_fields !== "undefined") setRequiredFields(response.data.required_fields);
                    if (typeof response.data.mod_impact !== "undefined") setImpact(response.data.mod_impact);
                    if (typeof response.data.mod_db_ids !== "undefined") setDbIds(response.data.mod_db_ids);
                    if (typeof response.data.config?.description !== "undefined") setDescription(response.data.config?.description);
                    if (typeof response.data.config?.description_fr !== "undefined") setDescriptionFr(response.data.config?.description_fr);
                    if (typeof response.data.config?.index !== "undefined") setIndex(response.data.config?.index);
                    if (typeof response.data.config?.queries !== "undefined") setQueries((response.data.config?.queries?.replaceAll("\\\"", "'")?.replace(/;/g, '; ') + "").replace("undefined", ""));
                    if (typeof response.data.config?.validation_queries !== "undefined") setValidationQueries((response.data.config?.validation_queries?.replaceAll("\\\"", "'")?.replace(/;/g, '; ') + "").replace("undefined", ""));
                    if (typeof response.data.config?.table !== "undefined") setTable(response.data.config?.table);
                    if (typeof response.data.config?.allow_filter !== "undefined") setFilter(response.data.config?.allow_filter == true);
                    if (typeof response.data.example === "string") {
                        setExample(response.data.example);
                        let base64String = response.data.example;
                        if (!isBase64Image(response.data.example)) {
                            // Extract MIME type
                            const mimeType = base64String.match(/^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,/)?.[1];
                            if (mimeType) {
                                // Convert base64 to binary
                                const byteCharacters = atob(base64String.replace(/^data:.*;base64,/, ''));
                                const byteNumbers = new Array(byteCharacters.length);
                                for (let i = 0; i < byteCharacters.length; i++) {
                                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                                }
                                const byteArray = new Uint8Array(byteNumbers);

                                // Create blob
                                const blob = new Blob([byteArray], { type: mimeType });
                                try { renderFile(blob); } catch (e) { console.e(e); }
                            }
                        }
                    }
                } else {
                    props.notify(props.t('errorOccured'), 7500, "top-center", props.theme, 1);
                }
                setLoading(false);
            });
        props.changeScreen('Licence');
    }, [props.api]);

    const onFileChange = (e) => {
        let files = e.target.files;
        let fileReader = new FileReader();
        let fileObj = files[0];
        fileReader.readAsDataURL(fileObj);

        if (e.target.files.length) {
            let fileName = fileObj.name;
            setExampleFileName(fileName);
        }

        if (!isImageFile(files[0])) {
            try { renderFile(fileObj); } catch (e) { console.e(e); }
        }

        fileReader.onload = (event) => {
            if (SftGlobalData.debug) {
                console.log("file", event.target.result);
            }
            setExample(event.target.result);
            setExampleOverwrite(true);
        }
    }

    const renderFile = (fileObj) => {
        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
            if (err) {
                console.log(err);
            }
            else {
                if (SftGlobalData.debug) {
                    console.log("DATA", resp);
                }
                let columns = resp.cols;
                columns.unshift({ name: '', key: -1 });
                for (let index = 0; index < columns.length; index++) {
                    const element = columns[index];
                    columns[index] = { name: element.name, key: (element.key + 1) };

                }
                setExampleCols(columns);
                setExampleRows(resp.rows);
            }
        });
    }

    const isImageFile = (file) => {
        // Check if the file exists
        if (!file) {
            return false;
        }
        // Get the file type
        const fileType = file.type;
        // Supported image file types
        const imageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'];
        // Check if the file type is in the supported image types array
        return imageTypes.includes(fileType);
    };

    const changeQuery = (query) => {
        setQueries(query);
        //setQueries(replaceMySQLKeywords(query));
    }

    const changeValidationQuery = (query) => {
        setValidationQueries(query);
        //setValidationQueries(replaceMySQLKeywords(query));
    }

    const isBase64Image = (base64String) => {
        // Regular expression to check if the base64 string starts with the data URL scheme for an image
        const regex = /^data:image\/(jpeg|jpg|png|gif|bmp|svg\+xml);base64,/i;
        return regex.test(base64String);
    }

    const replaceMySQLKeywords = (inputString) => {
        const mysqlKeywords = [
            'SELECT',
            'FROM',
            'WHERE',
            'GROUP BY',
            'ORDER BY',
            'LIMIT',
            'JOIN',
            'LEFT JOIN',
            'RIGHT JOIN',
            'INNER JOIN',
            'OUTER JOIN',
            'LEFT OUTER JOIN',
            'RIGHT OUTER JOIN',
            'ON',
            'AS',
            'INSERT',
            'INTO',
            'VALUES',
            'UPDATE',
            'SET',
            'DELETE',
            'DISTINCT',
            'COUNT',
            'SUM',
            'AVG',
            'MAX',
            'MIN',
            'AND',
            'OR',
            'NOT',
            'IN',
            'LIKE',
            'IS NULL',
            'IS NOT NULL',
            'BETWEEN',
            'CASE',
            'WHEN',
            'THEN',
            'ELSE',
            'END',
            'IF',
            'DECLARE',
            'BEGIN',
            'END',
            'PROCEDURE',
            'FUNCTION',
            'TRIGGER',
            'CREATE',
            'TABLE',
            'INDEX',
            'VIEW',
            'DATABASE',
            'ALTER',
            'DROP',
            'PRIMARY KEY',
            'FOREIGN KEY',
            'UNIQUE',
            'DEFAULT',
            'AUTO_INCREMENT',
            'NULL',
            'ADD',
            'ALL',
            'ANALYZE',
            'AND',
            'AS',
            'ASC',
            'BETWEEN',
            'BOTH',
            'BY',
            'CASE',
            'COLLATE',
            'COLUMN',
            'CONSTRAINT',
            'CREATE',
            'CROSS',
            'CURRENT_DATE',
            'CURRENT_TIME',
            'CURRENT_TIMESTAMP',
            'DATABASE',
            'DEFAULT',
            'DESC',
            'DISTINCT',
            'DROP',
            'ELSE',
            'END',
            'EXISTS',
            'FALSE',
            'FOR',
            'FROM',
            'FULL',
            'GROUP',
            'HAVING',
            'IF',
            'IGNORE',
            'IN',
            'INDEX',
            'INNER',
            'INSERT',
            'INTERVAL',
            'INTO',
            'IS',
            'JOIN',
            'KEY',
            'LEFT',
            'LIKE',
            'LIMIT',
            'NOT',
            'NULL',
            'ON',
            'OR',
            'ORDER',
            'OUTER',
            'PRIMARY',
            'REFERENCES',
            'RIGHT',
            'SELECT',
            'SET',
            'TABLE',
            'THEN',
            'TO',
            'TRUE',
            'UNION',
            'UNIQUE',
            'UPDATE',
            'VALUES',
            'WHEN',
            'WHERE',
            'WITH',
        ];

        // Use a regular expression to replace MySQL keywords
        const regex = new RegExp(`\\b(${mysqlKeywords.join('|')})\\b`, 'gi');
        return inputString.replace(regex, (match) => match.toUpperCase());
    }

    const removeSpacesAndQuotes = (inputString) => {
        // Remove spaces, single quotes, and double quotes using regular expressions
        const result = inputString.replace(/\s/g, '').replace(/'/g, '').replace(/"/g, '');
        return result.toLowerCase();
    }

    const removeQuotes = (inputString) => {
        // Remove spaces, single quotes, and double quotes using regular expressions
        const result = inputString.replace(/'/g, '').replace(/"/g, '');
        return result.toLowerCase();
    }

    const onSave = () => {
        let url = SftGlobalData.baseURL_API + 'excel&action=save';
        let postData = {
            key: props.api,
            mod_id: params.mod,
            mod_db_ids: dbIds,
            mod_name: name,
            mod_name_fr: nameFr,
            mod_required_fields: requiredFields,
            mod_impact: impact,
            example: example,
            mod_config: {
                description: description,
                description_fr: descriptionFr,
                table: table,
                index: index,
                allow_filter: filter,
                queries: queries.replaceAll("\t", " "),
                validation_queries: validationQueries.replaceAll("\t", " ")
            }
        };
        axios.post(url, postData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        })
            .then(response => {
                if (SftGlobalData.debug) {
                    console.log('URL', url);
                    console.log('POST', postData);
                    console.log('RESP', response);
                }
                if (typeof response.error === 'undefined') {
                    if (response.data.success) {
                        props.notify(props.t('savedChanges'), 7500, "top-right", props.theme, 1);
                        if (params.mod === "+") {
                            navigate("/" + props.lang + "/excel/edit/" + response.data.message);
                        }
                    } else {
                        props.notify(props.t('errorOccured') + " " + response.data.message, 7500, "top-right", props.theme, 1);
                    }
                } else {
                    props.notify(props.t('errorOccured'), 7500, "top-right", props.theme, 1);
                }
            });
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }} className={(props.screenWidth) < 600 ? 'not_expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme : 'expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme} >
            <div className={"container_list__title " + props.theme}><h2>Excel import</h2></div>
            <FloatingBackButton disabled={false} onClick={() => { navigate("/" + props.lang + "/excel"); }} />
            <FloatingSaveButton disabled={loading} onClick={() => { onSave(); }} />
            {/*************************************************************************************
              *
              *                                   NAMES
              *
              *************************************************************************************/}
            <div className='rowContainerBetween'>
                <TextField
                    minRows={1}
                    autoComplete="off"
                    inputProps={{ maxLength: 255 }}
                    className={props.theme + '_accent _50perc margin_top_10px  margin_right__10px '}
                    id="name_nl"
                    label={"Name NL"}
                    size={'small'}
                    sx={(props.theme === 'light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                        }, borderRadius: 3, marginTop: 0
                    }
                        :
                        {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0
                        }}
                    value={name}
                    onChange={(event) => { setName(event.target.value) }}
                />
                <TextField
                    minRows={1}
                    autoComplete="off"
                    inputProps={{ maxLength: 255 }}
                    className={props.theme + '_accent _50perc margin_top_10px '}
                    id="name_fr"
                    label={"Name FR"}
                    size={'small'}
                    sx={(props.theme === 'light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                        }, borderRadius: 3, marginTop: 0
                    }
                        :
                        {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0
                        }}
                    value={nameFr}
                    onChange={(event) => { setNameFr(event.target.value) }}
                />
            </div>

            {/*************************************************************************************
              *
              *                                DESCRIPTIONS
              *
              *************************************************************************************/}
            {<div className='rowContainerBetween'>
                <textarea
                    className={props.theme + '_accent _50perc margin_top_10px  margin_right__10px '}
                    value={description}
                    onChange={(e) => { setDescription(e.target.value) }}
                    placeholder="Description (NL)"
                    style={{ width: '100%', height: '150px', padding: '8px' }}
                />
                <textarea
                    className={props.theme + '_accent _50perc margin_top_10px '}
                    value={descriptionFr}
                    onChange={(e) => { setDescriptionFr(e.target.value) }}
                    placeholder="Description (FR)"
                    style={{ width: '100%', height: '150px', padding: '8px' }}
                />
            </div>}

            {/*************************************************************************************
              *
              *                                TABLE / INDEX
              *
              *************************************************************************************/}
            <div className='rowContainerBetween'>
                <TextField
                    minRows={1}
                    autoComplete="off"
                    inputProps={{ maxLength: 255 }}
                    className={props.theme + '_accent _50perc margin_top_10px  margin_right__10px '}
                    id="table"
                    label={"Table"}
                    size={'small'}
                    sx={(props.theme === 'light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                        }, borderRadius: 3, marginTop: 0
                    }
                        :
                        {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0
                        }}
                    value={table}
                    onChange={(event) => { setTable(event.target.value) }}
                />
                <TextField
                    minRows={1}
                    autoComplete="off"
                    inputProps={{ maxLength: 255 }}
                    className={props.theme + '_accent _50perc margin_top_10px '}
                    id="index"
                    label={"Index"}
                    size={'small'}
                    sx={(props.theme === 'light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                        }, borderRadius: 3, marginTop: 0
                    }
                        :
                        {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0
                        }}
                    value={index}
                    onChange={(event) => { setIndex(event.target.value) }}
                />
            </div>

            {/*************************************************************************************
              *
              *                               REQUIRED / IMPACT
              *
              *************************************************************************************/}
            <div className='rowContainerBetween alignCenterInContainer '>
                <TextField
                    minRows={1}
                    autoComplete="off"
                    inputProps={{ maxLength: 255 }}
                    className={props.theme + '_accent _50perc margin_top_10px  margin_right__10px '}
                    id="requiredFields"
                    label={"Required fields"}
                    size={'small'}
                    sx={(props.theme === 'light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                        }, borderRadius: 3, marginTop: 0
                    }
                        :
                        {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0
                        }}
                    value={requiredFields}
                    onChange={(event) => { setRequiredFields(removeQuotes(event.target.value)) }}
                />

                <TextField
                    minRows={1}
                    autoComplete="off"
                    inputProps={{ maxLength: 255 }}
                    className={props.theme + '_accent _50perc margin_top_10px  '}
                    id="impact"
                    label={"Impacted table(s)"}
                    size={'small'}
                    sx={(props.theme === 'light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                        }, borderRadius: 3, marginTop: 0
                    }
                        :
                        {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0
                        }}
                    value={impact}
                    onChange={(event) => { setImpact(event.target.value) }}
                />



            </div>

            {/*************************************************************************************
              *
              *                             EXAMPLE / FILTER
              *
              *************************************************************************************/}
            <div className='rowContainerBetween alignCenterInContainer' style={{ paddingRight: 5 }}>
                <div className={'rowContainerStart _50perc alignCenterInContainer margin_top_10px  margin_right__10px '}>
                    <span className={'  margin_right__10px '}><b> Example </b></span>

                    <div className="container _100perc">
                        <label htmlFor="file" className="input input-file">
                            <div className="button">
                                <input type="file" className="form-control form-control-sm" name="file" accept=".xlsx, .xls, .csv, image/jpeg, image/png, image/gif" onChange={onFileChange} />Browse
                            </div>
                            <input type="text" className="form-control form-control-sm" readOnly placeholder={exampleOverwrite ? exampleFileName : "Choose a file..."} />
                        </label>
                    </div>
                </div>

                <div className='rowContainerBetween alignCenterInContainer margin_top_10px _40perc '>
                    <div style={{ marginLeft: 5 }}>Allow store filter</div>
                    <Switch
                        checked={filter}
                        onChange={(event) => { setFilter(event.target.checked) }}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </div>
                <TextField
                    minRows={1}
                    autoComplete="off"
                    inputProps={{ maxLength: 255 }}
                    className={props.theme + '_accent _10perc margin_top_10px '}
                    id="db_ids"
                    label={"Db Ids"}
                    size={'small'}
                    sx={(props.theme === 'light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                        }, borderRadius: 3, marginTop: 0
                    }
                        :
                        {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0
                        }}
                    value={dbIds}
                    onChange={(event) => { setDbIds(event.target.value) }}
                />
            </div>

            <div className='rowContainerBetween'>
                {(isBase64Image(example) && example !== "") && <img src={example} style={{ maxWidth: "100%" }} />}
                {(!isBase64Image(example) && example !== "") && <OutTable data={exampleRows} columns={exampleCols} tableClassName="ExcelTable2007" tableHeaderRowClass="heading" />}
            </div>

            <br /><br />

            {/*************************************************************************************
              *
              *                               QUERIES
              *
              *************************************************************************************/}
            <div className='rowContainerBetween'>
                <div className="columnContainerStart alignCenterInContainer _50perc margin_right__10px">
                    <div className='_100perc'>
                        <h4>Import queries</h4>
                        <div style={{ position: 'relative', width: '100%' }}>
                            <textarea
                                className={props.theme + '_accent  '}
                                value={queries}
                                onChange={(e) => { changeQuery(e.target.value) }}
                                placeholder="Enter SQL code here..."
                                style={{ width: '100%', height: '200px', padding: '8px' }}
                            />

                            {/*queries !== "" && <SyntaxHighlighter language="sql" style={dracula} showLineNumbers >
                              {queries}
                            </SyntaxHighlighter>*/}
                        </div>
                    </div>
                </div>

                <div className="columnContainerStart alignCenterInContainer _50perc">
                    <div className='_100perc'>
                        <h4>Validation queries</h4>
                        {/*<textarea className='_100perc' style={{maxWidth: "100%", minHeight: 300}} onChange={(e)=>{changeValidationQuery(index, e.target.value)}} defaultValue={validationQueries}/>*/}
                        <div style={{ position: 'relative', width: '100%' }}>
                            <textarea
                                className={props.theme + '_accent  '}
                                value={validationQueries}
                                onChange={(e) => { changeValidationQuery(e.target.value) }}
                                placeholder="Enter SQL code here..."
                                style={{ width: '100%', height: '200px', padding: '8px' }}
                            />

                            {/*validationQueries !== "" && <SyntaxHighlighter language="sql" style={dracula} showLineNumbers >
                              {validationQueries}
                            </SyntaxHighlighter>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ExcelEditScreen;
