import React, { useState, useEffect, useRef }from 'react';
import '../../screens/logging/logging.css';
import axios from 'axios';
import SftGlobalData from '../../library/SftGlobalData';
import Colors from '../../library/Colors';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { Autocomplete, FormControlLabel, Switch, TextField } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../Card';

const LoggingModal = (props) =>  {
    const [loading, setLoading] = useState(true);
    const [customer, setCustomer] = useState(props.selectedDb);
    const [store, setStore] = useState({id: "NULL"});
    const [stores, setStores] = useState([]);
    const [tags, setTags] = useState ([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [duration, setDuration] = useState("< 10m");
    const [remark, setRemark] = useState("");
    const [solution, setSolution] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [extraFields, setExtraFields] = useState(false);
    const [caller, setCaller] = useState("");
    const [phone, setPhone] = useState("");
    const [teamviewerId, setTeamviewerId] = useState("");
    const [teamViewerPass, setTeamViewerPass] = useState("");
    const [lastLogs, setLastLogs] = useState([]);
    const inputEl = useRef();

    useEffect (()=>{
        if(loading){
            try{
                let extraLogFields = sessionStorage.getItem('LogExtraFields');
                if(typeof extraLogFields !== "undefined"){
                    setExtraFields(extraLogFields==="true");
                } else {
                    setExtraFields(false);
                }
            }catch(ex){}
            try{
                let url2 = SftGlobalData.baseURL_API+'logging&action=fetchLogTags';
                let postData2 = {sft_id: 0, sft: props.login?.sft_status}
                axios.post(url2, postData2, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                    .then(response => {
                        if(SftGlobalData.debug){
                            console.log('url', url2);
                            console.log('postData', postData2);
                            console.log('resp', response);
                        }
                        setTags(response.data);
                    });
            } catch(e){
                console.error(e);
            }
            setLoading(false);
        }
        
    }, [loading, props.login?.sft_status]);

    useEffect(()=>{
        try{
            let url = SftGlobalData.baseURL_API+'logging&action=fetchDeliveryAddress';
            let postData = {sft_id: props.selectedDb.cust_no, sft: props.login?.sft_status}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    setStores(response.data);
                });
        } catch(e){
            console.error(e);
        }

        try{
            let url = SftGlobalData.baseURL_API+'logging&action=fetchLogs';
            let postData = {sft_id: props.selectedDb.cust_no, limit: 5, sft: props.login?.sft_status}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    setLastLogs(response.data);
                });
        } catch(e){
            console.error(e);
        }
    }, [props.selectedDb, props.login?.sft_status])


    const handleChangeDuration = (event) => {
        const {
            target: { value },
        } = event;
        setDuration(value);
    };

    const lastLogging = lastLogs.map((row) => (
        <Card style={{width: '100%', marginBottom: 5, marginTop: 5, padding: 10, flex: 1, display: 'flex', flexDirection: 'column'}} func={()=>{}}>
            <div style={{width: '100%', marginBottom: 5}}><p style={{paddingBottom: 0, paddingTop: 0, marginBottom: 0, marginTop: 0}}><b>{row.log_date} - {row.log_owner} </b></p></div>
            <div style={{width: '100%'}}><p style={{paddingBottom: 0, paddingTop: 0, marginBottom: 0, marginTop: 0}}>{row.log_remark}</p></div>
        </Card>
    ))

    function onSaveLogging(task) {
        if(remark !== "" && typeof customer?.cust_no !== "undefined"){
            try{
                let temp = inputEl.current.innerText.replaceAll("\n", ",").replaceAll("Tags", "").replaceAll("*", "");
                temp = temp.substring(1,temp.length-1);
                temp = temp.split(',');

                let chips = "[";
                temp.forEach(tag => {
                    let splitTag =  tag.split("-");
                    chips += '"' + splitTag[0].trim() + '",';
                });
                chips = chips.slice(0, -1);
                chips += "]";

                chips = chips.replaceAll("\"\",", "").replaceAll(",\"\"", "");

                if(chips !== '[""]'){
                    setDisabled(true);
                    let url = SftGlobalData.baseURL_API+'logging&action=createLog';
                    let postData = {
                        sft_id: customer.cust_no, 
                        cust_del_id: store.id,
                        user_id: props.userId, 
                        remark: remark.replaceAll("\\", "\\\\'").replaceAll("'", "\\'"),
                        solution: solution.replaceAll("\\", "\\\\'").replaceAll("'", "\\'"),
                        tags: chips,
                        duration: duration,
                        caller: caller.replaceAll("\\", "\\\\'").replaceAll("'", "\\'"),
                        phone: phone.replaceAll("\\", "\\\\'").replaceAll("'", "\\'"),
                        teamviewer_id: teamviewerId.replaceAll("\\", "\\\\'").replaceAll("'", "\\'"),
                        teamviewer_pass: teamViewerPass.replaceAll("\\", "\\\\'").replaceAll("'", "\\'"),
                        is_task: task, 
                        sft: props.login?.sft_status

                    }
                    axios.post(url, postData, {
                        headers : {
                            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                        }})
                        .then(response => {
                            if(SftGlobalData.debug){
                                console.log('url', url);
                                console.log('postData', postData);
                                console.log('resp', response);
                            }
                            setRemark("");
                            setSolution("");
                            setSelectedTags([]);
                            setStore({});
                            setDuration("< 10");
                            setCaller("");
                            setPhone("");
                            setCustomer({});
                            setTeamViewerPass("");
                            setTeamviewerId("");
                            setDisabled(false);
                            props.close();
                        });
                    }
            } catch(e){
                console.error(e);
            }
        }
    }


    return (
        <div className={props.theme} style={{width:500, justifyContent: 'flex-start', alignContent: 'flex-start', textAlign: 'start'}}>
            <center><h2>Logging</h2></center>
            <div className={"log__container_options log__100_perc padding_top__10px padding_left__5px padding_right__5px"}>
                <Autocomplete
                  id="store-select"
                  className={props.theme+ '_accent log__100_perc'}
                  label={props.t('store')}
                  size={'small'}
                  sx={(props.theme==='light') ? {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.light_text
                      },
                    '& .MuiInputLabel-root': {
                        color: Colors.light_text
                     }, borderRadius: 3, marginTop: 0} 
                    :
                    {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.dark_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.dark_text_accent
                        }, borderRadius: 3, marginTop: 0}}

                  options={stores}
                  isOptionEqualToValue={(option, val)=>{return option.name === val.name;}}
                  onChange={(event, value, reason)=>{if(reason === "selectOption"){setStore(value);}else{setStore({})}}}
                  autoHighlight
                  getOptionLabel={(option) => {return option.name;}}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.id} - {option.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Store"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />

                
            </div>

            <div className={"log__container_options log__100_perc padding_left__5px padding_right__5px"}>
                <FormControl className={props.theme+ '_accent log__100_perc'}>
                  <InputLabel id="demo-simple-select-label">Duration</InputLabel>
                  <Select
                    className={props.theme+"_accent "+props.theme+"_text log__100_perc"}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={duration}
                    label="Duration"
                    onChange={handleChangeDuration}
                    style={{height: 40, paddingTop: 8, paddingBottom: 8}}>
                    <MenuItem value={"< 10m"}>&lt; 10m</MenuItem>
                    <MenuItem value={"< 20m"}>&lt; 20m</MenuItem>
                    <MenuItem value={"< 30m"}>&lt; 30m</MenuItem>
                    <MenuItem value={"< 60m"}>&lt; 60m</MenuItem>
                    <MenuItem value={"> 60m"}>&gt; 60m</MenuItem>
                  </Select>
                </FormControl>
            </div>
            <div className={"log__container_options log__100_perc padding_left__5px padding_right__5px"}>
                <Autocomplete
                    multiple
                    ref={inputEl}
                    id="tags-outlined"
                    className={props.theme+ '_accent log__100_perc'}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text
                         }, borderRadius: 3, marginTop: 0, height: '100%'} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0, height: '100%'}}
                    options={tags}
                    isOptionEqualToValue={(option, val)=>{return ((option.tag + " - " + option.parent) === (val.tag + " - " + val.parent))}}
                    getOptionLabel={(option) => (option.tag + " - " + option.parent)}
                    defaultValue={selectedTags}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Tags"
                        required
                      />
                    )}
                  />
            </div>

            <div className={"log__container_options log__100_perc padding_left__5px padding_right__5px"}>
                <TextField
                    required
                    multiline
                    minRows={3}
                    maxRows={3}
                    inputProps={{ maxLength: 255 }}
                    autoComplete="off" 
                    className={props.theme+ '_accent log__100_perc'}
                    id="remark"
                    label={props.t('remark')}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={remark}
                    onChange={(event)=>{setRemark(event.target.value)}}
                    />  
            </div>
            <div className={"log__container_options log__100_perc padding_left__5px padding_right__5px"}>
                <TextField
                    multiline
                    minRows={3}
                    maxRows={3}
                    autoComplete="off" 
                    className={props.theme+ '_accent log__100_perc'}
                    id="solution"
                    label={"Solution"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={solution}
                    onChange={(event)=>{setSolution(event.target.value)}}
                    />  
            </div>

            <FormControlLabel
              sx={{marginLeft: 0.1}}
              control={
                <Switch
                  checked={extraFields}
                  onChange={()=>{sessionStorage.removeItem('LogExtraFields'); sessionStorage.setItem('LogExtraFields', !extraFields ? "true" : "false"); setExtraFields(!extraFields);}}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Extra fields"
            />

            

            
            {extraFields && <div className={"log__container_options log__100_perc padding_left__5px padding_right__5px"}>
                <TextField
                    autoComplete="off" 
                    className={props.theme+ '_accent margin_right__10px log__100_perc'}
                    id="phone"
                    label={"Phone"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={phone}
                    onChange={(event)=>{setPhone(event.target.value)}}
                    />  
                <TextField
                    autoComplete="off" 
                    className={props.theme+ '_accent log__100_perc'}
                    id="caller"
                    label={"Caller"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={caller}
                    onChange={(event)=>{setCaller(event.target.value)}}
                    />  
            </div>}

            {extraFields && <div className={"log__container_options log__100_perc padding_left__5px padding_right__5px"}>
                <TextField
                    autoComplete="off" 
                    className={props.theme+ '_accent margin_right__10px log__100_perc'}
                    id="teamviewerId"
                    label={"Teamviewer ID"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={teamviewerId}
                    onChange={(event)=>{setTeamviewerId(event.target.value)}}
                    />  
                <TextField
                    autoComplete="off" 
                    className={props.theme+ '_accent log__100_perc'}
                    id="teamviewerPass"
                    label={"Teamviewer password"}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={teamViewerPass}
                    onChange={(event)=>{setTeamViewerPass(event.target.value)}}
                    />  
            </div>}

            <div className={'  log__100_perc padding_left__5px padding_right__5px'}>
                <Button disabled={disabled} className="modal_button_container_no_margin__button margin_top_10px log__100_perc" onClick={()=>{onSaveLogging(false)}}>Log {props.t("add")}</Button>
            </div>
            <div className={'  log__100_perc padding_left__5px padding_right__5px'}>
                <Button disabled={disabled} className="modal_button_container_no_margin__button log__100_perc" onClick={()=>{onSaveLogging(true)}}>Task {props.t("add")}</Button>
            </div>

            <div>
                {lastLogs.length > 0 && <h3 style={{marginBottom: 5, textAlign: 'center'}}>Last 5</h3>}
                {lastLogs.length > 0 && lastLogging}
            </div>
            

        </div>
    );
}

export default LoggingModal;
