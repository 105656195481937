import React, { useEffect, useState } from 'react';
import { Box, Button, InputAdornment, Switch, Tab, Tabs, TextareaAutosize, TextField } from '@mui/material';
import WizardIcon from '../../assets/ic_wizard.png';
import { TailSpin } from 'react-loader-spinner';
import axios from 'axios';
import { HexColorPicker } from "react-colorful";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import { useNavigate } from 'react-router-dom';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTiktok, faXTwitter, faWhatsapp, faInstagram, faFacebook, faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faShoppingCart, faGlobe, faEnvelope} from '@fortawesome/free-solid-svg-icons';

// Libraries
import SftGlobalData from '../../library/SftGlobalData';
import Colors from '../../library/Colors';
import './eticket.css';
import './steps.scss';
import ProgVarTable from './ProgVarTable';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3}}>
            <div className='rowContainerBetween'>{children}</div>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  

const EticketWizardScreen = (props) =>  {  

    let navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [step, setStep] = useState(0);
    const [disableControls, setDisableControls] = useState(false);

    // First step
    const [template, setTemplate] = useState('2');
    const [font, setFont] = useState('Montserrat');
    const [color, setColor] = useState(Colors.sft_blue);
    const [textColor, setTextColor] = useState('#FFFFFF');
    const [discountColor, setDiscountColor] = useState('#FF5454');
    const [discountTextColor, setDiscountTextColor] = useState('#FFFFFF');
    const [templates, setTemplates] = useState([]);

    // Second step
    const [header, setHeader] = useState(
        props?.lang === "nl" ? "Beste [CUST_FIRST_NAME], \n\nBedankt voor uw aankoop bij [kasticket_hoofd1]! We hopen dat u tevreden bent met uw gekozen artikelen en dat ze aan uw verwachtingen voldoen. \n\nNogmaals dank voor uw vertrouwen in [kasticket_hoofd1]. We kijken ernaar uit u binnenkort weer in onze winkel te mogen verwelkomen." : 
        props?.lang === "fr" ? "" :
        ""
    );
    const [footer, setFooter] = useState("[kasticket_hoofd1] \n[kasticket_hoofd2] \n[kasticket_hoofd3] \n[kasticket_hoofd4] \n[kasticket_hoofd5] \n[kasticket_hoofd6] \n[kasticket_hoofd7] \n");
    const [progVars, setProgVars] = useState({});
    const [selectedHeaderTag, setSelectedHeaderTag] = useState("");
    const [selectedFooterTag, setSelectedFooterTag] = useState("");
    const [selectedHeaderTagValue, setSelectedHeaderTagValue] = useState("");
    const [selectedFooterTagValue, setSelectedFooterTagValue] = useState("");
    const [headerReservation, setHeaderReservation] = useState(
        props?.lang === "nl" ? "Beste  [CUST_FIRST_NAME],\n\nWe zijn blij te kunnen bevestigen dat de volgende artikelen bij [kasticket_hoofd1] voor u zijn gereserveerd.\n\nHoud er rekening mee dat deze reservatie voor [aantal] dagen zal gelden. Als de artikelen voor deze datum niet zijn opgehaald, worden ze weer beschikbaar gesteld voor verkoop aan andere klanten." : 
        props?.lang === "fr" ? "" :
        ""
    );
    const [footerReservation, setFooterReservation] = useState("[kasticket_hoofd1] \n[kasticket_hoofd2] \n[kasticket_hoofd3] \n[kasticket_hoofd4] \n[kasticket_hoofd5] \n[kasticket_hoofd6] \n[kasticket_hoofd7] \n");
    const [selectedHeaderTagReservation, setSelectedHeaderTagReservation] = useState("");
    const [selectedFooterTagReservation, setSelectedFooterTagReservation] = useState("");
    const [selectedHeaderTagValueReservation, setSelectedHeaderTagValueReservation] = useState("");
    const [selectedFooterTagValueReservation, setSelectedFooterTagValueReservation] = useState("");

    // Third step
    const [article, setArticle] = useState({});
    const [detailUseImages, setDetailUseImages] = useState(true);
    const [detailLine1, setDetailLine1] = useState("[SUPPLIER_NAME] [ARTICLE_DETAIL_1] ");
    const [detailLine2, setDetailLine2] = useState("[CODE_2_DESCRIPTION] [CODE_1_LANG_1] [ARTICLE_COLOR_SUPPLIER] [COLOR_DESCRIPTION] ");
    const [detailLine3, setDetailLine3] = useState("[ARTICLE_SIZE_X_DESCRIPTION] [ARTICLE_SIZE_Y_DESCRIPTION] ");

    // Fourth step
    const [focusTarget, setFocusTarget] = useState("retouch");
    const [disclaimerAdvance, setDisclaimerAdvance] = useState(
        props?.lang === "nl" ? "Door een voorschot te betalen, bevestigt de klant de intentie om een nog niet beschikbaar artikel bij [kasticket_hoofd1] aan te schaffen. Dit voorschot garandeert de reservering van het betreffende artikel zodra het beschikbaar wordt. [kasticket_hoofd1] verbindt zich ertoe om het artikel te leveren zodra het beschikbaar is, met dien verstande dat leveringsdata onderhevig kunnen zijn aan verandering door factoren buiten onze controle./nIn geval van annulering van de bestelling door de klant vóór de levering van het artikel, zal het voorschot worden omgezet in een tegoed bij [kasticket_hoofd1], dat gebruikt kan worden voor toekomstige aankopen. Dit tegoed is niet inwisselbaar voor contant geld." : 
        props?.lang === "fr" ? "" :
        ""
    );
    const [disclaimerCommercialVoucher, setDisclaimerCommercialVoucher] = useState(
        props?.lang === "nl" ? "De commerciële waardebon geeft de houder recht op een korting van bepaalde % of een vast bedrag van korting op geselecteerde artikelen bij [kasticket_hoofd1]. De bon is beperkt geldig en moet binnen deze periode worden gebruikt.\nDe waardebon is enkel geldig voor aankopen bij [kasticket_hoofd1] en kan niet worden ingewisseld voor contant geld. Deze waardebon kan niet worden gecombineerd met andere acties, aanbiedingen of kortingen. De waardebon moet in één keer worden besteed; er wordt geen restwaarde in contant geld of als nieuwe bon uitgegeven." : 
        props?.lang === "fr" ? "" :
        ""
    );
    const [disclaimerGiftVoucher, setDisclaimerGiftVoucher] = useState(
        props?.lang === "nl" ? "Een cadeaubon is geldig tot [CadeauChequeAantalDagen] dagen na de datum van uitgifte. Na deze periode vervalt de geldigheid van de bon en kan deze niet meer worden ingewisseld. Deze bon kan niet worden ingewisseld voor contant geld en kan alleen worden gebruikt voor aankopen bij [kasticket_hoofd1]. Het is niet toegestaan om deze bon te kopiëren, te wijzigen of te reproduceren op welke manier dan ook. [kasticket_hoofd1] is niet verantwoordelijk voor verloren, gestolen of beschadigde bonnen. Het gebruik van deze bon impliceert acceptatie van de bovengenoemde voorwaarden." : 
        props?.lang === "fr" ? "" :
        ""
    );
    const [disclaimerRetouch, setDisclaimerRetouch] = useState(
        props?.lang === "nl" ? "Bij het aannemen van kledingartikelen voor retouche, streeft [kasticket_hoofd1] ernaar om de gewenste aanpassingen met de hoogst mogelijke zorg en vakmanschap uit te voeren. Klanten dienen bij het inleveren van de kleding duidelijk aan te geven welke retouches gewenst zijn.\nRetouches kunnen de oorspronkelijke staat van een kledingstuk veranderen. Klanten dienen zich bewust te zijn van het risico op mogelijke veranderingen in pasvorm, stijl, en comfort van het kledingstuk." : 
        props?.lang === "fr" ? "" :
        ""
    );
    const [disclaimerVoucher, setDisclaimerVoucher] = useState(
        props?.lang === "nl" ? "Een waardebon is geldig tot [WaardeBonAantalDagen] dagen na de datum van uitgifte. Na deze periode vervalt de geldigheid van de bon en kan deze niet meer worden ingewisseld. Deze bon kan niet worden ingewisseld voor contant geld en kan alleen worden gebruikt voor aankopen bij [kasticket_hoofd1]. Het is niet toegestaan om deze bon te kopiëren, te wijzigen of te reproduceren op welke manier dan ook. [kasticket_hoofd1] is niet verantwoordelijk voor verloren, gestolen of beschadigde bonnen. Het gebruik van deze bon impliceert acceptatie van de bovengenoemde voorwaarden." : 
        props?.lang === "fr" ? "" :
        ""
    );
    const [disclaimerReservation, setDisclaimerReservation] = useState(
        props?.lang === "nl" ? "Door het plaatsen van een reservatie bij [kasticket_hoofd1] en het betalen van een voorschot, gaat de klant akkoord met het volgende beleid:\n1.	De gereserveerde artikelen worden voor de klant opzij gehouden voor een periode van [aantal] dagen vanaf de datum van de reservatie.\n2.	Indien de klant de gereserveerde artikelen niet binnen deze periode ophaalt, behoudt [kasticket_hoofd1] zich het recht voor om de artikelen weer beschikbaar te stellen voor verkoop aan andere klanten.\n3.	In het geval dat de klant de gereserveerde artikelen niet binnen de vastgestelde periode ophaalt, kan het betaalde voorschot bedrag verbeurd worden verklaard ter compensatie van de gereserveerde opslag en potentiële gemiste verkoopkansen.\n4.	Voorschotten op reservaties zijn niet terugbetaalbaar in het geval van niet tijdig ophalen van de gereserveerde artikelen\nDeze voorwaarden zijn bedoeld om een eerlijke en efficiënte service te garanderen aan al onze klanten. " : 
        props?.lang === "fr" ? "" :
        ""
    );
    const [disclaimerReceipt, setDisclaimerReceipt] = useState(
        props?.lang === "nl" ? "Artikelen gekocht bij [kasticket_hoofd1] kunnen binnen [x aantal] dagen na aankoop worden omgeruild, op voorwaarde dat ze worden teruggebracht met de originele labels en zonder enige beschadiging. Om een omruiling te kunnen verwerken, dient het originele kassaticket overlegd te worden.\nLet op: Solden of afgeprijsde artikelen zijn uitgesloten van omruiling." : 
        props?.lang === "fr" ? "" :
        ""
    );
    const [disclaimerCustomerOrder, setDisclaimerCustomerOrder] = useState(
        props?.lang === "nl" ? "Wanneer een klant bij [kasticket_hoofd1] een bestelling plaatst voor een artikel dat momenteel niet op voorraad is, gelden de volgende voorwaarden:\n1.	[kasticket_hoofd1] zal alle redelijke inspanningen leveren om het bestelde artikel zo spoedig mogelijk beschikbaar te stellen. De verwachte levertijd wordt aan de klant meegedeeld, maar dient slechts als schatting. Deze kan variëren door factoren buiten onze controle.\n2.	In het geval dat de levering van het bestelde artikel aanzienlijk wordt vertraagd of onmogelijk blijkt, zal [kasticket_hoofd1] de klant hierover informeren en de optie bieden om de bestelling te annuleren met een volledige terugbetaling van eventuele vooraf betaalde bedragen.\n3.	Annuleringen van de bestelling door de klant na [aantal dagen] dagen vanaf de besteldatum kunnen onderhevig zijn aan administratieve kosten of een gedeeltelijke terugbetaling, afhankelijk van de voortgang van de bestelling.\n4.	[kasticket_hoofd1] is niet aansprakelijk voor enig ongemak of kosten die voortvloeien uit vertragingen in de levering of de onbeschikbaarheid van het product.\nDoor een bestelling te plaatsen voor een niet-beschikbaar artikel, gaat de klant akkoord met deze voorwaarden." : 
        props?.lang === "fr" ? "" :
        ""
    );
    const [disclaimerCustomerCardVoucher, setDisclaimerCustomerCardVoucher] = useState(
        props?.lang === "nl" ? "De kortingsbon is geldig voor een periode van [aantal] dagen vanaf de datum van uitgifte. Na het verstrijken van deze periode verliest de bon zijn geldigheid en kan niet meer worden gebruikt.\nDeze kortingsbon kan enkel worden gebruikt voor aankopen op artikelen zonder reeds bestaande korting bij [kasticket_hoofd1]. De bon is niet inwisselbaar voor contant geld en kan niet worden gecombineerd met andere lopende aanbiedingen of promoties. De kortingsbon is persoonlijk en niet overdraagbaar." : 
        props?.lang === "fr" ? "" :
        ""
    );
    const [disclaimerOffer, setDisclaimerOffer] = useState(
        props?.lang === "nl" ? "Deze offerte is een vrijblijvend voorstel van [kasticket_hoofd1] en is geldig voor een periode van [aantal] dagen vanaf de datum van uitgifte. Na het verstrijken van deze periode behoudt [kasticket_hoofd1] zich het recht voor om de offerte te herzien of in te trekken.\nHet presenteren van deze offerte impliceert geen verplichting voor [kasticket_hoofd1] om een overeenkomst aan te gaan. Eventuele aanpassingen of aanvullingen op deze offerte dienen schriftelijk te worden overeengekomen door beide partijen. [kasticket_hoofd1] is niet verantwoordelijk voor enige misverstanden of verwachtingen gebaseerd op de inhoud van de offerte, tenzij deze schriftelijk zijn bevestigd." : 
        props?.lang === "fr" ? "" :
        ""
    );

    // Fifth step
    const [logo, setLogo] = useState("");
    const [logoOverwrite, setLogoOverwrite] = useState(false);

    // Sixth step
    const [urlFacebook, setUrlFacebook] = useState("");
    const [urlInstagram, SetUrlInstagram] = useState("");
    const [urlTiktok, setUrlTiktok] = useState("");
    const [urlYoutube, setUrlYoutube] = useState("");
    const [urlTwitter, setUrlTwitter] = useState("");
    const [urlWebshop, setUrlWebshop] = useState("");
    const [urlWebsite, setUrlWebsite] = useState("");
    const [urlWhatsapp, setUrlWhatsapp] = useState("");
    const [urlEmail, setUrlEmail] = useState("");
    const [urlLinkedin, setUrlLinkedin] = useState("");

    // Seventh step
    const [smtpOptions, setSmtpOptions] = useState([]);
    const [smtpPreset, setSmtpPreset] = useState("0");
    const [smtpPresetId, setSmtpPresetId] = useState("1");
    const [smtpName, setSmtpName] = useState("SoftTouch_combell");
    const [smtpHost, setSmtpHost] = useState("178.208.32.88");
    const [smtpUser, setSmtpUser] = useState("**********");
    const [smtpPass, setSmtpPass] = useState("**********");
    const [smtpPort, setSmtpPort] = useState("2525");
    const [smtpSSL, setSmtpSSL] = useState(false);
    const [mailFromName, setMailFromName] = useState("");
    const [mailFromEmail, setMailFromEmail] = useState("");
    const [mailSubject, setMailSubject] = useState(
        props?.lang === "nl" ? "Bedankt voor uw [TRANSACTION_TYPE]!" : 
        props?.lang === "fr" ? "Merci pour votre [TRANSACTION_TYPE]!" :
        "Thank you for your [TRANSACTION_TYPE]!"
    );
    const [mailErrorEmail, setMailErrorEmail] = useState("");
    const [mailBccEmail, setMailBccEmail] = useState("");
    const [mailBccReservation, setMailBccReservation] = useState("");
    const [mailBccReceipt, setMailBccReceipt] = useState("");
    const [mailBccCustOrder, setMailBccCustOrder] = useState("");
    const [mailBccOffer, setMailBccOffer] = useState("");
    const [validateSmtpLoader, setValidateSmtpLoader] = useState(false);

    // Finish
    const [remark, setRemark] = useState("");

    const finishWizard = () => {
        if (window.confirm("Are you sure you are finished configuring the wizard?") === true) {
            setDisableControls(true);
            let url = SftGlobalData.baseURL_API + 'eticket&action=wizard';
            let postData = {
                sft_group: props.login.sft_group,
                sft_status: props.login.sft_status,
                sft_user_id: props.login.sft_user_id,
                login: props.login.login,
                key: props.api,


                settings: [{
                    store_id: "00",
                    pos_id: "0000",
                    smtp: {
                            preset: smtpPresetId,
                            name: smtpName,
                            host: smtpHost,
                            user: smtpUser,
                            pass: smtpPass,
                            port: smtpPort,
                            SSL: smtpSSL
                        },
                    mail_settings: {
                            sender_mail: mailFromEmail,
                            sender_name: mailFromName,
                            subject: mailSubject,
                            error_mail: mailErrorEmail,
                            bcc_mail: mailBccEmail,
                            bcc_mail_receipt: mailBccReceipt,
                            bcc_mail_reservation: mailBccReservation,
                            bcc_mail_cust_order: mailBccCustOrder,
                            bcc_mail_offer: mailBccOffer
                        },
                    layout: {
                            template_id: template,
                            design: "",
                            html: "",
                            accent_color: color,
                            accent_text_color: textColor,
                            discount_color: discountColor,
                            discount_text_color: discountTextColor,
                            font: font
                        },
                    query_settings: {
                            custom_joins: "",
                            custom_select: ""
                        },
                    links: {
                            website: urlWebsite,
                            webshop: urlWebshop,
                            facebook: urlFacebook,
                            instagram: urlInstagram,
                            tiktok: urlTiktok,
                            twitter: urlTwitter,
                            linkedIn: urlLinkedin,
                            youtube: urlYoutube, 
                            email: urlEmail,
                            whatsapp: urlWhatsapp,
                            other: ""
                        },
                    remark: remark
                }],
                eticket_header: header.replaceAll("\n", "<br/>"),
                eticket_footer: footer.replaceAll("\n", "<br/>"),
                eticket_header_reservation: headerReservation.replaceAll("\n", "<br/>"), 
                eticket_footer_reservation: footerReservation.replaceAll("\n", "<br/>"), 
                eticket_detail1: detailLine1, 
                eticket_detail2: detailLine2, 
                eticket_detail3: detailLine3, 
                eticket_use_images: detailUseImages, 
                eticket_disclaimer_advance: disclaimerAdvance.replaceAll("\n", "<br/>"),
                eticket_disclaimer_retouch: disclaimerRetouch.replaceAll("\n", "<br/>"), 
                eticket_disclaimer_voucher: disclaimerVoucher.replaceAll("\n", "<br/>"),
                eticket_disclaimer_gift_voucher: disclaimerGiftVoucher.replaceAll("\n", "<br/>"),
                eticket_disclaimer_comm_voucher: disclaimerCommercialVoucher.replaceAll("\n", "<br/>"),
                eticket_disclaimer_cc_voucher: disclaimerCustomerCardVoucher.replaceAll("\n", "<br/>"),
                eticket_disclaimer_receipt: disclaimerReceipt.replaceAll("\n", "<br/>"),
                eticket_disclaimer_reservation: disclaimerReservation.replaceAll("\n", "<br/>"),
                eticket_disclaimer_cust_order: disclaimerCustomerOrder.replaceAll("\n", "<br/>"),
                eticket_disclaimer_offer: disclaimerOffer.replaceAll("\n", "<br/>"),
                ticket_logo: logo,
                notify_support: (props?.login?.sft_status === "0")
            }
            axios.post(url, postData, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('postData', postData);
                    console.log('response', response);
                }
                navigate("/"+props.lang+"/eticket");
            });
        }
    }

    const validateSmtp = () => {
        setValidateSmtpLoader(true);
        let url = SftGlobalData.baseURL_API + 'eticket&action=validateSmtp';
        let postData = {
            sft_id: props.login.sft_status,
            sft_group: props.login.sft_group,
            login: props.login.login,
            key: props.api,
            host: smtpHost,
            user: smtpUser,
            port: smtpPort,
            pass: smtpPass,
            ssl: smtpSSL
        }
        axios.post(url, postData, {
        headers : {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
        }})
        .then(resp => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('postData', postData);
                console.log('response', resp);
            }
            props.notify(resp.data.message, 2000, "bottom-center", props.theme, 5456886); 
            setValidateSmtpLoader(false);
        });
    }

    useEffect(() => {
        props.changeScreen('Eticket');
        let url = SftGlobalData.baseURL_API + 'eticket&action=loadVars';
        let postData = {
            sft_id: props.login.sft_id,
            sft_group: props.login.sft_group,
            login: props.login.login,
            key: props.api
        }
        axios.post(url, postData, {
        headers : {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
        }})
        .then(response => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('response', response);
            }
            setProgVars(response.data);
            let url2 = SftGlobalData.baseURL_API + 'templates&action=fetchHeaders';
            let postData2 = {
                sft_id: props.login.sft_id,
                sft_group: props.login.sft_group,
                login: props.login.login,
                key: props.api,
                type: "ETICKET",
                active: 1
            }
            axios.post(url2, postData2, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
            .then(resp => {
                if(SftGlobalData.debug){
                    console.log('url', url2);
                    console.log('response', resp);
                }
                setTemplates(resp.data);
                let url3 = SftGlobalData.baseURL_API+'client&action=fetchSchedulerSMTP';
                let postData3 = {key: props.api}
                axios.post(url3, postData3, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                    .then(respo => {
                        if(SftGlobalData.debug){
                            console.log('url', url3);
                            console.log('postData', postData3);
                            console.log('response', respo);
                        }
                        setSmtpOptions(respo.data.servers);
                        let url4 = SftGlobalData.baseURL_API+'eticket&action=loadArticle';
                        let postData4 = {
                            sft_id: props.login.sft_id,
                            sft_group: props.login.sft_group,
                            login: props.login.login,
                            key: props.api
                        }
                        axios.post(url4, postData4, {
                            headers : {
                                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                            }})
                            .then(respon => {
                                if(SftGlobalData.debug){
                                    console.log('url', url4);
                                    console.log('postData', postData4);
                                    console.log('response', respon);
                                }
                                setArticle(respon.data);
                                setStep(1);
                                setLoading(false);
                            });
                        fetchMySftLogo();
                    });
            });
        });
     },[props.api]);

     const fetchArticleTags = () => {
        let arr = [];
        for(let x in article){
            if(x !== "ARTICLE_PHOTO")
                arr.push(x);
        }
        return arr.map((a) => (
            <tr key={a}  onClick={()=>{navigator.clipboard.writeText("["+a+"] ");}}>
                <td onClick={()=>{navigator.clipboard.writeText("["+a+"] ");}}>{a}</td>
                <td onClick={()=>{navigator.clipboard.writeText("["+a+"] ");}}>{article[a]}</td>
            </tr>
        ));

    }

    const fetchProgVarOptions = () => {
        let arr = [];
        for(let x in progVars){
            arr.push(x);
        }
        arr.push("CUST_FIRST_NAME");
        arr.push("CUST_LAST_NAME");
        arr.push("CUST_TITLE");
        arr.push("STORE_DESCRIPTION");
        arr.push("STORE_SHIPPING_NAME");
        arr.push("STORE_SHIPPING_COMPANY");
        arr.push("STORE_SHIPPING_STREET");
        arr.push("STORE_SHIPPING_STREET_NUMBER");
        arr.push("STORE_SHIPPING_ZIP");
        arr.push("STORE_SHIPPING_CITY");
        arr.push("STORE_SHIPPING_COUNTRY");
        arr.push("STORE_SHIPPING_EMAIL");
        arr.push("STORE_SHIPPING_PHONE");
        arr.push("TRANSACTION_ID");
        arr.push("TRANSACTION_TYPE");
        arr.push("CUST_ID");
        arr.push("DATE");
        arr.push("HOUR");
        arr.push("SEAL");
        arr.push("USER_NAME");
        arr.push("POS_DESCRIPTION");
        //console.log("progVars", progVars);
        //console.log("arr", arr);
        return arr.map((a) => (
            <MenuItem
            value={a}
            >
                {a}
            </MenuItem>
        ));

    }

    const getTagsPreview = (text) => {
        let resp = text;

        try{resp = resp.replaceAll('[Kasticket_hoofd]', progVars['Kasticket_hoofd']);}catch(exception){console.error(exception);}
        try{resp = resp.replaceAll('[Kasticket_voet]', progVars['Kasticket_voet']);}catch(exception){console.error(exception);}
        resp = resp.replaceAll('[B]', '<b>');
        resp = resp.replaceAll('[/B]', '</b>');
        resp = resp.replaceAll('[AlignCenter]', '<center>');
        resp = resp.replaceAll('[/AlignCenter]', '</center>');
        resp = resp.replaceAll('[TR]', '');
        resp = resp.replaceAll('[/TR]', '');
        resp = resp.replaceAll('[ReverseColor]', '');
        resp = resp.replaceAll('[/ReverseColor]', '');
        resp = resp.replaceAll('[CharacterWidth2]', '');
        resp = resp.replaceAll('[/CharacterWidth2]', '');
        resp = resp.replaceAll('[CharacterHeight2]', '');
        resp = resp.replaceAll('[/CharacterHeight2]', '');
        resp = resp.replaceAll('[CharacterWidthHeight2]', '');
        resp = resp.replaceAll('[/CharacterWidthHeight2]', '');
        resp = resp.replaceAll('[CharacterNormal]', '');
        resp = resp.replaceAll('[/CharacterNormal]', '');
        resp = resp.replaceAll('[CharacterBig]', '');
        resp = resp.replaceAll('[/CharacterBig]', '');
        resp = resp.replaceAll('[CharacterLarge]', '');
        resp = resp.replaceAll('[/CharacterLarge]', '');
        resp = resp.replaceAll('[AlignLeft]', '');
        resp = resp.replaceAll('[/AlignLeft]', '');
        resp = resp.replaceAll('[AlignRight]', '');
        resp = resp.replaceAll('[/AlignRight]', '');
        resp = resp.replaceAll('[Line1]', '<hr/>');
        resp = resp.replaceAll('[Line2]', '<hr/>');
        resp = resp.replaceAll('[Line3]', '<hr/>');
        resp = resp.replaceAll('[Line4]', '<hr/>');
        resp = resp.replaceAll('[Line5]', '<hr/>');
        resp = resp.replaceAll('[Line6]', '<hr/>');
        resp = resp.replaceAll('[ReceiptHeader1]', '[kasticket_hoofd1]');
        resp = resp.replaceAll('[ReceiptHeader2]', '[kasticket_hoofd2]');
        resp = resp.replaceAll('[ReceiptHeader3]', '[kasticket_hoofd3]');
        resp = resp.replaceAll('[ReceiptHeader4]', '[kasticket_hoofd4]');
        resp = resp.replaceAll('[ReceiptHeader5]', '[kasticket_hoofd5]');
        resp = resp.replaceAll('[ReceiptHeader6]', '[kasticket_hoofd6]');
        resp = resp.replaceAll('[ReceiptHeader7]', '[kasticket_hoofd7]');
        resp = resp.replaceAll('[ReceiptFooter1]', '[kasticket_voet1]');
        resp = resp.replaceAll('[ReceiptFooter2]', '[kasticket_voet2]');
        resp = resp.replaceAll('[ReceiptFooter3]', '[kasticket_voet3]');
        resp = resp.replaceAll('[ReceiptFooter4]', '[kasticket_voet4]');
        resp = resp.replaceAll('[ReceiptFooter5]', '[kasticket_voet5]');
        resp = resp.replaceAll('[ReceiptFooter6]', '[kasticket_voet6]');
        resp = resp.replaceAll('[ReceiptFooter7]', '[kasticket_voet7]');
        resp = resp.replaceAll('[ReceiptFooter8]', '[kasticket_voet8]');
        resp = resp.replaceAll('[ReceiptFooter9]', '[kasticket_voet9]');
        resp = resp.replaceAll('[ReceiptFooter10]', '[kasticket_voet10]');
        resp = resp.replaceAll('[ReceiptFooter11]', '[kasticket_voet11]');
        resp = resp.replaceAll('[ReceiptFooter12]', '[kasticket_voet10]');
        resp = resp.replaceAll('[ReceiptFooter13]', '[kasticket_voet13]');
        resp = resp.replaceAll('[ReceiptFooter14]', '[kasticket_voet14]');
        resp = resp.replaceAll('[ReceiptFooter15]', '[kasticket_voet15]');
        resp = resp.replaceAll('[ReceiptFooter16]', '[kasticket_voet16]');
        resp = resp.replaceAll('[ReceiptFooter17]', '[kasticket_voet17]');
        resp = resp.replaceAll('[ReceiptFooter18]', '[kasticket_voet18]');
        resp = resp.replaceAll('[ReceiptFooter19]', '[kasticket_voet19]');
        resp = resp.replaceAll('[ReceiptFooter20]', '[kasticket_voet20]');

        for(let x in progVars){
            //console.log('['+x+']', progVars[x]);
            resp = resp.replaceAll('['+x+']', progVars[x]);
        }
        return resp;
    }

    const getArticlePreviewLine = (text) => {
        let resp = text;

        for(let x in article){
            resp = resp.replaceAll('['+x+']', article[x]);
        }
        return resp;
    }

    const addTagToHeader = (tag) => {
        if(tag !== ""){
            setHeader(header + "[" + tag+"] ");
        }
    }

    const addTagToFooter = (tag) => {
        if(tag !== ""){
            setFooter(footer + "[" + tag+"] ");
        }
    }

    const addTagToHeaderReservation = (tag) => {
        if(tag !== ""){
            setHeaderReservation(headerReservation + "[" + tag+"] ");
        }
    }

    const addTagToFooterReservation = (tag) => {
        if(tag !== ""){
            setFooterReservation(footerReservation + "[" + tag+"] ");
        }
    }

    const attemptNext = (currentStep) => {
        if(currentStep === 1) {
            if(color !== "" && typeof color !== "undefined" && 
            textColor !== "" && typeof textColor !== "undefined"){
                if(firstStepTab === 0){
                    setFirstStepTab(1);
                } else {
                    if(discountTextColor !== "" && typeof discountTextColor !== "undefined" && 
                    discountColor !== "" && typeof discountColor !== "undefined"){
                        setStep(step+1);
                        setFirstStepTab(0);
                    } else {
                        props.notify(props.t('missing_fields'), 7500, "top-right", props.theme, 1); 
                    }
                }
            } else {
                props.notify(props.t('missing_fields'), 7500, "top-right", props.theme, 1); 
            }
        } else if(currentStep === 2) {
            if(secondStepTab === 0){
                setSecondStepTab(1);
            } else {
                setStep(step+1);
                setSecondStepTab(0);
            }
        } else if(currentStep === 4) {
            if(fourthStepTab !== 9){
                switch(fourthStepTab+1){
                    case 0:
                        setFocusTarget("retouch");
                        break;
                    case 1:
                        setFocusTarget("receipt");
                        break;
                    case 2:
                        setFocusTarget("reservation");
                        break;
                    case 3:
                        setFocusTarget("offer");
                        break;
                    case 4:
                        setFocusTarget("customerorder");
                        break;
                    case 5:
                        setFocusTarget("advance");
                        break;
                    case 6:
                        setFocusTarget("voucher");
                        break;
                    case 7:
                        setFocusTarget("giftvoucher");
                        break;
                    case 8:
                        setFocusTarget("commercialvoucher");
                        break;
                    case 9:
                        setFocusTarget("customercardvoucher");
                        break;
                }
                setFourthStepTab(fourthStepTab+1);
            } else {
                setStep(step+1);
                setFourthStepTab(0);
            }
        } else if(currentStep === 7){
            if((props.login.sft_status === "0" || (smtpHost !== "" && typeof smtpHost !== "undefined" && 
               smtpPass !== "" && typeof smtpPass !== "undefined" && 
               smtpUser !== "" && typeof smtpUser !== "undefined" && 
               smtpPort !== "" && typeof smtpPort !== "undefined")) && 
               mailFromEmail !== "" && typeof mailFromEmail !== "undefined" && 
               mailFromName !== "" && typeof mailFromName !== "undefined" && 
               mailSubject !== "" &&  typeof mailSubject !== "undefined"){
                if(SftGlobalData.validationTest(mailFromEmail, /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/)){
                    setStep(step+1);
                } else {
                    props.notify(props.t('emailHelperText'), 7500, "top-right", props.theme, 1); 
                }
                
            } else {
                props.notify(props.t('missing_fields'), 7500, "top-right", props.theme, 1); 
            }
        } else {
            setStep(step+1);
        }
    }

    const onSelectSmtpOption = (option) => {
        if(option === "-1"){
            setSmtpName("");
            setSmtpHost("");
            setSmtpUser("");
            setSmtpPass("");
            setSmtpPort("");
            setSmtpSSL(false);
            setSmtpPresetId("0");
        } else {
            setSmtpName(smtpOptions[option].smtpname);
            setSmtpHost(smtpOptions[option].smtphost);
            setSmtpUser("**********");
            setSmtpPass("**********");
            setSmtpPort(smtpOptions[option].smtpport);
            setSmtpSSL(smtpOptions[option].sslStartMode);
            setSmtpPresetId(smtpOptions[option].smtpid);
            //setMailFrom(smtpOptions[option].frommail);
        }
    }

    const fetchMySftLogo = () => {
        let urlLogo = SftGlobalData.baseURL_API+'client&action=fetchLogo';
        let postDataLogo = {key: props.api}
        axios.post(urlLogo, postDataLogo, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
            .then(responseLogo => {
                if(SftGlobalData.debug){
                    console.log('url', urlLogo);
                    console.log('postData', urlLogo);
                    console.log('response', responseLogo);
                }
                setLogo(responseLogo.data);
                setLogoOverwrite(false);
            });
    }

    const onLogoChange = (e) => {
        let files = e.target.files;
        let fileReader = new FileReader();
        fileReader.readAsDataURL(files[0]);
 
        fileReader.onload = (event) => {
            if(SftGlobalData.debug){
                console.log("logo", event.target.result);
            }
            setLogo(event.target.result);
            setLogoOverwrite(true);
        }
    }

    const onSaveLogo = async () => {
        try{
            let url = SftGlobalData.baseURL_API+'client&action=updateLogo';
            let postData = {
                key: props.api,
                logo: logo, user: props.username, sftUserId: props.userId
            }
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    props.notify(props.t('savedChanges'), 7500, "top-right", props.theme, 1); 
                    setLogoOverwrite(false);
                });
        } catch(e){
            console.error(e);
            props.notify(props.t('errorOccured'), 7500, "top-right", props.theme, 1); 
        }
    }

/*************************************************************************************
*
*                             TEMPLATE / PERSONALISATION
*
*************************************************************************************/
    const [firstStepTab, setFirstStepTab] = useState(0);
    const firstStep = () => {
        return (
            <div className='columnContainerStart'>
                {firstStepTab === 0 && <div className='box_fade-in text_center'>
                    {props.t('eticket_guide1')}<br/>
                    {props.t('eticket_guide2')}<br/>
                    {props.t('eticket_guide3')}<br/>
                </div>}
                {firstStepTab === 1 && <div className='box_fade-in text_center'>
                    {props.t('eticket_guide4')}<br/>
                    {props.t('eticket_guide5')}<br/>
                    {props.t('eticket_guide6')}<br/>
                </div>}
                <hr style={{width: '100%'}}/>
                <div className="rowContainerEvenly" style={{width: '100%'}} >
                    <Box className={props.theme + ' _100perc'}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 18 }} className={props.theme + ' container_tabs_below_subtitle'}>
                          <Tabs value={firstStepTab} onChange={(event, newValue) => {setFirstStepTab(newValue);}} className={props.theme} variant="scrollable" scrollButtons="auto">
                            <Tab label={props.t('general') + " "} {...a11yProps(0)} className={props.theme}/>
                            <Tab label={props.t('eticket_detail') + " "} {...a11yProps(1)} className={props.theme}/>                
                          </Tabs>
                      </Box>
                      <TabPanel value={firstStepTab} index={0}>
                      <div className="rowContainerEvenly" style={{width: '100%'}} >
                        <div className='margin_top_10px columnContainerBetween slide-in-blurred-left' style={{width: '25%'}}>
                            {props?.login?.sft_status !== "0" && <FormControl className={props.theme+ '_accent _100perc'}>
                              <InputLabel id="demo-simple-select-label">{props.t('template')}</InputLabel>
                              <Select
                                className={props.theme+"_accent "+props.theme+"_text _100perc"}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={template}
                                label={props.t('template')}
                                onChange={(event)=>{const {target: { value },} = event; setTemplate(value);}}
                                style={{height: 40, paddingTop: 8, paddingBottom: 8}}>
                                {templates.map((template) => (
                                    <MenuItem
                                    key={template.id}
                                    value={template.id}
                                    >
                                        {template.title}
                                    </MenuItem>
                                ))}
                                
                              </Select>
                            </FormControl>}
                            <FormControl className={props.theme+ '_accent _100perc'}>
                              <InputLabel id="demo-simple-select-label">{props.t('font')}</InputLabel>
                              <Select
                                className={props.theme+"_accent "+props.theme+"_text _100perc"}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={font}
                                label={props.t('font')}
                                onChange={(event)=>{const {target: { value },} = event; setFont(value);}}
                                style={{height: 40, paddingTop: 8, paddingBottom: 8}}>
                                <MenuItem value={"Arial"}>Arial</MenuItem>
                                <MenuItem value={"Brush Script MT"}>Brush Script MT</MenuItem>
                                <MenuItem value={"Comic Sans MS"}>Comic Sans MS</MenuItem>
                                <MenuItem value={"Courier New"}>Courier New</MenuItem>
                                <MenuItem value={"Georgia"}>Georgia</MenuItem>
                                <MenuItem value={"Garamond"}>Garamond</MenuItem>
                                <MenuItem value={"Montserrat"}>Montserrat</MenuItem>
                                <MenuItem value={"Open Sans"}>Open Sans</MenuItem>
                                <MenuItem value={"'Times New Roman'"}>Times New Roman</MenuItem>
                                <MenuItem value={"Verdana"}>Verdana</MenuItem>
                              </Select>
                            </FormControl>
                            <p style={{fontFamily: font, textAlign: 'center', backgroundColor: color, color: textColor, width: '100%', marginBottom: 1}}> <br/> {props.t('example_text')} <br/> &nbsp;</p>
                        </div>
                        <div className='margin_top_10px columnContainerBetween slide-in-blurred-top' style={{width: '25%'}}>
                            <TextField
                                minRows={1}
                                autoComplete="off" 
                                className={props.theme+ '_accent _100perc'}
                                id="accent_color"
                                label={props.t('accent_color')}
                                size={'small'}
                                sx={(props.theme==='light') ? {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.light_text
                                      },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.light_text
                                     }, borderRadius: 3, marginTop: 0} 
                                    :
                                    {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.dark_text
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.dark_text_accent
                                        }, borderRadius: 3, marginTop: 0}}
                                value={color.toUpperCase()}
                                onChange={(event)=>{setColor(event.target.value)}}
                                required
                                />  
                            <section className="resposive example margin_top_15px">
                                <HexColorPicker className="colorpicker" color={color} onChange={(event)=>{setColor(event);}} />
                            </section>
                        </div>
                        <div className='margin_top_10px columnContainerBetween slide-in-blurred-right' style={{width: '25%'}}>
                            <TextField
                                minRows={1}
                                autoComplete="off" 
                                className={props.theme+ '_accent _100perc'}
                                id="text_color"
                                label={props.t('text_color')}
                                size={'small'}
                                sx={(props.theme==='light') ? {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.light_text
                                      },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.light_text
                                     }, borderRadius: 3, marginTop: 0} 
                                    :
                                    {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.dark_text
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.dark_text_accent
                                        }, borderRadius: 3, marginTop: 0}}
                                value={textColor.toUpperCase()}
                                onChange={(event)=>{setTextColor(event.target.value)}}
                                required
                                />  
                            <section className="resposive example margin_top_15px">
                                <HexColorPicker className="colorpicker" color={textColor} onChange={(event)=>{setTextColor(event);}} />
                            </section>
                        </div>
                       </div>
                       </TabPanel>
                       <TabPanel value={firstStepTab} index={1}>
                      <div className="rowContainerEvenly" style={{width: '100%'}} >
                        <div className='margin_top_10px columnContainerBetween slide-in-blurred-left' style={{width: '25%'}}>
                            <TextField
                                minRows={1}
                                autoComplete="off" 
                                className={props.theme+ '_accent _100perc'}
                                id="accent_color_discount"
                                label={props.t('accent_color_discount')}
                                size={'small'}
                                sx={(props.theme==='light') ? {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.light_text
                                      },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.light_text
                                     }, borderRadius: 3, marginTop: 0} 
                                    :
                                    {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.dark_text
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.dark_text_accent
                                        }, borderRadius: 3, marginTop: 0}}
                                value={discountColor.toUpperCase()}
                                onChange={(event)=>{setDiscountColor(event.target.value)}}
                                required
                                />  
                            <section className="resposive example margin_top_15px">
                                <HexColorPicker className="colorpicker" color={discountColor} onChange={(event)=>{setDiscountColor(event);}} />
                            </section>
                        </div>
                        <div className='margin_top_10px columnContainerBetween slide-in-blurred-top' style={{width: '25%'}}>
                            <TextField
                                minRows={1}
                                autoComplete="off" 
                                className={props.theme+ '_accent _100perc'}
                                id="text_color_discount"
                                label={props.t('text_color_discount')}
                                size={'small'}
                                sx={(props.theme==='light') ? {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.light_text
                                      },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.light_text
                                     }, borderRadius: 3, marginTop: 0} 
                                    :
                                    {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.dark_text
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.dark_text_accent
                                        }, borderRadius: 3, marginTop: 0}}
                                value={discountTextColor.toUpperCase()}
                                onChange={(event)=>{setDiscountTextColor(event.target.value)}}
                                required
                                />  
                            <section className="resposive example margin_top_15px">
                                <HexColorPicker className="colorpicker" color={discountTextColor} onChange={(event)=>{setDiscountTextColor(event);}} />
                            </section>
                        </div>
                        <div className='margin_top_10px columnContainerBetween slide-in-blurred-right' style={{width: '25%'}}>
                            <p style={{fontFamily: font, textAlign: 'left', backgroundColor: discountColor, color: discountTextColor, width: '100%', marginTop: 3, marginBottom: 1, paddingTop: 5, paddingBottom: 5}}> &nbsp;&nbsp;&nbsp;<b>{props.t('solden')}:      € 10.00 - € 2.00 </b> &nbsp;</p>
                        </div>
                       </div>
                       </TabPanel>
                    </Box>
                </div>
            </div>
        )
    }

/*************************************************************************************
*
*                                HEADER & FOOTER
*
*************************************************************************************/
    const [secondStepTab, setSecondStepTab] = useState(0);
    const secondStep = () => {
        return (
            <div className='columnContainerStart'>
                {secondStepTab === 0 && <div className='box_fade-in text_center'>
                    {props.t('eticket_guide7')}<br/>
                    {props.t('eticket_guide8')}<br/>
                    {props.t('eticket_guide9')}
                </div>}
                {secondStepTab === 1 && <div className='box_fade-in text_center'>
                    {props.t('eticket_guide10')}<br/>
                    {props.t('eticket_guide11')}<br/>
                    <b><i>{props.t('eticket_guide12')}</i></b><br/>
                </div>}
                <hr style={{width: '100%'}}/>
                <div className='rowContainerBetween'>
                
                    <Box className={props.theme + ' _100perc'}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className={props.theme + ' container_tabs_below_subtitle'}>
                          <Tabs value={secondStepTab} onChange={(event, newValue) => {setSecondStepTab(newValue);}} className={props.theme} variant="scrollable" scrollButtons="auto">
                            <Tab label={props.t('general')+" "} {...a11yProps(0)} className={props.theme}/>
                            <Tab label={props.t('reservation')+" "} {...a11yProps(1)} className={props.theme}/>                
                          </Tabs>
                      </Box>
                      <TabPanel value={secondStepTab} index={0}>
                        <div className="_45perc slide-in-blurred-left">
                            <h4>{props.t('header')}</h4>
                            <hr/>
                            <div className="rowContainerStart">
                                <textarea className={"textareaBorderRight "+ ((props.theme==='light') ? 'light_accent_darker textarea' : 'dark_accent textarea')} id="memo" onChange={(event)=>{setHeader(event.target.value)}} name="header" value={header} style={{resize: 'none', minHeight: 245}}/>
                                <textarea className={"textareaBorderLeft "+ ((props.theme==='light') ? 'light_accent_darkest textarea' : 'dark_accent_darker textarea')} id="memo" disabled name="header_preview" value={getTagsPreview(header)} style={{resize: 'none', minHeight: 245}}/>
                            </div>
                            <div className="rowContainerBetween margin_bottom_10px" style={{alignItems: 'center'}}>
                                <FormControl className={props.theme+ '_accent _80perc'}>
                                  <InputLabel id="demo-simple-select-label">Tags</InputLabel>
                                  <Select
                                    className={props.theme+"_accent "+props.theme+"_text _100perc"}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedHeaderTag}
                                    label="Tags"
                                    onChange={(event)=>{const {target: { value },} = event; setSelectedHeaderTag(value); try{setSelectedHeaderTagValue(progVars[value]);}catch(exception){console.error(exception);}}}
                                    style={{height: 40, paddingTop: 8, paddingBottom: 8}}>
                                    {fetchProgVarOptions()}
                                  </Select>
                                </FormControl>
                                <Button type="button" className={"button_container__button_no_margin width_120px maxHeight "} onClick={()=>{addTagToHeader(selectedHeaderTag)}}>&nbsp;{props.t('add')}&nbsp;</Button>
                            </div>
                            <p>{selectedHeaderTagValue}</p>
                        </div>
                        <div className="_45perc slide-in-blurred-right">
                            <h4>{props.t('footer')}</h4>
                            <hr/>
                            <div className="rowContainerEnd">
                                <textarea className={"textareaBorderRight "+ ((props.theme==='light') ? 'light_accent_darker textarea' : 'dark_accent textarea')} id="memo" onChange={(event)=>{setFooter(event.target.value)}} name="footer" value={footer} style={{resize: 'none', minHeight: 245}}/>
                                <textarea className={"textareaBorderLeft "+ ((props.theme==='light') ? 'light_accent_darkest textarea' : 'dark_accent_darker textarea')} id="memo" disabled name="footer_preview" value={getTagsPreview(footer)} style={{resize: 'none', minHeight: 245}}/>
                            </div>
                            <div className="rowContainerBetween margin_bottom_10px" style={{alignItems: 'center'}}>
                                <FormControl className={props.theme+ '_accent _80perc'}>
                                  <InputLabel id="demo-simple-select-label">Tags</InputLabel>
                                  <Select
                                    className={props.theme+"_accent "+props.theme+"_text _100perc"}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedFooterTag}
                                    label="Tags"
                                    onChange={(event)=>{const {target: { value },} = event; setSelectedFooterTag(value);  try{setSelectedFooterTagValue(progVars[value]);}catch(exception){console.error(exception);}}}
                                    style={{height: 40, paddingTop: 8, paddingBottom: 8}}>
                                    {fetchProgVarOptions()}
                                  </Select>
                                </FormControl>
                                <Button type="button" className={"button_container__button_no_margin width_120px maxHeight "} onClick={()=>{addTagToFooter(selectedFooterTag)}}>&nbsp;{props.t('add')}&nbsp;</Button>
                            </div>
                            <p>{selectedFooterTagValue}</p>
                        </div>
                      </TabPanel>
                      <TabPanel value={secondStepTab} index={1}>
                      <div className="_45perc slide-in-blurred-left">
                            <h4>{props.t('header')} ({props.t('reservation')})</h4>
                            <hr/>
                            <div className="rowContainerStart">
                                <textarea className={"textareaBorderRight "+ ((props.theme==='light') ? 'light_accent_darker textarea' : 'dark_accent textarea')} id="memo" onChange={(event)=>{setHeaderReservation(event.target.value)}} name="headerReservation" value={headerReservation} style={{resize: 'none', minHeight: 245}}/>
                                <textarea className={"textareaBorderLeft "+ ((props.theme==='light') ? 'light_accent_darkest textarea' : 'dark_accent_darker textarea')} id="memo" disabled name="header_preview" value={getTagsPreview(headerReservation)} style={{resize: 'none', minHeight: 245}}/>
                            </div>
                            <div className="rowContainerBetween margin_bottom_10px" style={{alignItems: 'center'}}>
                                <FormControl className={props.theme+ '_accent _80perc'}>
                                  <InputLabel id="demo-simple-select-label">Tags</InputLabel>
                                  <Select
                                    className={props.theme+"_accent "+props.theme+"_text _100perc"}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedHeaderTagReservation}
                                    label="Tags"
                                    onChange={(event)=>{const {target: { value },} = event; setSelectedHeaderTagReservation(value); try{setSelectedHeaderTagValueReservation(progVars[value]);}catch(exception){console.error(exception);}}}
                                    style={{height: 40, paddingTop: 8, paddingBottom: 8}}>
                                    {fetchProgVarOptions()}
                                  </Select>
                                </FormControl>
                                <Button type="button" className={"button_container__button_no_margin width_120px maxHeight "} onClick={()=>{addTagToHeaderReservation(selectedHeaderTagReservation)}}>&nbsp;{props.t('add')}&nbsp;</Button>
                            </div>
                            <p>{selectedHeaderTagValueReservation}</p>
                        </div>
                        <div className="_45perc slide-in-blurred-right">
                            <h4>{props.t('footer')} ({props.t('reservation')})</h4>
                            <hr/>
                            <div className="rowContainerEnd">
                                <textarea className={"textareaBorderRight "+ ((props.theme==='light') ? 'light_accent_darker textarea' : 'dark_accent textarea')} id="memo" onChange={(event)=>{setFooterReservation(event.target.value)}} name="footer" value={footerReservation} style={{resize: 'none', minHeight: 245}}/>
                                <textarea className={"textareaBorderLeft "+ ((props.theme==='light') ? 'light_accent_darkest textarea' : 'dark_accent_darker textarea')} id="memo" disabled name="footer_preview" value={getTagsPreview(footerReservation)} style={{resize: 'none', minHeight: 245}}/>
                            </div>
                            <div className="rowContainerBetween margin_bottom_10px" style={{alignItems: 'center'}}>
                                <FormControl className={props.theme+ '_accent _80perc'}>
                                  <InputLabel id="demo-simple-select-label">Tags</InputLabel>
                                  <Select
                                    className={props.theme+"_accent "+props.theme+"_text _100perc"}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedFooterTagReservation}
                                    label="Tags"
                                    onChange={(event)=>{const {target: { value },} = event; setSelectedFooterTagReservation(value);  try{setSelectedFooterTagValueReservation(progVars[value]);}catch(exception){console.error(exception);}}}
                                    style={{height: 40, paddingTop: 8, paddingBottom: 8}}>
                                    {fetchProgVarOptions()}
                                  </Select>
                                </FormControl>
                                <Button type="button" className={"button_container__button_no_margin width_120px maxHeight "} onClick={()=>{addTagToFooterReservation(selectedFooterTagReservation)}}>&nbsp;{props.t('add')}&nbsp;</Button>
                            </div>
                            <p>{selectedFooterTagValueReservation}</p>
                        </div>
                      </TabPanel>
                    </Box>
                </div>
            </div>
        );
    }

/*************************************************************************************
*
*                                   DETAIL
*
*************************************************************************************/
    const thirdStep = () => {
        return (
            <div className='columnContainerStart'>
                <div className='box_fade-in text_center'>
                    {props.t('eticket_guide13')}<br/>
                    {props.t('eticket_guide14')}<br/>
                    {props.t('eticket_guide15')}
                </div>
                <hr style={{width: '100%'}}/>
                <div className='rowContainerBetween'>
                    <div className={"_50perc slide-in-blurred-left"}>
                    <TextField
                            minRows={1}
                            autoComplete="off" 
                            inputProps={{ maxLength: 255 }}
                            className={props.theme+ '_accent _100perc margin_top_10px'}
                            id="detail_line1"
                            label={props.t('detail_line')+" 1"}
                            size={'small'}
                            sx={(props.theme==='light') ? {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.light_text
                                  },
                                '& .MuiInputLabel-root': {
                                    color: Colors.light_text
                                 }, borderRadius: 3, marginTop: 0} 
                                :
                                {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.dark_text
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.dark_text_accent
                                    }, borderRadius: 3, marginTop: 0}}
                            value={detailLine1}
                            onChange={(event)=>{setDetailLine1(event.target.value)}}
                            />  
                        <TextField
                            minRows={1}
                            autoComplete="off" 
                            inputProps={{ maxLength: 255 }}
                            className={props.theme+ '_accent _100perc margin_top_10px'}
                            id="detail_line2"
                            label={props.t('detail_line')+" 2"}
                            size={'small'}
                            sx={(props.theme==='light') ? {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.light_text
                                  },
                                '& .MuiInputLabel-root': {
                                    color: Colors.light_text
                                 }, borderRadius: 3, marginTop: 0} 
                                :
                                {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.dark_text
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.dark_text_accent
                                    }, borderRadius: 3, marginTop: 0}}
                            value={detailLine2}
                            onChange={(event)=>{setDetailLine2(event.target.value)}}
                            /> 
                        <TextField
                            minRows={1}
                            autoComplete="off" 
                            inputProps={{ maxLength: 255 }}
                            className={props.theme+ '_accent _100perc margin_top_10px'}
                            id="detail_line3"
                            label={props.t('detail_line')+" 3"}
                            size={'small'}
                            sx={(props.theme==='light') ? {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.light_text
                                  },
                                '& .MuiInputLabel-root': {
                                    color: Colors.light_text
                                 }, borderRadius: 3, marginTop: 0} 
                                :
                                {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.dark_text
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.dark_text_accent
                                    }, borderRadius: 3, marginTop: 0}}
                            value={detailLine3}
                            onChange={(event)=>{setDetailLine3(event.target.value)}}
                            />  
                        <div className='rowContainerBetween alignCenterInContainer'>
                            <div style={{marginLeft: 5}}>{props.t('use_images')}</div>
                            <Switch
                              checked={detailUseImages}
                              onChange={(event)=>{setDetailUseImages(event.target.checked)}}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>

                        <div className='margin_top_15px rowContainerStart sftBorder'>
                            {detailUseImages && <div className='columnContainerCenter'><img alt="Article" src={"data:image/jpeg;base64,"+article["ARTICLE_PHOTO"]} style={{width: 100, height: 100, objectFit: 'contain'}}/></div>}
                            <div className='columnContainerCenter margin_left__10px'>
                                <div><b>{getArticlePreviewLine(detailLine1)}</b></div>
                                <div className='margin_top_5px'>{getArticlePreviewLine(detailLine2)}</div>
                                <div className='margin_top_5px'>{getArticlePreviewLine(detailLine3)}</div>
                            </div>

                        </div>
                    </div>
                    <div className={"_45perc columnContainerStart"}>
                        <div className='slide-in-blurred-right styled-table margin_top_10px'>
                            <table className='_100perc textAlignLeft '>
                                <thead>
                                    <tr className='sftBorderBottom'>
                                        <th className='sftBorderBottom textAlignLeft width_250px'>&nbsp; Tag</th>
                                        <th className='sftBorderBottom textAlignLeft'>&nbsp; {props.t('Value')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fetchArticleTags()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

/*************************************************************************************
*
*                                   DISCLAIMER
*
*************************************************************************************/
    const [fourthStepTab, setFourthStepTab] = useState(0);
    const fourthStep = () => {
        return (
            <div className='columnContainerStart'>
                <div className='box_fade-in text_center'>
                    {props.t('eticket_guide16')}<br/>
                    {props.t('eticket_guide17')}<br/>
                    {props.t('eticket_guide18')}
                </div>
                <hr style={{width: '100%'}}/>
                <div className="rowContainerEvenly" style={{width: '100%'}} >
                    <Box className={props.theme + ' _100perc'}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider'}} className={props.theme + ' container_tabs_below_subtitle'}>
                          <Tabs value={fourthStepTab} onChange={(event, newValue) => {setFourthStepTab(newValue);}} className={props.theme} variant="scrollable" scrollButtons="auto">
                            <Tab label={props.t('retouch')+" "} {...a11yProps(0)} className={props.theme}/>
                            <Tab label={props.t('receipt')+" "} {...a11yProps(1)} className={props.theme}/>   
                            <Tab label={props.t('reservation')+" "} {...a11yProps(2)} className={props.theme}/>
                            <Tab label={props.t('offer')+" "} {...a11yProps(3)} className={props.theme}/>
                            <Tab label={props.t('customer_order')+" "} {...a11yProps(4)} className={props.theme}/>
                            <Tab label={props.t('advance')+" "} {...a11yProps(5)} className={props.theme}/>
                            <Tab label={props.t('voucher')+" "} {...a11yProps(6)} className={props.theme}/>
                            <Tab label={props.t('gift_voucher')+" "} {...a11yProps(7)} className={props.theme}/>             
                            <Tab label={props.t('commercial_voucher')+" "} {...a11yProps(8)} className={props.theme}/>
                            <Tab label={props.t('cc_voucher')+" "} {...a11yProps(9)} className={props.theme}/>
                          </Tabs>
                        </Box>
                        <TabPanel value={fourthStepTab} index={0}>
                            <div className="rowContainerEvenly" style={{width: '100%'}} >
                                <div className={"_50perc"}>
                                    <TextField 
                                        multiline
                                        rows={15}
                                        autoComplete="off" 
                                        inputProps={{ maxLength: 1024}}
                                        className={props.theme+ '_accent _100perc margin_top_10px slide-in-blurred-left'}
                                        id="retouch"
                                        label={props.t('disclaimer') + " " + props.t('retouch')}
                                        size={'small'}
                                        sx={(props.theme==='light') ? {
                                            '& .MuiOutlinedInput-input': {
                                                color: Colors.light_text
                                              },
                                            '& .MuiInputLabel-root': {
                                                color: Colors.light_text
                                             }, borderRadius: 3, marginTop: 0} 
                                            :
                                            {
                                            '& .MuiOutlinedInput-input': {
                                                color: Colors.dark_text
                                                },
                                                '& .MuiInputLabel-root': {
                                                    color: Colors.dark_text_accent
                                                }, borderRadius: 3, marginTop: 0}}
                                        value={disclaimerRetouch}
                                        onChange={(event)=>{setDisclaimerRetouch(event.target.value)}}
                                        onFocus={()=>{setFocusTarget("retouch")}}
                                    />  
                                    
                                </div>
                                <div className={"_45perc columnContainerStart"}>
                                    {focusTarget !== "" && <ProgVarTable type={focusTarget} progVars={progVars} className='slide-in-blurred-right' />}
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={fourthStepTab} index={1}>
                            <div className="rowContainerEvenly" style={{width: '100%'}} >
                                <div className={"_50perc"}>
                                    <TextField 
                                            multiline
                                            rows={15}
                                            autoComplete="off" 
                                            inputProps={{ maxLength: 1024 }}
                                            className={props.theme+ '_accent _100perc margin_top_10px slide-in-blurred-left'}
                                            id="receipt"
                                            label={props.t('disclaimer') + " " + props.t('receipt')}
                                            size={'small'}
                                            sx={(props.theme==='light') ? {
                                                '& .MuiOutlinedInput-input': {
                                                    color: Colors.light_text
                                                  },
                                                '& .MuiInputLabel-root': {
                                                    color: Colors.light_text
                                                 }, borderRadius: 3, marginTop: 0} 
                                                :
                                                {
                                                '& .MuiOutlinedInput-input': {
                                                    color: Colors.dark_text
                                                    },
                                                    '& .MuiInputLabel-root': {
                                                        color: Colors.dark_text_accent
                                                    }, borderRadius: 3, marginTop: 0}}
                                            value={disclaimerReceipt}
                                            onChange={(event)=>{setDisclaimerReceipt(event.target.value)}}
                                            onFocus={()=>{setFocusTarget("receipt")}}
                                        />
                                </div>
                                <div className={"_45perc columnContainerStart"}>
                                    {focusTarget !== "" && <ProgVarTable type={focusTarget} progVars={progVars} className='slide-in-blurred-right' />}
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={fourthStepTab} index={2}>
                            <div className="rowContainerEvenly" style={{width: '100%'}} >
                                <div className={"_50perc"}>
                                    <TextField 
                                            multiline
                                            rows={15}
                                            autoComplete="off" 
                                            inputProps={{ maxLength: 1024 }}
                                            className={props.theme+ '_accent _100perc margin_top_10px slide-in-blurred-left'}
                                            id="reservation"
                                            label={props.t('disclaimer') + " " + props.t('reservation')}
                                            size={'small'}
                                            sx={(props.theme==='light') ? {
                                                '& .MuiOutlinedInput-input': {
                                                    color: Colors.light_text
                                                  },
                                                '& .MuiInputLabel-root': {
                                                    color: Colors.light_text
                                                 }, borderRadius: 3, marginTop: 0} 
                                                :
                                                {
                                                '& .MuiOutlinedInput-input': {
                                                    color: Colors.dark_text
                                                    },
                                                    '& .MuiInputLabel-root': {
                                                        color: Colors.dark_text_accent
                                                    }, borderRadius: 3, marginTop: 0}}
                                            value={disclaimerReservation}
                                            onChange={(event)=>{setDisclaimerReservation(event.target.value)}}
                                            onFocus={()=>{setFocusTarget("reservation")}}
                                        />
                                </div>
                                <div className={"_45perc columnContainerStart"}>
                                    {focusTarget !== "" && <ProgVarTable type={focusTarget} progVars={progVars} className='slide-in-blurred-right' />}
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={fourthStepTab} index={3}>
                            <div className="rowContainerEvenly" style={{width: '100%'}} >
                                <div className={"_50perc"}>
                                    <TextField 
                                            multiline
                                            rows={15}
                                            autoComplete="off" 
                                            inputProps={{ maxLength: 1024 }}
                                            className={props.theme+ '_accent _100perc margin_top_10px slide-in-blurred-left'}
                                            id="offer"
                                            label={props.t('disclaimer') + " " + props.t('offer')}
                                            size={'small'}
                                            sx={(props.theme==='light') ? {
                                                '& .MuiOutlinedInput-input': {
                                                    color: Colors.light_text
                                                  },
                                                '& .MuiInputLabel-root': {
                                                    color: Colors.light_text
                                                 }, borderRadius: 3, marginTop: 0} 
                                                :
                                                {
                                                '& .MuiOutlinedInput-input': {
                                                    color: Colors.dark_text
                                                    },
                                                    '& .MuiInputLabel-root': {
                                                        color: Colors.dark_text_accent
                                                    }, borderRadius: 3, marginTop: 0}}
                                            value={disclaimerOffer}
                                            onChange={(event)=>{setDisclaimerOffer(event.target.value)}}
                                            onFocus={()=>{setFocusTarget("offer")}}
                                        />
                                </div>
                                <div className={"_45perc columnContainerStart"}>
                                    {focusTarget !== "" && <ProgVarTable type={focusTarget} progVars={progVars} className='slide-in-blurred-right' />}
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={fourthStepTab} index={4}>
                            <div className="rowContainerEvenly" style={{width: '100%'}} >
                                <div className={"_50perc"}>
                                    <TextField 
                                            multiline
                                            rows={15}
                                            autoComplete="off" 
                                            inputProps={{ maxLength: 1024 }}
                                            className={props.theme+ '_accent _100perc margin_top_10px slide-in-blurred-left'}
                                            id="custorder"
                                            label={props.t('disclaimer') + " " + props.t('customer_order')}
                                            size={'small'}
                                            sx={(props.theme==='light') ? {
                                                '& .MuiOutlinedInput-input': {
                                                    color: Colors.light_text
                                                  },
                                                '& .MuiInputLabel-root': {
                                                    color: Colors.light_text
                                                 }, borderRadius: 3, marginTop: 0} 
                                                :
                                                {
                                                '& .MuiOutlinedInput-input': {
                                                    color: Colors.dark_text
                                                    },
                                                    '& .MuiInputLabel-root': {
                                                        color: Colors.dark_text_accent
                                                    }, borderRadius: 3, marginTop: 0}}
                                            value={disclaimerCustomerOrder}
                                            onChange={(event)=>{setDisclaimerCustomerOrder(event.target.value)}}
                                            onFocus={()=>{setFocusTarget("customerorder")}}
                                        />
                                </div>
                                <div className={"_45perc columnContainerStart"}>
                                    {focusTarget !== "" && <ProgVarTable type={focusTarget} progVars={progVars} className='slide-in-blurred-right' />}
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={fourthStepTab} index={5}>
                            <div className="rowContainerEvenly" style={{width: '100%'}} >
                                <div className={"_50perc"}>
                                    <TextField 
                                            multiline
                                            rows={15}
                                            autoComplete="off" 
                                            inputProps={{ maxLength: 1024 }}
                                            className={props.theme+ '_accent _100perc margin_top_10px slide-in-blurred-left'}
                                            id="advance"
                                            label={props.t('disclaimer') + " " + props.t('advance')}
                                            size={'small'}
                                            sx={(props.theme==='light') ? {
                                                '& .MuiOutlinedInput-input': {
                                                    color: Colors.light_text
                                                  },
                                                '& .MuiInputLabel-root': {
                                                    color: Colors.light_text
                                                 }, borderRadius: 3, marginTop: 0} 
                                                :
                                                {
                                                '& .MuiOutlinedInput-input': {
                                                    color: Colors.dark_text
                                                    },
                                                    '& .MuiInputLabel-root': {
                                                        color: Colors.dark_text_accent
                                                    }, borderRadius: 3, marginTop: 0}}
                                            value={disclaimerAdvance}
                                            onChange={(event)=>{setDisclaimerAdvance(event.target.value)}}
                                            onFocus={()=>{setFocusTarget("advance")}}
                                        />
                                </div>
                                <div className={"_45perc columnContainerStart"}>
                                    {focusTarget !== "" && <ProgVarTable type={focusTarget} progVars={progVars} className='slide-in-blurred-right' />}
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={fourthStepTab} index={6}>
                            <div className="rowContainerEvenly" style={{width: '100%'}} >
                                <div className={"_50perc"}>
                                    <TextField 
                                            multiline
                                            rows={15}
                                            autoComplete="off" 
                                            inputProps={{ maxLength: 1024 }}
                                            className={props.theme+ '_accent _100perc margin_top_10px slide-in-blurred-left'}
                                            id="voucher"
                                            label={props.t('disclaimer') + " " + props.t('voucher')}
                                            size={'small'}
                                            sx={(props.theme==='light') ? {
                                                '& .MuiOutlinedInput-input': {
                                                    color: Colors.light_text
                                                  },
                                                '& .MuiInputLabel-root': {
                                                    color: Colors.light_text
                                                 }, borderRadius: 3, marginTop: 0} 
                                                :
                                                {
                                                '& .MuiOutlinedInput-input': {
                                                    color: Colors.dark_text
                                                    },
                                                    '& .MuiInputLabel-root': {
                                                        color: Colors.dark_text_accent
                                                    }, borderRadius: 3, marginTop: 0}}
                                            value={disclaimerVoucher}
                                            onChange={(event)=>{setDisclaimerVoucher(event.target.value)}}
                                            onFocus={()=>{setFocusTarget("voucher")}}
                                        />
                                </div>
                                <div className={"_45perc columnContainerStart"}>
                                    {focusTarget !== "" && <ProgVarTable type={focusTarget} progVars={progVars} className='slide-in-blurred-right' />}
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={fourthStepTab} index={7}>
                            <div className="rowContainerEvenly" style={{width: '100%'}} >
                                <div className={"_50perc"}>
                                    <TextField 
                                            multiline
                                            rows={15}
                                            autoComplete="off" 
                                            inputProps={{ maxLength: 1024 }}
                                            className={props.theme+ '_accent _100perc margin_top_10px slide-in-blurred-left'}
                                            id="giftvoucher"
                                            label={props.t('disclaimer') + " " + props.t('gift_voucher')}
                                            size={'small'}
                                            sx={(props.theme==='light') ? {
                                                '& .MuiOutlinedInput-input': {
                                                    color: Colors.light_text
                                                  },
                                                '& .MuiInputLabel-root': {
                                                    color: Colors.light_text
                                                 }, borderRadius: 3, marginTop: 0} 
                                                :
                                                {
                                                '& .MuiOutlinedInput-input': {
                                                    color: Colors.dark_text
                                                    },
                                                    '& .MuiInputLabel-root': {
                                                        color: Colors.dark_text_accent
                                                    }, borderRadius: 3, marginTop: 0}}
                                            value={disclaimerGiftVoucher}
                                            onChange={(event)=>{setDisclaimerGiftVoucher(event.target.value)}}
                                            onFocus={()=>{setFocusTarget("giftvoucher")}}
                                        />
                                </div>
                                <div className={"_45perc columnContainerStart"}>
                                    {focusTarget !== "" && <ProgVarTable type={focusTarget} progVars={progVars} className='slide-in-blurred-right' />}
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={fourthStepTab} index={8}>
                            <div className="rowContainerEvenly" style={{width: '100%'}} >
                                <div className={"_50perc"}>
                                    <TextField 
                                            multiline
                                            rows={15}
                                            autoComplete="off" 
                                            inputProps={{ maxLength: 1024 }}
                                            className={props.theme+ '_accent _100perc margin_top_10px slide-in-blurred-left'}
                                            id="commvoucher"
                                            label={props.t('disclaimer') + " " + props.t('commercial_voucher')}
                                            size={'small'}
                                            sx={(props.theme==='light') ? {
                                                '& .MuiOutlinedInput-input': {
                                                    color: Colors.light_text
                                                  },
                                                '& .MuiInputLabel-root': {
                                                    color: Colors.light_text
                                                 }, borderRadius: 3, marginTop: 0} 
                                                :
                                                {
                                                '& .MuiOutlinedInput-input': {
                                                    color: Colors.dark_text
                                                    },
                                                    '& .MuiInputLabel-root': {
                                                        color: Colors.dark_text_accent
                                                    }, borderRadius: 3, marginTop: 0}}
                                            value={disclaimerCommercialVoucher}
                                            onChange={(event)=>{setDisclaimerCommercialVoucher(event.target.value)}}
                                            onFocus={()=>{setFocusTarget("commercialvoucher")}}
                                        />
                                </div>
                                <div className={"_45perc columnContainerStart"}>
                                    {focusTarget !== "" && <ProgVarTable type={focusTarget} progVars={progVars} className='slide-in-blurred-right'  />}
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={fourthStepTab} index={9}>
                            <div className="rowContainerEvenly" style={{width: '100%'}} >
                                <div className={"_50perc"}>
                                    <TextField 
                                        multiline
                                        rows={15}
                                        autoComplete="off" 
                                        inputProps={{ maxLength: 1024 }}
                                        className={props.theme+ '_accent _100perc margin_top_10px slide-in-blurred-left'}
                                        id="ccvoucher"
                                        label={props.t('disclaimer') + " " + props.t('cc_voucher')}
                                        size={'small'}
                                        sx={(props.theme==='light') ? {
                                            '& .MuiOutlinedInput-input': {
                                                color: Colors.light_text
                                              },
                                            '& .MuiInputLabel-root': {
                                                color: Colors.light_text
                                             }, borderRadius: 3, marginTop: 0} 
                                            :
                                            {
                                            '& .MuiOutlinedInput-input': {
                                                color: Colors.dark_text
                                                },
                                                '& .MuiInputLabel-root': {
                                                    color: Colors.dark_text_accent
                                                }, borderRadius: 3, marginTop: 0}}
                                        value={disclaimerCustomerCardVoucher}
                                        onChange={(event)=>{setDisclaimerCustomerCardVoucher(event.target.value)}}
                                        onFocus={()=>{setFocusTarget("customercardvoucher")}}
                                    />  
                                </div>
                                <div className={"_45perc columnContainerStart"}>
                                    {focusTarget !== "" && <ProgVarTable type={focusTarget} progVars={progVars} className='slide-in-blurred-right' t={props?.t}/>}
                                </div>
                            </div>
                        </TabPanel>
                    </Box>
                </div>
            </div>
        );
    }

/*************************************************************************************
*
*                                   LOGO
*
*************************************************************************************/
    const fifthStep = () => {
        return (
            <div className='rowContainerCenter'>
                <div className='_50perc slide-in-blurred-top'>
                    <div className='box_fade-in text_center'>
                        {props.t('eticket_guide19')}<br/>
                        {props.t('eticket_guide20')}
                    </div>
                    <hr/>
                    <div className='flex_center_column'>
                        <img alt="Customer logo" src={(logo?.length > 0 ? logo : SftGlobalData.noimage)} style={{width: 300, height: 100, objectFit: 'contain'}}/>
                    </div>
                    <br/>
                    <label className="text-white">{props.t('select_file')}</label>
                    <input type="file" className="form-control" name="image" onChange={onLogoChange}  accept="image/jpeg, image/png, image/jpg"/>
                    {props.login?.sft_status !== "0" && <Button className={"modal_button_container_no_margin__button margin_top_10px _100perc"} onClick={()=>{fetchMySftLogo()}}>{props.t('get_from_licence')}</Button>}
                    {props.login?.sft_status !== "0" && <Button className={"modal_button_container_no_margin__button margin_top_10px _100perc"} onClick={()=>{onSaveLogo();}} disabled={!logoOverwrite}>{props.t('save_to_licence')}</Button>}
                </div>
            </div>
        );
    }

/*************************************************************************************
*
*                                   URLS
*
*************************************************************************************/
    const sixthStep = () => {
        return (
            <div>
                <div className='box_fade-in text_center'>
                    {props.t('eticket_guide21')}<br/>
                    {props.t('eticket_guide22')}<br/>
                    <br/>
                </div>
                <hr style={{width: '100%'}}/>
                <TextField 
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc scale-up-center'}
                    id="facebook"
                    label={"Facebook"}
                    size={'small'}
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" sx={{ width: 20 }}>
                            <FontAwesomeIcon icon={faFacebook} size="lg" />
                          </InputAdornment>
                        ),
                      }}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={urlFacebook}
                    onChange={(event)=>{setUrlFacebook(event.target.value)}}
                />  
                <TextField 
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-2'}
                    id="instagram"
                    label={"Instagram"}
                    size={'small'}
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" sx={{ width: 20 }}>
                            <FontAwesomeIcon icon={faInstagram} size="lg" />
                          </InputAdornment>
                        ),
                      }}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={urlInstagram}
                    onChange={(event)=>{SetUrlInstagram(event.target.value)}}
                />  
                <TextField 
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-3'}
                    id="tiktok"
                    label={"TikTok"}
                    size={'small'}
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" sx={{ width: 20 }}>
                            <FontAwesomeIcon icon={faTiktok} size="lg" />
                          </InputAdornment>
                        ),
                      }}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={urlTiktok}
                    onChange={(event)=>{setUrlTiktok(event.target.value)}}
                />  
                <TextField 
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-3'}
                    id="twitter"
                    label={"Twitter / X"}
                    size={'small'}
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" sx={{ width: 20 }}>
                            <FontAwesomeIcon icon={faXTwitter} size="lg" />
                          </InputAdornment>
                        ),
                      }}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={urlTwitter}
                    onChange={(event)=>{setUrlTwitter(event.target.value)}}
                />  
                <TextField 
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-4'}
                    id="webshop"
                    label={"Webshop"}
                    size={'small'}
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" sx={{ width: 20 }}>
                            <FontAwesomeIcon icon={faShoppingCart} size="lg" />
                          </InputAdornment>
                        ),
                      }}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={urlWebshop}
                    onChange={(event)=>{setUrlWebshop(event.target.value)}}
                />  
                <TextField 
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-5'}
                    id="website"
                    label={"Website"}
                    size={'small'}
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" sx={{ width: 20 }}>
                            <FontAwesomeIcon icon={faGlobe} size="lg" />
                          </InputAdornment>
                        ),
                      }}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={urlWebsite}
                    onChange={(event)=>{setUrlWebsite(event.target.value)}}
                />  
                <TextField 
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-6'}
                    id="youtube"
                    label={"Youtube"}
                    size={'small'}
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" sx={{ width: 20 }}>
                            <FontAwesomeIcon icon={faYoutube} size="lg" />
                          </InputAdornment>
                        ),
                      }}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={urlYoutube}
                    onChange={(event)=>{setUrlYoutube(event.target.value)}}
                />  
                <TextField 
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-7'}
                    id="linkedin"
                    label={"LinkedIn"}
                    size={'small'}
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" sx={{ width: 20 }}>
                            <FontAwesomeIcon icon={faLinkedin} size="lg" />
                          </InputAdornment>
                        ),
                      }}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={urlLinkedin}
                    onChange={(event)=>{setUrlLinkedin(event.target.value)}}
                />  
                <TextField 
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-8'}
                    id="email"
                    label={"Email"}
                    size={'small'}
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" sx={{ width: 20 }}>
                            <FontAwesomeIcon icon={faEnvelope} size="lg" />
                          </InputAdornment>
                        ),
                      }}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={urlEmail}
                    onChange={(event)=>{setUrlEmail(event.target.value)}}
                />  
                <TextField 
                    minRows={1}
                    autoComplete="off" 
                    className={props.theme+ '_accent _100perc margin_top_10px scale-up-center-9'}
                    id="whatsapp"
                    label={"WhatsApp"}
                    size={'small'}
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" sx={{ width: 20 }}>
                            <FontAwesomeIcon icon={faWhatsapp} size="lg" />
                          </InputAdornment>
                        ),
                      }}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text_accent
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={urlWhatsapp}
                    onChange={(event)=>{setUrlWhatsapp(event.target.value)}}
                />  
            </div>
        );
    }
    
/*************************************************************************************
*
*                                   SMTP / MAIL
*
*************************************************************************************/
    const seventhStep = () => {
        return (
            <div className="rowContainerEvenly" style={{width: '100%'}} >
                {props.login?.sft_status === "0" && <div className='margin_top_10px slide-in-blurred-left columnContainerCenter' style={{width: '45%'}}>
                    <p>
                        <b>{props.t('eticket_guide23')}</b> <br/>
                        {props.t('eticket_guide24')}<br/>
                        {props.t('eticket_guide25')}<br/>
                        {props.t('eticket_guide26')}<br/>
                        {props.t('eticket_guide27')}<br/>
                        {props.t('eticket_guide28')}<br/>
                    </p>
                </div>}
                {props.login?.sft_status !== "0" && <div className='margin_top_10px slide-in-blurred-left' style={{width: '45%'}}>
                    <h4>SMTP {props.t('settings')}</h4>
                    <hr/>
                    <FormControl className={props.theme+ '_accent _100perc'}>
                      <InputLabel id="demo-simple-select-label">Preset</InputLabel>
                      <Select
                        className={props.theme+"_accent "+props.theme+"_text _100perc"}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={smtpPreset}
                        label="SMTP preset"
                        onChange={(event)=>{const {target: { value },} = event; setSmtpPreset(value); onSelectSmtpOption(value);}}
                        style={{height: 40, paddingTop: 8, paddingBottom: 8}}>
                            <MenuItem value={"-1"}>---</MenuItem>
                            {smtpOptions.map((row, index) => (
                                <MenuItem
                                key={index}
                                value={index}
                                >
                                    {row.smtpid} - {row.smtpname}
                                </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                    <TextField
                        disabled={smtpPreset!=="-1"}
                        minRows={1}
                        autoComplete="off" 
                        className={props.theme+ '_accent _100perc margin_top_10px'}
                        id="smtp_name"
                        label={"SMTP name"}
                        size={'small'}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                              },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text
                             }, borderRadius: 3, marginTop: 0} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }, borderRadius: 3, marginTop: 0}}
                        value={smtpName}
                        onChange={(event)=>{setSmtpName(event.target.value)}}
                        />  
                    <TextField
                        disabled={smtpPreset!=="-1"}
                        minRows={1}
                        autoComplete="off" 
                        className={props.theme+ '_accent _100perc margin_top_10px'}
                        id="smtp_host"
                        label={"SMTP host"}
                        size={'small'}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                              },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text
                             }, borderRadius: 3, marginTop: 0} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }, borderRadius: 3, marginTop: 0}}
                        value={smtpHost}
                        onChange={(event)=>{setSmtpHost(event.target.value)}}
                        required
                        />  
                    <TextField
                        disabled={smtpPreset!=="-1"}
                        minRows={1}
                        autoComplete="off" 
                        className={props.theme+ '_accent _100perc margin_top_10px'}
                        id="smtp_user"
                        label={"SMTP username"}
                        size={'small'}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                              },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text
                             }, borderRadius: 3, marginTop: 0} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }, borderRadius: 3, marginTop: 0}}
                        value={smtpUser}
                        onChange={(event)=>{setSmtpUser(event.target.value)}}
                        required
                        /> 
                    <TextField
                        disabled={smtpPreset!=="-1"}
                        minRows={1}
                        autoComplete="off" 
                        className={props.theme+ '_accent _100perc margin_top_10px'}
                        id="smtp_pass"
                        label={"SMTP password"}
                        size={'small'}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                              },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text
                             }, borderRadius: 3, marginTop: 0} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }, borderRadius: 3, marginTop: 0}}
                        value={smtpPass}
                        onChange={(event)=>{setSmtpPass(event.target.value)}}
                        required
                        />  
                    <TextField
                        disabled={smtpPreset!=="-1"}
                        minRows={1}
                        autoComplete="off" 
                        className={props.theme+ '_accent _100perc margin_top_10px'}
                        id="smtp_port"
                        label={"SMTP port"}
                        size={'small'}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                              },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text
                             }, borderRadius: 3, marginTop: 0} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }, borderRadius: 3, marginTop: 0}}
                        value={smtpPort}
                        onChange={(event)=>{setSmtpPort(event.target.value)}}
                        required
                        />  
                    <div className='rowContainerBetween alignCenterInContainer'>
                        <div style={{marginLeft: 5}}>SSL</div>
                        <Switch
                          disabled={smtpPreset!=="-1"}
                          checked={smtpSSL}
                          onChange={(event)=>{setSmtpSSL(event.target.checked)}}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </div>
                    {smtpPreset==="-1" && <LoadingButton 
                    loading={validateSmtpLoader}
                    type="button" 
                    className='login__button _100perc'
                    size="large"
                    onClick={()=>{validateSmtp()}}>&nbsp;Test connection&nbsp;</LoadingButton>}
                </div>}
                <div className='margin_top_10px slide-in-blurred-right' style={{width: '45%'}}>
                    <h4>{props.t('mail_settings')}</h4>
                    <hr/>
                    <TextField
                        minRows={1}
                        autoComplete="off" 
                        className={props.theme+ '_accent _100perc'}
                        id="mailFromName"
                        label={props.t('Sender_name')}
                        size={'small'}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                              },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text
                             }, borderRadius: 3, marginTop: 0} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }, borderRadius: 3, marginTop: 0}}
                        value={mailFromName}
                        onChange={(event)=>{setMailFromName(event.target.value)}}
                        required
                        />  
                    <TextField
                        minRows={1}
                        autoComplete="off" 
                        className={props.theme+ '_accent _100perc margin_top_10px'}
                        id="mailFromEmail"
                        label={props.t('Sender_email')}
                        size={'small'}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                              },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text
                             }, borderRadius: 3, marginTop: 0} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }, borderRadius: 3, marginTop: 0}}
                        value={mailFromEmail}
                        onChange={(event)=>{setMailFromEmail(event.target.value)}}
                        required
                        />  
                    <TextField
                        minRows={1}
                        autoComplete="off" 
                        className={props.theme+ '_accent _100perc margin_top_10px'}
                        id="mailSubject"
                        label={props.t('Subject')}
                        size={'small'}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                              },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text
                             }, borderRadius: 3, marginTop: 0} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }, borderRadius: 3, marginTop: 0}}
                        value={mailSubject}
                        onChange={(event)=>{setMailSubject(event.target.value)}}
                        required
                        />  
                    <TextField
                        minRows={1}
                        autoComplete="off" 
                        className={props.theme+ '_accent _100perc margin_top_10px'}
                        id="mailError"
                        label={props.t('Error_email')}
                        size={'small'}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                              },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text
                             }, borderRadius: 3, marginTop: 0} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }, borderRadius: 3, marginTop: 0}}
                        value={mailErrorEmail}
                        onChange={(event)=>{setMailErrorEmail(event.target.value)}}
                        />  
                    <TextField
                        minRows={1}
                        autoComplete="off" 
                        className={props.theme+ '_accent _100perc margin_top_10px'}
                        id="mailbcc"
                        label={"BCC ("+props.t('general')+")"}
                        size={'small'}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                              },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text
                             }, borderRadius: 3, marginTop: 0} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }, borderRadius: 3, marginTop: 0}}
                        value={mailBccEmail}
                        onChange={(event)=>{setMailBccEmail(event.target.value)}}
                        />  
                    <TextField
                        minRows={1}
                        autoComplete="off" 
                        className={props.theme+ '_accent _100perc margin_top_10px'}
                        id="mailbccreceipt"
                        label={"BCC ("+props.t('receipt')+")"}
                        size={'small'}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                              },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text
                             }, borderRadius: 3, marginTop: 0} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }, borderRadius: 3, marginTop: 0}}
                        value={mailBccReceipt}
                        onChange={(event)=>{setMailBccReceipt(event.target.value)}}
                        />  
                    <TextField
                        minRows={1}
                        autoComplete="off" 
                        className={props.theme+ '_accent _100perc margin_top_10px'}
                        id="mailbccreservation"
                        label={"BCC ("+props.t('reservation')+")"}
                        size={'small'}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                              },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text
                             }, borderRadius: 3, marginTop: 0} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }, borderRadius: 3, marginTop: 0}}
                        value={mailBccReservation}
                        onChange={(event)=>{setMailBccReservation(event.target.value)}}
                        />  
                    <TextField
                        minRows={1}
                        autoComplete="off" 
                        className={props.theme+ '_accent _100perc margin_top_10px'}
                        id="mailbcccustorder"
                        label={"BCC ("+props.t('customer_order')+")"}
                        size={'small'}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                              },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text
                             }, borderRadius: 3, marginTop: 0} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }, borderRadius: 3, marginTop: 0}}
                        value={mailBccCustOrder}
                        onChange={(event)=>{setMailBccCustOrder(event.target.value)}}
                        />  
                    <TextField
                        minRows={1}
                        autoComplete="off" 
                        className={props.theme+ '_accent _100perc margin_top_10px'}
                        id="mailbccoffer"
                        label={"BCC ("+props.t('offer')+")"}
                        size={'small'}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                              },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text
                             }, borderRadius: 3, marginTop: 0} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }, borderRadius: 3, marginTop: 0}}
                        value={mailBccOffer}
                        onChange={(event)=>{setMailBccOffer(event.target.value)}}
                        />  
                </div>
            </div>
        );
    }

/*************************************************************************************
*
*                                   PROGVAR
*
*************************************************************************************/
    const eightStep = () => {
        return (
            <div className='rowContainerCenter alignCenterInContainer'>
                TODO: lijstje kasvars tonen wanneer relevant.
            </div>
        );
        //<a href="https://imgflip.com/i/821na9"><img src="https://i.imgflip.com/821na9.jpg" title="made at imgflip.com"/></a>
    }

/*************************************************************************************
*
*                                   CONFIRMATION
*
*************************************************************************************/
    const ninthStep = () => {
        return (
            <div className='columnContainerCenter alignCenterInContainer'>
                {props.login.sft_status === "0" && <div className="_100perc">
                    <p className='margin_bottom_10px text_center'>{props.t('eticket_guide29')}</p>
                    <TextField 
                        multiline
                        minRows={1}
                        rows={3}
                        maxRows={10}
                        autoComplete="off" 
                        className={props.theme+ '_accent _100perc margin_top_10px margin_bottom_10px box_fade-in'}
                        id="remark"
                        label={props.t('remark')}
                        size={'small'}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                              },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text
                             }, borderRadius: 3, marginTop: 0} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }, borderRadius: 3, marginTop: 0}}
                        value={remark}
                        onChange={(event)=>{setRemark(event.target.value)}}
                    />  
                    <br/>
                </div>}
                <Button disabled={disableControls} type="button" className="button_container__button width_150px" onClick={()=>{finishWizard();}} >&nbsp; FINISH &nbsp;</Button>
            </div>
        );
    }


    return (
        <div className={(props.screenWidth) < 600 ? 'not_expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme : 'expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme}>
            <div className={"noBottomMargin container_list__title " + props.theme} style={{flex: 1, overflow: 'hidden', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', alignContent: 'center'}}>
                {<img className='scale-up-center' src={WizardIcon} width="40px" alt={"Wizard"} style={{ float: 'right'}} />}
                <h2 style={{width: '100%', marginLeft: 40}}>
                    {props.t('eticket')} {props?.login?.sft_status === "0" ? props.t('request') : ""} 
                    &nbsp;-&nbsp;
                    {step === 1 && props.t('colors')}
                    {step === 2 && props.t('headerandfooter')}
                    {step === 3 && props.t('eticket_detail')}
                    {step === 4 && props.t('disclaimer')}
                    {step === 5 && props.t('eticket_logo')}
                    {step === 6 && props.t('urls')}
                    {step === 7 && props.t('eticket_email')}
                    {step === 8 && props.t('eticket_finish')}
                </h2>
            </div>
            {loading && <div className={"container_list__title " + props.theme} style={{display:"flex", justifyContent: "space-evenly", marginTop: 5, border: 1}}>
                <TailSpin 
                    height="40"
                    width="40"
                    color={Colors.sft_blue}
                    ariaLabel='loading'
                />
            </div>}

            {!loading && (
            <div style={{minHeight: 675, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                <div className='columnContainerBetween'>
                    <br/>
                    <div className={'rowContainerBetween'}>
                        {step===1 &&<p className=" width_150px"></p>}
                        {(step !== 1 && step !== 0) && <Button disabled={disableControls} type="button" className="button_container__button width_150px" onClick={()=>{setStep(step-1);}} >&nbsp;{props.t('back')}&nbsp;</Button>}
                        <div style={{flex:1, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                            <nav className="form-steps">
        						<div className="form-steps__item form-steps__item--active">
        							<div className="form-steps__item-content">
        								<span className="form-steps__item-icon">1</span>
        								<span className="form-steps__item-text">Template</span>
        							</div>

        						</div>
        						<div className={"form-steps__item" + (step>=2 ? " form-steps__item--active" : "")}>
        							<div className="form-steps__item-content">
        								<span className="form-steps__item-icon">2</span>
        								<span className="form-steps__item-line"></span>
        								<span className="form-steps__item-text">Header & Footer</span>
        							</div>
        						</div>
        						<div className={"form-steps__item" + (step>=3 ? " form-steps__item--active" : "")}>
        							<div className="form-steps__item-content">
        								<span className="form-steps__item-icon">3</span>
        								<span className="form-steps__item-line"></span>
        								<span className="form-steps__item-text">Detail</span>
        							</div>
        						</div>
        						<div className={"form-steps__item" + (step>=4 ? " form-steps__item--active" : "")}>
        							<div className="form-steps__item-content">
        								<span className="form-steps__item-icon">4</span>
        								<span className="form-steps__item-line"></span>
        								<span className="form-steps__item-text">Disclaimer</span>
        							</div>
        						</div>
                                <div className={"form-steps__item" + (step>=5 ? " form-steps__item--active" : "")}>
        							<div className="form-steps__item-content">
        								<span className="form-steps__item-icon">5</span>
        								<span className="form-steps__item-line"></span>
        								<span className="form-steps__item-text">Logo</span>
        							</div>
        						</div>
                                <div className={"form-steps__item" + (step>=6 ? " form-steps__item--active" : "")}>
        							<div className="form-steps__item-content">
        								<span className="form-steps__item-icon">6</span>
        								<span className="form-steps__item-line"></span>
        								<span className="form-steps__item-text">URLs</span>
        							</div>
        						</div>
                                <div className={"form-steps__item" + (step>=7 ? " form-steps__item--active" : "")}>
        							<div className="form-steps__item-content">
        								<span className="form-steps__item-icon">7</span>
        								<span className="form-steps__item-line"></span>
        								<span className="form-steps__item-text">Email</span>
        							</div>
        						</div>
                                {/*<div className={"form-steps__item" + (step>=8 ? " form-steps__item--active" : "")}>
        							<div className="form-steps__item-content">
        								<span className="form-steps__item-icon">8</span>
        								<span className="form-steps__item-line"></span>
        								<span className="form-steps__item-text">Prog. Var.</span>
        							</div>
                                </div>*/}
                                <div className={"form-steps__item" + (step>=8 ? " form-steps__item--active" : "")}>
        							<div className="form-steps__item-content">
        								<span className="form-steps__item-icon">8</span>
        								<span className="form-steps__item-line"></span>
        								<span className="form-steps__item-text">Finish</span>
        							</div>
        						</div>
        					</nav>
                        </div>
                        
                        {/*<p><b>Step {step}</b></p>*/}
                        {(step !== 8) && <Button disabled={disableControls} type="button" className={"button_container__button width_150px "} onClick={()=>{attemptNext(step);}}>&nbsp;{props.t('next')}&nbsp;</Button>}
                        {step===8 &&<p className=" width_150px"></p>}
                    </div>
                    <hr style={{width: '100%'}}/>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: 1}}>
                    {step === 1 && firstStep()}
                    {step === 2 && secondStep()}
                    {step === 3 && thirdStep()}
                    {step === 4 && fourthStep()}
                    {step === 5 && fifthStep()}
                    {step === 6 && sixthStep()}
                    {step === 7 && seventhStep()}
                    {step === 8 && ninthStep()}
                    {/*step === 9 && ninthStep()*/}
                </div>
                
                

            </div>)}
            
        </div>
    );
}

export default EticketWizardScreen;
