import React, { useState, useEffect }from 'react';
import { TailSpin } from  'react-loader-spinner'
import axios from 'axios';
import SftGlobalData from '../../library/SftGlobalData';
import Colors from '../../library/Colors';
import Button from '@mui/material/Button';
import { Input } from '@mui/material';

import 'react-toastify/dist/ReactToastify.css';



const LicenceSchedulerForm = (props) =>  {
    const [loading, setLoading] = useState(true);
    const [smtpOptions, setSmtpOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(-1);
    const [name, setName] = useState("");
    const [host, setHost] = useState("");
    const [from, setFrom] = useState("");
    const [SSL, setSSL] = useState(false);


    useEffect (()=>{
        try{
            let url = SftGlobalData.baseURL_API+'client&action=fetchSchedulerSMTP';
            let postData = {key: props.api}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    setSmtpOptions(response.data.servers);
                    setLoading(false);
                });
        } catch(e){
            console.error(e);
        }
    }, [props.api]);

    const onSelectOption = (option) => {
        setSelectedOption(option);
        if(option === "-1"){
            setName("");
            setHost("");
            setFrom("");
            setSSL(false);
        } else {
            setName(smtpOptions[option].smtpname);
            setHost(smtpOptions[option].smtphost);
            setFrom(smtpOptions[option].frommail);
            setSSL(smtpOptions[option].sslStartMode);
        }
    }

    const onSave = async () => {
        try{
            let url = SftGlobalData.baseURL_API+'client&action=updateSchedulerSMTP';
            let postData = {key: props.api, id: smtpOptions[selectedOption].smtpid, user: props.username, sftUserId: props.userId}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    props.notify(props.t('savedChanges'), 7500, "top-right", props.theme, 1); 
                });
        } catch(e){
            console.error(e);
            props.notify(props.t('errorOccured'), 7500, "top-right", props.theme, 1); 
        }
    }


    return (
        <div className={props.theme + " _50perc"}>
            {loading && <div style={{display:"flex", justifyContent: "space-evenly", marginTop: 5}}>
                <TailSpin 
                    height="40"
                    width="40"
                    color={Colors.sft_blue}
                    ariaLabel='loading'
                />
            </div>}
            {!loading && <div>
                <select className={props.theme + " _100perc"} value={selectedOption} onChange={(e)=>{onSelectOption(e.target.value)}}>
                    <option value={-1}>---</option>
                    {smtpOptions.map((row, index)=>{
                        if(selectedOption === -1 && row.selected){
                            setSelectedOption(index);
                            setName(row.smtpname);
                            setHost(row.smtphost);
                            setFrom(row.frommail);
                            setSSL(row.sslStartMode);
                        }
                        return <option key={index} value={index}> {row.smtpname}</option>
                    })}
                </select>

                <Input className={props.theme + " _100perc margin_top_10px"} placeholder='Name' value={name} onChange={(e)=>{setName(e.target.value);}} disabled={true}/> <br/>
                <Input className={props.theme + " _100perc margin_top_10px"} placeholder='Host' value={host} onChange={(e)=>{setHost(e.target.value);}} disabled={true}/> <br/>
                <Input className={props.theme + " _100perc margin_top_10px"} placeholder='From' value={from} onChange={(e)=>{setFrom(e.target.value);}} disabled={true}/> <br/>
                
                
                <div className={props.theme + " _100perc"} style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center", paddingLeft: 10}}>
                    <p><b>SSL</b></p>
                    <label className="switch align_right">
                        <input type="checkbox" checked={SSL} onChange={() => {setSSL(!SSL);}} disabled={true}/>
                        <span className={"slider slider_" + props.theme + " round"}></span>
                    </label>
                </div>
                <Button className={"modal_button_container_no_margin__button margin_top_15px _100perc"} onClick={()=>{onSave();}}>{props.t('save')}</Button>
            </div>}
        </div>
    );
}

export default LicenceSchedulerForm;
