import React, { useState }from 'react';
import './logging.css';
import axios from 'axios';
import SftGlobalData from '../../library/SftGlobalData';
import Colors from '../../library/Colors';
import { Button, TextField } from '@mui/material';

const LoggingAddDeliveryAddressModal = (props) =>  {
    // Address
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [zip, setZip] = useState("");
    const [country, setCountry] = useState("BE");
    const [tel, setTel] = useState("");
    const [email, setEmail] = useState("");


    const saveAddress = () => {
        axios.post(SftGlobalData.baseURL_API + 'logging&action=addDeliveryAddress', 
            {
                sft_id: props.customer,
                name: name.replaceAll("\\", "\\\\").replaceAll("'", "\\'"),
                address: address.replaceAll("\\", "\\\\").replaceAll("'", "\\'"),
                city: city.replaceAll("\\", "\\\\").replaceAll("'", "\\'"),
                zip: zip.replaceAll("\\", "\\\\").replaceAll("'", "\\'"),
                country: country.replaceAll("\\", "\\\\").replaceAll("'", "\\'"),
                phone: tel.replaceAll("\\", "\\\\").replaceAll("'", "\\'"),
                email: email.replaceAll("\\", "\\\\").replaceAll("'", "\\'"), 
                sft: props.login?.sft_status
            },{
                headers : {'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'}
            })
        .then(response => {
            if(SftGlobalData.debug){
                console.log('POST', SftGlobalData.baseURL_API + 'logging&action=addDeliveryAddress');
                console.log("PostData", {
                    sft_id: props.customer,
                    name: name.replaceAll("\\", "\\\\").replaceAll("'", "\\'"),
                    address: address.replaceAll("\\", "\\\\").replaceAll("'", "\\'"),
                    city: city.replaceAll("\\", "\\\\").replaceAll("'", "\\'"),
                    zip: zip.replaceAll("\\", "\\\\").replaceAll("'", "\\'"),
                    country: country.replaceAll("\\", "\\\\").replaceAll("'", "\\'"),
                    phone: tel.replaceAll("\\", "\\\\").replaceAll("'", "\\'"),
                    email: email.replaceAll("\\", "\\\\").replaceAll("'", "\\'"), 
                    sft: props.login?.sft_status
                });
                console.log('response', response);
            }
            props.refresh();
        });
    }
    

    return (
        <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 410}}>
            <div style={{textAlign: 'left'}}>
                { 
                    // Address info
                }
                <TextField
                    autoComplete="off" 
                    className={props.theme+ '_accent log__100_perc margin_bottom_10px '}
                    id="name"
                    label={props.t('name')}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={name}
                    onChange={(event)=>{setName(event.target.value)}}
                    />
                <TextField
                    autoComplete="off" 
                    className={props.theme+ '_accent log__100_perc margin_bottom_10px '}
                    id="address"
                    label={props.t('address')}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={address}
                    onChange={(event)=>{setAddress(event.target.value)}}
                    />
                <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
                    <TextField
                        autoComplete="off" 
                        className={props.theme+ '_accent log__100_perc margin_bottom_10px margin_right__5px'}
                        id="zip"
                        label={props.t('zip')}
                        size={'small'}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                              },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text
                             }, borderRadius: 3, marginTop: 0} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }, borderRadius: 3, marginTop: 0}}
                        value={zip}
                        onChange={(event)=>{setZip(event.target.value)}}
                        />

                    <TextField
                        autoComplete="off" 
                        className={props.theme+ '_accent log__100_perc margin_bottom_10px '}
                        id="city"
                        label={props.t('city')}
                        size={'small'}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                              },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text
                             }, borderRadius: 3, marginTop: 0} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }, borderRadius: 3, marginTop: 0}}
                        value={city}
                        onChange={(event)=>{setCity(event.target.value)}}
                        />
                </div>

                <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
                    <TextField
                        autoComplete="off" 
                        className={props.theme+ '_accent log__100_perc margin_bottom_10px margin_right__5px'}
                        id="country"
                        label={props.t('country')}
                        size={'small'}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                              },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text
                             }, borderRadius: 3, marginTop: 0} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }, borderRadius: 3, marginTop: 0}}
                        value={country}
                        onChange={(event)=>{setCountry(event.target.value)}}
                        />

                    <TextField
                        autoComplete="off" 
                        className={props.theme+ '_accent log__100_perc margin_bottom_10px '}
                        id="tel"
                        label={props.t('phone')}
                        size={'small'}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                              },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text
                             }, borderRadius: 3, marginTop: 0} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }, borderRadius: 3, marginTop: 0}}
                        value={tel}
                        onChange={(event)=>{setTel(event.target.value)}}
                        />
                </div>
                
                <TextField
                    autoComplete="off" 
                    className={props.theme+ '_accent log__100_perc margin_bottom_10px '}
                    id="email"
                    label={props.t('email')}
                    size={'small'}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}
                    value={email}
                    onChange={(event)=>{setEmail(event.target.value)}}
                    />
            </div>
            <div>
                <Button 
                    variant="outlined" 
                    sx={{ borderRadius: 3, border: '2px solid '+Colors.sft_blue, color: Colors.sft_blue, width: '100%'}}
                    className=" " 
                    onClick={()=>{saveAddress()}}>{props.t('save')}</Button>
            </div>
        </div>
    );
}

export default LoggingAddDeliveryAddressModal;
