import { Box, Switch, Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ScheduleForm from '../components/ScheduleForm';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ paddingTop: 2}}>
            <div className='rowContainerBetween'>{children}</div>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const InformationScreen = (props) =>  {  
    const [holiday, setHoliday] = useState(props.holidays);
    const [tab, setTab] = useState(0);
    const months = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];

    useEffect(() => {
        props.changeScreen('logging');
        
      }, [props.api]);

    //console.log("props", props);

    return (
        <div className={'_100perc log__main_container log__padding_sidebar'}>
            <div className={' ' + props.theme + ' container_content_' + props.theme + ' box_fade-in margin_top_50px'} 
                style={{width: "98%",
                        borderRadius: 6,
                        marginTop: 20,
                        marginLeft: 10,
                        marginRight: 10,
                        paddingBottom: 10,
                        paddingLeft: 10,
                        paddingRight: 10}}
            >
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}>
                    
                    {
                        // --------------------------------------------------------------------
                        // TITLE / HEADER
                        // --------------------------------------------------------------------
                    }
                    <div className={props.theme + " _100perc "}>
                        <div className="container_list__title ">
                            <h2 className={props.theme} type="text" id="logging" name="logging">User information</h2>
                        </div>
                        <hr />
                    </div>
                </div>
                <div className='columnContainerCenter' style={{alignItems: 'center'}}>
                    <div className='_50perc rowContainerBetween width_max_500 margin_bottom_10px'>
                        <div className='bold'>Login:</div>
                        <div>{props.login.login}</div>
                    </div>
                    <div className='_50perc rowContainerBetween width_max_500 margin_bottom_10px'>
                        <div className='bold'>First name:</div>
                        <div>{props.login.first_name}</div>
                    </div>
                    <div className='_50perc rowContainerBetween width_max_500 margin_bottom_10px'>
                        <div className='bold'>Last name:</div>
                        <div>{props.login.last_name}</div>
                    </div>
                    <div className='_50perc rowContainerBetween width_max_500 margin_bottom_10px'>
                        <div className='bold'>SFT User ID:</div>
                        <div>{props.login.sft_user_id}</div>
                    </div>
                    <div className='_50perc rowContainerBetween width_max_500 margin_bottom_10px'>
                        <div className='bold'>SFT permissions:</div>
                        <div>{props.login.sft_group}</div>
                    </div>
                    <div className='_50perc rowContainerBetween width_max_500 margin_bottom_10px'>
                        <div className='bold'>SFT participation:</div>
                        <div>{props.login.sft_status === "1" ? "ALL" : props.login.sft_status === "2" ? "VLAANDEREN" : " WALLONIE"}</div>
                    </div>
                    <div className='_50perc rowContainerBetween width_max_500 margin_bottom_10px'>
                        <div className='bold'></div>
                        <div></div>
                    </div>
                    <div className='_50perc rowContainerBetween width_max_500 margin_bottom_10px'>
                        <div className='bold'>Holidays enabled?</div>
                        <div>
                            <Switch
                              checked={holiday}
                              onChange={()=>{setHoliday(!holiday); props.toggleHolidays();}}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>
                    </div>
                </div>



                {props.login?.sft_group === "SUPER" && <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}>
                    
                    {
                        // --------------------------------------------------------------------
                        // TITLE / HEADER EXTRA
                        // --------------------------------------------------------------------
                    }
                    <div className={props.theme + " _100perc "}>
                        <hr />
                        <div className="container_list__title ">
                            <h2 className={props.theme} type="text" id="logging" name="logging">Schedule</h2>
                        </div>
                        <hr />
                    </div>
                </div>}
                <div>
                    <Box className={props.theme + ' _100perc'}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider'}} className={props.theme + ' container_tabs_below_subtitle'}>
                            <Tabs value={tab} onChange={(event, newValue) => {setTab(newValue);}} className={props.theme} variant="scrollable" scrollButtons="auto">
                                
                                <Tab label={"Week "} {...a11yProps(0)} className={props.theme}/>
                                {props.login?.sft_group === "SUPER" && <Tab label={"Month "} {...a11yProps(1)} className={props.theme}/>}
                                {props.login?.sft_group === "SUPER" && <Tab label={"DEFAULT "} {...a11yProps(2)} className={props.theme}/>}
                            </Tabs>
                        </Box>
                        
                        <TabPanel value={tab} index={0}>
                            <div className='_100perc'>
                                <ScheduleForm disabled={props.login?.sft_group !== "SUPER"} api={props.api} login={props.login} mode="week" notify={(text, time, position, id) => {props.notify(text, time, position, props?.theme, id);}} t={props.t} theme={props.theme}/>
                            </div>
                        </TabPanel>
                        {props.login?.sft_group === "SUPER" && <TabPanel value={tab} index={1}>
                            <div className='_100perc'>
                                <ScheduleForm disabled={false} api={props.api} login={props.login} mode="month" notify={(text, time, position, id) => {props.notify(text, time, position, props?.theme, id);}} t={props.t} theme={props.theme}/>
                            </div>
                        </TabPanel>}
                        {props.login?.sft_group === "SUPER" && <TabPanel value={tab} index={2}>
                            <div className='_100perc'>
                                <ScheduleForm disabled={props.login?.sft_group !== "SUPER"} api={props.api} login={props.login} mode="week" notify={(text, time, position, id) => {props.notify(text, time, position, props?.theme, id);}} t={props.t} theme={props.theme} default={true} />
                            </div>
                        </TabPanel>}
                        
                    </Box>
                </div>
            </div>
        </div>
    );
}

export default InformationScreen;
