import React, { useState, useEffect }from 'react';
import axios from 'axios';
import SftGlobalData from '../../library/SftGlobalData';
import 'react-toastify/dist/ReactToastify.css';
import { DataGridPro } from '@mui/x-data-grid-pro';

const LicenceRevenueForm = (props) =>  {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [searchValue, setSearchValue] = useState("");

    useEffect (()=>{
        try{
            let url = SftGlobalData.baseURL_API+'client&action=revenue';
            let postData = {key: props.api}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    setData(response.data);
                    setLoading(false);
                });
        } catch(e){
            console.error(e);
        }
    }, [props.api]);

    const gridWebhookColumns = [
        {
            field: 'id',
            headerName: 'ID',
            minWidth: 75,
            width: 75,
            align: "left",
        },
        {
            field: 'login',
            headerName: 'Login',
            width: 250,
            align: "left",
        },
        {
            field: 'fname',
            headerName: 'First name',
            width: 150,
            align: "left",
        },
        {
            field: 'name',
            headerName: 'Last name',
            width: 200,
            align: "left",
        },
        {
            field: 'last_login',
            headerName: 'Last login',
            width: 200,
            align: "left",
        }
      ];

    function DataGrid() {
        return (
          <div className={props.theme} style={{width: '100%'}}>
            <input 
                type="text"
                name="search"
                className={props.theme+"_accent_darker "}
                style={{marginTop: 0 }}
                placeholder={props.t('search')}
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
            />
            
            {props.accountId !== "0" && <DataGridPro
                className={props.theme}
                columns={gridWebhookColumns}
                rows={data.filter(row => (row.id.match(new RegExp(searchValue, "i")) || row.login.match(new RegExp(searchValue, "i")) || row.last_login.match(new RegExp(searchValue, "i")) || row.name.match(new RegExp(searchValue, "i")) || row.fname.match(new RegExp(searchValue, "i"))))
                .map((row, index) => {return {
                        id: row.id,
                        login: row.login,
                        name: row.name,
                        fname: row.fname,
                        last_login: row.last_login
                    }
                })}
                rowHeight={38}
                style={{height: 400}}
                pagination={true}
                loading={loading}

                />   }       
          </div>
        );
    }


    return (
        <div>
            {DataGrid()}
        </div>
    );
}

export default LicenceRevenueForm;
