import React, { useState }from 'react';
import SftGlobalData from '../../library/SftGlobalData';
import { Switch } from '@mui/material';
import axios from 'axios';

const LicenceAdministrationCheckBox = (props) =>  { 
    const [checked, setChecked] = useState(props.checked);

    const changeListener = () => {
        updateModule({key: props.api, field: props.mod});
        setChecked(!checked);
    }

    const updateModule = (postData) => {
        let url = SftGlobalData.baseURL_API+'client&action=updateAdministration';
        axios.post(url, postData, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('postData', postData);
                    console.log('resp', response);
                }
            });
    }

    return (
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', justifyContent: 'center'}}>
            <Switch
                disabled={props.disabled}
                checked={ checked }
                onChange={() => {changeListener();}}
                inputProps={{ 'aria-label': 'controlled' }}
            />
            {props.label}
        </div>
    );
}

export default LicenceAdministrationCheckBox;
