import React from 'react';
import './App.css';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
        // You can also log the error to an error reporting service
        //logErrorToMyService(error, info);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div className={(this.props.screenWidth) < 600 ? 'not_expanded container_content _80perc ' + this.props.theme + ' container_content_' + this.props.theme : 'expanded container_content _80perc ' + this.props.theme + ' container_content_' + this.props.theme} >
                    <div className="errorbox">
                        <h1>ERROR</h1>
                        <br/>
                        <br/>
                        <h2>Er is iets misgelopen!</h2>
                        <p>Probeer de pagina te heropenen.</p>
                        <br/>
                        <h2>Something went wrong!</h2>
                        <p>Try to open the page again.</p>
                        <br/>
                        <h2>Il y a un erreur!</h2>
                        <p>Essayez de rouvrir la page.</p>
                    </div>
                </div>
            );
        }else{
            return this.props.children;
        }
        
    }
}

export default ErrorBoundary;