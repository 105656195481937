import React, { useState, useEffect } from 'react';
import './login.css';
import { 
    useParams,
    useNavigate } 
    from "react-router-dom";
import axios from 'axios';
import SftGlobalData from '../../library/SftGlobalData';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';

import logo_light from '../../assets/header/logo_light.png';
import logo_dark from '../../assets/header/logo_dark.png';
import '../../styles/main.css';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import crypto from 'crypto-js';


const LoginScreen = (props) =>  {
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [attempts, setAttempts] = useState(0);
    const [loggedIn, setLoggedIn] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);    
    const params = useParams();
    let navigate = useNavigate();

    //---------------------------------------------------------------------------------------------
    //    FUNCTIONS
    //---------------------------------------------------------------------------------------------

    const handleSubmit = (event) => {        
        event.preventDefault();
        setLoading(true);

        if(user === '' || password === ''){
            props.notify(props.t('missing_fields'), 7500, "top-center", props.theme, 1); 
            setLoading(false);
        } else if(attempts >= 3) {
            props.notify(props.t('too_many_attempts'), 7500, "top-center", props.theme, 1); 
            setLoading(false);
        }else {
            setAttempts(attempts + 1);
            let APIurl = SftGlobalData.baseURL_API + 'login';
            let postData = {
                login: user, 
                password: crypto.MD5(JSON.stringify(password)).toString()
            };
            axios.post(APIurl, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', APIurl);
                        console.log('data', postData);
                        console.log('response', response);
                        console.log('response error', typeof response.error);
                    }
                    if(typeof response.error === 'undefined'){
                        if(!response.data.is_valid){
                            props.notify(props.t('invalid_credentials'), 7500, "top-center", props.theme, 1); 
                            setLoading(false);
                        } else {
                            setAttempts(0);
                            props.cacheLogin(response.data); 
                            props.useDbs(response.data.dbs);
                            setLoggedIn(true); 
                        }
                    } else { 
                        props.notify(props.t('errorOccured'), 7500, "top-center", props.theme, 1);                      
                        setLoading(false);
                    }
                })
        }
    };

    useEffect(() => {
        if (loggedIn){
            props.changeScreen('environment');
            return navigate("/"+props.lang+"/environment");
        } else {
            props.changeScreen('login');
        }
     },[loggedIn]);

     useEffect(() => {
        if(params.error){
            props.notify(props.t('resetTokenExpired'), 7500, "top-center", props.theme, 1); 
            console.log("params", params);
            navigate("/"+props.lang+"/login");
        }        
     },[params.error, props.lang, props.theme]);

    const forgotPassword = () => {
        if(user === ''){
            props.notify(props.t('missing_fields'), 7500, "top-center", props.theme, 1); 
            setLoading(false);
        }else {
            setLoading(true);
            let APIurl = SftGlobalData.baseURL_API + 'user&action=resetToken';
            let postData = {
                login: user
            };
            axios.post(APIurl, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', APIurl);
                        console.log('data', postData);
                        console.log('response', response);
                        console.log('response error', typeof response.error);
                    }
                    if(typeof response.error === 'undefined'){
                        if(response.data.message !== 'success'){
                            props.notify(props.t('errorOccured'), 7500, "top-center", props.theme, 1); 
                            setLoading(false);
                        } else {
                            props.notify(props.t('succes'), 7500, "top-center", props.theme, 1); 
                            setLoading(false);
                        }
                    } else { 
                        props.notify(props.t('errorOccured'), 7500, "top-center", props.theme, 1);                 
                        setLoading(false);
                    }
                })
        }
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    //---------------------------------------------------------------------------------------------
    //    OUTPUT
    //---------------------------------------------------------------------------------------------

    return (
        <div className='center'>
            <div className='login__'>
                <form onSubmit={handleSubmit}>
                    <fieldset className={props.theme+' login__form login__form_' + props.theme}>
                        <img alt='SoftTouch Logo' src={(props.theme==='dark') ? logo_dark : logo_light} className='login__logo'/>
                        <br/> 

                        <FormControl sx={{ m: 1, width: '100%', margin: '0', marginBottom: '10px' }}>
                            <OutlinedInput
                                type='email'
                                name="txt_user" 
                                onChange={(event)=>{setUser(event.target.value)}}                                 
                                id="txt_user" 
                                className={props.theme+"_accent"}
                                value={user}
                                placeholder={props.t('username')}                                
                            />
                        </FormControl>

                        <FormControl sx={{ m: 1, width: '100%', margin: '0' }}>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                name="txt_password" 
                                className={props.theme+"_accent"}
                                value={password}
                                onChange={(event)=>{setPassword(event.target.value)}}
                                placeholder={props.t('password')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <div className='login__buttons'>
                            <LoadingButton
                                    loading={loading}
                                    type="submit" 
                                    className='login__button'
                                    size="large"
                                    >
                                    {!loading && props.t('login')}
                            </LoadingButton>
                            {!loading && <Button
                                onClick={() => {
                                    forgotPassword();
                                }}
                                className={'login_forgotpass__button_'+props.theme}                     
                            >
                            {props.t('forgot_password')} 
                            </Button>}
                        </div>
                    </fieldset>
                </form>           
            </div>
        </div>
    );
}

export default LoginScreen;