import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Colors from '../library/Colors';
import { useNavigate } from 'react-router-dom';

export default function HorizontalLinearStepper({ steps: stepData, t, onStepChange, canProceed, activeStep, onFinishUrl  }) {
    const [skipped, setSkipped] = React.useState(new Set());
    const navigate = useNavigate();
    const steps = stepData.map((step) => step.label);
    const isOptional = stepData.map((step) => step.isOptional);

    const isStepOptional = (stepIndex) => {
        return isOptional[stepIndex];
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        
        const nextStep = activeStep + 1;
        setSkipped(newSkipped);
        
        if (onStepChange) {
            onStepChange(nextStep);
        }

        // If it's the last step, navigate to the provided URL
        if (nextStep === steps.length && onFinishUrl) {
            navigate(onFinishUrl);  // Use navigate to move to the desired URL
        }
    };

    const handleBack = () => {
        const prevStep = activeStep - 1;
        if (onStepChange) {
            onStepChange(prevStep);
        }
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            throw new Error("You can't skip a step that isn't optional.");
        }
        const nextStep = activeStep + 1;
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });

        if (onStepChange) {
            onStepChange(nextStep);
        }
    };

    const handleReset = () => {
        setSkipped(new Set());
        if (onStepChange) {
            onStepChange(0);
        }
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                        labelProps.optional = (
                            <Typography variant="caption">Optional</Typography>
                        );
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <>
                </>
            ) : (
                <React.Fragment>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            variant="outlined"
                            sx={{
                                borderRadius: 3,
                                border: '2px solid ' + Colors.sft_blue,
                                color: Colors.sft_blue,
                                marginLeft: '2rem'
                            }}
                            disabled={activeStep === 0}
                            onClick={handleBack}
                        >
                            {t('back')}
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {isStepOptional(activeStep) && (
                            <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                {t('skip')}
                            </Button>
                        )}
                        <Button
                            color="inherit"
                            variant="outlined"
                            sx={{
                                borderRadius: 3,
                                border: '2px solid ' + Colors.sft_blue,
                                color: Colors.sft_blue,
                                marginRight: '2rem',
                            }}
                            onClick={handleNext}
                            disabled={!canProceed}  // Disable "Next" button if canProceed is false
                        >
                            {activeStep === steps.length - 1 ? t('finish') : t('next')}
                        </Button>
                    </Box>
                </React.Fragment>
            )}
        </Box>
    );
}

/**

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color:  var(--sft_blue) !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color:  var(--sft_blue) !important;
}
 */