import React from 'react';



const LicenceEaster = (props) =>  {
    console.log('       ___');
    console.log('    .-*)) `*-.');
    console.log('   /*  ((*   *\'.');
    console.log('  |   *))  *   *\\');
    console.log('  | *  ((*   *  /');
    console.log('   \\  *))  *  .\'');
    console.log('    \'-.((*_.-\'');

    return (
        <div className={props.theme + " _100perc pink"} style={{display:"flex", justifyContent: "space-evenly", borderTopRightRadius: '8px', borderBottomRightRadius: '8px'}}>
            <img src="https://visrecepten.nl/sites/default/files/2017-08/zalm.png" alt="Zalm" style={{objectFit: 'contain', width: '100%'}}/>
        </div>
        
    );
}

export default LicenceEaster;
