import * as React from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { Save } from '@mui/icons-material';

export default function FloatingSaveButton(props) {
  return (
    <Box disabled={props.disabled} onClick={()=>{props?.onClick();}} style={{position: 'fixed', right: 14, bottom: 24, zIndex: 10}} sx={{ '& > :not(style)': { m: 1 } }}>
      <Fab size="medium" color={"primary"} aria-label="save">
        <Save />
      </Fab>
    </Box>
  );
}