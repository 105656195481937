import React, { useEffect, useState } from 'react';
import axios from 'axios';

import ModuleCard from '../components/ModuleCard';
import appStoreBadge from '../assets/mobile-icons/appStoreBadge.svg';
import SftGlobalData from '../library/SftGlobalData';
import { TailSpin } from 'react-loader-spinner';
import Colors from '../library/Colors';
import Button from '@mui/material/Button';

const MobileModulesScreen = (props) => {
    const [modules, setModules] = useState([]);
    const [userGroups, setUserGroups] = useState([])
    const [loading, setLoading] = useState(true);
    const [selectedUserGroup, setSelectedUserGroup] = useState('0000')

    useEffect(() => {
        if(props.api !== ""){
            props.changeScreen('Licence');
            let url = SftGlobalData.baseURL_API_old + '?key='+props.api+'&auth=1'; // first, insert new modules if need be.
            axios.get(url)
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('response', response);
                }
            });

            url = SftGlobalData.baseURL_API_old + '?key='+props.api+'&get=modules';
            if(selectedUserGroup !== '0000'){
                url = SftGlobalData.baseURL_API_old + '?key='+props.api+'&get=modules&groupId='+selectedUserGroup;
            }
            axios.get(url)
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('response', response);
                }
                if(response.data !== "0 results"){
                    setModules(response.data.modules);
                    axios.get(SftGlobalData.baseURL_API_old + '?key='+props.api+'&get=userGroups')
                    .then(response => {
                        if(SftGlobalData.debug){
                            console.log('url', SftGlobalData.baseURL_API_old + '?key='+props.api+'&get=userGroups');
                            console.log('response', response);
                        }
                        setUserGroups(response.data.groups);
                        setLoading(false);
                    });
                } else {
                    url = SftGlobalData.baseURL_API_old + '?key='+props.api+'&auth=1';
                    axios.get(url)
                    .then(response => {
                        if(SftGlobalData.debug){
                            console.log('url', url);
                            console.log('response', response);
                        }
                        url = SftGlobalData.baseURL_API_old + '?key='+props.api+'&get=modules';
                        if(selectedUserGroup !== '0000'){
                            url = SftGlobalData.baseURL_API_old + '?key='+props.api+'&get=modules&groupId='+selectedUserGroup;
                        }
                        axios.get(url)
                        .then(response => {
                            if(SftGlobalData.debug){
                                console.log('url', url);
                                console.log('response', response);
                            }
                            if(response.data !== "0 results"){
                                setModules(response.data.modules);
                                axios.get(SftGlobalData.baseURL_API_old + '?key='+this.props.api+'&get=userGroups')
                                .then(response => {
                                    if(SftGlobalData.debug){
                                        console.log('url', SftGlobalData.baseURL_API_old + '?key='+this.props.api+'&get=userGroups');
                                        console.log('response', response);
                                    }
                                    setUserGroups(response.data.groups);
                                    setLoading(false);
                                });
                            } else {
                                setModules([]);
                                setUserGroups([]);
                                setLoading(false);
                            }
                        });
                    });
                }
            });
        }
    },[props.api, selectedUserGroup]);

    const resetHandler = async () => {
        //console.log(SftGlobalData.baseURL_API_old + '?key='+props.api+'&delete=1&modules=1&groupId='+selectedUserGroup);
        axios.get(SftGlobalData.baseURL_API_old + '?key='+props.api+'&delete=1&modules=1&groupId='+selectedUserGroup)
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', SftGlobalData.baseURL_API_old + '?key='+props.api+'&delete=1&modules=1&groupId='+selectedUserGroup);
                    console.log('response', response);
                }
                setLoading(true);
                let url = SftGlobalData.baseURL_API_old + '?key='+props.api+'&get=modules';
                if(selectedUserGroup !== '0000'){
                    url = SftGlobalData.baseURL_API_old + '?key='+props.api+'&get=modules&groupId='+selectedUserGroup;
                }
                axios.get(url)
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('response', response);
                    }
                    setModules(response.data.modules);
                    setLoading(false);
                });
            });
      }

    const modulesMapper = modules.map(module => {
        let modName = module.mod;
        modName = modName.substring(3);
        let translatedName = module.title_nl;
        switch(props.lang){
            case 'nl':
                translatedName = module.title_nl;
                break;
            case 'fr':
                translatedName = module.title_fr;
                break;
            case 'en':
                translatedName = module.title_en;
                break;
            default:
                translatedName = module.title_nl;
                break;
        }
        return (<ModuleCard key={modName} title={translatedName} setting={modName} checked={module.value} api={props.api} lang={props.lang} groupId={selectedUserGroup} theme={props.theme}/>);
    });

    const userGroupMapper = userGroups.map(userGroup => {
        return (
                <option key={userGroup.groupid} value={userGroup.groupid}>{userGroup.name}</option>
        );
    });

    const changeUserGroup = (event) => {
        setSelectedUserGroup(event.target.value);
        setLoading(true);
    }

    return (
        <div className={(props.screenWidth) < 600 ? 'not_expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme : 'expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme} >
            <div className={"container_list__title "}><h2 className={props.theme}>{props.t('modules')}</h2></div>
            {loading && <div className={"container_list__title " + props.theme} style={{display:"flex", justifyContent: "space-evenly", marginTop: 5, border: 1}}>
                <TailSpin 
                    height="40"
                    width="40"
                    color={Colors.sft_blue}
                    ariaLabel='loading'
                />
            </div>}
            {!loading && 
                (<select  className={(props.theme==='light') ? 'light_accent_darker _100perc' : 'dark_accent_darker _100perc'} id="usergrp" name="usergrp" onChange={changeUserGroup} value={selectedUserGroup}>
                    <option value="0000">{props.t('general')}</option>
                    {userGroupMapper}
                </select>)}

            {!loading && modulesMapper}
            {/*!loading && <ModuleCard key={'Label'} setting={'Label'} title={'Label'} api={props.api} lang={props.lang} groupId={selectedUserGroup} theme={props.theme}/>*/}
            {!loading && <ModuleCard key={'General'} setting={'General'} title={'General'} api={props.api} lang={props.lang} groupId={selectedUserGroup} theme={props.theme}/>}
            {!loading && modules.length > 0 && selectedUserGroup !== '0000' && <div><Button className="button_container__button _100perc" onClick={()=>{resetHandler();}}>{props.t('use_general')}</Button></div>}

            <br/><br/><br/>
            <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
              	<a href="https://play.google.com/store/apps/details?id=com.softtouch.myfasmanmobile" target="_blank" rel="noreferrer">
          			<img align="center" border="0" src="https://assets.unlayer.com/projects/0/1665410573694-afbeelding_2022-10-10_160254239.png" 
                        alt=""
                        title="" 
                        style={{paddingTop: 5, outline: 'none', textDecoration: 'none', msInterpolationMode: 'bicubic', clear: 'both', display: 'inline-block', border: 'none', height: 'auto', float: 'none', width: '100%', maxWidth: '146.67px'}}
                        width="146.67"
                    />
          		</a>
                <a href="https://apps.apple.com/be/app/myfasman-mobile/id1554095410" target="_blank" rel="noreferrer">
    		    	<picture className="mx-auto">
    		          <source
    		            srcSet={appStoreBadge}
    		            media="(prefers-color-scheme: dark)"
    		          />
    		          <img
    		            src={appStoreBadge}
    		            alt=""
    		            style={{paddingTop: 5, outline: 'none', textDecoration: 'none', msInterpolationMode: 'bicubic', clear: 'both', display: 'inline-block', border: 'none', height: 'auto', float: 'none', width: '100%', maxWidth: '146.67px'}}
                        width="146.67"
    		          />
    		        </picture>
    		    </a>
                <a href="https://appgallery.huawei.com/app/C105724949" target="_blank" rel="noreferrer">
    		    	<img align="center" border="0" src="https://assets.unlayer.com/projects/0/1665411047511-afbeelding_2022-10-10_161048036.png" 
                        alt="" 
                        title="" 
                        style={{paddingTop: 5, outline: 'none', textDecoration: 'none', msInterpolationMode: 'bicubic', clear: 'both', display: 'inline-block', border: 'none', height: 'auto', float: 'none', width: '100%', maxWidth: '146.67px'}}
                        width="146.67"
                    />
    		    </a>
          </div>
          <br/>
        </div>
    );
}



export default MobileModulesScreen;