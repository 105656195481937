import '../../styles/main.css';
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';

import testLogo         from '../../assets/Test.png';
import bpost            from '../../assets/shipping-icons/bpost.svg';
import budbee           from '../../assets/shipping-icons/budbee.svg';
import colisprive       from '../../assets/shipping-icons/colisprive.svg';
import deutschepost     from '../../assets/shipping-icons/deutschepost.svg';
import dhl_express      from '../../assets/shipping-icons/dhl_express.svg';
import dhl              from '../../assets/shipping-icons/dhl.svg';
import dpd              from '../../assets/shipping-icons/dpd.svg';
import fedex            from '../../assets/shipping-icons/fedex.svg';
import gls              from '../../assets/shipping-icons/gls.svg';
import mondial_relay    from '../../assets/shipping-icons/mondial_relay.svg';
import postnl           from '../../assets/shipping-icons/postnl.svg';
import quicargo         from '../../assets/shipping-icons/quicargo.svg';
import ups              from '../../assets/shipping-icons/ups.svg';
import sendcloud        from '../../assets/shipping-icons/sendcloud.svg';




const ShippingProvider = (props) =>  {  
    const [selectedProvider, setSelectedProvider] = useState();
    const [selectedShipper, setSelectedShipper] = useState();
    const [selectedPlatform, setSelectedPlatform] = useState();

    useEffect(() => {
        if(props.shipmentType.shipper !== '' && props.shipmentType.shipper !== undefined && props.shipmentType.provider !== '' && props.shipmentType.provider !== undefined && props.receiptNr !== undefined){
            //console.log("providers count: ", );
            props.providers.forEach(element => {
                if((props.shipmentType.provider === element.platform + "_" + element.provider)){
                    if(!props.showProviderForm && props.showProviderForm !== "" && props.error === ''){
                        setSelectedPlatform(element.platform + "_" + element.provider);
                        setSelectedProvider(element.provider);
                        setSelectedShipper(element.platform);
                        let shipment_type       = {shipper: element.platform, provider: element.provider};
                        props.setShipmentTypeData(shipment_type);
                        props.switchForm(true);
                    }
                } else {
                    let shipment_type       = {shipper: element.platform, provider: element.provider};
                    props.setShipmentTypeData(shipment_type);
                    setSelectedProvider(element.platform + "_" + element.provider); 
                    setSelectedShipper(element.provider);
                    setSelectedPlatform(element.platform);
                }
            });
        }
    },[props.setLoading]);   
     
     
    const displayShippingProviders = props.providers.map((providers, index) => {
        let cardImage = testLogo;
        switch(providers.provider){
            case "bpost":
                cardImage = bpost;
                break;
            case "budbee":
                cardImage = budbee;
                break;
            case "colisprive":
                cardImage = colisprive;
                break;
            case "deutschepost":
                cardImage = deutschepost;
                break;
            case "dhl_express":
                cardImage = dhl_express;
                break;                        
            case "dhl":
                cardImage = dhl;
                break;                        
            case "dpd":
                cardImage = dpd;
                    break;                        
            case "fedex":
                cardImage = fedex;
                    break;                        
            case "gls":
                cardImage = gls;
                    break;                        
            case "mondial_relay":
                cardImage = mondial_relay;
                    break;                     
            case "postnl":
                cardImage = postnl;
                    break;                 
            case "quicargo":
                cardImage = quicargo;
                    break;            
            case "ups":
                cardImage = ups;
                    break;
            case "sendcloud":
                cardImage = sendcloud;
                    break;
            default:                    
                cardImage = testLogo
                break;
        }
        return (
            
                <div key={index} 
                className={(selectedProvider === providers.platform + "_" + providers.provider) ? "shipping_provider_card_selected_"+props.theme : "shipping_provider_card_"+props.theme} 
                onClick={()=>{
                setSelectedProvider(providers.platform + "_" + providers.provider); 
                setSelectedShipper(providers.provider);
                setSelectedPlatform(providers.platform);
                }}>
                    <img src={cardImage} width="150" height="150"></img>
                    <p>{providers.provider + " - " + (providers.platform === 'direct' ? props.t('shippingProviderDirect') : providers.platform)}</p>
                </div>
        );
    });
     
    function checkIfVisible(){
        try{            
            if(props.showProviderForm === "F" && props.shipmentType.shipper !== "" && (typeof props.shipmentType.shipper !== "undefined") && props.shipmentType.provider !== "" && (typeof props.shipmentType.provider !== "undefined")){            
                props.changeVisible(false);
                validateData();
            }
        }catch(err) {}
    }

    function validateData(){   
        let allowNext = true; 
        if(selectedProvider === undefined){
            allowNext = false;
            props.notify(props.t('shipmentProviderNotification'), 7500, "top-right", props.theme, 1); 
        }
        if(allowNext === true){
            let shipment_type       = {shipper: selectedPlatform, provider: selectedShipper};
            //console.log("switch screen: ", selectedPlatform + " - " + selectedShipper)
            props.setShipmentTypeData(shipment_type);
            props.switchForm(true);
        }
    }

  return (
    <div style={{padding: 5}}>
          { <div className="container_list__title">
                {props.screenWidth >= 600 && <Button variant="contained" className={'button_container__button width_140px align_left'} onClick={()=>{props.switchForm(false);}}>{props.t('back')}</Button>}
                {props.screenWidth < 600 && <h2 className={(props.theme==='dark') ? "dark " : "light margin_bottom_none "} style={{textAlign: 'center', width: '100%'}}>{props.t('titleShippingProvider')}</h2>}
                {props.screenWidth >= 600 && <h2 className={(props.theme==='dark') ? "dark container_list__title  detail" : "light container_list__title margin_bottom_none detail"} type="text" id="list_name" name="listName">{props.t('titleShippingProvider')}</h2>}
                {props.screenWidth < 600 && <div className="margin_bottom_10px"></div>}
                {props.screenWidth < 600 && <Button variant="contained" className={'button_container__button width_140px align_left'} onClick={()=>{props.switchForm(false);}}>{props.t('back')}</Button>}
                <Button variant="contained" className={'button_container__button width_140px align_right'} onClick={()=>{validateData();}}>{props.t('next')}</Button>
            </div>   }          
      {checkIfVisible()}
      <div className={"container_list__title " + props.theme} style={{display:"flex", justifyContent: "space-evenly", marginTop: 5, border: 1}}></div>
        <div className="app-content shipping">  
        {(props.providers.length > 0) ? displayShippingProviders  : ""}
        </div>       
      </div>
  );
}

export default ShippingProvider;
