import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { faPencilAlt, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import axios from 'axios';
import '../App.css';
import "../styles/main.css";
import FloatingAddButton from '../components/FloatingAddButton';

import SftGlobalData from '../library/SftGlobalData';
import ListItemV2 from '../components/ListItemV2';
import Colors from '../library/Colors';



const UserScreen = (props) =>  {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);    
    const [selectedUser, setSelectedUser] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    let navigate = useNavigate();
    const gridColumns = [
        {
            field: 'id',
            headerName: props.t('id'),
            width: 50,
            align: "left",
        },
        {
            field: 'name',
            headerName: props.t('name'),
            headerClassName: 'bold',
            minWidth: 175,
            width: 250,
            align: "left",
        },
        {
            field: 'login',
            headerName: props.t('login'),
            headerClassName: 'bold',
            minWidth: 175,
            width: 250,
            align: "left",
        },
        {
            field: 'active',
            headerName: props.t('active'),
            width: 100,
            align: "left",
            renderCell: (params)=>{return <ListItemV2 
                key={params.row.id} 
                id={params.row.id} 
                lang={props.lang} 
                name={params.row.name}
                active={params.row.active} 
                api={props.api} 
                theme={props.theme}
                disabled={params.row.owner === '1'}
                submitHandler={(user,value)=>{setUserActive(user, value);}}
                />}
        },
        {
            field: 'edit',
            headerName: props.t('edit'),
            minWidth: 60,    
            maxWidth: 60,  
            align: 'center',      
            headerAlign: 'center', 
            renderCell: (params) => {
            return  <Button style={{color: (props.theme === 'dark' ? Colors.dark_text : Colors.light_text), height:"100%", borderRadius: "50%" }} onClick={()=>{navigate("/"+props.lang+"/user-detail/"+params.row.id)}} >
                        <FontAwesomeIcon icon={faPencilAlt} color={Colors.def_orange} size="2x" />
                    </Button>;        
            }
        },
        {
            field: 'delete',
            headerName: props.t('delete'),
            minWidth: 60,    
            maxWidth: 60,  
            align: 'center',      
            headerAlign: 'center', 
            renderCell: (params) => {
            return  (params.row.owner !== '1') ? <Button style={{color: (props.theme === 'dark' ? Colors.dark_text : Colors.light_text), height:"100%", borderRadius: "50%" }} onClick={()=>{deleteUser(params.row);}} >
                        <FontAwesomeIcon icon={faTrashCan} color={Colors.def_red} size="2x" />
                    </Button> : <div></div>;        
            }
        },
      ];


    const StyledModal = styled(ModalUnstyled)`
    position: fixed;
    text-align: center;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    `;

    const Backdrop = styled('div')`
    z-index: -1;
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    `;

    const style = {
        width: 400,
        bgcolor: 'background.paper',
        border: '0px solid #000',
        borderRadius: '6px',
        p: 2,
        px: 4,
        pb: 3,
      };

    useEffect(() => {
        props.changeScreen('Licence');
        let url = SftGlobalData.baseURL_API + 'account&action=fetch';
        axios.post(url, {key: props.api}, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('response', response);
                }
                if(typeof response.error === 'undefined'){
                    setUsers(response.data);
                } else { 
                    props.notify(props.t('errorOccured'), 7500, "top-center", props.theme, 1);
                }
                setLoading(false);
            });
     },[props.api]);

    const setUserActive = (user, val) => {
        let url = SftGlobalData.baseURL_API + 'account&action=updateActive';
        axios.post(url, {key: props.api, account_id: user, is_active: val === 'Y'}, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('response', response);
                }
                if(typeof response.error === 'undefined'){

                } else { 
                    props.notify(props.t('errorOccured'), 7500, "top-center", props.theme, 1);
                }
            });
    }

    const deleteUserRequest = (id) => {
        let url = SftGlobalData.baseURL_API + 'account&action=delete';
        setLoading(true);
        axios.post(url, {key: props.api, id: id}, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('response', response);
                }
                if(typeof response.error === 'undefined'){
                    let url = SftGlobalData.baseURL_API + 'account&action=fetch';
                    axios.post(url, {key: props.api}, {
                        headers : {
                            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                        }})
                        .then(response => {
                            if(SftGlobalData.debug){
                                console.log('url', url);
                                console.log('response', response);
                            }
                            if(typeof response.error === 'undefined'){
                                setUsers(response.data);
                            } else { 
                                props.notify(props.t('errorOccured'), 7500, "top-center", props.theme, 1);
                            }
                            setLoading(false);
                        });
                } else { 
                    props.notify(props.t('errorOccured'), 7500, "top-center", props.theme, 1);
                }
            });
    }

    const deleteUser = (user) => {
        if(SftGlobalData.debug){
            console.log('delete', user);
        }
        setSelectedUser(user);
        setModalOpen(true);
    }

    function DataGrid() {
        return (

            <DataGridPro
                //apiRef={apiRef}
                className={props.theme}
                //initialState={initialGrid}
                columns={gridColumns}
                //onColumnWidthChange={()=>{visibilityChangedHandler();}}
                //onColumnVisibilityModelChange={()=>{visibilityChangedHandler();}}
                //onPreferencePanelClose={()=>{visibilityChangedHandler();}}
                rows={users.map((row, index) => {return {
                        id: row.id_account_db,
                        global_id: row.id_account,
                        login: row.login,
                        name: row.surname + " " + row.name,
                        language: row.language,
                        created_at: row.created_at,
                        updated_at: row.updated_at,
                        active: (row.is_active === "1" ? 'Y' : 'N'),
                        owner: row.is_owner,
                        edit: "Edit",
                        delete: "Delete"
                    }
                })}
                rowHeight={38}
                autoHeight={true}
                pagination={true}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 20, 30]}
                loading={loading}
                />

        );
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column'}} className={(props.screenWidth) < 600 ? 'not_expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme : 'expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme} >
            <StyledModal
            aria-labelledby="unstyled-modal-title"
            aria-describedby="unstyled-modal-description"
            open={modalOpen}
            onClose={()=>{setModalOpen(false)}}
            BackdropComponent={Backdrop}
            >
                <Box sx={style} className={props.theme}>
                    <h2 id="unstyled-modal-title">{props.t('user_delete_title')}</h2>
                    <p id="unstyled-modal-description">{props.t('user_delete_descr')} {selectedUser.login}.</p>
                    <p>{props.t('confirm_question')}</p>

                    <Button className="modal_button_container__button" onClick={()=>{deleteUserRequest(selectedUser.id); setModalOpen(false);}}>{props.t('yes')}</Button>
                    <Button className="modal_button_container__button" onClick={()=>{setModalOpen(false);}}>{props.t('no')}</Button>
                </Box>
            </StyledModal>

            <FloatingAddButton disabled={modalOpen || loading} onClick={()=>{navigate("/"+props.lang+"/user-detail/+")}} />
            <div className={"container_list__title " + props.theme}><h2>{props.t('users')}</h2></div>

            {DataGrid()}
            <br/>
            {!loading && <Button className="button_container__button _100perc" type="button" onClick={()=>{navigate("/"+props.lang+"/app-defaults")}}>{props.t('default_settings')}</Button>}
        </div>
    );
}

export default UserScreen;
