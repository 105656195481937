import React, { useState, useEffect, useRef, useCallback } from 'react';
import { TailSpin } from  'react-loader-spinner'
import axios from 'axios';
import SftGlobalData from '../../library/SftGlobalData';
import './licence.css';
import Colors from '../../library/Colors';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faServer, faPencilAlt, faKey, faHistory, faHammer, faPlus, faQrcode, faCheckCircle, faCalendarPlus, faLevelUpAlt, faPlay, faStop } from '@fortawesome/free-solid-svg-icons'
import QRCode from "react-qr-code";
import { Alert, Input, Switch } from '@mui/material';
import TextField from '@mui/material/TextField';
import LicenceTableForm from '../../components/licence/LicenceTableForm';
import LicenceAPIForm from '../../components/licence/LicenceAPIForm';
import LicenceDoorCounterForm from '../../components/licence/LicenceDoorCounterForm';
import LicenceSMSForm from '../../components/licence/LicenceSMSForm';
import LicenceConnectionHistoryForm from '../../components/licence/LicenceConnectionHistory';
import LicenceExcelForm from '../../components/licence/LicenceExcelForm';
import LicenceAddonForm from '../../components/licence/LicenceAddonForm';
import LicenceSchedulerForm from '../../components/licence/LicenceSchedulerForm';
import LicenceShippingForm from '../../components/licence/LicenceShippingForm';
import LicenceAdministrationForm from '../../components/licence/LicenceAdministrationForm';
import LicenceLogoForm from '../../components/licence/LicenceLogoForm';
import LicenceEaster from '../../components/licence/LicenceEaster';
import LoadingButton from '@mui/lab/LoadingButton';
import LicenceRevenueForm from '../../components/licence/LicenceRevenueForm';
import FloatingLogButton from '../../components/FloatingLogButton';
import LoggingModal from '../../components/logging/LoggingModal';
import LicenceConnectionsOverviewForm from '../../components/licence/LicenceConnectionsOverviewForm';
import LicenceEDIForm from '../../components/licence/LicenceEDIForm';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const StyledModal = styled(ModalUnstyled)`
    position: fixed;
    text-align: center;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    `;

    const Backdrop = styled('div')`
    z-index: -1;
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    `;

    const style = {
        bgcolor: 'background.paper',
        border: '0px solid #000',
        borderRadius: 6,
        p: 2,
        px: 4,
        pb: 3,
      };

    const StyledModal2 = styled(ModalUnstyled)`
        position: fixed;
        text-align: center;
        z-index: 1300;
        right: 0;
        bottom: 0;
        top: 0;
        left: 8%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    `;
    
    const style2 = {
        bgcolor: 'background.paper',
        border: '0px solid #000',
        borderRadius: 0,
        height: "100%",
        p: 2,

    };

const LicenceScreen = (props) =>  {
    const [logModal, setLogModal] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [loadingModules, setLoadingModules] = useState(true);
    const [dbId, setDbId] = useState("");
    const [clientId, setClientId] = useState("");
    const [licenceKey, setLicenceKey] = useState("");
    const [name, setName] = useState("");
    //const [description, setDescription] = useState("");
    const [host, setHost] = useState("");
    const [database, setDatabase] = useState("");
    const [dbVersion, setDbVersion] = useState("");
    const [mysqlVersion, setMysqlVersion] = useState("");
    const [daysValid, setDaysValid] = useState("");
    //const [createdAt, setCreatedAt] = useState("");
    const [updatedAt, setUpdatedAt] = useState("");
    /*const [active, setActive] = useState(0);
    const [allowUpdate, setAllowUpdate] = useState(1);
    const [modEdi, setModEdi] = useState(0);
    const [modMarketing, setModMarketing] = useState(0);
    const [modWebshop, setmodWebshop] = useState(0);
    const [modB2b, setModB2b] = useState(0);
    const [modMyfasmanWeb, setModMyfasmanWeb] = useState(0);*/
    const [monthlyRent, setMonthlyRent] = useState(0);
    const [hasTraining, setHasTraining] = useState(0);
    //const [softtouchId, setSofttouchId] = useState(0);
    const [softtouchCustId, setSofttouchCustId] = useState(0);

    const [salesLicences, setSalesLicences] = useState([]);
    const [officeLicences, setOfficeLicences] = useState([]);
    const [consultationLicences, setConsultationLicences] = useState([]);
    const [collectionLicences, setCollectionLicenes] = useState([]);
    //const [trainingLicences, setTrainingLicences] = useState([]);
    const [cloudLicences, setCloudLicences] = useState([]);
    //const [sftSalesLicences, setSftSalesLicences] = useState([]);
    //const [sftOfficeLicences, setSftOfficeLicences] = useState([]);
    const [smartphoneLicences, setSmartphoneLicences] = useState([]);
    const [smartscannerLicences, setSmartscannerLicences] = useState([]);

    const [searchValue, setSearchValue] = useState("");

    const [modules, setModules] = useState({
        db_id: 0,
        client_id: 0,
        api: {accountId: "0", users: []},
        doorcounter: [],
        excel: [],
        addons: [],
        sms: {},
        memo: "",
        scheduler: {}
    });

    const [memo, setMemo] = useState("");

    const [QRcode, setQRcode] = useState("");
    const [hasQR, setHasQR] = useState(false);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('QR');
    const [modalTarget, setModalTarget] = useState(0);
    //const [modalAction, setModalAction] = useState("");
    const [generatingTraining, setGeneratingTraining] = useState(false);

    const [licenceDate, setLicenceDate] = useState("2022-12-31");
    const [licType, setLicType] = useState("1");
    const [isTrial, setIsTrial] = useState(false);
    const [licenceAmount, setLicenceAmount] = useState(1);


    const [value, setValue] = React.useState(0);
    const [modulesTab, setModulesTab] = React.useState(0);

    // Advanced settings
    const [dbHost, setDbHost] = useState("");
    const [dbUser, setDbUser] = useState("");
    const [dbPassword, setDbPassword] = useState("");
    const [dbDatabase, setDbDatabase]= useState("");
    const [dbPort, setDbPort] = useState("3306");

    // Traininng settinggs
    const [dbTestHost, setDbTestHost] = useState("");
    const [dbTestUser, setDbTestUser] = useState("");
    const [dbTestPassword, setDbTestPassword] = useState("");
    const [dbTestDatabase, setDbTestDatabase]= useState("");
    const [dbTestPort, setDbTestPort] = useState("3306");

    const [myfasmanParse, setMyfasmanParse] = useState("");
    const [myfasmanParseTest, setMyfasmanParseTest] = useState("");

    const [credentialsDb, setCredentialsDb] = useState("");
    const [credentialsApi, setCredentialsApi] = useState("");
    
    const inputExt = useRef(null);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleModuleChange = (event, newValue) => {
        setModulesTab(newValue);
    };

    const openTargetedModal = (content, action, target) => {
        setModalContent(content);
        if(SftGlobalData.debug){
            console.log("ModalAction", action);
        }
        //setModalAction(action);
        setModalTarget(target);
        setModalOpen(true);
    }


    useEffect(() => {
        props.changeScreen('Licence');
        if(SftGlobalData.debug) console.log("PROPS", props);
        setDisabled(true);
        let url = SftGlobalData.baseURL_API + 'client&action=fetch';

        axios.post(url, {key: props.api, user: props.username, sftUserId: props.userId}, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('response', response);
                    console.log('selectedDb', props.selectedDb);
                }
                if(typeof response.error === 'undefined'){
                    setDbId(response.data.db_id);
                    setClientId(response.data.client_id);
                    setLicenceKey(response.data.key);
                    setName(response.data.name);
                    if(response.data.name === "Kristof" && SftGlobalData.easter1 && SftGlobalData.easter2){
                        SftGlobalData.easter3 = true;
                    } else {
                        SftGlobalData.easter3 = false;
                    }
                    //setDescription(response.data.description);
                    setHost(response.data.host);
                    setDatabase(response.data.database);
                    setDbVersion(response.data.db_version);
                    setMysqlVersion(response.data.mysql_version);
                    setDaysValid(response.data.days_valid);
                    let licenceD = new Date(new Date().getTime()+(parseInt(response.data.days_valid)*24*60*60*1000));
                    setLicenceDate(licenceD.getFullYear()+"-"+(licenceD.getMonth()+1).toString().padStart(2, '0')+"-"+licenceD.getDate().toString().padStart(2, '0'));
                    //setCreatedAt(response.data.created_at);
                    setUpdatedAt(response.data.updated_at);
                    /*setActive(response.data.active);
                    setAllowUpdate(response.data.allow_update);
                    setModEdi(response.data.mod_edi);
                    setModB2b(response.data.mod_b2b);
                    setModMarketing(response.data.mod_marketing);
                    setmodWebshop(response.data.mod_webshop);
                    setModMyfasmanWeb(response.data.mod_myfasman_web);*/
                    setMonthlyRent(response.data.monthly_rent === "1");
                    setHasTraining(response.data.hasTraining);
                    //setSofttouchId(response.data.softtouch_id);
                    setSofttouchCustId(response.data.softtouch_cust_id);

                    setSalesLicences(response.data.salesLicences);
                    setOfficeLicences(response.data.officeLicences);
                    setConsultationLicences(response.data.consultationLicences);
                    setCollectionLicenes(response.data.collectionLicences);
                    //setTrainingLicences(response.data.trainingLicences);
                    setCloudLicences(response.data.cloudLicences);
                    //setSftSalesLicences(response.data.sftSalesLicences);
                    //setSftOfficeLicences(response.data.sftOfficeLicences);
                    setSmartphoneLicences(response.data.smartphoneLicences);
                    setSmartscannerLicences(response.data.smartscannerLicences);
                    
                    var reqUID = Math.floor((Math.random() * 10000) + 1);
                    axios.get(SftGlobalData.baseURL_office + 'qr/request.php?&api=get&qr=yes&db_id='+response.data.db_id+'&client_id='+response.data.client_id+'&req='+reqUID)
                        .then(response => {
                            if(SftGlobalData.debug){
                                console.log('GET', SftGlobalData.baseURL_office + 'qr/request.php?&api=get&qr=yes&db_id='+response.data.db_id+'&client_id='+response.data.client_id+'&req='+reqUID);
                                console.log('response', response);
                            }
                            try{
                                setQRcode(response.data.baseData);
                                setHasQR(response.data.has_connection);
                            }catch(e){
                                console.error(e);
                            }
                            setDisabled(false);
                            axios.post(SftGlobalData.baseURL_API + 'client&action=fetchModules', {key: props.api, user: props.username, sftUserId: props.userId},{
                                headers : {
                                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                                }})
                            .then(response => {
                                if(SftGlobalData.debug){
                                    console.log('POST', SftGlobalData.baseURL_API + 'client&action=fetchModules');
                                    console.log('response', response);
                                }
                                try{
                                    setModules(response.data);
                                    setMemo(response.data.memo);
                                }catch(e){
                                    console.error(e);
                                }
                                setLoadingModules(false);
                                
                            });
                        });
                } else { 
                    props.notify(props.t('errorOccured'), 2000, "top-right", props.theme, 1); 
                }
            });
     },[props.api]);
    
     const setInputRef = useCallback((element) => {
        inputExt.current = element;
        /*if (element) {
          element.focus();
          element.select();
        }*/
      }, []);

    // ----------------------------------------------------
    // |              PERMISSION FUNCTIONS                |
    // ----------------------------------------------------

    const suppCheck = () => {
        return ((props.group === "ADMIN" || props.group === "SUPER") || props.group === "DEV" || props.group === "SUPP");
    }

    const devCheck = () => {
        return ((props.group === "ADMIN" || props.group === "SUPER") || props.group === "DEV");
    }

    const adminCheck = () => {
        return ((props.group === "ADMIN" || props.group === "SUPER"));
    }

    const superCheck = () => {
        return (props.group === "SUPER");
    }

    // ----------------------------------------------------
    // |                    FUNCTIONS                     |
    // ----------------------------------------------------

    function copyKeyToClipboard(value) {
        navigator.clipboard.writeText(value)
        /*const el = copyKey;
        el.select();
        document.execCommand("copy");*/
        props.notify(props.t('copied') + " " + value, 2000, "top-right", props.theme, 1); 
    }


    const saveMemo = () => {
        let url = SftGlobalData.baseURL_API + 'client&action=updateMemo';
        axios.post(url, {
            key: props.api,
            memo: SftGlobalData.escapeText(memo), user: props.username, sftUserId: props.userId
        }, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        })
        .then(response => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('response', response);
            }
            if(response.data.message === "Done"){
                props.notify(props.t('savedChanges'), 2000, "top-right", props.theme, 1); 
            } else { 
                props.notify(props.t('errorOccured'), 2000, "top-right", props.theme, 1); 
            }
        });
    }

    const isTrialHandler = () => {
        if(isTrial){
            let licenceD = new Date(new Date().getTime()+(parseInt(daysValid)*24*60*60*1000));
            setLicenceDate(licenceD.getFullYear()+"-"+(licenceD.getMonth()+1).toString().padStart(2, '0')+"-"+licenceD.getDate().toString().padStart(2, '0'));
        } else {
            let licenceD = new Date(new Date().getTime()+(30*24*60*60*1000));
            setLicenceDate(licenceD.getFullYear()+"-"+(licenceD.getMonth()+1).toString().padStart(2, '0')+"-"+licenceD.getDate().toString().padStart(2, '0'));
        }
        setIsTrial(!isTrial);
        
    }

    const addLicence = async () => {

        if(parseInt(licenceAmount) > 0){
        
            let url = SftGlobalData.baseURL_API + 'client&action=addLicence';
            let postData = {
                key: props.api,
                lic_type: licType,
                is_trial: isTrial,
                valid_until: licenceDate,
                amount: licenceAmount, user: props.username, sftUserId: props.userId
            };
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }
            })
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('data', postData);
                    console.log('response', response);
                }
                switch(licType){
                    case "1": setSalesLicences(response.data); props.notify(props.t('savedChanges'), 2000, "top-right", props.theme, 1);  setModalOpen(false); if(value === 0) {setValue(1);} setValue(0); break;
                    case "2": setOfficeLicences(response.data); props.notify(props.t('savedChanges'), 2000, "top-right", props.theme, 1);  setModalOpen(false); if(value === 1) {setValue(0);} setValue(1); break;
                    case "3": setConsultationLicences(response.data); props.notify(props.t('savedChanges'), 2000, "top-right", props.theme, 1);  setModalOpen(false); if(value === 3) {setValue(0);} setValue(3); break;
                    case "4": setCollectionLicenes(response.data); props.notify(props.t('savedChanges'), 2000, "top-right", props.theme, 1);  setModalOpen(false); if(value === 4) {setValue(0);} setValue(4); break;
                    case "6": setCloudLicences(response.data); props.notify(props.t('savedChanges'), 2000, "top-right", props.theme, 1);  setModalOpen(false); if(value === 2) {setValue(0);} setValue(2); break;
                    case "9": setSmartphoneLicences(response.data); props.notify(props.t('savedChanges'), 2000, "top-right", props.theme, 1);  setModalOpen(false); if(value === 5) {setValue(0);} setValue(5); break;
                    case "10": setSmartscannerLicences(response.data); props.notify(props.t('savedChanges'), 2000, "top-right", props.theme, 1);  setModalOpen(false); if(value === 6) {setValue(0);} setValue(6); break;
                    default: props.notify(props.t('errorOccured'), 2000, "top-right", props.theme, 1);  break;
                }
            });
        }
    }

    const licenceCall = async (url, postData) => {
        setModalOpen(false);
        try{
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    props.notify(props.t('savedChanges'), 2000, "top-right", props.theme, 1); 
                    setSalesLicences(response.data.mysales);
                    setOfficeLicences(response.data.office);
                    setConsultationLicences(response.data.consultation);
                    setCollectionLicenes(response.data.collection);
                    setCloudLicences(response.data.cloud);
                    setSmartphoneLicences(response.data.mobilephone);
                    setSmartscannerLicences(response.data.mobilescan);
                });
        } catch(e){
            console.error(e);
        }
    } 


    const getCredentials = async () => {
        let url = SftGlobalData.baseURL_API + 'connection&action=getCredentials';
            let postData = {
                key: props.api, user: props.username, sftUserId: props.userId
            };
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }
            })
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('data', postData);
                    console.log('response', response);
                }
                setCredentialsDb(response.data.db);
                setCredentialsApi(response.data.api);
            });
    }

    

    const getModalContent = () => {
        switch(modalContent) {
            case "ExtendAll":
                return (
                    <div>
                        <p>{props.t("extend_all_confirm")}</p>
                        <p><b>{props.t("days_valid")}:</b> {daysValid}</p>
                        <Input className={props.theme + " _100perc margin_top_10px"} placeholder='Value' value={licenceDate} onChange={(e)=>{setLicenceDate(e.target.value);}}/>
                        <Button className={"modal_button_container_no_margin__button margin_top_15px _100perc"} onClick={()=>{licenceCall(SftGlobalData.baseURL_API + 'client&action=extendAll', {key: props.api, value: licenceDate, user: props.username, sftUserId: props.userId})}}>{props.t('save')}</Button>
                    </div>
                )
            case "QuickExtend":
                return (
                    <div>
                        <p>{props.t("quick_extend_confirm")}</p>
                        <Button className={"modal_button_container_no_margin__button margin_top_15px _100perc"} onClick={()=>{licenceCall(SftGlobalData.baseURL_API + 'client&action=quickExtend', {key: props.api, user: props.username, sftUserId: props.userId})}}>{props.t('yes')}</Button>
                    </div>
                )
            case "AutoUpdateAll":
                return (
                    <div>
                        <p>{props.t("auto_update_all_confirm")}</p>
                        <Button className={"modal_button_container_no_margin__button margin_top_15px _100perc"} onClick={()=>{licenceCall(SftGlobalData.baseURL_API + 'client&action=autoUpdateAll', {key: props.api, user: props.username, sftUserId: props.userId})}}>{props.t('yes')}</Button>
                    </div>
                )
            case "ToggleStatusAll":
                return (
                    <div>
                        <p>{props.t("toggle_status_all_confirm")}</p>
                        <Button className={"modal_button_container_no_margin__button margin_top_15px _100perc"} onClick={()=>{licenceCall(SftGlobalData.baseURL_API + 'client&action=statusAll', {key: props.api, user: props.username, sftUserId: props.userId})}}>{props.t('yes')}</Button>
                    </div>
                )
            case "StatusOnAll":
                return (
                    <div>
                        <p>{props.t("on_status_all_confirm")}</p>
                        <Button className={"modal_button_container_no_margin__button margin_top_15px _100perc"} onClick={()=>{licenceCall(SftGlobalData.baseURL_API + 'client&action=statusAllOn', {key: props.api, user: props.username, sftUserId: props.userId})}}>{props.t('yes')}</Button>
                    </div>
                )
            case "StatusOffAll":
                return (
                    <div>
                        <p>{props.t("off_status_all_confirm")}</p>
                        <Button className={"modal_button_container_no_margin__button margin_top_15px _100perc"} onClick={()=>{licenceCall(SftGlobalData.baseURL_API + 'client&action=statusAllOff', {key: props.api, user: props.username, sftUserId: props.userId})}}>{props.t('yes')}</Button>
                    </div>
                )
                
            case "TrialOffAll":
                return (
                    <div>
                        <p>{props.t("trial_off_all_confirm")}</p>
                        <Button className={"modal_button_container_no_margin__button margin_top_15px _100perc"} onClick={()=>{licenceCall(SftGlobalData.baseURL_API + 'client&action=trialOffAll', {key: props.api, user: props.username, sftUserId: props.userId})}}>{props.t('yes')}</Button>
                    </div>
                )
            case "Add":
                return (
                    <div className='_100perc' style={{display: 'flex', justifyContent:'flex-start', flexDirection: 'column'}}>
                        <select className={props.theme + " _100perc"} value={licType} onChange={(e)=>{setLicType(e.target.value)}}>
                            <option value="1">{props.t("mysales")}</option>
                            <option value="2" >{props.t("myfashionmanager")}</option>
                            <option value="3" >{props.t("consultation")}</option>
                            <option value="4" >{props.t("collection")}</option>
                            <option value="6" >{props.t("myfashionmanager")} {props.t("cloud")}</option>
                            <option value="9" >{props.t("myfasmanmobile")} ({props.t("smartphone")})</option>
                            <option value="10" >{props.t("myfasmanmobile")} ({props.t("scanner")})</option>
                        </select>

                        <div className={props.theme + " _100perc"} style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center", paddingLeft: 10}}>
                            <p><b>{props.t("trial")}?</b></p>
                            <label className="switch align_right">
                                <input type="checkbox" checked={isTrial} onChange={() => {isTrialHandler()}}/>
                                <span className={"slider slider_" + props.theme + " round"}></span>
                            </label>
                        </div>
                        <label className='_100perc bold margin_top_10px text_left'>{props.t("valid_until")}:</label>
                        <Input className={props.theme + " _100perc margin_top_10px"} placeholder='Value' value={licenceDate} onChange={(e)=>{setLicenceDate(e.target.value);}}/>
                        <label className='_100perc bold margin_top_10px text_left'>{props.t("amount")}:</label>
                        <Input inputRef={setInputRef} className={props.theme + " _100perc margin_top_10px"} onClick={()=>{inputExt.current.select();}} placeholder='Value' value={licenceAmount} onChange={(e)=>{setLicenceAmount(e.target.value);}}/>
                        <Button className={"modal_button_container_no_margin__button margin_top_15px _100perc"} onClick={()=>{addLicence()}}>{props.t('add')}</Button>
                    </div>
                )
            case "Licence_history":
                return <LicenceConnectionHistoryForm api={props.api} theme={props.theme} t={props.t} screenWidth={props.screenWidth} licenceId={modalTarget}/>
            case "Credentials":
                getCredentials();
                return <div style={{width: 500, wordBreak: 'break-all'}}>
                            <textarea className={(props.theme==='light') ? 'light_accent_darker textarea margin_bottom_10px' : 'dark_accent_darker textarea margin_bottom_10px'} id="dbCredentials" name="dbCredentials" value={credentialsDb} style={{resize: 'none', minHeight: 155}} readOnly/>
                            <textarea className={(props.theme==='light') ? 'light_accent_darker textarea margin_bottom_10px' : 'dark_accent_darker textarea margin_bottom_10px'} id="apiCredentials" name="apiCredentials" value={credentialsApi} style={{resize: 'none', minHeight: 100}} readOnly/>
                            <textarea className={(props.theme==='light') ? 'light_accent_darker textarea margin_bottom_10px' : 'dark_accent_darker textarea margin_bottom_10px'} id="keyCredentials" name="keyCredentials" value={props.api} style={{resize: 'none', minHeight: 200}} readOnly/>
                        </div>
            case "QR":
                return  <div className="light _margin padding_top__10px">
                            <QRCode value={QRcode} size={200} />
                            {hasQR ? <p style={{margin: 0, color: Colors.def_green}}>{props.t("ACTIVE")}</p> : <p style={{margin: 0, color: Colors.def_red}}>{props.t("not_available")}</p>}
                            <Button  className="modal_button_container_no_margin__button margin_top_10px" style={{width: '100%'}} onClick={()=>{copyKeyToClipboard(QRcode);}}>COPY DATA TO CLIPBOARD</Button>
                        </div>
            case "History":
                return <LicenceConnectionHistoryForm api={props.api} theme={props.theme} t={props.t} screenWidth={props.screenWidth} licenceId={0}/>
            case "Basic": 
                return <div>
                            <table className='licence__client'> 
                                <tbody>
                                    <tr>
                                        <td width={175} style={{textAlign: 'left'}}>
                                            <b>{props.t('cust_no')}: </b>
                                        </td>
                                        <td style={{textAlign: 'right'}}>
                                            <Input disabled={!adminCheck()} className={"shipping_field_full_width licence__input"} placeholder='Customer id' value={softtouchCustId} onChange={(e)=>{setSofttouchCustId(e.target.value);}}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'left'}}>
                                            <b>{props.t('days_valid')}: </b>
                                        </td>
                                        <td style={{textAlign: 'right'}}>
                                            <Input className={"shipping_field_full_width licence__input"} style={{textAlign: 'end'}} placeholder='Days valid' value={daysValid} onChange={(e)=>{setDaysValid(e.target.value);}}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width={175} style={{textAlign: 'left'}}>
                                            <b>{props.t('lic_name')}: </b>
                                        </td>
                                        <td style={{textAlign: 'right'}}>
                                            <Input className={"shipping_field_full_width licence__input"} placeholder='Licence name' value={name} onChange={(e)=>{setName(e.target.value);}}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'left'}}>
                                            <b>{props.t('lic_key')}: </b>
                                        </td>
                                        <td style={{textAlign: 'right'}}>
                                            <a onClick={()=>{copyKeyToClipboard(licenceKey)}}>{licenceKey}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'left'}}>
                                            <b>{props.t('host')}: </b>
                                        </td>
                                        <td style={{textAlign: 'right'}}>
                                            {host}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'left'}}>
                                            <b>{props.t('SQL_version')}: </b>
                                        </td>
                                        <td style={{textAlign: 'right'}}>
                                            {mysqlVersion}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'left'}}>
                                            <b>{props.t('last_updated')}: </b>
                                        </td>
                                        <td style={{textAlign: 'right'}}>
                                            {updatedAt}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'left'}}>
                                            <b>{props.t('monthly_rent')}: </b>
                                        </td>
                                        <td style={{textAlign: 'right'}}>
                                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', justifyContent: 'flex-end'}}>
                                                <Switch
                                                    disabled={(props.login !== "jeroen@softtouch.be" && props.login !== "kristof@softtouch.be" && props.login !== "bert.vdv@softtouch.be")}
                                                    checked={ monthlyRent }
                                                    onChange={() => {setMonthlyRent(!monthlyRent);}}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <Button className={"modal_button_container_no_margin__button margin_top_15px _100perc margin_bottom_none"} onClick={()=>{saveBasic(); setModalOpen(false);}}>{props.t('save')}</Button>
                        </div>
        case "Advanced":
            return <div className='flex_start_column' style={{justifyContent: 'flex-start', textAlign: 'left'}}>
                        <textarea className={(props.theme==='light') ? 'light_accent_darker textarea margin_bottom_10px' : 'dark_accent_darker textarea margin_bottom_10px'} id="myfasmanparse" onChange={(event)=>{setMyfasmanParse(event.target.value)}} name="myfasmanparse" value={myfasmanParse} style={{resize: 'none', minHeight: 155}}/>
                        <Button className={"button_inverted margin_top_5px margin_bottom_10px _100perc "} onClick={()=>{fillAdvancedDbFields(parseFasManLogin(myfasmanParse));}}>PARSE</Button> 
                        <TextField
                                className={(props.theme==='light') ? 'light_accent shipping_field_full_width' : 'dark_accent_darker shipping_field_full_width'}
                                id="host"
                                label={"Host"}
                                sx={(props.theme==='light') ? {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.light_text
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.sft_blue
                                    }} 
                                    :
                                    {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.dark_text
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.sft_blue
                                        }}}
                                value={dbHost}            
                                onChange={(event)=>{setDbHost(event.target.value)}}
                                />
                        <TextField
                                className={(props.theme==='light') ? 'light_accent shipping_field_full_width' : 'dark_accent_darker shipping_field_full_width'}
                                id="database"
                                label={"Database"}
                                sx={(props.theme==='light') ? {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.light_text
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.sft_blue
                                    }} 
                                    :
                                    {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.dark_text
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.sft_blue
                                        }}}
                                value={dbDatabase}            
                                onChange={(event)=>{setDbDatabase(event.target.value)}}
                                />
                            <TextField
                                className={(props.theme==='light') ? 'light_accent shipping_field_full_width' : 'dark_accent_darker shipping_field_full_width'}
                                id="user"
                                label={"User"}
                                sx={(props.theme==='light') ? {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.light_text
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.sft_blue
                                    }} 
                                    :
                                    {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.dark_text
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.sft_blue
                                        }}}
                                value={dbUser}            
                                onChange={(event)=>{setDbUser(event.target.value)}}
                                />
                            <TextField
                                className={(props.theme==='light') ? 'light_accent shipping_field_full_width' : 'dark_accent_darker shipping_field_full_width'}
                                id="password"
                                label={"Password"}
                                sx={(props.theme==='light') ? {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.light_text
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.sft_blue
                                    }} 
                                    :
                                    {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.dark_text
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.sft_blue
                                        }}}
                                value={dbPassword}            
                                onChange={(event)=>{setDbPassword(event.target.value)}}
                                />
                            <TextField
                                className={(props.theme==='light') ? 'light_accent shipping_field_full_width' : 'dark_accent_darker shipping_field_full_width'}
                                id="port"
                                label={"Port"}
                                sx={(props.theme==='light') ? {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.light_text
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.sft_blue
                                    }} 
                                    :
                                    {
                                    '& .MuiOutlinedInput-input': {
                                        color: Colors.dark_text
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.sft_blue
                                        }}}
                                value={dbPort}            
                                onChange={(event)=>{setDbPort(event.target.value)}}
                                />
                            <Button className={"modal_button_container_no_margin__button margin_top_15px _100perc margin_bottom_none"} onClick={()=>{saveAdvanced();}}>{props.t('save')}</Button>
                    </div>
        case "Training":
            return <div>
                        {hasTraining === "0" ? <p><b>No training</b> active...</p> : <p><b>{props.t("training")} active!</b></p>}
                            <div className='flex_start_column' style={{justifyContent: 'flex-start', textAlign: 'left'}}>
                                <textarea className={(props.theme==='light') ? 'light_accent_darker textarea margin_bottom_10px' : 'dark_accent_darker textarea margin_bottom_10px'} id="myfasmanparsetest" onChange={(event)=>{setMyfasmanParseTest(event.target.value)}} name="myfasmanparsetest" value={myfasmanParseTest} style={{resize: 'none', minHeight: 155}}/>
                                <Button className={"button_inverted margin_top_5px margin_bottom_10px _100perc"} onClick={()=>{fillTestDbFields(parseFasManLogin(myfasmanParseTest));}}>PARSE</Button>
                                <TextField
                                    className={(props.theme==='light') ? 'light_accent shipping_field_full_width' : 'dark_accent_darker shipping_field_full_width'}
                                    id="host_test"
                                    label={"Host"}
                                    sx={(props.theme==='light') ? {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.light_text
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.sft_blue
                                        }} 
                                        :
                                        {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.dark_text
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: Colors.sft_blue
                                            }}}
                                    value={dbTestHost}            
                                    onChange={(event)=>{setDbTestHost(event.target.value)}}
                                    />
                                <TextField
                                    className={(props.theme==='light') ? 'light_accent shipping_field_full_width' : 'dark_accent_darker shipping_field_full_width'}
                                    id="database_test"
                                    label={"Database"}
                                    sx={(props.theme==='light') ? {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.light_text
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.sft_blue
                                        }} 
                                        :
                                        {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.dark_text
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: Colors.sft_blue
                                            }}}
                                    value={dbTestDatabase}            
                                    onChange={(event)=>{setDbTestDatabase(event.target.value)}}
                                    />
                                <TextField
                                    className={(props.theme==='light') ? 'light_accent shipping_field_full_width' : 'dark_accent_darker shipping_field_full_width'}
                                    id="user_test"
                                    label={"User"}
                                    sx={(props.theme==='light') ? {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.light_text
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.sft_blue
                                        }} 
                                        :
                                        {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.dark_text
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: Colors.sft_blue
                                            }}}
                                    value={dbTestUser}            
                                    onChange={(event)=>{setDbTestUser(event.target.value)}}
                                    />
                                <TextField
                                    className={(props.theme==='light') ? 'light_accent shipping_field_full_width' : 'dark_accent_darker shipping_field_full_width'}
                                    id="password_test"
                                    label={"Password"}
                                    sx={(props.theme==='light') ? {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.light_text
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.sft_blue
                                        }} 
                                        :
                                        {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.dark_text
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: Colors.sft_blue
                                            }}}
                                    value={dbTestPassword}            
                                    onChange={(event)=>{setDbTestPassword(event.target.value)}}
                                    />
                                <TextField
                                    className={(props.theme==='light') ? 'light_accent shipping_field_full_width' : 'dark_accent_darker shipping_field_full_width'}
                                    id="port_test"
                                    label={"Port"}
                                    sx={(props.theme==='light') ? {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.light_text
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: Colors.sft_blue
                                        }} 
                                        :
                                        {
                                        '& .MuiOutlinedInput-input': {
                                            color: Colors.dark_text
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: Colors.sft_blue
                                            }}}
                                    value={dbTestPort}            
                                    onChange={(event)=>{setDbTestPort(event.target.value)}}
                                    />
                                <Button className={"modal_button_container_no_margin__button margin_top_5px _100perc margin_bottom_none"} onClick={()=>{addTraining();}}>{props.t('save')}</Button>
                                {hasTraining !== "0" ? 
                                    <LoadingButton size='small' loading={generatingTraining} className={"button_inverted margin_top_5px _100perc"} onClick={()=>{createAndAddTraining();}}>{props.t('sync')}</LoadingButton> :
                                    <LoadingButton size='small' loading={generatingTraining} disabled={generatingTraining} className={"button_inverted margin_top_5px _100perc"} onClick={()=>{createAndAddTraining();}}>{props.t('generate')}</LoadingButton>
                                 }
                            </div>
                    </div>
        default:
            return <div></div>
            
        }
    }

    const fillTestDbFields = (db) => {
        try{
            setDbTestHost(db.host);
            setDbTestDatabase(db.database);
            setDbTestUser(db.user);
            setDbTestPassword(db.password);
            setDbTestPort(db.port);
            setMyfasmanParseTest("");
        } catch(e){
            console.error(e);
        }
    }

    const fillAdvancedDbFields = (db) => {
        try{
            setDbHost(db.host);
            setDbDatabase(db.database);
            setDbUser(db.user);
            setDbPassword(db.password);
            setDbPort(db.port);
            setMyfasmanParse("");
        } catch(e){
            console.error(e);
        }
    }

    const parseFasManLogin = (INISyntax) => {
        var regex = {
            section: /^\s*\[\s*([^\]]*)\s*\]\s*$/,
            param: /^\s*([\w\.\-\_]+)\s*=\s*(.*?)\s*$/,
            comment: /^\s*;.*$/
        };
        var value = {};
        var lines = INISyntax.split(/\r\n|\r|\n/);
        var section = null;
    
        for(let x=0;x<lines.length;x++){
            var match = "";
            if(regex.comment.test(lines[x])){
                return;
            }else if(regex.param.test(lines[x])){
                match = lines[x].match(regex.param);
                if(section){
                    value[section][match[1]] = match[2];
                }else{
                    value[match[1]] = match[2];
                }
            }else if(regex.section.test(lines[x])){
                match = lines[x].match(regex.section);
                value[match[1]] = {};
                section = match[1];
            }else if(lines.length === 0 && section){
                section = null;
            };
    
        }
    
        return value[section];
    }

    const saveAdvanced = async()=> {
        let url = SftGlobalData.baseURL_API + 'connection&action=updateAdvanced';
        let postData = {
                        key: props.api,
                        db_host: dbHost,
                        db_name: dbDatabase,
                        db_user: dbUser,
                        db_pass: dbPassword,
                        db_port: dbPort, user: props.username, sftUserId: props.userId
                    };
        axios.post(url, postData, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
        .then(response => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('postData', postData);
                console.log('response', response);
            }
            if(response.data.message === "Success"){
                props.notify(props.t('savedChanges'), 2000, "top-right", props.theme, 1); 
                setModalOpen(false);
            } else if(response.data.message === "error"){
                props.notify(response.data.error, 2000, "top-right", props.theme, 1); 
            } else {
                props.notify(props.t('errorOccured'), 2000, "top-right", props.theme, 1); 
            }
            
        });
    }

    const saveBasic = async() => {
        let licenceD = new Date(new Date().getTime()+(parseInt(daysValid)*24*60*60*1000));
        setLicenceDate(licenceD.getFullYear()+"-"+(licenceD.getMonth()+1).toString().padStart(2, '0')+"-"+licenceD.getDate().toString().padStart(2, '0'));

        try{
            let url = SftGlobalData.baseURL_API + 'client&action=editBasic';
            let postData = { key: props.api, days_valid: daysValid, name: name, user: props.username, sftUserId: props.userId, monthly_rent: monthlyRent, cust_id: softtouchCustId, orig_cust_id: props?.selectedDb?.cust_no }
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    if(softtouchCustId != props?.selectedDb?.cust_no){
                        props.onRefresh();
                    }
                });
        } catch(e){
            console.error(e);
        }


        props.notify(props.t('savedChanges'), 2000, "top-right", props.theme, 1); 
    }

    const addTraining = async () => {
        let url = SftGlobalData.baseURL_API + 'connection&action=addTraining';
        let postData = {
                        key: props.api,
                        db_host: dbTestHost,
                        db_name: dbTestDatabase,
                        db_user: dbTestUser,
                        db_pass: dbTestPassword,
                        db_port: dbTestPort, user: props.username, sftUserId: props.userId
                    };
        axios.post(url, postData, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
        .then(response => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('postData', postData);
                console.log('response', response);
            }
            props.notify(props.t('savedChanges'), 2000, "top-right", props.theme, 1); 
        });
    }

    const createAndAddTraining = async () => {
        setGeneratingTraining(true);
        let url = SftGlobalData.baseURL_API + 'connection&action=createTestDb';
        let postData = {
                        override: "EG68$#sO!*eCXx",
                        key: props.api, user: props.username, sftUserId: props.userId
                    };
        axios.post(url, postData, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
        .then(response => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('postData', postData);
                console.log('response', response);
            }
            props.notify('Training sync completed', 7500, "top-right", props.theme, 1); 
            setHasTraining("1");
            setGeneratingTraining(false);
            
        });
    }

    /*const handleChangeIndex = (index) => {
        setValue(index);
      };*/

    
    const getLicenceAmount = (licences) => {
        let max = licences.filter(licence => ((licence.id.match(new RegExp(searchValue, "i")) || licence.description.match(new RegExp(searchValue, "i")) || licence.remark.match(new RegExp(searchValue, "i")) || licence.warehouse.match(new RegExp(searchValue, "i")) || licence.pos_id.match(new RegExp(searchValue, "i"))) && (licence.active === "1")) ).length;
        let used = licences.filter(licence => (licence.description !== "" && licence.active === "1")).length;
        return "("+used+"/"+max+")";
    }
    //************************************************************************************************************************************
    //
    //                                              RETURN BODY (HTML)
    //
    //************************************************************************************************************************************
    return (
        <div className={((props.screenWidth) < 600 ? 'not_expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme : 'expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme) + (SftGlobalData.easter3 ? " pink" : "")} >
            {
                // --------------------------------------------------------------------
                // LOGGING
                // --------------------------------------------------------------------
            }
            {!disabled && <FloatingLogButton disabled={false} onClick={()=>{setLogModal(true)}} />}
            <StyledModal2
            aria-labelledby="unstyled-modal-title"
            aria-describedby="unstyled-modal-description"
            open={logModal}
            onClose={()=>{setLogModal(false)}}
            BackdropComponent={Backdrop}
            >
                <Box sx={style2} style={{minWidth: 300, backgroundColor: (props.theme === 'dark' ? Colors.dark_basic : Colors.light_basic)}} className={props.theme + " slide-in-right"}>
                    <LoggingModal login={props.loginObj} t={props.t} userId={props.userId} selectedDb={props.selectedDb} close={()=>{setLogModal(false);}} theme={props.theme}/>
                </Box>
            </StyledModal2>
            {
                // --------------------------------------------------------------------
                // MODAL
                // --------------------------------------------------------------------
            }
            <StyledModal
            aria-labelledby="unstyled-modal-title"
            aria-describedby="unstyled-modal-description"
            open={modalOpen}
            onClose={()=>{setModalOpen(false)}}
            BackdropComponent={Backdrop}
            >
                <Box sx={style} style={{minWidth: 400, backgroundColor: (props.theme === 'dark' ? Colors.dark_basic : Colors.light_basic)}} className={props.theme}>
                    <p className={"primary_border_bottom text_center margin_top_none margin_bottom_15px bold "} style={{fontWeight: 1200}}>{modalContent}</p>
                    {getModalContent()}
                    <Button className="modal_button_container_no_margin__button margin_top_10px" style={{width: '100%'}} onClick={()=>{setModalOpen(false);}}>{props.t("close")}</Button>
                </Box>
            </StyledModal>

            {
                // --------------------------------------------------------------------
                // LOADING VIEW
                // --------------------------------------------------------------------
            }

            {disabled && <div style={{display:"flex", justifyContent: "space-evenly", marginTop: 5}}>
                        <TailSpin 
                            height="40"
                            width="40"
                            color={Colors.sft_blue}
                            ariaLabel='loading'
                        />
                    </div>}

            {
                // --------------------------------------------------------------------
                // TITLE / HEADER
                // --------------------------------------------------------------------
            }
            {!disabled && <div className={'licence__container'} >
                {props.screenWidth >= 1200 && 
                    <div className={"licence_header"}>
                        <div>
                            <a className={"bold"} onClick={()=>{copyKeyToClipboard(name)}}>{name}</a>  {adminCheck() && <span>({daysValid})</span>} | <a onClick={()=>{copyKeyToClipboard(licenceKey)}}>{licenceKey}</a> | {host} [{dbVersion}]
                        </div>


                        
                        <div>
                            {adminCheck() && <Button className={"sft_blue_text"} onClick={()=>{setModalContent("Basic"); setModalOpen(true);}}><FontAwesomeIcon icon={faPencilAlt} color={Colors.sft_blue} size="1x" /> &nbsp;{props.t("basic")}</Button>} {adminCheck() && <span>|</span>}
                            {adminCheck() && <Button className={"sft_blue_text"} onClick={()=>{setModalContent("Advanced"); setModalOpen(true);}}><FontAwesomeIcon icon={faServer} color={Colors.sft_blue} size="1x" /> &nbsp;{props.t("advanced")}</Button>} {adminCheck() && <span>|</span>}
                            {devCheck() && <Button className={"sft_blue_text"} onClick={()=>{setModalContent("Training"); setModalOpen(true);}}><FontAwesomeIcon icon={faHammer} color={Colors.sft_blue} size="1x" /> &nbsp;{props.t("training")}</Button>} {devCheck() && <span>|</span>}
                            {(superCheck() || props.loginObj.sft_status === "3") && <Button className={"sft_blue_text"} onClick={()=>{setModalContent("Credentials"); setModalOpen(true);}}><FontAwesomeIcon icon={faKey} color={Colors.sft_blue} size="1x" /> &nbsp;{props.t("credentials")}</Button>} {adminCheck() && <span>|</span>}
                            {suppCheck() && <Button className={"sft_blue_text"} onClick={()=>{setModalContent("History"); setModalOpen(true);}}><FontAwesomeIcon icon={faHistory} color={Colors.sft_blue} size="1x" /> &nbsp;{props.t("history")}</Button>} {suppCheck() && <span>|</span>}
                            <Button className={"sft_blue_text"} onClick={()=>{setModalContent("QR"); setModalOpen(true);}}><FontAwesomeIcon icon={faQrcode} color={Colors.sft_blue} size="1x" /> &nbsp;QR</Button> {adminCheck() && <span>|</span>}
                            {adminCheck() && <Button className={"sft_blue_text"} onClick={()=>{setModalContent("Add"); setModalOpen(true);}}><FontAwesomeIcon icon={faPlus} color={Colors.sft_blue} size="1x" /> &nbsp;{props.t("add")}</Button>}
                        </div>
                        {/*dbId && containerConnection()}
                        {dbId && containerClient()}
                        {dbId && containerQr()*/}
                    </div>
                }
                {props.screenWidth < 900 && 
                    <div className={"licence_header"}>
                        <div>
                            <a className={"bold"} onClick={()=>{copyKeyToClipboard(name)}}>{name}</a> | <a onClick={()=>{copyKeyToClipboard(licenceKey)}}>{licenceKey}</a> | DB [{dbVersion}]
                        </div>
                        <br/>
                        <div>
                            {/*adminCheck() && <Button className={"sft_blue_text width_120px"} onClick={()=>{setModalContent("Basic"); setModalOpen(true);}}><FontAwesomeIcon icon={faPencilAlt} color={Colors.sft_blue} size="1x" /> &nbsp;{props.t("basic")}</Button>*/} 
                            {/*adminCheck() && <Button className={"sft_blue_text width_120px"} onClick={()=>{setModalContent("Advanced"); setModalOpen(true);}}><FontAwesomeIcon icon={faServer} color={Colors.sft_blue} size="1x" /> &nbsp;{props.t("advanced")}</Button>*/} 
                            {/*devCheck() && <Button className={"sft_blue_text width_120px"} onClick={()=>{setModalContent("Training"); setModalOpen(true);}}><FontAwesomeIcon icon={faHammer} color={Colors.sft_blue} size="1x" /> &nbsp;{props.t("training")}</Button>*/}
                            {/*devCheck() && <Button className={"sft_blue_text width_120px"} onClick={()=>{setModalContent("Credentials"); setModalOpen(true);}}><FontAwesomeIcon icon={faKey} color={Colors.sft_blue} size="1x" /> &nbsp;{props.t("credentials")}</Button>*/}
                            {/*suppCheck() && <Button className={"sft_blue_text width_120px"} onClick={()=>{setModalContent("History"); setModalOpen(true);}}><FontAwesomeIcon icon={faHistory} color={Colors.sft_blue} size="1x" /> &nbsp;{props.t("history")}</Button>*/}
                            {adminCheck() && <Button className={"sft_blue_text width_120px"} onClick={()=>{setModalContent("Add"); setModalOpen(true);}}><FontAwesomeIcon icon={faPlus} color={Colors.sft_blue} size="1x" /> &nbsp;{props.t("add")}</Button>}
                            <Button className={"sft_blue_text width_120px"} onClick={()=>{setModalContent("QR"); setModalOpen(true);}}><FontAwesomeIcon icon={faQrcode} color={Colors.sft_blue} size="1x" /> &nbsp;QR</Button>
                        </div>
                    </div>
                }

                {
                    // --------------------------------------------------------------------
                    // LICENTIES
                    // --------------------------------------------------------------------
                }
                {/*<div className={"container_list__subtitle border_radius " + props.theme}><h2 className={"text_left dark_text padding_left__10px"}>{props.t("licences")}</h2></div>
                */}
                <hr style={{marginTop: 5, marginBottom: 5}}/>
                {!loadingModules && <Box className={props.theme + ' licence__enivronment'}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 1 }} className={props.theme + ' container_tabs_below_subtitle'}>
                        <Tabs value={modulesTab} onChange={handleModuleChange} className={props.theme} variant="scrollable" scrollButtons="auto">
                            <Tab label="Licences" {...a11yProps(0)}  className={props.theme}/>
                            <Tab label="Administration" {...a11yProps(1)}  className={props.theme}/>
                            <Tab label="Memo" {...a11yProps(2)} className={props.theme}/>
                            <Tab label="EDI" {...a11yProps(3)}  className={props.theme}/>
                            <Tab label="API" {...a11yProps(4)}  className={props.theme}/>
                            <Tab label="SMS" {...a11yProps(5)} className={props.theme}/>
                            <Tab label="Add-ons" {...a11yProps(6)} className={props.theme}/>
                            <Tab label="Shipping data" {...a11yProps(7)} className={props.theme}/>
                            <Tab label="Logo" {...a11yProps(8)} className={props.theme}/>
                            {devCheck() && <Tab label="Door counter" {...a11yProps(9)}  className={props.theme}/>}
                            {devCheck() && <Tab label="Excel" {...a11yProps(10)} className={props.theme}/>}
                            {devCheck() && <Tab label="Scheduler (SMTP)" {...a11yProps(11)} className={props.theme}/>}
                            {(SftGlobalData.easter1 && SftGlobalData.easter2 && SftGlobalData.easter3) && <Tab label="Zalm" {...a11yProps(devCheck() ? 12 : 9)} className={props.theme}/>}
                            {(SftGlobalData.easter1 && SftGlobalData.easter2 && SftGlobalData.easter3) && <Tab label="Doom" {...a11yProps(devCheck() ? 13 : 10)} className={props.theme}/>}
                        </Tabs>
                    </Box>
                    <TabPanel style={{height: "100%"}} value={modulesTab} index={0}>
                        {
                            // Licences
                        }
                        <input 
                            type="text"
                            name="search"
                            className={props.theme+"_accent_darker txt_search_licence"}
                            style={{marginTop: 0, marginBottom: 0}}
                            placeholder={props.t('search')}
                            value={searchValue}
                            onChange={e => setSearchValue(e.target.value)}
                        />
                        <div style={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', flex: 1}}>
                            {suppCheck() && <Button onClick={()=>{setModalContent("QuickExtend"); setModalOpen(true);}} className={"sft_blue_text margin_right__10px"}><FontAwesomeIcon icon={faCalendarPlus} color={Colors.sft_blue} />&nbsp; {props.t("quick_extend")}</Button>}
                            {props.screenWidth > 900 && adminCheck() && <Button onClick={()=>{setModalContent("ExtendAll"); setModalOpen(true);}} className={"sft_blue_text margin_right__10px"}><FontAwesomeIcon icon={faCalendarPlus} color={Colors.sft_blue} />&nbsp; {props.t("extend_all")}</Button>}
                            <Button onClick={()=>{setModalContent("AutoUpdateAll"); setModalOpen(true);}} className={"sft_blue_text margin_right__10px"}><FontAwesomeIcon icon={faLevelUpAlt} color={Colors.sft_blue} />&nbsp; {props.t("auto_update")}</Button>
                            {props.screenWidth > 900 && adminCheck() && <Button onClick={()=>{setModalContent("TrialOffAll"); setModalOpen(true);}} className={"sft_blue_text margin_right__10px"}><FontAwesomeIcon icon={faCheckCircle} color={Colors.sft_blue} />&nbsp; {props.t("trial_off")}</Button>}
                            {/*adminCheck() && <Button onClick={()=>{setModalContent("ToggleStatusAll"); setModalOpen(true);}} className={"sft_blue_text margin_right__10px"}><FontAwesomeIcon icon={faStepBackward} color={Colors.sft_blue} />&nbsp; {props.t("toggle_status")}</Button>*/}
                            {props.screenWidth > 900 && adminCheck() && <Button onClick={()=>{setModalContent("StatusOnAll"); setModalOpen(true);}} className={"sft_blue_text margin_right__10px"}><FontAwesomeIcon icon={faPlay} color={Colors.sft_blue} />&nbsp; {props.t("on_status")}</Button>}
                            {props.screenWidth > 900 && adminCheck() && <Button onClick={()=>{setModalContent("StatusOffAll"); setModalOpen(true);}} className={"sft_blue_text margin_right__10px"}><FontAwesomeIcon icon={faStop} color={Colors.sft_blue} />&nbsp; {props.t("off_status")}</Button>}
                        </div>
                        <Box className={props.theme + ' licence__enivronment'}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className={props.theme + ' container_tabs_below_subtitle'}>
                                <Tabs value={value} onChange={handleChange} className={props.theme} variant="scrollable" scrollButtons="auto">
                                <Tab label={"Sales "+getLicenceAmount(salesLicences)} {...a11yProps(0)}  className={props.theme}/>
                                <Tab label={"Office "+getLicenceAmount(officeLicences)} {...a11yProps(1)}  className={props.theme}/>
                                <Tab label={"Cloud "+getLicenceAmount(cloudLicences)} {...a11yProps(2)} className={props.theme}/>
                                <Tab label={"Consultation "+getLicenceAmount(consultationLicences)} {...a11yProps(3)} className={props.theme}/>
                                <Tab label={"Collection "+getLicenceAmount(collectionLicences)} {...a11yProps(4)} className={props.theme}/>
                                <Tab label={"Smartphone "+getLicenceAmount(smartphoneLicences)} {...a11yProps(5)} className={props.theme}/>
                                <Tab label={"Smartscanner "+getLicenceAmount(smartscannerLicences)} {...a11yProps(6)} className={props.theme}/>
                                <Tab label={"Revenue "} {...a11yProps(7)} className={props.theme}/>
                                {adminCheck() && <Tab label="Connections " {...a11yProps(8)} className={props.theme}/>}
                                </Tabs>
                            </Box>
                            
                            <TabPanel value={value} index={0}>
                                <LicenceTableForm screenWidth={props.screenWidth} daysValid={daysValid} notify={(text, time, position, theme, id) => {props.notify(text, time, position, theme, id);}} username={props.username} userId={props.userId} licenceDate={licenceDate} adminCheck={((props.group === "ADMIN" || props.group === "SUPER"))} devCheck={((props.group === "ADMIN" || props.group === "SUPER") || props.group === "DEV")} suppCheck={((props.group === "ADMIN" || props.group === "SUPER") || props.group === "DEV" || props.group === "SUPP")} api={props.api} type={1} searchValue={searchValue} targetModal={(content, action, target)=>openTargetedModal(content, action, target)} licenceData={salesLicences} fixLicenceData={(data)=>{setSalesLicences(data);}} t={props.t} theme={props.theme}/>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <LicenceTableForm screenWidth={props.screenWidth} daysValid={daysValid} notify={(text, time, position, theme, id) => {props.notify(text, time, position, theme, id);}} username={props.username} userId={props.userId} licenceDate={licenceDate} adminCheck={((props.group === "ADMIN" || props.group === "SUPER"))} devCheck={((props.group === "ADMIN" || props.group === "SUPER") || props.group === "DEV")} suppCheck={((props.group === "ADMIN" || props.group === "SUPER") || props.group === "DEV" || props.group === "SUPP")} api={props.api} type={2} searchValue={searchValue} targetModal={(content, action, target)=>openTargetedModal(content, action, target)} licenceData={officeLicences} fixLicenceData={(data)=>{setOfficeLicences(data);}} t={props.t} theme={props.theme}/>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <LicenceTableForm screenWidth={props.screenWidth} daysValid={daysValid} notify={(text, time, position, theme, id) => {props.notify(text, time, position, theme, id);}} username={props.username} userId={props.userId} licenceDate={licenceDate} adminCheck={((props.group === "ADMIN" || props.group === "SUPER"))} devCheck={((props.group === "ADMIN" || props.group === "SUPER") || props.group === "DEV")} suppCheck={((props.group === "ADMIN" || props.group === "SUPER") || props.group === "DEV" || props.group === "SUPP")} api={props.api} type={6} searchValue={searchValue} targetModal={(content, action, target)=>openTargetedModal(content, action, target)} licenceData={cloudLicences} fixLicenceData={(data)=>{setCloudLicences(data);}} t={props.t} theme={props.theme}/>
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <LicenceTableForm screenWidth={props.screenWidth} daysValid={daysValid} notify={(text, time, position, theme, id) => {props.notify(text, time, position, theme, id);}} username={props.username} userId={props.userId} licenceDate={licenceDate} adminCheck={((props.group === "ADMIN" || props.group === "SUPER"))} devCheck={((props.group === "ADMIN" || props.group === "SUPER") || props.group === "DEV")} suppCheck={((props.group === "ADMIN" || props.group === "SUPER") || props.group === "DEV" || props.group === "SUPP")} api={props.api} type={3} searchValue={searchValue} targetModal={(content, action, target)=>openTargetedModal(content, action, target)} licenceData={consultationLicences} fixLicenceData={(data)=>{setConsultationLicences(data);}} t={props.t} theme={props.theme}/>
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                <LicenceTableForm screenWidth={props.screenWidth} daysValid={daysValid} notify={(text, time, position, theme, id) => {props.notify(text, time, position, theme, id);}} username={props.username} userId={props.userId} licenceDate={licenceDate} adminCheck={((props.group === "ADMIN" || props.group === "SUPER"))} devCheck={((props.group === "ADMIN" || props.group === "SUPER") || props.group === "DEV")} suppCheck={((props.group === "ADMIN" || props.group === "SUPER") || props.group === "DEV" || props.group === "SUPP")} api={props.api} type={4} searchValue={searchValue} targetModal={(content, action, target)=>openTargetedModal(content, action, target)} licenceData={collectionLicences} fixLicenceData={(data)=>{setCollectionLicenes(data);}} t={props.t} theme={props.theme}/>
                            </TabPanel>
                            <TabPanel value={value} index={5}>
                                <LicenceTableForm screenWidth={props.screenWidth} daysValid={daysValid} notify={(text, time, position, theme, id) => {props.notify(text, time, position, theme, id);}} username={props.username} userId={props.userId} licenceDate={licenceDate} adminCheck={((props.group === "ADMIN" || props.group === "SUPER"))} devCheck={((props.group === "ADMIN" || props.group === "SUPER") || props.group === "DEV")} suppCheck={((props.group === "ADMIN" || props.group === "SUPER") || props.group === "DEV" || props.group === "SUPP")} api={props.api} type={9} searchValue={searchValue} targetModal={(content, action, target)=>openTargetedModal(content, action, target)} licenceData={smartphoneLicences} fixLicenceData={(data)=>{setSmartphoneLicences(data);}} t={props.t} theme={props.theme}/>
                            </TabPanel>
                            <TabPanel value={value} index={6}>
                                <LicenceTableForm screenWidth={props.screenWidth} daysValid={daysValid} notify={(text, time, position, theme, id) => {props.notify(text, time, position, theme, id);}} username={props.username} userId={props.userId} licenceDate={licenceDate} adminCheck={((props.group === "ADMIN" || props.group === "SUPER"))} devCheck={((props.group === "ADMIN" || props.group === "SUPER") || props.group === "DEV")} suppCheck={((props.group === "ADMIN" || props.group === "SUPER") || props.group === "DEV" || props.group === "SUPP")} api={props.api} type={10} searchValue={searchValue} targetModal={(content, action, target)=>openTargetedModal(content, action, target)} licenceData={smartscannerLicences} fixLicenceData={(data)=>{setSmartscannerLicences(data);}} t={props.t} theme={props.theme}/>
                            </TabPanel>
                            <TabPanel value={value} index={7}>
                                <LicenceRevenueForm screenWidth={props.screenWidth} username={props.username} userId={props.userId} api={props.api} theme={props.theme} t={props.t} />
                            </TabPanel>
                            {adminCheck() && <TabPanel value={value} index={8}>
                                <LicenceConnectionsOverviewForm screenWidth={props.screenWidth} username={props.username} userId={props.userId} api={props.api} theme={props.theme} t={props.t} limited={true} notify={(text, time, position, theme, id) => {props.notify(text, time, position, theme, id);}} />
                            </TabPanel>}
                        </Box>
                    </TabPanel>
                    
                    <TabPanel style={{height: "100%"}} value={modulesTab} index={1}>
                        { 
                            // Administration
                        }
                        <LicenceAdministrationForm username={props.username} userId={props.userId} notify={(text, time, position, theme, id) => {props.notify(text, time, position, theme, id);}} dbId={dbId} custId={softtouchCustId} clientId={clientId} disabled={!adminCheck()} apiAccount={modules?.api?.accountId} apiData={modules?.api?.users} lang={props.lang} api={props.api} t={props.t} theme={props.theme} selectedDb={props.selectedDb} dbs={props.dbs} setDbs={(databases)=>{props.setDbs(databases);}} />
                    </TabPanel>
                    <TabPanel  style={{height: "100%"}} value={modulesTab} index={2}>
                        {
                            // CUSTOMER MEMO
                        }
                        <textarea className={(props.theme==='light') ? 'light_accent_darker textarea' : 'dark_accent_darker textarea'} id="memo" onChange={(event)=>{setMemo(event.target.value)}} name="memo" value={memo} style={{resize: 'vertical', minHeight: 650}}/>
                        <Button className="modal_button_container_no_margin__button margin_top_5px" style={{width: '100%'}} onClick={()=>{saveMemo()}}>{props.t('save')}</Button>
                    </TabPanel>
                    <TabPanel style={{height: "100%"}} value={modulesTab} index={3}>
                        {
                            // EDI
                        }
                        <LicenceEDIForm dbDescr={database} login={props.login} sft={props.loginObj.sft_status} username={props.username} userId={props.userId} theme={props.theme} t={props.t} api={props.api} notify={(text, time, position, theme, id) => {props.notify(text, time, position, theme, id);}} setMemo={(memo)=>{setMemo(memo);}}/>
                    </TabPanel>
                    <TabPanel value={modulesTab} style={{height: "100%"}} index={4}>
                        {
                            // API
                        }
                        <LicenceAPIForm disabled={!devCheck()} dbDescr={database} username={props.username} userId={props.userId} dbId={dbId} clientId={clientId} accountId={modules?.api?.accountId} apiData={modules?.api?.users} theme={props.theme} t={props.t} api={props.api} notify={(text, time, position, theme, id) => {props.notify(text, time, position, theme, id);}} setMemo={(memo)=>{setMemo(memo);}}/>
                    </TabPanel>
                    <TabPanel style={{height: "100%"}} value={modulesTab} index={5}>
                        {
                            // SMS
                        }
                        <LicenceSMSForm disabled={!adminCheck()} username={props.username} userId={props.userId} theme={props.theme} t={props.t} api={props.api} notify={(text, time, position, theme, id) => {props.notify(text, time, position, theme, id);}} />
                    </TabPanel>
                    <TabPanel style={{height: "100%"}} value={modulesTab} index={6}>
                        {
                            // ADD ONS
                        }
                        <LicenceAddonForm disabled={!adminCheck()} username={props.username} userId={props.userId} api={props.api} t={props.t} theme={props.theme} database={database} />
                    </TabPanel>
                    <TabPanel style={{height: "100%"}} value={modulesTab} index={7}>
                        {
                            // CUSTOMER DELIVERY ADDRESSES
                        }
                        <LicenceShippingForm username={props.username} userId={props.userId} api={props.api} theme={props.theme} t={props.t}/>
                    </TabPanel>
                    <TabPanel style={{height: "100%"}} value={modulesTab} index={8}>
                        {
                            // CUSTOMER LOGO
                        }
                        <LicenceLogoForm username={props.username} userId={props.userId} api={props.api} theme={props.theme} t={props.t} notify={(text, time, position, theme, id) => {props.notify(text, time, position, theme, id);}}/>
                    </TabPanel>
                    {devCheck() && <TabPanel style={{height: "100%"}} value={modulesTab} index={9}>
                        {
                            // DOOR COUNTER
                        }
                        <LicenceDoorCounterForm username={props.username} userId={props.userId} data={modules?.doorcounter} theme={props.theme} t={props.t} api={props.api} dbId={dbId} custId={softtouchCustId} clientId={clientId} notify={(text, time, position, theme, id) => {props.notify(text, time, position, theme, id);}}/>
                    </TabPanel>}
                    {devCheck() && <TabPanel style={{height: "100%", overflowY: 'auto'}} value={modulesTab} index={10}>
                        {
                            // EXCEL
                        }
                        <LicenceExcelForm username={props.username} userId={props.userId} api={props.api} t={props.t} theme={props.theme} database={database} />
                    </TabPanel>}
                    {devCheck() && <TabPanel style={{height: "100%"}} value={modulesTab} index={11}>
                        {
                            // SCHEDULER (SMTP)
                        }
                        <LicenceSchedulerForm username={props.username} userId={props.userId} theme={props.theme} t={props.t} api={props.api} notify={(text, time, position, theme, id) => {props.notify(text, time, position, theme, id);}} />
                    </TabPanel>}
                    { (SftGlobalData.easter1 && SftGlobalData.easter2 && SftGlobalData.easter3) &&
                        <TabPanel style={{height: "100%"}} value={modulesTab} index={devCheck() ? 12 : 9}>
                            <LicenceEaster />
                        </TabPanel>
                    }
                    { (SftGlobalData.easter1 && SftGlobalData.easter2 && SftGlobalData.easter3) &&
                        <TabPanel style={{height: "100%"}} value={modulesTab} index={devCheck() ? 13 : 10}>
                            <iframe width="1200" height="800" src="https://diekmann.github.io/wasm-fizzbuzz/doom/" title="Doom"/>
                        </TabPanel>
                    }
                </Box>}
            </div>}            
        </div>
    );
}

export default LicenceScreen;
