import '../../styles/main.css';
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import axios from 'axios';
import SftGlobalData from '../../library/SftGlobalData';

import { TailSpin } from  'react-loader-spinner';
import Colors from '../../library/Colors';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';

const ShippingOptions = (props) =>  {
    const [loading, setLoading] = useState(true);    
    const [options, setOptions] = useState([]);    
    const [trackingEmail, setTrackingEmail] = useState(props.shipmentOptions.tracking_email);
    const [trackingSms, setTrackingSms] = useState(props.shipmentOptions.tracking_sms);
    const [saturdayDelivery, setSaturdayDelivery] = useState(props.shipmentOptions.saturday_delivery);
    const [secondDelivery, setSecondDelivery] = useState(props.shipmentOptions.second_delivery);
    const [deliverySameday, setDeliverySameday] = useState(props.shipmentOptions.sameday_delivery);
    const [deliveryEvening, setDeliveryEvening] = useState(props.shipmentOptions.evening_delivery);
    const [returnLabel, setReturnLabel] = useState(props.shipmentOptions.return_label);
    const [signed, setSigned] = useState(props.shipmentOptions.signed);
    const [basicInsurance, setBasicInsurance] = useState(props.shipmentOptions.basic_insurance);
    const [extraInsurance, setExtraInsurance] = useState(props.shipmentOptions.extra_insurance);
    const [weight, setWeight] = useState(props.shipmentOptions.weight);
    const [parcelValue, setParcelValue] = useState(0);
    const [deliveryDate, setDeliveryDate] = useState(props.shipmentOptions.delivery_date);
    const [paper_size, setPaperSize] = useState(props.shipmentOptions.paper_size);
    const [receipt_nr, setReceipt_nr] = useState((typeof props.receiptNr != "undefined" ? props.receiptNr : generateOrderId()));

    /*
    let customer            = {firstname: "", lastname: "", company: "", street_name: "", house_number: "",zip: "", city: "",country: "",email: "", phone: "", language: ""};
    let shipper             = {name: "", company: "", street_name: "", house_number: "", zip: "", city: "", country: "", email: "", phone: ""};
    let shipment_type       = {shipper: "", provider: ""};
    let shipment_options    = {shipment_type: "", tracking_email: "", tracking_sms: "", saturday_delivery: "", second_delivery: "", sameday_delivery: "", evening_delivery: "", sameday_delivery: "", return_label: "", sameday_delivery: "", signed: "", basic_insurance: "", extra_insurance: "", weight: "", delivery_date: "", parcels: "", order_id: 0};*/
   
    useEffect(() => {
            if(props.api !== ""){             
                let url = SftGlobalData.baseURL_API + 'shipping&action=fetchShippingOptions';
                axios.post(url, 
                    {
                        key: props.api,
                        provider: props.shipmentType.shipper + "_" + props.shipmentType.provider 
                    }, {
                        headers : {
                            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                        }
                    }).then(response => {
                        if(SftGlobalData.debug){
                            console.log('receiptnr: ', props.receiptNr);
                            console.log('url', url);
                            console.log('data', {key: props.api});
                            console.log('response providers', response.data);
                        }                
                        setLoading(false);    
                        setOptions(response.data.options);
                        setDeliveryDate(response.data.options[0].suggested_deliverydate);  
                        setPaperSize(response.data.options[0].paper_size);             
                        var elementsInArray = response.data.options.length;
                        
                        for(let i = 0; i < elementsInArray; i++){
                            if(response.data.options[i].type === props.shipmentOptions["shipment_type"]){
                                props.setSelectedOption(i);
                                setTrackingEmail(response.data.options[i].tracking_email);   
                                setTrackingSms(response.data.options[i].tracking_sms);
                                setSaturdayDelivery(response.data.options[i].saturday_delivery);
                                setBasicInsurance(response.data.options[i].basic_insurance);
                                changeExtraInsurance(response.data.options[i].extra_insurance);
                                setSecondDelivery(response.data.options[i].second_delivery);
                                setSigned(response.data.options[i].signed);
                                setReturnLabel(response.data.options[i].return_label);
                                setDeliverySameday(response.data.options[i].delivery_sameday);
                                setDeliveryEvening(response.data.options[i].delivery_evening);        
                                setDeliveryDate(response.data.options[i].suggested_deliverydate);   
                                setPaperSize(response.data.options[i].paper_size);
                                if(props.showOptionsForm === '0' && props.showLabelButton === 'EMAIL' && props.error === ''){ 
                                    props.setSelectedPrintOption("email");
                                    let shipment_options    = {shipment_type: props.shipmentOptions["shipment_type"], 
                                    tracking_email:     response.data.options[i].tracking_email, 
                                    tracking_sms:       response.data.options[i].tracking_sms, 
                                    saturday_delivery:  response.data.options[i].saturday_delivery, 
                                    second_delivery:    response.data.options[i].second_delivery, 
                                    evening_delivery:   response.data.options[i].delivery_evening, 
                                    sameday_delivery:   response.data.options[i].suggested_deliverydate, 
                                    return_label:       response.data.options[i].return_label, 
                                    signed:             response.data.options[i].signed, 
                                    basic_insurance:    response.data.options[i].basic_insurance, 
                                    extra_insurance:    response.data.options[i].extra_insurance, 
                                    weight:             0, 
                                    parcelValue:        0, 
                                    delivery_date:      response.data.options[i].suggested_deliverydate, 
                                    parcels:            1,
                                    paper_size:         response.data.options[i].paper_size};

                                    props.setShipmentOptions(shipment_options);        
                                    props.switchForm(true);   
                                } else if(props.showOptionsForm === '0' && props.showLabelButton === 'PRE-DEFINE' && props.error === '')   {
                                    props.setSelectedPrintOption("shipping");
                                    let shipment_options    = {shipment_type: props.shipmentOptions["shipment_type"], 
                                    tracking_email:     response.data.options[i].tracking_email, 
                                    tracking_sms:       response.data.options[i].tracking_sms, 
                                    saturday_delivery:  response.data.options[i].saturday_delivery, 
                                    second_delivery:    response.data.options[i].second_delivery, 
                                    evening_delivery:   response.data.options[i].delivery_evening, 
                                    sameday_delivery:   response.data.options[i].suggested_deliverydate, 
                                    return_label:       response.data.options[i].return_label, 
                                    signed:             response.data.options[i].signed, 
                                    basic_insurance:    response.data.options[i].basic_insurance, 
                                    extra_insurance:    response.data.options[i].extra_insurance, 
                                    weight:             0, 
                                    parcelValue:        0, 
                                    delivery_date:      response.data.options[i].suggested_deliverydate, 
                                    parcels:            1,
                                    paper_size:         response.data.options[i].paper_size};

                                    props.setShipmentOptions(shipment_options);        
                                    props.switchForm(true);   
                                } else if(props.showOptionsForm === '0' && props.showLabelButton === 'PRINT' && props.error === '')   {
                                    props.setSelectedPrintOption("print");
                                    let shipment_options    = {shipment_type: props.shipmentOptions["shipment_type"], 
                                    tracking_email:     response.data.options[i].tracking_email, 
                                    tracking_sms:       response.data.options[i].tracking_sms, 
                                    saturday_delivery:  response.data.options[i].saturday_delivery, 
                                    second_delivery:    response.data.options[i].second_delivery, 
                                    evening_delivery:   response.data.options[i].delivery_evening, 
                                    sameday_delivery:   response.data.options[i].suggested_deliverydate, 
                                    return_label:       response.data.options[i].return_label, 
                                    signed:             response.data.options[i].signed, 
                                    basic_insurance:    response.data.options[i].basic_insurance, 
                                    extra_insurance:    response.data.options[i].extra_insurance, 
                                    weight:             0, 
                                    parcelValue:        0, 
                                    delivery_date:      response.data.options[i].suggested_deliverydate, 
                                    parcels:            1,
                                    paper_size:         response.data.options[i].paper_size};

                                    props.setShipmentOptions(shipment_options);        
                                    props.switchForm(true);   
                                }                
                            }
                        }
                    }
                ); 
            }
     },[props.api]);

     const handleChange = (event) => {
        props.setSelectedOption(event.target.value);
        setTrackingEmail(options[event.target.value].tracking_email);        
        setTrackingSms(options[event.target.value].tracking_sms);
        setSaturdayDelivery(options[event.target.value].saturday_delivery);
        setBasicInsurance(options[event.target.value].basic_insurance);        
        setSecondDelivery(options[event.target.value].second_delivery);
        setSigned(options[event.target.value].signed);
        setReturnLabel(options[event.target.value].return_label);
        setDeliverySameday(options[event.target.value].delivery_sameday);
        setDeliveryEvening(options[event.target.value].delivery_evening);        
        setDeliveryDate(options[event.target.value].suggested_deliverydate);
      };

    const displayShippingOptions = options.map((option, index) => {
        return (
            <MenuItem key={index} value={index}>{option.type}</MenuItem>
        );
    });

    
    function showTrackingEmail() {
        try {
            if(trackingEmail !== "invisible"){   
                if(options[props.selectedOption].tracking_email === "optional"){
                    return <div className={(props.screenWidth > 1400 ? 'shipping_option_full_width' : (props.screenWidth < 600 ? 'shipping_option_full_width' : 'shipping_option'))}>
                        <label className='shipping_option_text shipping_label'>{props.t('trackingEmail')}</label>
                        <FormControlLabel className={(props.hideSidebar === true ? 'shipping_toggle_full_width' : 'shipping_toggle')}
                            label=""
                            control={
                            <div>
                                <label className="switch align_right">                            
                                    <input type="checkbox" checked={(trackingEmail === "checked") ? true : false} onChange={()=>{changeCheckedStateTrackingEmail();}}/>
                                    <span className={"slider slider_" + props.theme + " round"}></span> 
                                </label>
                            </div>} 
                        />  
                    </div>    
                } else {
                    return <div className={(props.screenWidth > 1400 ? 'shipping_option_full_width' : (props.screenWidth < 600 ? 'shipping_option_full_width' : 'shipping_option'))}>
                        <label className='shipping_option_text shipping_label'>{props.t('trackingEmail')}</label>
                        <FormControlLabel className={(props.hideSidebar === true ? 'shipping_toggle_full_width' : 'shipping_toggle')}
                            label=""
                            control={
                            <div>
                                <label className="switch align_right">                            
                                    <input type="checkbox" checked={true} disabled="disabled" onChange={()=>{changeCheckedStateTrackingEmail();}}/>
                                    <span className={"slider slider_" + props.theme + " round"}></span> 
                                </label>
                            </div>} 
                        />  
                    </div>    
                }
            }
        } catch (error){}
    } 

    function changeCheckedStateTrackingEmail(){
        if(trackingEmail === "checked"){
            setTrackingEmail("optional");
        } else {
            setTrackingEmail("checked");
        }
    }

    function showTrackingSms() {
        try {
            if(trackingSms !== "invisible"){           
                if(options[props.selectedOption].tracking_sms === "optional"){
                    return <div className={(props.screenWidth > 1400 ? 'shipping_option_full_width' : (props.screenWidth < 600 ? 'shipping_option_full_width' : 'shipping_option'))}>
                        <label className='shipping_option_text shipping_label'>{props.t('trackingSms')}</label>
                        <FormControlLabel className={(props.hideSidebar === true ? 'shipping_toggle_full_width' : 'shipping_toggle')}
                            label=""
                            control={
                            <div>
                                <label className="switch align_right">                            
                                    <input type="checkbox" checked={(trackingSms === "checked") ? true : false} onChange={()=>{changeCheckedStateTrackingSms();}}/>
                                    <span className={"slider slider_" + props.theme + " round"}></span> 
                                </label>
                            </div>} 
                        />  
                    </div>    
                } else {
                    return <div className={(props.screenWidth > 1400 ? 'shipping_option_full_width' : (props.screenWidth < 600 ? 'shipping_option_full_width' : 'shipping_option'))}>
                        <label className='shipping_option_text shipping_label'>{props.t('trackingSms')}</label>
                        <FormControlLabel className={(props.hideSidebar === true ? 'shipping_toggle_full_width' : 'shipping_toggle')}
                            label=""
                            control={
                            <div>
                                <label className="switch align_right">                            
                                    <input type="checkbox" checked={true} disabled="disabled" onChange={()=>{changeCheckedStateTrackingSms();}}/>
                                    <span className={"slider slider_" + props.theme + " round"}></span> 
                                </label>
                            </div>} 
                        />  
                    </div>    
                }                
            }
        } catch (error){}
    } 

    function changeCheckedStateTrackingSms(){
        if(trackingSms === "checked"){
            setTrackingSms("optional");
        } else {
            setTrackingSms("checked");
        }
    }

    function showSaturdayDelivery() {
        try {
            if(saturdayDelivery !== "invisible"){       
                if(options[props.selectedOption].saturday_delivery === "optional"){
                    return <div className={(props.screenWidth > 1400 ? 'shipping_option_full_width' : (props.screenWidth < 600 ? 'shipping_option_full_width' : 'shipping_option'))}>
                        <label className='shipping_option_text shipping_label'>{props.t('saturdayDelivery')}</label>
                        <FormControlLabel className={(props.hideSidebar === true ? 'shipping_toggle_full_width' : 'shipping_toggle')}
                            label=""
                            control={
                            <div>
                                <label className="switch align_right">                            
                                    <input type="checkbox" checked={(saturdayDelivery === "checked") ? true : false} onChange={()=>{changeCheckedStateSaturdayDeliver();}}/>
                                    <span className={"slider slider_" + props.theme + " round"}></span> 
                                </label>
                            </div>} 
                        />  
                    </div>    
                } else {
                    return <div className={(props.screenWidth > 1400 ? 'shipping_option_full_width' : (props.screenWidth < 600 ? 'shipping_option_full_width' : 'shipping_option'))}>
                        <label className='shipping_option_text shipping_label'>{props.t('saturdayDelivery')}</label>
                        <FormControlLabel className={(props.hideSidebar === true ? 'shipping_toggle_full_width' : 'shipping_toggle')}
                            label=""
                            control={
                            <div>
                                <label className="switch align_right">                            
                                    <input type="checkbox" checked={true} disabled="disabled" onChange={()=>{changeCheckedStateSaturdayDeliver();}}/>
                                    <span className={"slider slider_" + props.theme + " round"}></span> 
                                </label>
                            </div>} 
                        />  
                    </div>    
                }   
            }
        } catch (error){}
    }  

    function changeCheckedStateSaturdayDeliver(){
        if(saturdayDelivery === "checked"){
            setSaturdayDelivery("optional");
        } else {
            setSaturdayDelivery("checked");
        }
    }

    function showSignedDelivery() {
        try {
            if(signed !== "invisible"){           
                if(options[props.selectedOption].signed === "optional"){
                    return <div className={(props.screenWidth > 1400 ? 'shipping_option_full_width' : (props.screenWidth < 600 ? 'shipping_option_full_width' : 'shipping_option'))}>
                        <label className='shipping_option_text shipping_label'>{props.t('signedDelivery')}</label>
                        <FormControlLabel className={(props.hideSidebar === true ? 'shipping_toggle_full_width' : 'shipping_toggle')}
                            label=""
                            control={
                            <div>
                                <label className="switch align_right">                            
                                    <input type="checkbox" checked={(signed === "checked") ? true : false} onChange={()=>{changeCheckedStateSigned();}}/>
                                    <span className={"slider slider_" + props.theme + " round"}></span> 
                                </label>
                            </div>} 
                        />  
                    </div>    
                } else {
                    return <div className={(props.screenWidth > 1400 ? 'shipping_option_full_width' : (props.screenWidth < 600 ? 'shipping_option_full_width' : 'shipping_option'))}>
                        <label className='shipping_option_text shipping_label'>{props.t('signedDelivery')}</label>
                        <FormControlLabel className={(props.hideSidebar === true ? 'shipping_toggle_full_width' : 'shipping_toggle')}
                            label=""
                            control={
                            <div>
                                <label className="switch align_right">                            
                                    <input type="checkbox" checked={true} disabled="disabled" onChange={()=>{changeCheckedStateSigned();}}/>
                                    <span className={"slider slider_" + props.theme + " round"}></span> 
                                </label>
                            </div>} 
                        />  
                    </div>    
                }        
            }
        } catch (error){}
    }    

    function changeCheckedStateSigned(){
        if(signed === "checked"){
            setSigned("optional");
        } else {
            setSigned("checked");
        }
    }

    function showSecondPresentation() {
        try {
            if(secondDelivery !== "invisible"){       
                if(options[props.selectedOption].second_delivery === "optional"){
                    return <div className={(props.screenWidth > 1400 ? 'shipping_option_full_width' : (props.screenWidth < 600 ? 'shipping_option_full_width' : 'shipping_option'))}>
                        <label className='shipping_option_text shipping_label'>{props.t('secondPresentation')}</label>
                        <FormControlLabel className={(props.hideSidebar === true ? 'shipping_toggle_full_width' : 'shipping_toggle')}
                            label=""
                            control={
                            <div>
                                <label className="switch align_right">                            
                                    <input type="checkbox" checked={(secondDelivery === "checked") ? true : false} onChange={()=>{changeCheckedStateSecondPresentation();}}/>
                                    <span className={"slider slider_" + props.theme + " round"}></span> 
                                </label>
                            </div>} 
                        />  
                    </div>    
                } else {
                    return <div className={(props.screenWidth > 1400 ? 'shipping_option_full_width' : (props.screenWidth < 600 ? 'shipping_option_full_width' : 'shipping_option'))}>
                        <label className='shipping_option_text shipping_label'>{props.t('secondPresentation')}</label>
                        <FormControlLabel className={(props.hideSidebar === true ? 'shipping_toggle_full_width' : 'shipping_toggle')}
                            label=""
                            control={
                            <div>
                                <label className="switch align_right">                            
                                    <input type="checkbox" checked={true} disabled="disabled" onChange={()=>{changeCheckedStateSecondPresentation();}}/>
                                    <span className={"slider slider_" + props.theme + " round"}></span> 
                                </label>
                            </div>} 
                        />  
                    </div>    
                }        
            }
        } catch (error){}
    }

    function changeCheckedStateSecondPresentation(){
        if(secondDelivery === "checked"){
            setSecondDelivery("optional");
        } else {
            setSecondDelivery("checked");
        }
    }

    function showReturnLabel() {
        try {           
            return <div className={(props.screenWidth > 1400 ? 'shipping_option_full_width' : (props.screenWidth < 600 ? 'shipping_option_full_width' : 'shipping_option'))}>
                <label className='shipping_option_text shipping_label'>{props.t('returnLabel')}</label>
                <FormControlLabel className={(props.hideSidebar === true ? 'shipping_toggle_full_width' : 'shipping_toggle')}
                    label=""
                    control={
                    <div>
                        <label className="switch align_right">                            
                            <input type="checkbox" checked={(returnLabel === "checked") ? true : false} onChange={()=>{changeCheckedStateReturnLabel();}}/>
                            <span className={"slider slider_" + props.theme + " round"}></span> 
                        </label>
                    </div>} 
                />  
            </div>       
        } catch (error){}
    } 

    function changeCheckedStateReturnLabel(){
        if(returnLabel === "checked"){
            setReturnLabel("optional");
        } else {
            setReturnLabel("checked");
        }
    }

    function showBasicInsurance() {
        try {
            if(basicInsurance !== "invisible"){     
                if(options[props.selectedOption].basic_insurance === "optional"){
                    return <div className={(props.screenWidth > 1400 ? 'shipping_option_full_width' : (props.screenWidth < 600 ? 'shipping_option_full_width' : 'shipping_option'))}>
                        <label className='shipping_option_text shipping_label'>{props.t('basicInsurance')}</label>
                        <FormControlLabel className={(props.hideSidebar === true ? 'shipping_toggle_full_width' : 'shipping_toggle')}
                            label=""
                            control={
                            <div>
                                <label className="switch align_right">                            
                                    <input type="checkbox" checked={(basicInsurance === "checked") ? true : false} onChange={()=>{changeCheckedStateBasicInsurance();}}/>
                                    <span className={"slider slider_" + props.theme + " round"}></span> 
                                </label>
                            </div>} 
                        />  
                    </div>    
                } else {
                    return <div className={(props.screenWidth > 1400 ? 'shipping_option_full_width' : (props.screenWidth < 600 ? 'shipping_option_full_width' : 'shipping_option'))}>
                        <label className='shipping_option_text shipping_label'>{props.t('basicInsurance')}</label>
                        <FormControlLabel className={(props.hideSidebar === true ? 'shipping_toggle_full_width' : 'shipping_toggle')}
                            label=""
                            control={
                            <div>
                                <label className="switch align_right">                            
                                    <input type="checkbox" checked={true} disabled="disabled" onChange={()=>{changeCheckedStateBasicInsurance();}}/>
                                    <span className={"slider slider_" + props.theme + " round"}></span> 
                                </label>
                            </div>} 
                        />  
                    </div>    
                } 
            }
        } catch (error){}
    }  

    function changeCheckedStateBasicInsurance(){
        if(basicInsurance === "checked"){
            setBasicInsurance("optional");
        } else {
            setBasicInsurance("checked");
        }
    }

    function showExtraInsurance() {
        try {
            if(extraInsurance !== "invisible"){               
                return <TextField
                className={(props.theme==='light') ? 'light_accent  ' + (props.screenWidth <= 1400 ? ' shipping_width_49perc shipping_float_left shipping_field': 'shipping_field_full_width') +  ' margin_top_10px' : 'dark_accent_darker ' + (props.screenWidth < 1400 ? 'shipping_width_49perc shipping_float_left': 'shipping_field_full_width') + ' margin_top_10px'}
                id="extra_insurance"
                label={props.t('extraInsurance')}
                sx={(props.theme==='light') ? {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.light_text
                      },
                    '& .MuiInputLabel-root': {
                        color: Colors.light_text
                    }} 
                    :
                    {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.dark_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.dark_text
                        }}}
                value={extraInsurance}
                onChange={(event)=>{changeExtraInsurance(event.target.value)}}
                />      
            } else {
                return "";
            }
        } catch (error){}
    }    

    function changeExtraInsurance(newValue){
        if(validationTest(newValue, /^\d+$/)){
            setExtraInsurance(newValue);
        } else if(newValue === ""){
            setExtraInsurance(newValue);
        }
    }

    function showSameDayDelivery() {
        try {
            if(deliverySameday !== "invisible"){               
                return <div className={(props.screenWidth > 1400 ? 'shipping_option_full_width' : (props.screenWidth < 600 ? 'shipping_option_full_width' : 'shipping_option'))}>
                <label className='shipping_option_text shipping_label'>{props.t('sameDayDelivery')}</label>
                <FormControlLabel className={(props.hideSidebar === true ? 'shipping_toggle_full_width' : 'shipping_toggle')}
                    label=""
                    control={
                    <div>
                        <label className="switch align_right">
                            <input type="checkbox" checked={(deliverySameday === "checked") ? true : false} onChange={()=>{changeCheckedStateSameDayDelivery();}} />
                            <span className={"slider slider_" + props.theme + " round"}></span>
                        </label>
                    </div>} 
                />  
            </div>
            }
        } catch (error){}
    }

    function changeCheckedStateSameDayDelivery(){
        if(deliverySameday === "checked"){
            setDeliverySameday("optional");
        } else {
            setDeliverySameday("checked");
        }
    }

    function showEveningDelivery() {
        try {
            if(deliveryEvening !== "invisible"){               
                return  <div className={(props.screenWidth > 1400 ? 'shipping_option_full_width' : (props.screenWidth < 600 ? 'shipping_option_full_width' : 'shipping_option'))}>
                <label className='shipping_option_text shipping_label'>{props.t('eveningDelivery')}</label>
                <FormControlLabel className={(props.hideSidebar === true ? 'shipping_toggle_full_width' : 'shipping_toggle')}
                    label=""
                    control={
                    <div>
                        <label className="switch align_right">
                            <input type="checkbox" checked={(deliveryEvening === "checked") ? true : false} onChange={()=>{changeCheckedStateEveningDelivery();}} />
                            <span className={"slider slider_" + props.theme + " round"}></span>
                        </label>
                    </div>} 
                />  
            </div>
            }
        } catch (error){}
    }

    function changeCheckedStateEveningDelivery(){
        if(deliveryEvening === "checked"){
            setDeliveryEvening("optional");
        } else {
            setDeliveryEvening("checked");
        }
    }

    function showWeight() {
        try {
            if(weight !== "invisible"){               
                return <TextField
                className={(props.theme==='light') ? 'light_accent ' + (props.screenWidth <= 1400 ? 'shipping_width_50perc shipping_float_left': 'shipping_field_full_width') +  ' margin_top_10px' : 'dark_accent_darker ' + (props.screenWidth <= 1400 ? 'shipping_width_50perc shipping_float_left': 'shipping_field_full_width') +  ' margin_top_10px'}
                id="weight"
                label={props.t('weight')}
                sx={(props.theme==='light') ? {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.light_text
                      },
                    '& .MuiInputLabel-root': {
                        color: Colors.light_text
                    }} 
                    :
                    {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.dark_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.dark_text
                        }}}
                value={weight}
                onChange={(event)=>{changeWeight(event.target.value)}}
                />      
            }
        } catch (error){}
    }    

    function changeWeight(newWeight){
        if(validationTest(newWeight, /^\d+$/)){
            setWeight(newWeight);
        } else if(newWeight === ""){
            setWeight(newWeight);
        }
    }

    function showParcelValue() {
        try {
            if(parcelValue !== "invisible"){               
                return <TextField
                className={(props.theme==='light') ? 'light_accent ' + (props.screenWidth <= 1400 ? 'shipping_width_50perc shipping_float_right': 'shipping_field_full_width') +  ' margin_top_10px' : 'dark_accent_darker ' + (props.screenWidth <= 1400 ? 'shipping_width_50perc shipping_float_right': 'shipping_field_full_width') +  ' margin_top_10px'}
                id="parcelValue"
                label={props.t('parcelValue')}
                sx={(props.theme==='light') ? {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.light_text
                      },
                    '& .MuiInputLabel-root': {
                        color: Colors.light_text
                    }} 
                    :
                    {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.dark_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.dark_text
                        }}}
                value={parcelValue}
                onChange={(event)=>{changeParcelValue(event.target.value)}}
                />      
            }
        } catch (error){}
    }    

    function changeParcelValue(newParcelValue){
        if(validationTest(newParcelValue, /^\d+$/)){
            setParcelValue(newParcelValue);
        } else if(newParcelValue === ""){
            setParcelValue(newParcelValue);
        }
    }

    function showDatePicker() {
      try {
        if(deliveryDate !== "invisible"){               
            return <TextField
            className={(props.theme==='light') ? 'light_accent ' + (props.screenWidth <= 1400 ? 'shipping_width_49perc shipping_float_left': ' shipping_field_full_width') +  ' margin_top_10px' : 'dark_accent_darker  ' + (props.screenWidth <= 1400 ? 'shipping_width_49perc shipping_float_left': 'shipping_field_full_width') +  ' margin_top_10px'}
            id="date"
            label={props.t('deliveryDate')}
            type="date"
            sx={(props.theme==='light') ? {
                '& .MuiOutlinedInput-input': {
                    color: Colors.light_text
                  },
                '& .MuiInputLabel-root': {
                    color: Colors.light_text
                }} 
                :
                {
                '& .MuiOutlinedInput-input': {
                    color: Colors.dark_text
                    },
                    '& .MuiInputLabel-root': {
                        color: Colors.dark_text
                    }}}
            value={deliveryDate}
            onChange={(event)=>{changeDeliveryDate(event.target.value)}}
            InputLabelProps={{
                shrink: true,
              }}
            />      
        }
    } catch (error){}
    }    

    function changeDeliveryDate(newDeliveryDate){
        let date = new Date();
        if( new Date(newDeliveryDate) >= new Date(date)){
            setDeliveryDate(newDeliveryDate);            
        }
    }

    function showOrderNr() {
        try {                      
            return <TextField
            className={(props.theme==='light') ? 'light_accent ' + (props.screenWidth <= 1400 ? (props.screenWidth < 600 ? ' shipping_field_full_width ' : ' shipping_width_49perc ') : ' shipping_field_full_width ') +  ' margin_top_10px' : 'dark_accent_darker ' + (props.screenWidth <= 1400 ? (props.screenWidth < 600 ? ' shipping_field_full_width ' : ' shipping_width_49perc ') : 'shipping_field_full_width') +  ' margin_top_10px'}
            id="orderId"
            label={props.t('orderId')}
            sx={(props.theme==='light') ? {
                '& .MuiOutlinedInput-input': {
                    color: Colors.light_text
                    },
                '& .MuiInputLabel-root': {
                    color: Colors.light_text
                }} 
                :
                {
                '& .MuiOutlinedInput-input': {
                    color: Colors.dark_text
                    },
                    '& .MuiInputLabel-root': {
                        color: Colors.dark_text
                    }}}
            value={receipt_nr}
            onChange={(event)=>{props.setReceiptNr(event.target.value); setReceipt_nr(event.target.value);}}
            />               
        } catch (error){}
    }    

    function generateOrderId(){
        let receiptnr = "man_" + Math.round((new Date()).getTime() / 1000);
        props.setReceiptNr(receiptnr);
        return receiptnr;
    }

    function showOptions() {
        return (<div className='_100perc'>
            <InputLabel>{props.t('shipmentType')}</InputLabel>
            <Select
            className={'shipping_options ' + props.theme}
            id="shipment-type"
            value={props.selectedOption}
            label={props.t('shipmentType')}
            onChange={handleChange}
            sx={(props.theme==='light') ? {
                '& .MuiInputLabel-root ': {
                    color: Colors.light_text                        
                  },
                '& .MuiSelect-outlined ': {
                    height: '18px',
                    padding: '8px 14px'
                },
                '& .MuiSelect-nativeInput ': {
                    color: Colors.light_text,
                    padddingLeft: '0px'
                    }
                } 
                :
                {
                '& .MuiInputLabel-root ': {
                    color: Colors.dark_text
                    },
                '& .MuiSelect-outlined ': {
                    height: '18px',
                    padding: '8px 14px'
                },
                '& .MuiSelect-nativeInput ': {
                    color: Colors.dark_text,
                    padddingLeft: '0px'
                    }  
                }}
            >
            {displayShippingOptions}
            </Select>
            <div className='margin_top_10px'>
                <div className={(props.screenWidth <= 1400 ? (props.screenWidth < 600 ? 'margin_bottom_10px' : 'shipping_fields') : '')}>
                {showTrackingEmail()}
                {showTrackingSms()}
                {showSaturdayDelivery()}
                {showSignedDelivery()}
                {showSecondPresentation()}
                {showReturnLabel()}
                {showSameDayDelivery()}
                {showEveningDelivery()}
                {showBasicInsurance()}
                </div>
               
                    {showExtraInsurance()}
                    {showWeight()}
                    {showParcelValue()}
                    {showDatePicker()}
                    {showOrderNr()}
                
            </div>
        </div>)
     }

    function validateData(printOption){
        props.setSelectedPrintOption(printOption);
        let shipment_options    = {shipment_type: options[props.selectedOption].type, tracking_email: trackingEmail, tracking_sms: trackingSms, saturday_delivery: saturdayDelivery, second_delivery: secondDelivery, evening_delivery: deliveryEvening, sameday_delivery: deliverySameday, return_label: returnLabel, signed: signed, basic_insurance: basicInsurance, extra_insurance: (extraInsurance !== "" ? extraInsurance : 0), weight: (weight !== "" ? weight : 0), parcelValue: (parcelValue !== "" ? parcelValue : 0), delivery_date: deliveryDate, parcels: props.shipmentOptions.parcels, paper_size: paper_size};
        props.setShipmentOptions(shipment_options);        
        props.switchForm(true);
    }

    function validationTest(data, validationString) 
    {
        if(data === undefined)
            return false;

        if (validationString.test(data))
        {
            return true;
        } else {
            return false;
        }
    }
      
  return (
    <div style={{padding: 5, height: '100%'}} >     
        { <div className="container_list__title margin_bottom_none">
                {/*<Button variant="contained" className={'button_container__button width_140px align_left'} onClick={()=>{props.switchForm(false);}}>{props.t('back')}</Button>
                <h2 className={(props.theme==='dark') ? "dark container_list__title detail" : "light container_list__title detail"} type="text" id="list_name" name="listName">{props.t('titleShippingOptions')}</h2>*/}

                {props.screenWidth >= 600 && <Button variant="contained" className={'button_container__button width_140px align_left'} onClick={()=>{props.switchForm(false);}}>{props.t('back')}</Button>}
                {props.screenWidth < 600 && <h2 className={(props.theme==='dark') ? "dark " : "light margin_bottom_none "} style={{textAlign: 'center', width: '100%'}}>{props.t('titleShippingOptions')}</h2>}
                {props.screenWidth >= 600 && <h2 className={(props.theme==='dark') ? "dark container_list__title  detail" : "light container_list__title margin_bottom_none detail"} type="text" id="list_name" name="listName">{props.t('titleShippingOptions')}</h2>}
                {props.screenWidth < 600 && <div className="margin_bottom_10px"></div>}
                {props.screenWidth < 600 && <Button variant="contained" className={'button_container__button width_140px align_left'} onClick={()=>{props.switchForm(false);}}>{props.t('back')}</Button>}
            </div>}
        {loading && <div className={"container_list__title " + props.theme} style={{display:"flex", justifyContent: "space-evenly", marginTop: 5, border: 1}}>
                        <TailSpin 
                            height="40"
                            width="40"
                            color={Colors.sft_blue}
                            ariaLabel='loading'
                        />
                    </div>}
        {!loading && <div className="app-content shipping">{showOptions()}</div>}
        {props.screenWidth < 600 && <div><br/></div>}
        {(props.showLabelButton === "ALL" || props.showLabelButton === "EMAIL") ? <Button variant="contained" className={(props.screenWidth < 600 ? 'button_container__button ' : 'button_container__button width_140px align_right shipping_button_high') + (props.screenWidth <= 1400 ? (props.screenWidth < 600 ? ' shipping_field_full_width ' :'shipping_bottom_20px shipping_button_move_up') : '')} onClick={()=>{validateData("email"); }}>{props.t('sendLabelEmail')}</Button> : <div></div>}
        {(props.showLabelButton === "ALL" || props.showLabelButton === "PRE-DEFINE") ? <Button variant="contained" className={(props.screenWidth < 600 ? 'button_container__button ' : 'button_container__button width_140px align_right shipping_button_high') + (props.screenWidth <= 1400 ? (props.screenWidth < 600 ? ' shipping_field_full_width ' :'shipping_bottom_20px shipping_button_move_up') : '')} onClick={()=>{validateData("shipping");}}>{props.t('generateShipping')}</Button> : <div></div>}
        {(props.showLabelButton === "ALL" || props.showLabelButton === "PRINT") ? <Button variant="contained" className={(props.screenWidth < 600 ? 'button_container__button ' : 'button_container__button width_140px align_right shipping_button_high') + (props.screenWidth <= 1400 ? (props.screenWidth < 600 ? ' shipping_field_full_width ' :'shipping_bottom_20px shipping_button_move_up') : '')} onClick={()=>{validateData("print");}}>{props.t('printLabel')}</Button> : <div></div>}
    </div>
  );
}

export default ShippingOptions;
