import React, { useState, useEffect, useMemo } from 'react';
import { Switch, TextField, Button, Modal, Box, Typography, MenuItem, Select, FormControl, ToggleButton, ToggleButtonGroup } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import leeuw from '../../assets/leeuw.png';
import coq from '../../assets/coq.png';
const useStyles = makeStyles(() => ({
  list: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)', // Creates two equal-width columns
    gap: '10px', // Adds some space between the items
  },
  listItem: {
    width: '100%', // Ensures the item spans the full width of the column
    textAlign: 'center',
  },
}));

const MatchingModal = ({
  open,
  onClose,
  currentRow,
  handleAction,
  isFamilyInputVisible,
  selectedClient,
  handleClientSelection,
  familyMemberFirstName,
  familyMemberLastName,
  setFamilyMemberFirstName,
  setFamilyMemberLastName,
  t,
  theme,
  customers,
}) => {
  const classes = useStyles();

  const [addAsNew, setAddAsNew] = useState(false);
  const [newClientCompanyName, setNewClientCompanyName] = useState("");
  const [newClientFirstName, setNewClientFirstName] = useState("");
  const [newClientLastName, setNewClientLastName] = useState("");
  const [selectedClientEmail1, setSelectedClientEmail1] = useState("");
  const [selectedClientEmail2, setSelectedClientEmail2] = useState("");
  const [newClientType, setNewClientType] = React.useState("1");

  const handleNewClientType = (event, newAlignment) => {
    setNewClientType(newAlignment);
  };

  const [selectedExistingClient, setSelectedExisitingClient] = useState(null);

  const [selectedEmails, setSelectedEmails] = useState([]);
  const emails = useMemo(() =>
    currentRow?.zoho_email ? currentRow?.zoho_email.split(",").map(email => email.trim()) : [],
    [currentRow?.zoho_email]
  );

  useEffect(() => {
    console.log("customers");
    console.log(customers);
    console.log(theme);
    // If there is only one email, select it by default
    if (emails.length === 1) {
      setSelectedEmails([emails[0]]);
      setSelectedClientEmail1(currentRow.email ?? '');
      setSelectedClientEmail2(currentRow.email2 ?? '')
    }
  }, [emails]);

  useEffect(() => {
    // If there is only one email, select it by default
    if(currentRow?.id?.includes("_no_match")) {
      setAddAsNew(true);
    }else{
      setAddAsNew(false);
      console.log("currentRow?.client_id_matches.split(",").length:", currentRow?.client_id_matches.split(",").length);

      if (currentRow?.client_id_matches.split(",").length === 1) {
        handleClientSelection(currentRow.client_id_matches.split(",")[0]);
      }
    }
  }, [currentRow?.client_id_matches, currentRow?.id]);

  const handleSelectEmail = (email) => {
    if (emails.length === 1) {
      // Do nothing if there's only one email, as it must stay selected
      return;
    }

    // Enable single selection only (current behavior)
    if (selectedEmails.includes(email)) {
      setSelectedEmails([]); // Deselect if already selected
    } else {
      setSelectedEmails([email]); // Select the new email
    }

    // Multiple selection logic (to be uncommented for future use)
    /*
    setSelectedEmails(prevSelectedEmails => 
      prevSelectedEmails.includes(email) 
        ? prevSelectedEmails.filter(selectedEmail => selectedEmail !== email)
        : [...prevSelectedEmails, email]
    );
    */
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose(false);
        setFamilyMemberFirstName('');
        setFamilyMemberLastName('');
        setSelectedEmails([]);
        handleClientSelection('');
        setAddAsNew(false);
        console.log("modalClosed");
      }} // Reset state
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 800,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-title" variant="h6" component="h2">
          Matching Information

          <Switch
            checked={currentRow?.id?.includes("_no_match") ? true : addAsNew}
            className='align_right'
            style={{ marginRight: 10 }}
            onChange={() => { 
              handleAction("checkCreateNew");
              setAddAsNew(!addAsNew);
             }}
            disabled={currentRow?.id?.includes("_no_match")}
            inputProps={{ 'aria-label': 'controlled' }}
          />

        </Typography>

        <Typography component="div" sx={{ mt: 1 }}>
              <b>Proposed email:</b>
              <List className={classes.list}>
                {emails.length > 0
                  ? emails.map((email, index) => (
                    <ListItem
                      key={index}
                      className={classes.listItem}
                      button
                      selected={selectedEmails.includes(email)}
                      onClick={() => handleSelectEmail(email)}
                    >
                      <ListItemText primary={email} />
                    </ListItem>
                  ))
                  : <ListItem>No email provided</ListItem>}
              </List>
        </Typography>

        {currentRow && (
          <>

            {addAsNew ? (
              <FormControl fullWidth sx={{ mt: 2 }}>
                <Typography component="div" sx={{ mb: 2 }}>
                  <b>Add to exisiting client:</b>
                </Typography>
                <Autocomplete
                  disablePortal
                  value={selectedExistingClient}
                  onChange={(event, newValue) => {
                    setSelectedExisitingClient(newValue);
                  }}
                  options={customers}
                  getOptionLabel={(option) => `${option.klnr}: ${option.firmanaam}`} // Ensures label is displayed properly
                  renderOption={(props, option) => (
                    <MenuItem {...props} key={option.klnr}>
                      <ListItemText
                        primary={`${option?.klnr}: ${option?.firmanaam}`}
                        secondary={`Email: ${option.email ?? ''} | Email2: ${option.email2 ?? ""}`}
                      />
                    </MenuItem>
                  )}
                  renderInput={(params) => <TextField {...params} label="customers" />}
                />
                  
              {((selectedExistingClient?.email && !selectedExistingClient?.email2) || (!selectedExistingClient?.email && !selectedExistingClient?.email2)) && selectedEmails.length > 0 && selectedExistingClient && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                  onClick={() => handleAction('addToEmail1_exist', { klnr: selectedExistingClient.klnr, email: selectedExistingClient.email ,email2: selectedEmails[0] })}
                  style={{ width: '100%' }}
                >
                  Set proposed email to Email 2
                </Button>
              )}

              {((!selectedExistingClient?.email && selectedExistingClient?.email2) || (!selectedExistingClient?.email && !selectedExistingClient?.email2))  && selectedEmails.length > 0 && selectedExistingClient?.email && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                  onClick={() => handleAction('addToEmail2_exist', { klnr: selectedExistingClient.klnr, email2: selectedExistingClient.email ,email: selectedEmails[0] })}
                  style={{ width: '100%' }}
                >
                  Set proposed email to Email 1
                </Button>
              )}

              {selectedExistingClient?.email && selectedExistingClient?.email2 && selectedEmails.length > 0 && selectedExistingClient?.email && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    onClick={() => handleAction('addToEmail1_exist', { klnr: selectedExistingClient.klnr, email: selectedExistingClient.email ,email2: selectedEmails[0] })}
                    style={{ width: '100%' }}
                  >
                    Overwrite Email 1 with proposed email
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    onClick={() => handleAction('addToEmail2_exist', { klnr: selectedExistingClient.klnr, email2: selectedExistingClient.email ,email: selectedEmails[0] })}
                    style={{ width: '100%' }}
                  >
                    Overwrite Email 2 with proposed email
                  </Button>
                </>
              )}

                <Typography component="div" sx={{ mt: 2, mb: 1 }}>
                  <b>New client:</b>

                <ToggleButtonGroup
                    value={newClientType}
                    exclusive
                    onChange={handleNewClientType}
                    sx={{ float: "right" }}
                    aria-label="client type"
                >
                  <ToggleButton value="1">
                    <img alt="Vlaanderen" className='small_icon' src={leeuw} />
                  </ToggleButton>
                  <ToggleButton value="2" >
                    <img alt="Wallonie" className='small_icon' src={coq} />
                  </ToggleButton>
                </ToggleButtonGroup>
                </Typography>
                <TextField
                  autoComplete="off"
                  className={`${theme}_accent ${theme}_text _100perc`}
                  style={{ width: '100%', margin: "0.3rem" }}
                  id="new_client_company_name"
                  label={t('new_client_company_name')}
                  value={newClientCompanyName}
                  onChange={(event) => setNewClientCompanyName(event.target.value)}
                />
                <TextField
                  autoComplete="off"
                  className={`${theme}_accent ${theme}_text _100perc`}
                  style={{ width: '100%', margin: "0.3rem" }}
                  id="new_client_last_name"
                  label={t('new_client_last_name')}
                  value={newClientLastName}
                  onChange={(event) => setNewClientLastName(event.target.value)}/>
                <TextField
                  autoComplete="off"
                  className={`${theme}_accent ${theme}_text _100perc`}
                  style={{ width: '100%', margin: "0.3rem" }}
                  id="new_client_first_name"
                  label={t('new_client_first_name')}
                  value={newClientFirstName}
                  onChange={(event) => setNewClientFirstName(event.target.value)}
                />
              </FormControl>
            )
              :
              (
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <Typography component="div" sx={{ mb: 1 }}>
                    <b>Proposed clients:</b>
                  </Typography>
                  <Select
                    labelId="select-client-label"
                    id="select-client"
                    value={selectedClient}
                    label="Select Client"
                    onChange={(e) => {
                      console.log("e:", e);
                      handleClientSelection(e.target.value);
                      currentRow.client_id_matches.split(',').map((client, index) => {
                        if(client == e.target.value){
                          console.log("client:", client);
                          console.log("e.target.value:", e.target.value);
                          setSelectedClientEmail1(currentRow.email?.split(',')[index] ?? '');
                          setSelectedClientEmail2(currentRow.email2?.split(',')[index] ?? '')
                        }
                      })
                    }}
                    renderValue={(selected) => {
                      let clients = currentRow.client_id_matches?.split(',');
                      let names = currentRow.company_name?.split(',');
                      //get index of selected inside clients & get name at that index
                      let index = clients?.indexOf(selected);
                      let name = names?.[index];
                      if (!selected) return "";
                      return `${selected}: ${name}` ?? '';
                    }}
                  >
                    {currentRow.client_id_matches &&
                     
                      currentRow.client_id_matches.split(',').map((client, index) => (
                        <MenuItem key={client} value={client}>
                          <ListItemText
                            primary={`${client}: ${currentRow.company_name?.split(',')[index]}`}
                            secondary={`Email: ${currentRow.email?.split(',')[index] ?? ''} | Email2: ${currentRow.email2?.split(',')[index] ?? ""}`}
                          />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>)}

            {((selectedClientEmail1 && !selectedClientEmail2) || (!selectedClientEmail1 && !selectedClientEmail2)) && selectedEmails.length > 0 && selectedClient && !addAsNew && (
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={() => handleAction('addToEmail1', { klnr: selectedClient, email: selectedClientEmail1 ,email2: selectedEmails[0] })}
                style={{ width: '100%' }}
              >
                Set proposed email to Email 2
              </Button>
            )}

            {((!selectedClientEmail1 && selectedClientEmail2) || (!selectedClientEmail1 && !selectedClientEmail2))  && selectedEmails.length > 0 && selectedClient  && !addAsNew && (
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={() => handleAction('addToEmail2', { klnr: selectedClient, email2: selectedClientEmail2 ,email: selectedEmails[0] })}
                style={{ width: '100%' }}
              >
                Set proposed email to Email 1
              </Button>
            )}

            {selectedClientEmail1 && selectedClientEmail2 && selectedEmails.length > 0 && selectedClient  && !addAsNew && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                  onClick={() => handleAction('addToEmail1', { klnr: selectedClient, email: selectedEmails[0] ,email2: selectedClientEmail2 })}
                  style={{ width: '100%' }}
                >
                  Overwrite Email 1 with proposed email
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                  onClick={() => handleAction('addToEmail2', { klnr: selectedClient, email: selectedClientEmail1 ,email2: selectedEmails[0] })}
                  style={{ width: '100%' }}
                >
                  Overwrite Email 2 with proposed email
                </Button>
              </>
            )}

            {selectedEmails.length > 0 && selectedClient  && !addAsNew && (
              <Button
                variant="outlined"
                color="primary"
                sx={{ mt: 2 }}
                onClick={() => handleAction('addNewFamilyMember')}
                style={{ width: '100%' }}
              >
                Add as family member
              </Button>
            )}

            {selectedClient && isFamilyInputVisible && (
              <FormControl fullWidth sx={{ mt: 2 }}>

                {selectedClient && isFamilyInputVisible && (
                  <>
                    <TextField
                      autoComplete="off"
                      className={`${theme}_accent ${theme}_text _100perc`}
                      style={{ width: '100%', margin: "0.3rem" }}
                      id="family_first_name"
                      label={t('first_name_family_member')}
                      value={familyMemberFirstName}
                      onChange={(event) => setFamilyMemberFirstName(event.target.value)}
                    />
                    <TextField
                      autoComplete="off"
                      className={`${theme}_accent ${theme}_text _100perc`}
                      style={{ width: '100%', margin: "0.3rem" }}
                      id="family_last_name"
                      label={t('last_name_family_member')}
                      value={familyMemberLastName}
                      onChange={(event) => setFamilyMemberLastName(event.target.value)}
                    />
                  </>
                )}

              {(familyMemberFirstName || familyMemberLastName) && !addAsNew && (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    onClick={() => handleAction('confirmAddNewFamilyMember', { klnr: selectedClient, firstName: familyMemberFirstName, lastName: familyMemberLastName, email:selectedEmails[0] })}
                    disabled={!selectedClient || (!familyMemberFirstName && !familyMemberLastName) || selectedEmails.length === 0} // Disable until a client is selected and a name is entered
                    style={{ width: '100%' }}>
                    Confirm
                  </Button>
              )}

              </FormControl>

            )}

            { selectedEmails.length > 0 && addAsNew && (
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={() => {
                  setSelectedEmails([]);
                  handleAction('createNewClient',{clientName: newClientCompanyName, firstName: newClientFirstName, lastName: newClientLastName, email: selectedEmails[0], clientType: newClientType });
                }}
                disabled={!newClientCompanyName || (!newClientFirstName && !newClientLastName) || selectedEmails.length === 0} // Disable until a client is selected and a name is entered
                style={{ width: '100%' }}>
                Create new client
              </Button>
            )}

            <Button
              variant="outlined"
              color="secondary"
              sx={{ mt: 2 }}
              onClick={() => {
                setSelectedEmails([]);
                handleAction('ignore');
              }}
              style={{ width: '100%' }}
            >
              Ignore
            </Button>
          </>
        )}
      </Box>
    </Modal>
  );
}

export default MatchingModal;
