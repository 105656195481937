import React, { useState } from 'react';

const ApplicationContainer = (props) =>  {
    const [active, setActive] = useState(props.active);

    const activeHandler = () => {
        setActive(!active);
        props.func(!active);
    }

    return (
        <div className={props.theme}>
            <fieldset className={(props.theme==='light') ? 'light container_list' : 'dark  container_list'}>
                
                    <legend className='_100perc'>
                        <span className="number">
                            <div className="left_sidebar_item__image">
                                <img src={props.icon} alt={props.title}/>
                            </div>
                        </span> 
                        {props.title}
                        <label className="switch align_right">
                            <input type="checkbox" checked={active} onChange={() => {activeHandler();}}/>
                            <span className={"slider slider_" + props.theme + " round"}></span>
                        </label>
                    </legend>
                
                    {props.children}
            </fieldset>
        </div>
    );
}

export default ApplicationContainer;
