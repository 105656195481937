import React, { useState, useEffect }from 'react';
import { TailSpin } from  'react-loader-spinner'
import axios from 'axios';
import SftGlobalData from '../../library/SftGlobalData';
import Colors from '../../library/Colors';
import Button from '@mui/material/Button';
import { FormControl, Input, InputLabel, ListSubheader, MenuItem, Select, Switch, TextField } from '@mui/material';
import { DataGridPro, GridCellEditStopReasons, useGridApiRef } from '@mui/x-data-grid-pro';


const LicenceEDIForm = (props) =>  {
    const [loading, setLoading] = useState(true);
    const [updateEdi, setUpdateEdi] = useState(false);

    const [host, setHost] = useState("softtouch.eu");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [processingOption, setProcessingOption] = useState("");
    const [salesReportsOption, setSalesReportOption] = useState("");
    const [fashionCloudReportOption, setFashionCloudReportOption] = useState("");
    const [email, setEmail] = useState("");

    const [list, setList] = useState([]);
    const apiRef = useGridApiRef();
    const gridColumns = [
        {
            field: 'id',
            headerName: props.t('id'),
            width: 50,
            align: "left",
        },
        {
            field: 'store_name',
            headerName: props.t('name'),
            headerClassName: 'bold',
            align: "left",
        },
        {
            field: 'store_gln',
            headerName: 'GLN',
            align: "left",
            minWidth: 150,
            editable: true,
        },
        {
            field: 'corp',
            headerName: 'Coid',
            width: 50,
            align: "left",
        },
        {
            field: 'corp_name',
            headerName: 'Corp name',
            align: "left",
        },
        {
            field: 'corp_gln',
            headerName: 'Corp GLN',
            minWidth: 150,
            align: "left",
            editable: true,
        },
        {
            field: 'shipping_name',
            headerName: 'Store name',
            align: "left",
        },
        {
            field: 'shipping_company',
            headerName: 'Store company',
            align: "left",
        },
        {
            field: 'shipping_street_name',
            headerName: 'Store street',
            align: "left",
        },
        {
            field: 'shipping_zip',
            headerName: 'Store zip',
            align: "left",
        },
        {
            field: 'shipping_city',
            headerName: 'Store city',
            align: "left",
        },
      ];


    useEffect (()=>{
        refreshData();
    }, [props.api]);

    const refreshData = () => {
        try{
            let url = SftGlobalData.baseURL_API+'edi&action=fetch';
            let postData = {key: props.api}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    
                    setProcessingOption(response.data.auto_processing);
                    setSalesReportOption(response.data.sales_reports);
                    setFashionCloudReportOption(response.data.fashion_cloud_reports);
                    setEmail(response.data.customer_notify_email);
                    setUpdateEdi(response.data.update_edi);
                    setHost(response.data.host);
                    setUsername(response.data.user);
                    setPassword(response.data.password);

                    fetchClient();
                });
        } catch(e){
            console.error(e);
        }
    }

    const fetchClient = () => {
        let url2 = SftGlobalData.baseURL_API+'edi&action=fetchClient';
        let postData2 = {key: props.api}
        axios.post(url2, postData2, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
            .then(response2 => {
                if(SftGlobalData.debug){
                    console.log('url', url2);
                    console.log('postData', postData2);
                    console.log('resp', response2);
                }

                if(response2.data.error){
                    // Notify error
                    props.notify("Error occurred while fetching client DB data...", 2000, "top-right", props.theme, 78652); 
                    setList([]);
                } else {
                    setList(response2.data.data);
                }
                setLoading(false);
            });
    }

    const updateClient = (postData) => {
        try{
            let url = SftGlobalData.baseURL_API+'edi&action=updateClient';
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    
                    //refreshData();
                });
        } catch(e){
            console.error(e);
        }
    }

    const onSave = async () => {
        try{
            let url = SftGlobalData.baseURL_API+'edi&action=save';
            let postData = {
                key: props.api,
                auto_processing: processingOption,
                sales_reports: salesReportsOption,
                fashion_cloud_reports: fashionCloudReportOption,
                customer_notify_email: email, 
                update_edi: updateEdi, 
                host: host,
                username: username,
                password: password,
                login: props.login,
            }
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    props.notify(response.data.message, 2000, "top-right", props.theme, 78651); 
                });
        } catch(e){
            console.error(e);
            props.notify("Error occurred while saving data...", 2000, "top-right", props.theme, 78651); 
        }
    }

    

    return (
        <div className={props.theme + " _100perc"} >
            {loading && <div style={{display:"flex", justifyContent: "space-evenly", marginTop: 5}}>
                <TailSpin 
                    height="40"
                    width="40"
                    color={Colors.sft_blue}
                    ariaLabel='loading'
                />
            </div>}
            {!loading && <div className='columnContainerBetween _100perc' >
            <div className='rowContainerBetween _100perc margin_top_10px'>
                <FormControl style={{width: '49%'}}>
                    <InputLabel id="apl" htmlFor="aplselect">Auto processing</InputLabel>
                    <Select labelId="apl" id="aplselect" value={processingOption} onChange={(event)=>{setProcessingOption(event.target.value)}} label="Auto processing" >
                        <MenuItem value=""><em>None</em></MenuItem>
                        {SftGlobalData.vlChecker(props.sft) && <ListSubheader >Vlaanderen</ListSubheader>}
                        {SftGlobalData.vlChecker(props.sft) && <MenuItem value={"VL1"}>Vlaanderen 1</MenuItem>}
                        {SftGlobalData.wallChecker(props.sft) && <ListSubheader>Wallonie</ListSubheader>}
                        {SftGlobalData.wallChecker(props.sft) && <MenuItem value={"WALL1"}>Wallonie 1</MenuItem>}
                    </Select>
                </FormControl>

                <FormControl style={{width: '50%'}}>
                    <InputLabel id="srl" htmlFor="srlselect">Sales reports</InputLabel>
                    <Select labelId="srl" id="srlselect" value={salesReportsOption} onChange={(event)=>{setSalesReportOption(event.target.value)}} label="Sales reports">
                        <MenuItem value=""><em>None</em></MenuItem>
                        {SftGlobalData.vlChecker(props.sft) && <ListSubheader >Vlaanderen</ListSubheader>}
                        {SftGlobalData.vlChecker(props.sft) && <MenuItem value={"VL1"}>Vlaanderen 1</MenuItem>}
                        {SftGlobalData.vlChecker(props.sft) && <MenuItem value={"VL2"}>Vlaanderen 2</MenuItem>}
                        {SftGlobalData.wallChecker(props.sft) && <ListSubheader>Wallonie</ListSubheader>}
                        {SftGlobalData.wallChecker(props.sft) && <MenuItem value={"WALL1"}>Wallonie 1</MenuItem>}
                    </Select>
                </FormControl>
            </div>

            <div className='rowContainerBetween _100perc margin_top_10px'>
                <TextField
                    minRows={1}
                    maxRows={1}
                    inputProps={{ maxLength: 255}}
                    autoComplete="off" 
                    className={props.theme+ '_accent shipping_width_49perc'}
                    id="email"
                    label={"Customer notify email"}
                    size={'small'}
                    style={{height: 56}}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text, height: 56
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text, height: 56
                         }, borderRadius: 3, marginTop: 0, height: 56} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text, height: 56
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent, height: 56
                            }, borderRadius: 3, marginTop: 0, height: 56}}
                    value={email}
                    onChange={(event)=>{setEmail(event.target.value)}}
                    />

                <FormControl style={{width: '50%'}}>
                    <InputLabel id="srl" htmlFor="srlselect">Fashion cloud reports</InputLabel>
                    <Select labelId="srl" id="srlselect" value={fashionCloudReportOption} onChange={(event)=>{setFashionCloudReportOption(event.target.value)}}  label="Fashion cloud reports">
                        <MenuItem value=""><em>None</em></MenuItem>
                        {SftGlobalData.vlChecker(props.sft) && <ListSubheader >Vlaanderen</ListSubheader>}
                        {SftGlobalData.vlChecker(props.sft) && <MenuItem value={"BELGIUM"}>Belgium</MenuItem>}
                        {SftGlobalData.wallChecker(props.sft) && <ListSubheader>Wallonie</ListSubheader>}
                        {SftGlobalData.wallChecker(props.sft) && <MenuItem value={"BELGIUM"}>Belgium</MenuItem>}
                    </Select>
                </FormControl>
            </div>

            <div className='rowContainerBetween _100perc margin_top_10px'>
                
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', justifyContent: 'center'}}>
                    <Switch
                        disabled={loading}
                        checked={ updateEdi }
                        onChange={() => {setUpdateEdi(!updateEdi);}}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <span><b>Update EDI</b></span>
                    
                </div>

                <FormControl style={{width: '50%'}}>
                </FormControl>
                
            </div>

            <hr style={{width: "100%"}}/>

            <TextField
                minRows={1}
                maxRows={1}
                inputProps={{ maxLength: 255}}
                autoComplete="off" 
                className={props.theme+ '_accent shipping_width_49perc margin_top_10px'}
                id="email"
                label={"Host"}
                size={'small'}
                sx={(props.theme==='light') ? {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.light_text
                      },
                    '& .MuiInputLabel-root': {
                        color: Colors.light_text
                     }, borderRadius: 3, marginTop: 0} 
                    :
                    {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.dark_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.dark_text_accent
                        }, borderRadius: 3, marginTop: 0}}
                value={host}
                onChange={(event)=>{setHost(event.target.value)}}
                />

                <div className='rowContainerBetween _100perc margin_top_10px'>
                    <TextField
                        minRows={1}
                        maxRows={1}
                        inputProps={{ maxLength: 255}}
                        autoComplete="off" 
                        className={props.theme+ '_accent shipping_width_49perc '}
                        id="username"
                        label={"Username"}
                        size={'small'}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                              },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text
                             }, borderRadius: 3, marginTop: 0} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }, borderRadius: 3, marginTop: 0}}
                        value={username}
                        onChange={(event)=>{setUsername(event.target.value)}}
                        />
                    
                    <TextField
                        minRows={1}
                        maxRows={1}
                        inputProps={{ maxLength: 255}}
                        autoComplete="off" 
                        className={props.theme+ '_accent _50perc '}
                        id="password"
                        label={"Password"}
                        size={'small'}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                              },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text
                             }, borderRadius: 3, marginTop: 0} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }, borderRadius: 3, marginTop: 0}}
                        value={password}
                        onChange={(event)=>{setPassword(event.target.value)}}
                        />
                </div>
            
            
                
            </div>}
            <Button className={"modal_button_container_no_margin__button margin_top_15px _100perc"} onClick={()=>{onSave();}}>{props.t('save')}</Button>

            <hr/>
            <div className='columnContainerStart _100perc'>
                <div className='rowContainerCenter _100perc'>
                    <DataGridPro
                        apiRef={apiRef}
                        className={'excel_grid margin_bottom_40px ' + props.theme}
                        columns={gridColumns}
                        rows={list.map((row, index) => {return row; })}
                        onCellEditStop={(params, event) => {
                            //console.log("params", params );
                            if (params.reason === GridCellEditStopReasons.cellFocusOut || params.reason === "enterKeyDown" || params.reason === "tabKeyDown") {
                              //event.defaultMuiPrevented = true;
                              //handleCellEditCommit(params.row.id, params.field, event.target.value);
                              let postData = {
                                key: props.api,
                                target: (params.field === "corp_gln" ? "corp" : "store"),
                                target_id: (params.field === "corp_gln" ? params.row.corp : params.row.id),
                                target_value: event.target.value
                              };
                              updateClient(postData);
                            }
                          }}
                          rowThreshold={0}
                          rowHeight={38}
                          autoHeight={true}
                          loading={loading}
                          pagination={false}
                          autosizeOnMount={true}
                          autosizeOptions={{
                            includeOutliers: true,
                            includeHeaders: true,
                          }}
                        />
                </div>
            </div>

            <br/><br/>

        </div>
    );
}

export default LicenceEDIForm;
