import React, { useState, useEffect } from 'react';
import { TailSpin } from  'react-loader-spinner';
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import SftGlobalData from '../library/SftGlobalData';
import Colors from '../library/Colors';

import CardSliderWithImage from '../components/CardSliderWithImage';
import testLogo from '../assets/Test.png';
import ic_mysft from '../assets/application-icons/ic_mysft.png';
import ic_revenue from '../assets/application-icons/ic_revenue.png';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import crypto from 'crypto-js';
import FloatingBackButton from '../components/FloatingBackButton';
import FloatingSaveButton from '../components/FloatingSaveButton';

const UserDetailScreen = (props) => {
    let navigate = useNavigate();
    const params = useParams();
    let isUpdated = false;

    const [loading, setLoading] = useState(true);
    const [active, setActive] = useState(1);
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [sft_user_id, setSftUserId] = useState(0);
    const [isAdmin, setIsAdmin] = useState(0);
    const [apps, setApps] = useState([]);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        if(props.api !== ''){
            if(params.userId !== "+"){
                let url = SftGlobalData.baseURL_API + 'account&action=fetchOne';
                axios.post(url, 
                    {
                        id: params.userId,
                        key: props.api
                    }, {
                        headers : {
                            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                        }
                    }).then(response => {
                        if(SftGlobalData.debug){
                            console.log('url', url);
                            console.log('data', {id: params.userId, key: props.api});
                            console.log('response', response);
                        }
                        setActive(response.data.is_active === "1");
                        setLogin(response.data.login);
                        setName(response.data.name);
                        setSurname(response.data.surname);                        
                        setSftUserId(response.data.sft_user_id);
                        setIsAdmin(response.data.is_owner === "1");
                        setApps(response.data.applications);

                        url = SftGlobalData.baseURL_API + 'sftData';
                        axios.post(url, 
                            {
                                type: 'users',
                                userid: response.data.sft_user_id,
                                key: props.api
                            }, {
                                headers : {
                                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                                }
                            }).then(response => {
                                if(SftGlobalData.debug){
                                    console.log('url', url);
                                    console.log('data', { type: 'users', userid: response.data.sft_user_id, key: props.api });
                                    console.log('response', response);
                                }
                                setUsers(response.data.data);
                                setLoading(false);

                            }
                        );
                    }
                );
            } else {                
                let url = SftGlobalData.baseURL_API + 'apps';
                axios.post(url, 
                    {
                        key: props.api
                    }, {
                        headers : {
                            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                        }
                    }).then(response => {
                        if(SftGlobalData.debug){
                            console.log('url', url);
                            console.log('data', {key: props.api});
                            console.log('response', response);
                        }
                        setApps(response.data);

                        url = SftGlobalData.baseURL_API + 'sftData';
                        axios.post(url, 
                            {
                                type: 'users',
                                key: props.api
                            }, {
                                headers : {
                                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                                }
                            }).then(response => {
                                if(SftGlobalData.debug){
                                    console.log('url', url);
                                    console.log('data', {type: 'users',key: props.api})
                                    console.log('response', response);
                                }
                                setUsers(response.data.data);
                                setLoading(false);
                                setSftUserId(response.data.data[0].userid);
                            }
                        );

                    }
                );
            }
        }
     },[props.api, params.userId]);

    const getApps = apps.map(function(data, idx){
        let cardImage = testLogo;
        switch(data.id){
            case "1":
                cardImage = ic_mysft;
                break;
            case "2":
                cardImage = ic_revenue;
                break;
            default:
                cardImage = testLogo
                break;
        }
        return (
            <CardSliderWithImage key={data.id} noClick={params.userId === "+"} uri={"/"+props.lang+"/user-detail/"+params.userId+"/"+data.id} title={data.description} active={data.user_active} image={cardImage} theme={props.theme} data={data} type={"application"} applications={apps} lang={props.lang} userId={params.userId} appId={params.appId} changeState={(applications)=>{setApps(applications);}}/>
        );
    });

    const changeLogin = (event) => {
        setLogin(event.target.value);
        isUpdated = true;
    }

    const changePassword = (event) => {
        setPassword(event.target.value);
        isUpdated = true;
    }

    const changeName = (event) => {
        setName(event.target.value);
        isUpdated = true;
    }

    const changeSurname = (event) => {
        setSurname(event.target.value);
        isUpdated = true;
    }

    const changeSftUserId = (event) => {
        setSftUserId(event.target.value);
        isUpdated = true;
    }

    const usersMapper = users.map(user => {
        return (
            <option key={user.userid} value={user.userid} selected={sft_user_id === user.userid}>{user.userid} - {user.naam}</option>
        );
    });

    function copyKeyToClipboard(value) {
        navigator.clipboard.writeText(value)
        props.notify(props.t('copied') + " " + value, 2000, "top-right", props.theme, 1); 
    }

    const checkIfUserExists = async (event) => {
        let APIurl = SftGlobalData.baseURL_API + 'user&action=checkIfAccountExistsForDatabase';
        return await axios.post(APIurl, 
            {
                login: login,
                key: props.api
            },  {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('error?', typeof response.error);
                    console.log('response: ', response);
                }

                if(response.data.loginKnown == "false"){  
                    return false;
                } else {
                    return true;
                }
            });
    }

    const handleSubmit = async () => {        
        if((validationTest(login, /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,99})+$/))){     // 99 characters after last . to allow ".shop" 
                let postData = {
                        key: props.api,
                        id: params.userId,
                        login: login,
                        password: password === "" ? "" : crypto.MD5(JSON.stringify(password)).toString(),
                        name: name,
                        surname: surname,
                        is_active: active ? "1" : "0",
                        is_owner: isAdmin ? "1" : "0",
                        sft_user_id: sft_user_id,
                        my_softtouch_active: apps[0].user_active,
                        revenue_app_active: apps[1].user_active


                };
                if(SftGlobalData.debug){
                    console.log("postData: ", postData);
                }
                let userCheck = await checkIfUserExists();
            if(userCheck === false && params.userId === '+'){  
                let APIurl = SftGlobalData.baseURL_API + 'account&action=update';
                axios.post(APIurl, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('error?', typeof response.error);
                    }
                    if(typeof response.error === 'undefined'){
                        if(SftGlobalData.debug){
                            console.log('response', response);
                        }                        
                        if(!isAdmin){
                            navigate("/"+props.lang+"/user-detail/"+response.data.id_account_db+"/apps");
                        } else {
                            navigate("/"+props.lang+"/user-detail/"+response.data.id_account_db);
                        }
                        
                    } else { 
                        props.notify(props.t('errorOccured'), 7500, "top-center", props.theme, 1);
                    }
                })
            } else if(params.userId !== '+') {
                let APIurl = SftGlobalData.baseURL_API + 'account&action=update';
                axios.post(APIurl, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('error?', typeof response.error);
                    }
                    if(typeof response.error === 'undefined'){
                        if(SftGlobalData.debug){
                            console.log('response', response);
                        }                        
                        isUpdated = false;
                        props.notify(props.t('savedChanges'), 7500, "top-center", props.theme, 1);
                    } else { 
                        props.notify(props.t('errorOccured'), 7500, "top-center", props.theme, 1);
                    }
                })
            } else{
                props.notify(props.t('login_used'), 7500, "top-center", props.theme, 1);
            }
        }
        else{
            props.notify(props.t('login_invalid'), 7500, "top-center", props.theme, 1);
        }
    };

    function generatePassword(){
        var length           = 10;
        var result           = '';
        var characters       = 'abcdefghjkmnpqrstuvwxyz123456789ABCDEFGHJKMNPQRSTUVWXYZ';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        
        setPassword(result);
        setShowPassword(true);
        copyKeyToClipboard(result);
        //return result;
    }


      
        const confirmAction = () => {
            if(isUpdated === true){
                let confirmAction = window.confirm(props.t('save_changes_question'));
                if (confirmAction) {
                navigate("/"+props.lang+"/users");
                } 
            } else {
                navigate("/"+props.lang+"/users");
            }
          }

        const handleClickShowPassword = () => {
            setShowPassword(!showPassword);
        };
        
        const handleMouseDownPassword = (event) => {
            event.preventDefault();
        };

    function validationTest(data, validationString) 
    {
        if(data === undefined)
            return false;

        if (validationString.test(data))
        {
            var te_testen = data.split("@").pop();
            if((props.group !== "ADMIN" && props.group !== "SUPER") && te_testen.trim().toLowerCase() === 'softtouch.be')
                return false;
            else 
                return true;
        } else {
            return false;
        }
    }

    return (
        <div className={(props.screenWidth) < 600 ? 'not_expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme : 'expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme} >
            <FloatingBackButton disabled={false} onClick={()=>{confirmAction()}} />
            <FloatingSaveButton disabled={loading} onClick={()=>{handleSubmit();}} />
            <div className={"container_list__title " + props.theme}><h2>{props.t('users')}</h2></div>
            <form>
                {loading && <div className={"container_list__title " + props.theme} style={{display:"flex", justifyContent: "space-evenly", marginTop: 5, border: 1}}>
                    <TailSpin 
                        height="40"
                        width="40"
                        color={Colors.sft_blue}
                        ariaLabel='loading'
                    />
                </div>}        

                {!loading && <fieldset className={(props.theme==='light') ? 'light container_list' : 'dark  container_list'}>
                    <div>
                        <legend className='_100perc'>
                            <span className="number">1</span> {props.t('credentials')}
                            <label className="switch align_right">
                                <input type="checkbox" checked={active} onChange={() => {setActive(!active);}}/>
                                <span className={"slider slider_" + props.theme + " round"}></span>
                            </label>
                        </legend>
                    </div>

                    <label className='margin_bottom_5px' htmlFor="tst_lgn">{props.t('login')}:</label>
                    <FormControl sx={{ m: 1, width: '100%', margin: '0', marginBottom: '10px' }}>
                        <OutlinedInput
                            id="tst_lgn"
                            type={'text'}
                            name="tst_lgn" 
                            className={props.theme+"_accent_darker"}
                            autoComplete='off'
                            value={login}
                            onChange={changeLogin}
                            placeholder={props.t('login')}
                        />
                    </FormControl>
                                    
                    {(params.userId === "+" || props.isSft) && <label htmlFor="usr_password">{props.t('password')}:</label>}
                    {(params.userId === "+" || props.isSft) && <FormControl sx={{ m: 1, width: '100%', flex: 1, flexDirection:"row", margin: '0', marginBottom: '10px' }}>
                        <OutlinedInput
                            id="new-password"
                            type={showPassword ? 'text' : 'password'}
                            name="new-password" 
                            className={props.theme+"_accent_darker _90perc margin_right__5px"}
                            value={password}
                            onChange={changePassword}
                            autoComplete="new-password"
                            placeholder={props.t('password')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <Button onClick={()=>{generatePassword()}} className=" margin_left__5px _10perc button_container__button_colors" style={{marginBottom: 0, height: 43}}>{props.t('generate')}</Button>
                    </FormControl>}
                
                    <label className='margin_bottom_5px' htmlFor="tst_name">{props.t('first_name')}:</label>
                    <FormControl sx={{ m: 1, width: '100%', margin: '0', marginBottom: '10px' }}>
                        <OutlinedInput
                            id="tst_name"
                            type={'text'}
                            name="tst_name" 
                            className={props.theme+"_accent_darker"}
                            value={name}
                            onChange={changeName}
                            placeholder={props.t('first_name')}
                        />
                    </FormControl>

                    <label className='margin_bottom_5px' htmlFor="tst_surname">{props.t('surname')}:</label>
                    <FormControl sx={{ m: 1, width: '100%', margin: '0', marginBottom: '10px' }}>
                        <OutlinedInput
                            id="tst_surname"
                            type={'text'}
                            name="tst_surname" 
                            className={props.theme+"_accent_darker"}
                            value={surname}
                            onChange={changeSurname}
                            placeholder={props.t('surname')}
                        />
                    </FormControl>

                    <label className='margin_bottom_5px' htmlFor="sft_user_id">{props.t('sft_user_id')}:</label>
                    <select className={(props.theme==='light') ? 'light_accent_darker _100perc' : 'dark_accent_darker _100perc'} id="sft_user_id" name="sft_user_id" onChange={changeSftUserId} value={sft_user_id}>
                        {usersMapper}   
                    </select>

                    <label className='margin_bottom_5px' htmlFor="user_admin">{props.t('admin')}?</label>
                    <label className="switch align_right">
                        <input type="checkbox" checked={isAdmin} onChange={() => {setIsAdmin(!isAdmin);}} id="user_admin" name="user_admin"/>
                        <span className={"slider slider_" + props.theme + " round"}></span>
                    </label>
                    <br/>
                    <br/>
                    {!isAdmin && <div>
                        <div>
                            <legend className='_100perc'>
                                <span className="number">2</span> {props.t('access')}
                            </legend>
                        </div>
                        {getApps}

                    </div>}
                </fieldset>}
            </form>
        </div>
    );
}

export default UserDetailScreen;
