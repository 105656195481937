import React, { useState, useEffect, useRef }from 'react';
import './logging.css';
import axios from 'axios';
import SftGlobalData from '../../library/SftGlobalData';
import Colors from '../../library/Colors';
import { Autocomplete, Box, Button, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';

const LoggingAddFileModal = (props) =>  {
    const [tags, setTags] = useState ([]);
    const [fileMode, setFileMode] = useState('file');
    const [disabled, setDisabled] = useState(false);
        
    const [title, setTitle] = useState("");
    const [sort, setSort] = useState(50);
    const [selectedTag, setSelectedTag] = useState ({});
    const [txtContents, setTxtContents] = useState("");
    const [file, setFile] = useState("");
    const [fileName, setFileName] = useState("");

    const inputFile = useRef();

    useEffect (()=>{
        try{
            let url2 = SftGlobalData.baseURL_API+'logging&action=fetchLogTags';
            let postData2 = {sft_id: 0, sft: props.login?.sft_status}
            axios.post(url2, postData2, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url2);
                        console.log('postData', postData2);
                        console.log('resp', response);
                    }
                    setTags(response.data);
                });
        } catch(e){
            console.error(e);
        }
    }, [props.login?.sft_status]);


    const handleChange = (event, newAlignment) => {
        setFileMode(newAlignment);
    };

    function onFileChange(e) {
        let files = e.target.files;
        let fileReader = new FileReader();
        fileReader.readAsDataURL(files[0]);
 
        fileReader.onload = (event) => {
            setFile(event.target.result);
        }
        //console.log("DEBUG", inputFile.current.files[0].name);

        setFileName(inputFile.current.files[0].name);
    }
    
    

    const save = () => {
        if(title !== ""){
            setDisabled(true);
            try{
                let url2 = SftGlobalData.baseURL_API+'logging&action=uploadFile';
                let postData2 = {
                    sft_id: props.customer,
                    sft: props.login?.sft_status,
                    file_data: file,
                    filename: fileMode === "text" ? (title.replaceAll("\\", "").replaceAll("'", "").replaceAll(" ", "")+".txt") : fileName,
                    title: title.replaceAll("\\", "").replaceAll("'", "\\'"),
                    tag: typeof selectedTag?.tag === "undefined" ? "" : selectedTag.tag,
                    txt: txtContents,
                    is_txt: fileMode === "text",
                    sort: sort,
                    is_store: props.is_store,
                    store_id: props?.store
                }
                axios.post(url2, postData2, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                    .then(response => {
                        if(SftGlobalData.debug){
                            console.log('url', url2);
                            console.log('postData', postData2);
                            console.log('resp', response);
                        }
                        setDisabled(false);
                        props.refresh();
                    });
            } catch(e){
                console.error(e);
                setDisabled(false);
            }
        }
    }
    

    return (
        <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 410}}>
            <div style={{textAlign: 'left'}}>
                { 
                    // File info
                }
                <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
                    <TextField
                        required
                        autoComplete="off" 
                        className={props.theme+ '_accent log__100_perc margin_bottom_10px margin_right__5px'}
                        id="title"
                        label={props.t('name')}
                        size={'small'}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                              },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text
                             }, borderRadius: 3, marginTop: 0} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }, borderRadius: 3, marginTop: 0}}
                        value={title}
                        onChange={(event)=>{setTitle(event.target.value)}}
                        />
                    <TextField
                        autoComplete="off" 
                        className={props.theme+ '_accent margin_bottom_10px '}
                        id="sort"
                        label={props.t('sort')}
                        size={'small'}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                              },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text
                             }, borderRadius: 3, marginTop: 0} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }, borderRadius: 3, marginTop: 0}}
                        value={sort}
                        onChange={(event)=>{setSort(event.target.value)}}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} 
                        />

                </div>

                <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
                    <ToggleButtonGroup
                      //color={Colors.sft_blue}
                      value={fileMode}
                      exclusive
                      onChange={handleChange}
                      aria-label="Type"
                      className='margin_right__10px'
                      style={{height: 40}}
                    >
                          <ToggleButton value="file" className='log__button_width'>{props.t('file')}</ToggleButton>
                          <ToggleButton value="text" className='log__button_width'>{props.t('text')}</ToggleButton>
                    </ToggleButtonGroup>

                    <Autocomplete
                      id="tags-select"
                      className={props.theme+ '_accent log__tag_width'}
                      label={props.t('tags')}
                      size={'small'}
                      sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                          },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text
                         }, borderRadius: 3, marginTop: 0} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }, borderRadius: 3, marginTop: 0}}

                      options={tags}
                      isOptionEqualToValue={(option, val)=>{return (option.tag + " - " + option.parent) === (val.tag + " - " + val.parent)}}
                      onChange={(event, value, reason)=>{if(reason === "selectOption"){setSelectedTag(value);} else { setSelectedTag({});}}}
                      autoHighlight
                      getOptionLabel={(option) => {return option.tag + " - " + option.parent}}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.tag} - {option.parent}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={props.t('tags')}
                          inputProps={{
                            ...params.inputProps,
                             // disable autocomplete and autofill
                          }}
                        />
                      )}
                        />
                </div>

                <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
                    {fileMode === "text" && <TextField
                            multiline
                            minRows={10}
                            maxRows={10}
                            autoComplete="off" 
                            className={props.theme+ '_accent log__100_perc margin_top_10px'}
                            id="txtContents"
                            size={'small'}
                            label={props.t('note')}
                            sx={(props.theme==='light') ? {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.light_text
                                  },
                                '& .MuiInputLabel-root': {
                                    color: Colors.light_text
                                 }, borderRadius: 3, marginTop: 0} 
                                :
                                {
                                '& .MuiOutlinedInput-input': {
                                    color: Colors.dark_text
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: Colors.dark_text_accent
                                    }, borderRadius: 3, marginTop: 0}}
                            value={txtContents}
                            onChange={(event)=>{setTxtContents(event.target.value)}}
                            />}

                    {fileMode === "file" && <div className='margin_top_10px log__100_perc'>
                        <label className="text-white">{props.t('select_file')}</label>
                        <input type="file" ref={inputFile} className="form-control" name="File" onChange={onFileChange} style={{width: '100%'}} />
                    </div>}
                </div>

            </div>
            <div>
                <Button 
                    variant="outlined" 
                    sx={{ borderRadius: 3, border: '2px solid '+Colors.sft_blue, color: Colors.sft_blue, width: '100%'}}
                    disabled={disabled}
                    className=" " 
                    onClick={()=>{save()}}>{props.t('save')}</Button>
            </div>
        </div>
    );
}

export default LoggingAddFileModal;
