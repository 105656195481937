import React, { useState, useEffect } from 'react';
import '../../screens/logging/logging.css';
import axios from 'axios';
import SftGlobalData from '../../library/SftGlobalData';
import Colors from '../../library/Colors';
import 'react-toastify/dist/ReactToastify.css';
import { DataGridPro, useGridApiContext, useGridApiRef } from '@mui/x-data-grid-pro';
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

function CustomEditComponent(props) {
    const { id, field, value } = props;
    const apiRef = useGridApiContext();
  
    const handleValueChange = (event) => {
        const newValue = event.target.value; // The new value entered by the user
        //console.log("TEST");
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    const saveValueChange = () => {
        //console.log("SAVE?", props.orig);
        //console.log("SAVE!", value);
        //if(props.orig !== value){
            if (window.confirm("Save changes?") === true) {
                try{
                    //console.log("props", props);
                    let url = SftGlobalData.baseURL_API+'logging&action=updateDeliveryAddress';
                    let postData = {id: props.row.id, field: props.sql, value: value.replaceAll("\\", "\\\\'").replaceAll("'", "\\'"), user_id: props.user, sft: props.login?.sft_status};
                    axios.post(url, postData, {
                        headers : {
                            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                        }})
                        .then(response => {
                            if(SftGlobalData.debug){
                                console.log('url', url);
                                console.log('postData', postData);
                                console.log('resp', response);
                            }
                            props.reload();
                        });
                } catch(e){
                    console.error(e);
                }
            } else {
                apiRef.current.setEditCellValue({ id, field, value: props.orig });
            }
        //}
    }

    const handleEnter = (e) => {
        if(e.keyCode === 13){
            saveValueChange();
        }
    }
  
    return <input type="text" value={value} onChange={handleValueChange} onKeyDown={(event)=>{handleEnter(event);}} onBlur={()=>{saveValueChange()}}/>;
  }

const StoresTable = (props) =>  {
    const [pageSize, setPageSize] = useState(10);
    const [searchValue, setSearchValue] = useState("");
    const gridColumns = [
        {
            field: 'id',
            headerName: 'ID',
            headerClassName: 'bold',
            minWidth: 75,
            width: 75,
            align: "left",
        },
        {
            field: 'name',
            headerName: props.t('name'),
            minWidth: 150,
            width: 150,
            align: "left",
            editable: true,
            renderEditCell: (params)=>{return <CustomEditComponent login={props.login} orig={params.row.name} sql={"klde_naam1"} user={props.userId} reload={()=>{props.loadStores();}} {...params}/>}
        },
        {
            field: 'name2',
            headerName: props.t('name') + ' 2',
            minWidth: 150,
            width: 150,
            align: "left",
            editable: true,
            renderEditCell: (params)=>{return <CustomEditComponent login={props.login} orig={params.row.name2} sql={"klde_naam2"} user={props.userId} reload={()=>{props.loadStores();}} {...params}/>}
        },
        {
            field: 'address',
            headerName: props.t('address'),
            minWidth: 160,
            align: "left",
            editable: true,
            renderEditCell: (params)=>{return <CustomEditComponent login={props.login} orig={params.row.address} sql={"klde_adres1"} user={props.userId} reload={()=>{props.loadStores();}} {...params}/>}
        },
        {
            field: 'address2',
            headerName: props.t('address')+' 2',
            minWidth: 160,
            align: "left",
            editable: true,
            renderEditCell: (params)=>{return <CustomEditComponent login={props.login} orig={params.row.address2} sql={"klde_adres2"} user={props.userId} reload={()=>{props.loadStores();}} {...params}/>}
        },
        {
            field: 'zip',
            headerName: props.t('zip'),
            width: 75,
            align: "left",
            editable: true,
            renderEditCell: (params)=>{return <CustomEditComponent login={props.login} orig={params.row.zip} sql={"klde_postcode"} user={props.userId} reload={()=>{props.loadStores();}} {...params}/>}
        },
        {
            field: 'city',
            headerName: props.t('city'),
            width: 120,
            align: "left",
            editable: true,
            renderEditCell: (params)=>{return <CustomEditComponent login={props.login} orig={params.row.city} sql={"klde_gemeente"} user={props.userId} reload={()=>{props.loadStores();}} {...params}/>}
        },
        {
            field: 'country',
            headerName: props.t('country'),
            width: 50,
            align: "left",
            editable: true,
            renderEditCell: (params)=>{return <CustomEditComponent login={props.login} orig={params.row.country} sql={"klde_landcode"} user={props.userId} reload={()=>{props.loadStores();}} {...params}/>}
        },
        {
            field: 'tel',
            headerName: props.t('phone'),
            width: 120,
            align: "left",
            editable: true,
            renderEditCell: (params)=>{return <CustomEditComponent login={props.login} orig={params.row.tel} sql={"klde_tel1"} user={props.userId} reload={()=>{props.loadStores();}} {...params}/>}
        },
        {
            field: 'tel2',
            headerName: props.t('phone')+' 2',
            width: 120,
            align: "left",
            editable: true,
            renderEditCell: (params)=>{return <CustomEditComponent login={props.login} orig={params.row.tel2} sql={"klde_tel2"} user={props.userId} reload={()=>{props.loadStores();}} {...params}/>}
        },
        {
            field: 'fax',
            headerName: props.t('fax'),
            width: 120,
            align: "left",
            editable: true,
            renderEditCell: (params)=>{return <CustomEditComponent login={props.login} orig={params.row.fax} sql={"klde_fax1"} user={props.userId} reload={()=>{props.loadStores();}} {...params}/>}
        },
        {
            field: 'fax2',
            headerName: props.t('fax')+' 2',
            width: 120,
            align: "left",
            editable: true,
            renderEditCell: (params)=>{return <CustomEditComponent login={props.login} orig={params.row.fax2} sql={"klde_fax2"} user={props.userId} reload={()=>{props.loadStores();}} {...params}/>}
        },
        {
            field: 'gsm',
            headerName: props.t('mobile'),
            minWidth: 120,
            align: "left",
            editable: true,
            renderEditCell: (params)=>{return <CustomEditComponent login={props.login} orig={params.row.gsm} sql={"klde_gsm"} user={props.userId} reload={()=>{props.loadStores();}} {...params}/>}
        },
        {
            field: 'email',
            headerName: props.t('email'),
            minWidth: 120,
            align: "left",
            editable: true,
            renderEditCell: (params)=>{return <CustomEditComponent login={props.login} orig={params.row.email} sql={"klde_email"} user={props.userId} reload={()=>{props.loadStores();}} {...params}/>}
        },
        {
            field: 'btw',
            headerName: props.t('tax'),
            minWidth: 120,
            align: "left",
            editable: true,
            renderEditCell: (params)=>{return <CustomEditComponent login={props.login} orig={params.row.btw} sql={"klde_btw"} user={props.userId} reload={()=>{props.loadStores();}} {...params}/>}
        },
        {
            field: 'divers',
            headerName: props.t('misc'),
            minWidth: 120,
            align: "left",
            editable: true,
            renderEditCell: (params)=>{return <CustomEditComponent login={props.login} orig={params.row.divers} sql={"klde_divers"} user={props.userId} reload={()=>{props.loadStores();}} {...params}/>}
        },
        {
            field: 'created_at',
            headerName: props.t('created_at'),
            minWidth: 120,
            align: "left",
        },
        {
            field: 'modified_at',
            headerName: props.t('updated_at'),
            minWidth: 120,
            align: "left",
        },
        
      ];
    const apiRef = useGridApiRef();
    
    
    useEffect(()=>{
        //console.log("adding subscriptions");
        try{
            apiRef.current.subscribeEvent(
                'columnOrderChange',
                ()=>{visibilityChangedHandler();},
            );
        }catch(ex){
    
        }
    }, [apiRef]);

    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        //console.log("cookie", cname + "=" + cvalue + ";" + expires + ";path=/");
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    const visibilityChangedHandler = () => {
        let temp = apiRef.current.exportState();
        if(typeof temp.columns.columnVisibilityModel === "undefined"){
            temp.columns.columnVisibilityModel = {};
        }
        try{
            temp.preferencePanel.open = false;
        }catch(e){
    
        }
        try{
            delete temp.pagination;
        }catch(e){
            console.error(e);
        }
        setCookie("initialStateStores", JSON.stringify(temp), 100000);
    }


    function DataGrid() {
        return (
          <div>
            <DataGridPro
                apiRef={apiRef}
                className={props.theme}
                initialState={props.initialGrid}
                columns={gridColumns}
                onColumnWidthChange={()=>{visibilityChangedHandler();}}
                onColumnVisibilityModelChange={()=>{visibilityChangedHandler();}}
                onPreferencePanelClose={()=>{visibilityChangedHandler();}}
                rows={props.data.filter(row => (row.cust_id?.match(new RegExp(searchValue, "i")) || row.name?.match(new RegExp(searchValue, "i")) || row.name2?.match(new RegExp(searchValue, "i")) || row.address?.match(new RegExp(searchValue, "i")) || row.address2?.match(new RegExp(searchValue, "i")) || row.email?.match(new RegExp(searchValue, "i")) || row.gsm?.match(new RegExp(searchValue, "i")) || row.tel?.match(new RegExp(searchValue, "i")) || row.tel2?.match(new RegExp(searchValue, "i"))))
                .map((row, index) => {return {
                        id: row.id,
                        custId: row.cust_id,
                        name: row.name,
                        name2: row.name2,
                        address: row.address,
                        address2: row.address2,
                        zip: row.zip,
                        city: row.city,
                        country: row.country,
                        tel: row.tel,
                        tel2: row.tel2,
                        fax: row.fax,
                        fax2: row.fax2,
                        gsm: row.gsm,
                        email: row.email,
                        btw: row.btw,
                        divers: row.divers,
                        created_at: row.created_at,
                        modified_at: row.modified_at
                    }
                })}
                rowHeight={38}
                style={{maxHeight: 500}}
                pagination={true}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 20, 30]}
                />
          </div>
        );
    }
    
    return (
        <div>
            <div className='log__container_options'>
                <TextField
                id="input-with-icon-textfield"
                autoComplete="off" 
                className={props.theme+ '_accent margin_right__10px'}
                sx={{ borderRadius: 3, marginTop: 0 }}
                size={'small'}
                fullWidth
                type="search"
                placeholder={props.t('search')}
                onChange={(e) => setSearchValue(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{color: Colors.sft_blue}} />
                    </InputAdornment>
                  ),
                }}
               />
            </div>

            
            {DataGrid()}
        </div>
    );
}

export default StoresTable;
